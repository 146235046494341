import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/ared-logo.svg'
import _imports_1 from '../../assets/auth-right-bg.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute top-14 mt-20 text-center lg:block hidden" }, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "w-[8rem]",
        alt: "right view"
      }),
      _createElementVNode("h6", { class: "text-white tracking-widest text-[19px] mt-2 font-['Montserrat']" }, "Look Inward")
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: _imports_1,
      alt: "right view",
      class: "w-full auth-object-bg lg:block hidden"
    }, null, -1))
  ], 64))
}