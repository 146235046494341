<template>
  <DashboardHeader title="Dashboard" :icon="icon.dashboard">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium text-[0.8rem] text-[#959CBD]"
          >Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span
        >
        <img
          src="../../assets/today.png"
          alt="africa"
          class="w-[0.8rem] h-[0.8rem] rounded ml-1"
        />
      </div>
    </template>
  </DashboardHeader>

  <div
    class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative"
  >
    <!-- tabs -->
    <div class="tabs-block" id="tabs-block">
      <div class="mb-3">
        <ul
          class="flex flex-wrap text-sm font-medium text-center mb-2"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          <li class="mr-2">
            <button
              class="inline-block p-4 py-2 rounded-md w-full"
              id="tab3"
              data-tabs-target="#all"
              type="button"
              role="tab"
              aria-controls="all"
              aria-selected="false"
              v-on:click="handleSetactiveTab('all')"
              v-bind:class="[
                activeTab === 'all'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]"
            >
              All
            </button>
          </li>

          <li class="mr-2">
            <button
              class="inline-block p-4 py-2 rounded-md w-full"
              id="tab2"
              data-tabs-target="#routerlocations"
              type="button"
              role="tab"
              aria-controls="routerlocations"
              aria-selected="false"
              v-on:click="handleSetactiveTab('router locations')"
              v-bind:class="[
                activeTab === 'router locations'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]"
            >
              Router Locations
            </button>
          </li>
          <li class="mr-2">
            <button
              class="inline-block p-4 py-2 rounded-md w-full"
              id="tab1"
              data-tabs-target="#kiosklocations"
              type="button"
              role="tab"
              aria-controls="kiosklocations"
              aria-selected="false"
              v-on:click="handleSetactiveTab('kiosk locations')"
              v-bind:class="[
                activeTab === 'kiosk locations'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]"
            >
              Kiosk Locations
            </button>
          </li>
        </ul>
      </div>
      <div id="myTabContent">
        <div id="tab1" role="tabpanel" aria-labelledby="profile-tab">
          <div class="row">
            <div class="col-md-12 animated slideInUp">
              <div class="card-body">
                <div class="w-full h-[500px] mt-6" id="map"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import moment from 'moment';
import mapboxgl from 'mapbox-gl';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import axios from '../../axios-interceptor';
import { Router } from '@/interfaces/router';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import {
  routerTableHeaders as _routerTableHeaders,
  routerTableIcons as _routerTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';

export default defineComponent({
  name: 'locationsClusterPage',
  components: {
    DashboardHeader,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Serial Number');

    const routers = ref<Router[]>([]);
    const routerTableHeaders = ref([..._routerTableHeaders]);
    const routerTableIcons = ref([..._routerTableIcons]);

    const accessToken = ref(
      'pk.eyJ1IjoiZ3MwMWhhbiIsImEiOiJjbGtjamU3dmEwN2pwM2pxam9taGZ0cTJ6In0.8_zydJrYKh6734GskrBRqA'
    );
    const fetchingRouters = ref(false);

    const mapStyle = ref({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [12.550343, 55.665957],
      zoom: 12,
    });

    return {
      routers,
      routerTableHeaders,
      routerTableIcons,
      tableName,
      icon,
      accessToken,
      fetchingRouters,
      mapStyle,
    };
  },

  data() {
    return {
      activeTab: 'all',
      realms: [],
      currentPage: 'All Devices',
      partnerId: '',
      plan: '',

      devices: [
        {
          has_kiosk: '',
        },
      ],
      filteredDevices: [
        {
          has_kiosk: '',
        },
      ],
      deviceType: 'routers',
    };
  },

  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },
  mounted() {
    this.partnerId = this.loggedInUser.partner.id;
    this.plan = this.loggedInUser.partner?.plan;
    this.fetchRouters();
  },
  methods: {
    async handleSetactiveTab(tab: string) {
      this.activeTab = tab;
      if (this.activeTab == 'all') {
        this.currentPage = 'All Devices';
        this.deviceType = 'all';
        await this.fetchRouters();
        this.filterRouters(this.deviceType);
      } else if (this.activeTab == 'router locations') {
        this.currentPage = 'Router Location Cluster';
        this.deviceType = 'routers';
        this.filterRouters('routers');
      } else if (this.activeTab == 'kiosk locations') {
        this.currentPage = 'Kiosk Location Cluster';
        this.deviceType = 'kiosks';
        this.filterRouters('kiosks');
      }
    },
    async getAllRouters() {
      try {
        await axios
          .get(`routers/?partner=${this.partnerId}`)
          .then((response) => {
            if (response.status === 200) {
              this.devices = response.data.results;
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    // TODO: dynamically call endpoint
    // ? should probably use unpaginated endpoint for these queries
    filterRouters(deviceType: string) {
      if (deviceType == 'routers') {
        this.filteredDevices = this.devices.filter(
          (device) => !device.has_kiosk
        );
      } else if (deviceType == 'kiosks') {
        this.filteredDevices = this.devices.filter(
          (device) => device.has_kiosk
        );
      } else {
        this.filteredDevices = this.devices;
      }
    },
    fetchRouters() {
      this.fetchingRouters = true;

      axios
        .get(`routers/?partner=${this.partnerId}`)
        .then((response: { data: any }) => {
          this.routers = response.data.results as Router[];
          this.fetchingRouters = false;

          // Filter and get the first 100 valid routers (with lat and lon available)
          const validRouters = this.routers
            .filter((router) => router.lat && router.lon)
            .slice(0, 1000);

          if (validRouters.length) {
            this.initializeMap(validRouters, true);
          } else {
            this.initializeMap([], false);
          }
        })
        .catch(() => {
          this.fetchingRouters = false;
        });
    },

    initializeMap(routers: Router[], markLocation: boolean) {
      mapboxgl.accessToken = this.accessToken;

      const map = new mapboxgl.Map(this.mapStyle);

      if (routers.length && markLocation) {
        // Calculate clusters and add them to the map as a source
        map.on('load', function () {
          map.addSource('clusters', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: routers.map((router) => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [router.lon, router.lat],
                },
                properties: {
                  id: router.id,
                  ip_address: router.ip_address,
                  serial_number: router.serial_number,
                  location_name: router.location_name,
                },
              })),
            },
            cluster: true,
            clusterMaxZoom: 15, // Maximum zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (pixels)
          });

          // Add cluster layer
          map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'clusters',
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': [
                'step',
                ['get', 'point_count'],
                '#51bbd6',
                10,
                '#f1f075',
                100,
                '#f28cb1',
              ],
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                10,
                30,
                100,
                40,
              ],
            },
          });

          // Add cluster count layer
          map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'clusters',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            },
          });

          // Add unclustered points layer
          map.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'clusters',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#11b4da',
              'circle-radius': 8,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#fff',
            },
          });

          // Add click event for clusters
          map.on('click', 'clusters', function (e: any) {
            const features = map.queryRenderedFeatures(e.point, {
              layers: ['clusters'],
            });
            const clusterId = features[0].properties.cluster_id;

            map
              .getSource('clusters')
              .getClusterExpansionZoom(
                clusterId,
                function (err: any, zoom: number) {
                  if (err) return;

                  map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom: zoom,
                  });
                }
              );

            const source = map.getSource('clusters');

            source.getClusterLeaves(
              clusterId,
              Infinity,
              0,
              function (err: any, leafFeatures: any[]) {
                if (err) return;

                if (leafFeatures.length > 0) {
                  const popup = new mapboxgl.Popup()
                    .setLngLat(features[0].geometry.coordinates)
                    .setHTML(generatePopupHTML(leafFeatures))
                    .addTo(map);
                }
              }
            );
          });

          // Show popup for individual points
          map.on('click', 'unclustered-point', function (e: any) {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const properties = e.features[0].properties;
            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(
                `<p>Serial No: ${properties.serial_number} - IP Address: ${properties.ip_address}</p>`
              )
              .addTo(map);
          });

          // Function to generate popup HTML for clustered points
          function generatePopupHTML(features: any[]) {
            let html = '<div>';

            features.forEach((feature) => {
              const properties = feature.properties;
              html += `<p>Serial No: ${properties.serial_number} - IP Address: ${properties.ip_address}</p>`;
            });

            html += '</div>';
            return html;
          }
        });
      }

      // If there are routers, calculate the average center to set the map center
      if (routers.length) {
        const centerLngLat = routers.reduce(
          (acc, router) => [acc[0] + router.lon, acc[1] + router.lat],
          [0, 0]
        );
        const averageLngLat = [
          centerLngLat[0] / routers.length,
          centerLngLat[1] / routers.length,
        ];

        map.setCenter(averageLngLat);
      } else {
        // Set a default center if no routers are available
        map.setCenter([30.0557, -1.9397]);
      }

      // Optionally, set a specific zoom level to fit all the markers within the map view
      map.setZoom(8); // Adjust the zoom level as needed to fit the markers nicely
    },
  },
});
</script>
<style scoped>
.map {
  width: 100%;
  height: 30rem;
}
</style>
