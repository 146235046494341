<template>
  <div class="absolute top-14 mt-20 text-center lg:block hidden">
    <img src="../../assets/ared-logo.svg" class="w-[8rem]" alt="right view" />
    <h6 class="text-white tracking-widest text-[19px] mt-2 font-['Montserrat']">Look Inward</h6>
  </div>
  <img src="../../assets/auth-right-bg.svg" alt="right view" class="w-full auth-object-bg lg:block hidden" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AuthRightImage',
});
</script>
