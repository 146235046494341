import instance from '@/axios-interceptor';
import {  Package } from '@/store/types/billing';

export default {
  async fetchBillings({ commit }: any, partner: number) {
    const response = await instance.get(`partner/billing/?partner=${partner}`);

    commit('setBillings', response.data);
  },
  async fetchSMSBillings({ commit }: any, partner: number) {
    const response = await instance.get(`partner/sms-package/billing/?partner=${partner}`);
    commit('setSMSBillings', response.data);
  },
  async fetchPayments({ commit }: any, partner: number) {
    const response = await instance.get(
      `partner/billing/payment/?partner=${partner}`
    );
    commit('setPayments', response.data);
  },
  async fetchCustomers({ commit }: any, partner: number) {
    const response = await instance.get(
      `smes/?partner=${partner}`
    );
    commit('setCustomers', response.data);
  },
  async getPackages({ commit }: any) {
    const packages = await instance.get(`partner/package/`);
    commit('setPackages', packages.data as unknown as Package[]);
  },
  async payPackage(
    { commit }: any,
    payload: {
      sme: number;
      payment_method: string;
      package: number;
    }
  ) {
    await instance.post(`partner/billing/`, payload);
  },
  async approvePackage(
    { commit }: any,
    payload: {
      bill: number;
      partner: number;
    }
  ) {
    await instance.post(`partner/subscription/`, payload);
  },
  async getMonthlyRevenues({ commit }: any, payload: number) {
    const response = await instance.get(
      `partner/revenue/${localStorage.getItem('partnerId')}`
    );
    commit('setMonthlyRevenues', response.data.revenues);
  },
  async calculateMonthlyRevenues() {
    await instance.get(
      `partner/revenue/${localStorage.getItem('partnerId')}/calculate/`
    );
  },
};
