import axios from 'axios';

let apiBaseUrl: string;

switch (process.env.VUE_APP_ENV) {
  case 'dev':
    apiBaseUrl = process.env.VUE_APP_DEV_API_BASE_URL;
    break;
  case 'qa':
    apiBaseUrl = process.env.VUE_APP_QA_API_BASE_URL;
    break;
  case 'uat':
    apiBaseUrl = process.env.VUE_APP_UAT_API_BASE_URL;
    break;
  case 'prod':
    apiBaseUrl = process.env.VUE_APP_PROD_API_BASE_URL;
    break;
  default:
    apiBaseUrl = 'http://localhost:8000/api/';
    // apiBaseUrl = 'https://app-ared-api-dev-south-africa-001.shirikihub.com/api/';

}

const API_URL = axios.create({
  baseURL: `${apiBaseUrl}v1/`,
});

API_URL.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

export { API_URL, apiBaseUrl };
