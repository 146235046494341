<template>
  <div
    class="bg-white w-full rounded-[0.938rem] md:p-6 p-4 shadow overflow-auto relative my-6"
  >
    <h3
      class="text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
    >
      Add Survey
    </h3>
    <form class="">
      <div class="grid md:grid-cols-3 sm:gap-3 gap-1">
        <div class="mb-2">
          <label
            for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
            >Survey Name:</label
          >
          <input
            placeholder="Title"
            v-model="title"
            required
            type="text"
            id="title"
            class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
          />
        </div>

        <div class="mb-2">
          <label
            for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
            >Realms:</label
          >
          <select
            class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
            id="small-input"
            v-model="realm"
            required
          >
            <option value="">Select Realm</option>
            <option
              v-for="realmItem in realmslist"
              :key="realmItem.id"
              :value="realmItem.id"
            >
              {{ realmItem.name }}
            </option>
          </select>
        </div>
        <div class="mb-2">
          <label
            for="small-input"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
            >Is Enabled :</label
          >

          <select
            id="enabled"
            type="name"
            class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
            v-model="enabled"
          >
            <option value="" disabled>Select Status</option>
            <option value="true">Yes</option>

            <option value="false">No</option>
          </select>
        </div>
      </div>
      <div class="w-full flex lg:justify-end sm:justify-end items-center mt-3">
        <button
          @click="addSurvey"
          type="button"
          class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium border mr-2 flex items-center justify-center"
        >
          Add Survey
          <span
            v-if="isLoader"
            :class="{ 'spinner btn-spinner ml-2': isLoader }"
          >
          </span>
        </button>
        <button
          @click="cancelAddSurvey"
          class="bg-violet-100 font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import instance from '@/axios-interceptor';
import router from '@/router';

export default {
  name: 'surveyAddEditPage',
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoader: false,
      realmslist: [
        {
          id: '',
          name: '',
        },
      ],

      title: '',
      enabled: '',
      realm: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  mounted() {
    this.getRealms();
  },
  methods: {
    cancelAddSurvey() {
      router.push({ name: 'surveys' });
    },

    getRealms() {
      instance
        .get(`realms/?partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          this.realmslist = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async addSurvey() {
      let data = {
        title: this.title,
        realm: this.realm,
        enabled: this.enabled,
      };
      this.isLoader = true;
      await instance
        .post(`surveys/`, data)
        .then((response) => {
          this.isLoader = false;
          if (response.status === 201) {
            this.$toast.success('Surveys added Sucessfully', {
              position: 'top-right',
            });
            router.push('/surveys');
          }
        })
        .catch((error) => {
          this.isLoader = false;
          console.log(error);
        });
    },
  },
};
</script>
