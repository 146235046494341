<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl max-h-[85vh] overflow-y-auto">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3 class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
            Add Food or Drink Menu
          </h3>
          <form>
            <div class="mb-4">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Realm *</label>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="realm" v-model="realm" required>
                <option value="">Select Realm</option>
                <option v-for="realmItem in realmslist" :key="realmItem.id" :value="realmItem.id">
                  {{ realmItem.name }}
                </option>
              </select>
              <small v-if="missingFields.includes('Realm')" class="text-[#f24236] mt-2">
                Realm field is required
              </small>
            </div>

            <div class="mb-4">
              <div class="flex justify-between">
                <label class="inline-flex items-center space-x-2">
                  <input type="checkbox" v-model="foodChecked" @change="handleCheckboxChange('foodChecked')"
                    :disabled="foodDrinksChecked" class="form-checkbox text-indigo-600 h-5 w-5" />
                  <span class="text-gray-700">Food Menu</span>
                </label>
                <label class="inline-flex items-center space-x-2">
                  <input type="checkbox" v-model="drinksChecked" @change="handleCheckboxChange('drinksChecked')"
                    :disabled="foodDrinksChecked" class="form-checkbox text-indigo-600 h-5 w-5" />
                  <span class="text-gray-700">Drinks Menu</span>
                </label>
                <label class="inline-flex items-center space-x-2">
                  <input type="checkbox" v-model="foodDrinksChecked" @change="handleCheckboxChange('foodDrinksChecked')"
                    :disabled="foodChecked || drinksChecked" class="form-checkbox text-indigo-600 h-5 w-5" />
                  <span class="text-gray-700">Food & Drinks Menu</span>
                </label>
              </div>
              <small v-if="missingFields.includes('Menu Type')" class="text-[#f24236] mt-2">
                Menu Type field is required
              </small>
            </div>

            <div class="mb-6" v-if="foodChecked && !foodDrinksChecked">
              <label for="small-input" class="block mb-2 text-sm font-medium text-gray-90">Food Menu File *</label>
              <p class="text-xs mb-4 text-[#424242]">
                Upload a PDF file of the food menu that will be accessed or
                downloaded
              </p>
              <input id="file" ref="foodMenuFile" type="file" accept="application/pdf"
                placeholder="Choose a file or drop it here..."
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
              <small v-if="missingFields.includes('Food Menu File')" class="text-[#f24236] mt-2">
                Food Menu File is required
              </small>
            </div>

            <div class="mb-6" v-if="drinksChecked && !foodDrinksChecked">
              <label for="small-input" class="block mb-2 text-sm font-medium text-gray-90">Drinks Menu File *</label>
              <p class="text-xs mb-4 text-[#424242]">
                Upload a PDF file of the drinks menu that will be accessed or
                downloaded
              </p>
              <input id="file" ref="drinksMenuFile" type="file" accept="application/pdf"
                placeholder="Choose a file or drop it here..."
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
              <small v-if="missingFields.includes('Drinks Menu File')" class="text-[#f24236] mt-2">
                Drinks Menu File is required
              </small>
            </div>

            <div class="mb-6" v-if="foodDrinksChecked">
              <label for="small-input" class="block mb-2 text-sm font-medium text-gray-90">Food & Drinks Menu File
                *</label>
              <p class="text-xs mb-4 text-[#424242]">
                Upload a PDF file of the food & drinks menu that will be
                accessed or downloaded
              </p>
              <input id="file" ref="foodDrinksMenuFile" type="file" accept="application/pdf"
                placeholder="Choose a file or drop it here..."
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
              <small v-if="missingFields.includes('Food & Drinks Menu File')" class="text-[#f24236] mt-2">
                Food & Drinks Menu File is required
              </small>
            </div>

            <div class="w-full bg-[#424242] dark:bg-neutral-600" v-if="loading">
              <div class="bg-primary p-0.5 text-center text-xs font-medium leading-none text-white animated"
                :style="{ width: `${uploadProgress}%` }">
                {{ uploadProgress }}/%
              </div>
            </div>

            <div class="w-full flex lg:justify-end justify-start items-center mt-3">
              <button @click="uploadMenu" type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                Upload
                <span v-if="loading" :class="{ 'spinner btn-spinner ml-2': loading }">
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import axios from '@/axios-interceptor';

import { apiBaseUrl } from '../../config';

interface XMRParams {
  url: string;
  token: string;
  formData: FormData;
  totalMenus: number;
  currentMenu: number;
}

export default defineComponent({
  name: 'uploadMenu',
  data() {
    return {
      loading: false,
      uploadModal: false,
      showCategoryModal: false,
      realmslist: [
        {
          id: '',
          name: '',
        },
      ],
      categorylist: [
        {
          id: '',
          name: '',
        },
      ],
      category: '',
      thumbnail: null,
      type: '',
      realm: '',
      status: 'Enabled',
      uploadProgress: 0,
      thumbnailFile: {} as File,
      missingFields: [] as string[],
      foodChecked: false,
      drinksChecked: false,
      foodDrinksChecked: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'accessToken']),
    menuType() {
      if (this.foodChecked && this.drinksChecked) {
        return '2 menus';
      } else if (this.foodChecked) {
        return 'food';
      } else if (this.drinksChecked) {
        return 'drinks';
      }

      return 'food_&_drinks';
    },
    menuFile() {
      const foodMenuFileRef = this.$refs.foodMenuFile as any;
      const foodMenuFile = foodMenuFileRef?.files[0];

      const drinksMenuFileRef = this.$refs.drinksMenuFile as any;
      const drinksMenuFile = drinksMenuFileRef?.files[0];

      const foodDrinksMenuFileRef = this.$refs.foodDrinksMenuFile as any;
      const foodDrinksMenuFile = foodDrinksMenuFileRef?.files[0];

      if (this.foodChecked) {
        return foodMenuFile;
      } else if (this.drinksChecked) {
        return drinksMenuFile;
      }

      return foodDrinksMenuFile;
    },
    menuFileName() {
      const foodMenuFileRef = this.$refs.foodMenuFile as any;
      const foodMenuFile = foodMenuFileRef?.files[0];

      const drinksMenuFileRef = this.$refs.drinksMenuFile as any;
      const drinksMenuFile = drinksMenuFileRef?.files[0];

      const foodDrinksMenuFileRef = this.$refs.foodDrinksMenuFile as any;
      const foodDrinksMenuFile = foodDrinksMenuFileRef?.files[0];

      if (this.foodChecked) {
        return foodMenuFile.name;
      } else if (this.drinksChecked) {
        return drinksMenuFile.name;
      }

      return foodDrinksMenuFile.name;
    },
  },
  mounted() {
    this.getRealms();
    this.getCategories();
  },
  methods: {
    handleCheckboxChange(checkbox: string) {
      if (checkbox === 'foodDrinksChecked' && this.foodDrinksChecked) {
        this.foodChecked = false;
        this.drinksChecked = false;
      }
    },
    getRealms() {
      axios
        .get(`realms/?partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          this.realmslist = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    closeCategoryModal() {
      this.showCategoryModal = false;
      this.getCategories();
    },

    getCategories() {
      axios
        .get(`categories/?realm__partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          this.categorylist = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async uploadMenu() {
      const foodMenuFileRef = this.$refs.foodMenuFile as any;
      const foodMenuFile = foodMenuFileRef?.files[0];

      const drinksMenuFileRef = this.$refs.drinksMenuFile as any;
      const drinksMenuFile = drinksMenuFileRef?.files[0];

      const foodDrinksMenuFileRef = this.$refs.foodDrinksMenuFile as any;
      const foodDrinksMenuFile = foodDrinksMenuFileRef?.files[0];

      if (!this.realm) {
        this.missingFields.push('Realm');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Realm'
        );
      }

      if (!this.foodChecked && !this.drinksChecked && !this.foodDrinksChecked) {
        this.missingFields.push('Menu Type');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Menu Type'
        );
      }

      if (!foodMenuFile && this.foodChecked) {
        this.missingFields.push('Food Menu File');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Food Menu File'
        );
      }

      if (!drinksMenuFile && this.drinksChecked) {
        this.missingFields.push('Drinks Menu File');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Drinks Menu File'
        );
      }

      if (!foodDrinksMenuFile && this.foodDrinksChecked) {
        this.missingFields.push('Food & Drinks Menu File');
      } else {
        this.missingFields = this.missingFields.filter(
          (field) => field !== 'Food & Drinks Menu File'
        );
      }

      if (this.missingFields.length > 0) {
        return this.$toast.warning('Provide the missing fields', {
          position: 'top-right',
        });
      }

      const formData = new FormData();

      const url = `${apiBaseUrl}v1/food-drink-menu/`;
      const token = this.accessToken;
      let totalMenus, currentMenu;

      if (this.foodChecked && this.drinksChecked) {
        totalMenus = 2;

        for (currentMenu = 1; currentMenu <= totalMenus; currentMenu++) {
          formData.append(
            'file',
            currentMenu === 1 ? foodMenuFile : drinksMenuFile
          );
          formData.append('realm', this.realm);
          formData.append('menu_type', currentMenu === 1 ? 'food' : 'drinks');
          formData.append(
            'original_file_name',
            currentMenu === 1 ? foodMenuFile.name : drinksMenuFile.name
          );

          this.uploadWithXMR({ url, token, formData, totalMenus, currentMenu });
        }
      } else {
        totalMenus = 1;
        currentMenu = 1;

        formData.append('file', this.menuFile);
        formData.append('realm', this.realm);
        formData.append('menu_type', this.menuType);
        formData.append('original_file_name', this.menuFileName);

        this.uploadWithXMR({ url, token, formData, totalMenus, currentMenu });
      }
    },
    uploadWithXMR(params: XMRParams) {
      const { url, token, formData, totalMenus, currentMenu } = params;

      const xhr = new XMLHttpRequest();

      this.loading = true;

      xhr.open('POST', url, true);

      xhr.setRequestHeader('Authorization', `Bearer ${token}`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          // Update the progress value and display it to the user
          this.uploadProgress = progress;
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          // Request completed successfully

          if (totalMenus === 2 && currentMenu === 1) {
            this.$toast.success('Food Menu uploaded successfully', {
              position: 'top-right',
            });
          } else if (totalMenus === 2 && currentMenu === totalMenus) {
            this.$toast.success('Drinks Menu uploaded successfully', {
              position: 'top-right',
            });
          } else {
            this.$toast.success('Menu uploaded successfully', {
              position: 'top-right',
            });
          }

          this.uploadProgress = 0;

          if (currentMenu === totalMenus) {
            this.loading = false;
            this.$emit('close');
          }
        } else {
          // Request completed with an error
          this.loading = false;
          const response = JSON.parse(xhr.responseText);
          this.$toast.error(response.error, {
            position: 'top-right',
          });
          this.uploadProgress = 0;
        }
      };

      xhr.onerror = () => {
        if (xhr.status === 0) {
          this.$toast.error('Request failed. Check your internet connection.', {
            position: 'top-right',
          });
          this.loading = false;
          this.uploadProgress = 0;
        } else {
          this.loading = false;
          this.uploadProgress = 0;
          this.$toast.error('An error occurred during the upload', {
            position: 'top-right',
          });
        }
      };

      xhr.send(formData);
    },
  },
});
</script>
