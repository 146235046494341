import axios from '@/axios-interceptor';
import router from '@/router';
import { useToast } from 'vue-toast-notification';

const state: any = {
  refresh_token: '',
  access_token: '',
  loggedInUser: {},
  isAuthenticated: false,
  hasReceivedOTP: false,
  hasFetchedUserInfo: false,
  loginBtn: {
    text: 'Log In',
    disabled: false,
  },
};

const $toast = useToast();

const mutations: any = {
  setLoggedInUser: function (state: any, user: any) {
    state.loggedInUser = user;
  },
  setAuthenticated: function (state: any) {
    state.isAuthenticated = !state.isAuthenticated;
  },
  setHasReceivedOTP: function (state: any, payload: boolean) {
    state.hasReceivedOTP = payload;
  },
  setHasFetchedUserInfo: function (state: any, payload: boolean) {
    state.hasFetchedUserInfo = payload;
  },
  setLoginBtn(state: any, payload: any) {
    state.loginBtn.text = payload.text;
    state.loginBtn.disabled = payload.text !== 'Log In' ? true : false;
  },
  setRefreshToken: function (state: any, refreshToken: string) {
    state.refresh_token = refreshToken;
  },
  setAccessToken: function (state: any, accessToken: string) {
    state.access_token = accessToken;
  },
  clearUserData: function (state: any) {
    state.refresh_token = '';
    state.access_token = '';
    state.loggedInUser = {};
    state.isAuthenticated = false;
    state.hasReceivedOTP = false;
    state.hasFetchedUserInfo = false;
  },
};

const actions: any = {
  fetchUser: async ({ commit, state }: any) => {
    try {
      await axios.get(`auth/users/me/`).then((response) => {
        const user = response.data;

        if (user.role === 'partner_admin' || user.role === 'partner_staff') {
          if (!user.changed_password_after_initial_login) {
            if (state.isAuthenticated) {
              localStorage.clear();
              commit('clearUserData');
              router.push({ path: '/' });
              window.location.reload();
              $toast.info('You may login again', {
                position: 'top-right',
                duration: 8000,
              });
            } else {
              commit('setAuthenticated');
              router.replace('/change-password');
            }
          } else {
            commit('setLoggedInUser', response.data);
            commit('setHasFetchedUserInfo', true);
            localStorage.setItem('partnerId', response.data.partner.id);
            localStorage.setItem('activeId', `${0}`);
          }
        } else {
          commit('clearUserData');
          commit('setLoginBtn', { text: 'Log In' });

          $toast.error('No partner account associated with this user', {
            position: 'top-right',
          });

          router.replace('/');
        }
      });
    } catch (e: any) {
      console.log(e);
    }
  },
  refreshToken: async ({ state, commit }: any) => {
    const refreshUrl = 'auth/jwt/refresh/';
    try {
      await axios
        .post(refreshUrl, { refresh: state.refresh_token })
        .then((response) => {
          if (response.status === 200) {
            commit('setAccessToken', response.data.access);
          }
        });
    } catch (e: any) {
      if (e.response.status === 401) {
        $toast.info('Your session has expired. Login again', {
          position: 'top-right',
        });
      } else {
        console.log(e.response);
      }
    }
  },
};

const getters: any = {
  loggedInUser: (state: any) => state.loggedInUser,
  authError: (state: any) => state.authError,
  isAuthenticated: (state: any) => state.isAuthenticated,
  loginBtn: (state: any) => state.loginBtn,
  accessToken: (state: any) => state.access_token,
  refreshToken: (state: any) => state.refresh_token,
  hasReceivedOTP: (state: any) => state.hasReceivedOTP,
  hasFetchedUserInfo: (state: any) => state.hasFetchedUserInfo,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
