<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3
            class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
          >
            {{ submit }}
          </h3>
          <form>
            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Realm Type</label
              >
              <select
                type="text"
                required
                name="realm-type"
                aria-describedby="version-label"
                id="realmType"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                v-model="type"
              >
                <option value="">Select Realm Type</option>
                <option value="partner_realm">My Realm</option>
                <option value="sme_realm">SME Realm</option>
              </select>
            </div>
            <div class="mb-2" v-if="type === 'sme_realm'">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Realms:</label
              >
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                v-model="sme"
                type="text"
                required
                name="realm-sme"
                aria-describedby="version-label"
                id="realmSme"
              >
                <option value="">Select Realm Sme</option>
                <option
                  v-for="sme in smes"
                  :value="realm_type === 'partner_realm' ? '' : sme.id"
                  :key="sme.id"
                >
                  {{ sme.name }}
                </option>
              </select>
            </div>

            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              >
                Real Name:</label
              >
              <input
                placeholder=" Name"
                v-model="name"
                type="text"
                required
                name="name"
                aria-describedby="version-label"
                id="realm-name"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
              />
            </div>

            <div
              class="w-full flex lg:justify-end justify-start items-center mt-3"
            >
              <button
                @click="addRealm"
                type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center"
              >
                Add Realm<span
                  v-if="isLoader"
                  :class="{ 'spinner btn-spinner ml-2': isLoader }"
                >
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import instance from '@/axios-interceptor';

export default {
  name: 'addrealmForm',
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoader: false,
      smes: [
        {
          id: '',
          name: '',
        },
      ],
      name: '',
      price: 15,
      type: '',
      sme: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  mounted() {
    this.getSmes();
  },
  methods: {
    getSmes() {
      instance
        .get(`smes/?partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          this.smes = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async addRealm() {
      if (this.name) {
        let data;
        if (this.sme) {
          data = {
            sme: this.sme,
            name: this.name,
          };
        } else {
          data = {
            name: this.name,

            partner: this.loggedInUser.partner.id,
          };
        }
        this.isLoader = true;

        await instance
          .post(`realms/`, data)
          .then((response) => {
            this.isLoader = false;
            this.$emit('close');
            if (response.status === 201) {
              if (response.data.detail) {
                this.$toast.success(response.data.detail, {
                  position: 'top-right',
                });
              } else {
                this.$toast.success('Realm Added Successfully', {
                  position: 'top-right',
                });
              }
            }
          })
          .catch((error) => {
            this.isLoader = false;
            this.$emit('close');
            console.log(error);
          });
      } else {
        return false;
      }
    },
  },
};
</script>
