<template>
  <div class="top-0 z-20">
    <div class="bg-white max-h-[4.188rem] flex py-3 fixed w-full">
      <div class="lg:w-[8vw] w-[60px] md:flex items-center justify-center">
        <img src="../../assets/ared-africa.png" alt="africa" class="w-[3.188rem]" />
      </div>
      <div class="flex w-full justify-between items-center lg:px-[40px] pr-[20px] pl-2">
        <button type="button"
          class="lg:min-w-[30rem] sm:min-w-[15rem] min-w-[12rem] lg:h-[2.938rem] h-[2rem] bg-light rounded-md text-primary font-poppins font-semibold lg:text-[1.062rem] sm:text-[0.8rem] text-[0.7rem]">
          {{ loggedInUser.partner?.name }} on
          {{ loggedInUser.partner?.plan?.name }} Plan
        </button>

        <div class="w-1/3 bg-[#f24236] flex justify-center ml-6 rounded-md">
          <p class="text-white text-sm py-3" v-if="offlineRouters.length === 1">
            {{ offlineRouters.length }} router/box is offline. <router-link to="/routers" class="underline">Click
              here to see
            </router-link>
          </p>
          <p class="text-white text-sm py-3" v-if="offlineRouters.length > 1">
            {{ offlineRouters.length }} routers/boxes are offline. <router-link to="/routers" class="underline">Click
              here to see
            </router-link>
          </p>
        </div>

        <div class="min-w-[10vw] flex items-center justify-between relative md:flex ml-auto mr-[20px]">
          <button class="flex flex-row items-center z-10" @click.stop="handleOpenOrCloseModal">
            <div class="flex flex-col items-end justify-end mr-2">
              <span class="text-textLight font-medium text-[0.75rem] font-poppins">Welcome</span>
              <span class="font-semibold text-[0.75rem] font-poppins text-black">{{ loggedInUser.full_name }}
              </span>
            </div>
            <img :src="loggedInUser.profile_pic
              ? loggedInUser.profile_pic
              : require('../../assets/user-avatar.svg')
              " alt="africa" class="w-[2rem] h-[2rem] rounded-[1rem]" />
          </button>
          <div v-if="isOpenModal"
            class="z-10 absolute w-[10vw] min-h-[5vh] bg-white shadow top-[3.188rem] right-0 items-left justify-end">
            <button @click="profile"
              class="px-4 py-2 hover:bg-light hover:text-primary border-b-1 text-left w-full font-bold text-[0.75rem] font-poppins">
              My Profile
            </button>
            <button @click.stop="handleLogout"
              class="px-4 py-2 hover:bg-light hover:text-primary w-full text-left font-bold text-[0.75rem] font-poppins">
              Sign Out
            </button>
          </div>
        </div>
        <div id="close-btn" class="mt-2 flex items-center justify-between relative lg:hidden toggle-btn"
          @click.stop="onToggle">
          <span @click="toggleActive" :class="[isToggleicon ? 'open' : 'close']"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import axios from 'axios';
import router from '@/router';
import { mapGetters, mapMutations } from 'vuex';
import { defineComponent, PropType, ref } from 'vue';

import { Router } from '@/interfaces/router';
import instance from '@/axios-interceptor';
import { User } from '../../helpers/types/user';

export default defineComponent({
  name: 'DashboardTopNav',

  props: {
    isOpenModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    companyLabel: {
      type: String as PropType<string>,
      default: 'RESTAURANT 1',
    },
    user: {
      type: Object as PropType<User>,
      default: () => {
        return {
          name: `${process.env.VUE_APP_TEST_USER_FIRST_NAME} ${process.env.VUE_APP_TEST_USER_LAST_NAME}`,
          country: `${process.env.VUE_APP_TEST_USER_COUNTRY}`,
          avatar: `${process.env.VUE_APP_TEST_USER_AVATAR}`,
        };
      },
    },
  },

  setup() {
    const flag = ref<string>('');
    const isToggleicon = ref(false);
    return {
      flag,
      isToggleicon,
    };
  },
  data() {
    return {
      selectedLanguage: 'en',
      full_name: '',
      profile_pic: require('../../assets/avatar.png'),
      zabbixUserName: 'Admin',
      zabbixPassword: 'wizzdev2021',
      zabbixAuthenticationToken: '',
      zabbixBaseUri: 'https://zabbix.shirikihub.com/zabbix/',
      zabbixApiEndpoint: 'api_jsonrpc.php',
      routerList: [] as Router[],
      onlineRouters: [] as any,
      offlineRouters: [] as any,
      filteredRouters: [
        {
          host: '',
          id: '',
          hostid: '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapMutations(['setAuthenticated']),
    handleLogout() {
      this.setAuthenticated();
      localStorage.clear();
      router.push({ path: '/' });
    },
    handleOpenOrCloseModal() {
      this.$emit('handleOpenOrCloseModal');
    },

    onToggle() {
      this.$emit('onToggle');
    },
    toggleActive() {
      this.isToggleicon = !this.isToggleicon;
    },
    profile() {
      router.push({ path: '/account-info' });
    },
    fetchRouters() {
      instance
        .get(`routers/?partner=${this.loggedInUser.partner.id}`)
        .then((response: { data: any }) => {
          this.routerList = response.data.results;

          if (this.routerList.length) {
            this.loginZabbix();
          }
        })
        .catch((error: any) => {
          console.log("Could not fetch the routers in the top nav bar", error);
        });
    },
    async loginZabbix() {
      delete axios.defaults.headers.common['Authorization'];

      const loginData = {
        jsonrpc: '2.0',
        method: 'user.login',
        params: {
          user: this.zabbixUserName,
          password: this.zabbixPassword,
        },
        id: 1,
      };

      const endpoint = `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`;

      try {
        // Step 1: Authenticate with Zabbix
        const loginResponse = await axios.post(endpoint, loginData);

        if (loginResponse.status === 200) {
          this.zabbixAuthenticationToken = loginResponse.data.result;
        } else {
          this.$toast.error('Could not authenticate w/Zabbix.', {
            position: 'top-right',
          });
          return;
        }

        // Step 2: Get host information
        const getHostData = {
          jsonrpc: '2.0',
          method: 'host.get',
          params: {
            output: ['hostid', 'host'],
            selectInterfaces: ['interfaceid', 'ip'],
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        const hostResponse = await axios.post(endpoint, getHostData);

        if (hostResponse.status === 200) {
          const zabbixHosts = hostResponse.data.result;
          const routerSerialNumbers = Object.keys(this.routerList).map(
            (router: any) => this.routerList[router].serial_number
          );

          const filteredRouters = [];

          for (let i = 0; i < routerSerialNumbers.length; i++) {
            const hostName = 'mini-server_' + routerSerialNumbers[i];
            const foundHost = zabbixHosts.find(
              (host: any) => host.host === hostName
            );

            if (foundHost) {
              filteredRouters.push(foundHost);
            }
          }

          this.filteredRouters = filteredRouters;
        } else {
          this.$toast.error('Could not get the hosts.', {
            position: 'top-right',
          });
          return;
        }

        // Step 3: Retrieve online status for each filtered router
        for (const router of this.filteredRouters) {
          const itemData = {
            jsonrpc: '2.0',
            method: 'item.get',
            params: {
              output: ['lastvalue', 'status'],
              hostids: router.hostid,
              search: {
                key_: 'zabbix[host,agent,available]',
                name: 'Linux: Zabbix agent availability',
              },
            },
            auth: this.zabbixAuthenticationToken,
            id: 1,
          };

          const itemResponse = await axios.post(endpoint, itemData);

          if (itemResponse.status === 200 && itemResponse.data.result.length) {
            const onlineStatus = itemResponse.data.result[0].lastvalue;

            const onlineRouter = {
              router: router.host.split('_')[1],
              status: onlineStatus,
            };

            this.onlineRouters.push(onlineRouter);
          }
        }

        // Step 4: Update the 'is_online' property for each router
        this.routerList.forEach((router) => {
          const onlineRouter = this.onlineRouters.find(
            (online: any) => online.router === router.serial_number
          );

          if (onlineRouter && onlineRouter.status === '1') {
            router.is_online = true;
          } else {
            router.is_online = false;
            this.offlineRouters.push(router);
          }
        });

      } catch (error) {
        console.log('ZABBIX ERROR: ', error);
      }
    },
  },
  created() {
    this.fetchRouters();
  }
});
</script>
