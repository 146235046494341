import { Payment, SME } from '@/interfaces/billing';
import {
  Billing,
  BillingState,
  ChartData,
  Package,
  SmsPackageBilling,
} from '@/store/types/billing';

export default {
  setBillings(state: BillingState, payload: Billing[]) {
    state.billings = payload;
  },
  setSMSBillings(state: BillingState, payload: SmsPackageBilling[]) {
    state.smsBillings = payload;
  },
  setPayments(state: BillingState, payload: Payment[]) {
    state.payments = payload;
  },
  setCustomers(state: BillingState, payload: SME[]) {
    state.customers = payload;
  },
  setChartData(state: BillingState, payload: ChartData[]) {
    state.chartData = payload;
  },
  setTotalAmount(state: BillingState, payload: number) {
    state.totalAmount = payload;
  },
  setMonthlyRevenues(state: BillingState, payload: number) {
    state.revenues = payload;
  },
  setPackage(
    state: BillingState,
    payload: { packageName: string; payementStatus: string }
  ) {
    state.package = payload;
  },
  setPackages(state: BillingState, payload: Package[]) {
    state.packages = payload;
  },
};
