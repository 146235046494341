<template>
  <div class="flex flex-col my-6">
    <div class="sm:flex items-center justify-between">
      <div class="sm:mb-0 mb-2">
        <i class="fa-regular fa-calendar w-[1rem] h-[1rem] rounded mr-2"></i>
        <span class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">Realms</span>
      </div>
      <div class="">
        <button @click="showModal = true" type="button"
          class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]">
          <img src="../../assets/plus-icon.png" class="w-[0.6rem] h-[0.6rem] mr-1" alt="" />
          Add Realm
        </button>
        <addrealmForm v-if="showModal" @close="closeModal"></addrealmForm>
      </div>
    </div>
  </div>

  <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative">
    <div class="tabs-block" id="tabs-block">
      <div class="mb-3">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center mb-2" id="myTab"
          data-tabs-toggle="#myTabContent" role="tablist">
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false"
              v-on:click="handleSetActiveTab('partner')" v-bind:class="[
          activeTab === 'partner'
            ? 'bg-light text-[#4e8d6e]'
            : 'bg-[transparent]',
        ]">
              Partner Realms
            </button>
          </li>
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="dashboard-tab" data-tabs-target="#dashboard"
              type="button" role="tab" aria-controls="dashboard" aria-selected="false"
              v-on:click="handleSetActiveTab('sme')" v-bind:class="[
          activeTab === 'sme'
            ? 'bg-light text-[#4e8d6e]'
            : 'bg-[transparent]',
        ]">
              Sme Realms
            </button>
          </li>
        </ul>
      </div>
      <div id="myTabContent">
        <div class="md:w-[100%] w-[50%] mb-2 mt-4">
          <label class="relative block">
            <span class="sr-only">Search</span>
            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
              <img src="../../assets/search-icon.png" alt="" class="w-[0.8rem] h-[0.8rem]" />
            </span>
            <input v-model="searchQuery"
              class="font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
              placeholder="Search" type="text" name="search" />
          </label>
        </div>
        <div class="p-3 flex items-center justify-between md:w-[100%] w-[50%]" v-if="showButtons()">
          <div>
            <span class="me-2 text-[#d3aa3b] font-bold text-sm">{{ rowsSelected }} Rows selected</span>
            <span class="cursor-pointer text-[#5e72e4] font-bold text-sm" @click="clearRows">clear</span>
          </div>
          <div>
            <button type="button"
              class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
              @click="unallocateRoutersFromSme">
              unassign SME router
            </button>
            <button type="button"
              class="capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
              @click="unallocateRoutersFromPartner">
              unassign Partner router
            </button>
          </div>
        </div>
        <!-- :sorting="true" -->
        <!-- @sort="sortBy" -->
        <!-- :inputShow="true" -->
        <!-- :selectAllValues="selectAll" -->
        <!-- @selectAllRows="selectAllRows" -->
        <SmeTable :tableName="'Realms'" :tableHeaderData="activeTab === 'partner' ? realmtableHeader : accountsTableHeader
          ">
          <template v-slot:tableBodyData>
            <span v-if="!paginatedTableData.length" class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap">
              No records
            </span>
            <div v-else v-for="item in paginatedTableData" :key="item.id"
              class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
              <!-- <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <input
                      v-model="item.selected"
                      @change="updateSelectedRows(item)"
                      type="checkbox"
                      class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                    />
                  </div>
                </div>
              </td> -->
              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
          item?.name
        }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
            item?.type
          }}</span>
                  </div>
                </div>
              </td>
              <td v-if="item.sme">
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
          item.sme.name
        }}</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
            item.no_of_routers
          }}</span>
                </div>
              </td>

              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
            createdAt(item.created)
          }}</span>
                </div>
              </td>
              <td>
                <div>
                  <span
                    class="capitalize px-2 py-1 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center cursor-pointer"
                    @click="realmDetails(item.id)">view</span>
                </div>
              </td>
            </div>
          </template>
        </SmeTable>
        <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="apiData"
          @handleNext="handlleNext" @handlePrev="handlePrev">
          <template v-slot:section>
            <div>
              <div class="flex items-center">
                <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                  class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                  <select v-model="selectedOption" @change="handleChange">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select></span>
              </div>
            </div>
          </template>
        </DashboardTableTitle>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import { mapGetters } from 'vuex';
import instance from '../../axios-interceptor';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import addrealmForm from './addRealm.vue';
import moment from 'moment';
import router, { useRoute } from '@/router';

export default defineComponent({
  name: 'realmsPage',
  components: {
    DashboardTableTitle,
    SmeTable,
    addrealmForm,
  },
  setup() {
    // const mode = ref(4);
    const pageNumber = ref(1);
    const perPageItems = ref(5);
    const startIndex = ref(0);

    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Ticket ID');

    const center = ref({ lat: -1.8899577, lng: 30.0634073 });

    const accountsTableHeader = ref([
      'Name',
      'Type',
      'Sme',
      'Routers',
      'Added',
      'Action',
    ]);
    const realmtableHeader = ref([
      'Name',
      'Type',
      'Routers',
      'Added',
      'Action',
    ]);
    const selectedSubscription = ref(false);

    return {
      center,

      tableName,
      icon,

      selectedSubscription,

      pageNumber,
      perPageItems,
      startIndex,
      realmtableHeader,
      accountsTableHeader,
    };
  },
  data() {
    return {
      showModal: false,
      billings: [
        {
          id: '',
          name: '',
          selected: false,
          sme: {
            id: '',
            name: '',
          },
          no_of_routers: '',
          created: '',
        },
      ],
      rowsSelected: 0,
      selectAll: false,
      selectedRows: [] as string[],
      page: 1,
      perSize: 10,
      sortKey: '', // Sorting key
      sortDirection: 'asc',
      searchQuery: '',
      propLoaded: false,
      selectedOption: 10,
      apiData: [],
      isLoading: false,
      activeTab: 'partner',
      filteredMicroFranchisees: [],

      totalMicroFranchisees: null,
      baseUserUrl: '',
    };
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.handleSetActiveTab(this.activeTab);
    },
    selectAllRows(checked: any) {
      this.selectAll = checked;
      this.billings.forEach((row) => {
        row.selected = this.selectAll;
      });
      this.rowsSelected = this.paginatedTableData.length;
    },
    sortBy(key: string) {
      let currentKey;
      if (key === 'Name') {
        currentKey = 'name';
      } else if (key === 'Routers') {
        currentKey = 'no_of_routers';
      } else if (key === 'Sme') {
        currentKey = 'smeName';
      } else {
        currentKey = 'created';
      }
      if (this.sortKey === currentKey) {
        // Toggle the sort direction if the same key is clicked again
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = currentKey;
        this.sortDirection = 'asc';
      }
    },
    updateSelectedRows(row: any) {
      if (!row.selected) {
        this.selectAll = false;
      } else {
        const selectedRows = this.billings.filter((row) => row.selected);
        this.rowsSelected = selectedRows.length;
        this.selectAll = selectedRows.length === this.billings.length;
      }
    },
    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('M/D/YYYY, h:mm:ss a');
      }
    },
    handlePrev() {
      this.page--;
      this.fetchRealms(this.activeTab);
    },
    handlleNext() {
      this.page++;
      this.fetchRealms(this.activeTab);
    },
    handleChange() {
      this.perSize = this.selectedOption;
      this.fetchRealms(this.activeTab);
    },
    fetchRealms(baseUrl: string) {
      instance
        .get(baseUrl)
        .then((response) => {
          this.isLoading = false;
          this.apiData = response.data;
          this.propLoaded = true;
          this.billings = response.data.map((row: any) => ({
            ...row,
            selected: false,
            smeName: row?.sme?.name,
          }));
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    realmDetails(id: any) {
      router.push({ name: 'realms-details', params: { id: id } });
    },
    handleSetActiveTab(tab: string) {
      this.activeTab = tab;
      if (tab === 'partner') {
        const baseUrl = `realms/?partner=${this.loggedInUser.partner.id}`;
        this.fetchRealms(baseUrl);
      } else {
        const baseUrl = `realms/?sme__partner=${this.loggedInUser.partner.id}`;
        this.fetchRealms(baseUrl);
      }
    },
    unallocateRoutersFromSme() {
      const route = useRoute();
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const assignSmeRoutersUrl = 'routers/assign-to-sme/';
      const data = {
        routers: selectedRouters,
        sme: route.params.id,
      };
      instance
        .patch(assignSmeRoutersUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.fetchRealms(this.activeTab);
            this.$toast.success(response.data.detail, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unallocateRoutersFromPartner() {
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const unassignRoutersFromPartnerUrl = 'routers/assign-to-partner/';
      const data = {
        routers: selectedRouters,
      };
      instance
        .patch(unassignRoutersFromPartnerUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.fetchRealms(this.activeTab);
            this.$toast.success(`Router removed`, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearRows() {
      this.paginatedTableData.filter((row) => (row.selected = false));
      this.selectAll = false;
    },
    showButtons() {
      // TODO: REWORK ON THE UNASSIGN REALM FROM PARTNER AND SME FEATURE
      // return this.paginatedTableData.some((row) => row.selected === true);
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.billings;
      }

      // Filter table data based on search query
      const filteredData = this.billings.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const name = item.name.toString().toLowerCase();
        const no_of_routers = item.no_of_routers.toString().toLowerCase();
        const smeName = item?.sme?.name.toString().toLowerCase();
        const created = this.createdAt(item.created.toString().toLowerCase());
        const query = this.searchQuery.toLowerCase();

        //Check if name or age contain the search query
        return (
          name?.includes(query) ||
          no_of_routers?.includes(query) ||
          smeName?.includes(query) ||
          created?.includes(query)
        );
      });

      return filteredData;
    },

    sortedTableData(sortKey: any): any[] {
      if (!this.sortKey) {
        // If sort key is empty, return the filtered data as-is
        return this.filteredTableData;
      }

      // Sort the filtered table data based on the sort key and direction
      const sortedData = this.filteredTableData.slice().sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];

        // Perform the actual comparison based on the data type
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return (
            aValue.localeCompare(bValue) *
            (this.sortDirection === 'asc' ? 1 : -1)
          );
        } else {
          return (aValue - bValue) * (this.sortDirection === 'asc' ? 1 : -1);
        }
      });

      return sortedData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.sortedTableData.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.handleSetActiveTab('partner');
  },
});
</script>