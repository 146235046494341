import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/ared-logo.svg'


const _hoisted_1 = { class: "w-full lg:flex justify-between" }
const _hoisted_2 = { class: "lg:w-[50%] h-100vh md:overflow-auto lg:py-10" }
const _hoisted_3 = { class: "flex flex-col justify-center items-center relative" }
const _hoisted_4 = { class: "max-form w-full lg:py-0 py-10" }
const _hoisted_5 = { class: "bg-white rounded-[0.432rem] lg:p-0 p-4" }
const _hoisted_6 = { class: "mt-10" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "w-full flex justify-center items-center my-6" }
const _hoisted_9 = { class: "font-poppins font-normal text-[#838282] text-[12.9px]" }
const _hoisted_10 = { class: "lg:w-[50%]" }
const _hoisted_11 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_auth_right_image = _resolveComponent("auth-right-image")!
  const _component_SmeSnackbar = _resolveComponent("SmeSnackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[13] || (_cache[13] = _createElementVNode("img", {
            src: _imports_0,
            class: "w-[8rem] lg:hidden block m-auto mb-5",
            alt: "right view"
          }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[11] || (_cache[11] = _createElementVNode("h1", { class: "font-['poppins'] font-bold text-[26px] text-center" }, "Sign Up", -1)),
            _cache[12] || (_cache[12] = _createElementVNode("h1", { class: "font-poppins lg:font-bold font-normal lg:text-[19px] text-[15px] mt-2 tracking-widest text-primary text-center" }, " ARED PARTNER Platform ", -1)),
            _createElementVNode("form", _hoisted_6, [
              _createVNode(_component_TextField, {
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                name: "firstName",
                placeholder: "First Name",
                type: "text",
                modelValue: _ctx.v$.firstName.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.firstName.$model) = $event)),
                showErrors: _ctx.v$.firstName.$errors.length ? true : false,
                errors: _ctx.v$.firstName.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createVNode(_component_TextField, {
                name: "lastName",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Last Name",
                type: "text",
                modelValue: _ctx.v$.lastName.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.lastName.$model) = $event)),
                showErrors: _ctx.v$.lastName.$errors.length ? true : false,
                errors: _ctx.v$.lastName.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createVNode(_component_TextField, {
                name: "email",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Email",
                type: "email",
                modelValue: _ctx.v$.email.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.email.$model) = $event)),
                showErrors: _ctx.v$.email.$errors.length ? true : false,
                errors: _ctx.v$.email.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createVNode(_component_TextField, {
                name: "password",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Password",
                type: "password",
                modelValue: _ctx.v$.password.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.v$.password.$model) = $event)),
                showErrors: _ctx.v$.password.$errors.length ? true : false,
                errors: _ctx.v$.password.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createVNode(_component_TextField, {
                name: "phone",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Phone Number",
                type: "text",
                modelValue: _ctx.v$.phone.$model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.v$.phone.$model) = $event)),
                showErrors: _ctx.v$.phone.$errors.length ? true : false,
                errors: _ctx.v$.phone.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createVNode(_component_SelectField, {
                name: "partner",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                label: "Select Partner",
                modelValue: _ctx.v$.partner.$model,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$.partner.$model) = $event)),
                showErrors: _ctx.v$.partner.$errors.length ? true : false,
                errors: _ctx.v$.partner.$errors,
                data: _ctx.partners
              }, null, 8, ["modelValue", "showErrors", "errors", "data"]),
              _createVNode(_component_SelectField, {
                name: "subscription",
                class: "mb-5 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                label: "Select Subscription",
                modelValue: _ctx.v$.subscription.$model,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.v$.subscription.$model) = $event)),
                showErrors: _ctx.v$.subscription.$errors.length ? true : false,
                errors: _ctx.v$.subscription.$errors,
                data: _ctx.subscriptions
              }, null, 8, ["modelValue", "showErrors", "errors", "data"]),
              _createElementVNode("button", {
                type: "button",
                class: "w-full bg-secondary font-['poppins'] text-[15px] text-black rounded-[0.432rem] p-3 font-semibold mb-3 mt-4",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleSignIn && _ctx.handleSignIn(...args)))
              }, " Cancel "),
              _createElementVNode("button", {
                type: "button",
                class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleSignup && _ctx.handleSignup(...args))),
                disabled: _ctx.v$.$invalid
              }, " Add SME ", 8, _hoisted_7),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                  _cache[10] || (_cache[10] = _createTextVNode("Already have an account? ")),
                  _createElementVNode("button", {
                    type: "button",
                    class: "text-success font-semibold lg:text-[#4D99FF] text-[#4E8D6E]",
                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleSignIn && _ctx.handleSignIn(...args)))
                  }, " Sign In ")
                ])
              ])
            ])
          ])
        ]),
        _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"max-form w-full bottom-5 lg:flex hidden justify-between items-center\" data-v-30bf6f9e><ul class=\"flex\" data-v-30bf6f9e><li data-v-30bf6f9e><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-30bf6f9e><a href=\"javascript:void(0)\" data-v-30bf6f9e>Terms</a></span></li><li data-v-30bf6f9e><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-30bf6f9e><a href=\"javascript:void(0)\" data-v-30bf6f9e>Plans</a></span></li><li data-v-30bf6f9e><span class=\"text-success font-poppins font-medium text-[0.778rem]\" data-v-30bf6f9e><a href=\"javascript:void(0)\" data-v-30bf6f9e>Contact Us</a></span></li></ul></div>", 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_auth_right_image)
      ]),
      (_ctx.showSnackbar)
        ? (_openBlock(), _createBlock(_component_SmeSnackbar, {
            key: 0,
            message: "Successfully registered as sme, kindly use credentials sent to your email to login to your dashboard account.",
            onButtonClick: _ctx.handleCloseSnackbar
          }, null, 8, ["onButtonClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}