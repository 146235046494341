import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative" }
const _hoisted_2 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  class: "flex flex-wrap -mb-px text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = { class: "mr-2" }
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { class: "mr-2" }
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "mr-2" }
const _hoisted_13 = { class: "mr-2" }
const _hoisted_14 = { class: "mr-2" }
const _hoisted_15 = {
  id: "myTabContent",
  class: "pt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AggregatorDeposit = _resolveComponent("AggregatorDeposit")!
  const _component_InitiateTransactionRollback = _resolveComponent("InitiateTransactionRollback")!
  const _component_CommissionPayments = _resolveComponent("CommissionPayments")!
  const _component_MicroFranchiseeDeposit = _resolveComponent("MicroFranchiseeDeposit")!
  const _component_CreditApproval = _resolveComponent("CreditApproval")!
  const _component_CreditSuspend = _resolveComponent("CreditSuspend")!
  const _component_SMSMessages = _resolveComponent("SMSMessages")!
  const _component_MicroFranchiseePenalty = _resolveComponent("MicroFranchiseePenalty")!
  const _component_MicroFranchiseeReimbursement = _resolveComponent("MicroFranchiseeReimbursement")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex flex-col md:block hidden my-6" }, [
      _createElementVNode("div", { class: "flex items-center" }, [
        _createElementVNode("i", { class: "fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2" }),
        _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, "Active Micro Franchisees")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'Aggregator_Deposit' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab1",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 'Aggregator_Deposit'))
              }, " Aggregator Deposit", 2)
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'Initiate_TXN_Rollback' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab2",
                "data-tabs-target": "#dashboard",
                type: "button",
                role: "tab",
                "aria-controls": "dashboard",
                "aria-selected": "false",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 'Initiate_TXN_Rollback'))
              }, "Initiate TXN Rollback", 2)
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'Approve_TXN_Rollback' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab3",
                "data-tabs-target": "#settings",
                type: "button",
                role: "tab",
                "aria-controls": "settings",
                "aria-selected": "false",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeTab = 'Approve_TXN_Rollback'))
              }, "Approve TXN Rollback", 2)
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'Commission_Payments' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab4",
                "data-tabs-target": "#settings",
                type: "button",
                role: "tab",
                "aria-controls": "settings",
                "aria-selected": "false",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activeTab = 'Commission_Payments'))
              }, "Commission Payments", 2)
            ]),
            _createElementVNode("li", _hoisted_9, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'MF_Deposit' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab5",
                "data-tabs-target": "#settings",
                type: "button",
                role: "tab",
                "aria-controls": "settings",
                "aria-selected": "false",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.activeTab = 'MF_Deposit'))
              }, "MF Deposit", 2)
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'MF_Credit_Approval' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab6",
                "data-tabs-target": "#settings",
                type: "button",
                role: "tab",
                "aria-controls": "settings",
                "aria-selected": "false",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.activeTab = 'MF_Credit_Approval'))
              }, "MF Credit Approval", 2)
            ]),
            _createElementVNode("li", _hoisted_11, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'MF_Credit_Suspend' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab7",
                "data-tabs-target": "#settings",
                type: "button",
                role: "tab",
                "aria-controls": "settings",
                "aria-selected": "false",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.activeTab = 'MF_Credit_Suspend'))
              }, "MF Credit Suspend", 2)
            ]),
            _createElementVNode("li", _hoisted_12, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'MF_Deposit_SMS' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab8",
                "data-tabs-target": "#settings",
                type: "button",
                role: "tab",
                "aria-controls": "settings",
                "aria-selected": "false",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.activeTab = 'MF_Deposit_SMS'))
              }, "MF Deposit SMS", 2)
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'MF_Penalties' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab9",
                "data-tabs-target": "#settings",
                type: "button",
                role: "tab",
                "aria-controls": "settings",
                "aria-selected": "false",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.activeTab = 'MF_Penalties'))
              }, "MF Penalties", 2)
            ]),
            _createElementVNode("li", _hoisted_14, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === 'Reimbursement' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                id: "tab10",
                "data-tabs-target": "#settings",
                type: "button",
                role: "tab",
                "aria-controls": "settings",
                "aria-selected": "false",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.activeTab = 'Reimbursement'))
              }, "Reimbursement", 2)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.activeTab === 'Aggregator_Deposit')
            ? (_openBlock(), _createBlock(_component_AggregatorDeposit, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'Initiate_TXN_Rollback')
            ? (_openBlock(), _createBlock(_component_InitiateTransactionRollback, { key: 1 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'Approve_TXN_Rollback')
            ? (_openBlock(), _createBlock(_component_InitiateTransactionRollback, { key: 2 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'Commission_Payments')
            ? (_openBlock(), _createBlock(_component_CommissionPayments, { key: 3 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'MF_Deposit')
            ? (_openBlock(), _createBlock(_component_MicroFranchiseeDeposit, { key: 4 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'MF_Credit_Approval')
            ? (_openBlock(), _createBlock(_component_CreditApproval, { key: 5 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'MF_Credit_Suspend')
            ? (_openBlock(), _createBlock(_component_CreditSuspend, { key: 6 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'MF_Deposit_SMS')
            ? (_openBlock(), _createBlock(_component_SMSMessages, { key: 7 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'MF_Penalties')
            ? (_openBlock(), _createBlock(_component_MicroFranchiseePenalty, { key: 8 }))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'Reimbursement')
            ? (_openBlock(), _createBlock(_component_MicroFranchiseeReimbursement, { key: 9 }))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}