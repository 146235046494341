import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../assets/plus-icon.png'
import _imports_1 from '../../assets/search-icon.png'


const _hoisted_1 = { class: "w-full my-3" }
const _hoisted_2 = { class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto" }
const _hoisted_3 = { class: "md:w-[30%] w-[50%]" }
const _hoisted_4 = { class: "relative block" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "bg-[#F3F6F9] w-[2.806rem] h-[2.806rem] rounded-[0.337rem] flex items-center justify-center" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "flex flex-col ml-3" }
const _hoisted_10 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_11 = { class: "text-[#464E5F]" }
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_14 = {
  key: 0,
  type: "button",
  class: "bg-[#4E8D6D] px-6 py-2 rounded-[0.327rem] flex items-center"
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "flex items-center justify-end" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm"
}
const _hoisted_20 = { class: "spinner-container" }
const _hoisted_21 = {
  key: 0,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_addMediaform = _resolveComponent("addMediaform")!
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_SmeTable = _resolveComponent("SmeTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "All Media",
      description: "",
      icon: _ctx.icon.fileIcon
    }, {
      aside: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          class: "bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: _imports_0,
            class: "md:w-[1rem] w-[0.6rem] md:h-[1rem] h-[0.6rem] mt-1 mr-2",
            alt: ""
          }, null, -1),
          _createTextVNode(" Add Media ")
        ])),
        (_ctx.showModal)
          ? (_openBlock(), _createBlock(_component_addMediaform, {
              key: 0,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["icon"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.propLoaded)
          ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
              key: 0,
              perPageItems: _ctx.perSize,
              pageNumber: _ctx.page,
              ApiData: _ctx.FilesList,
              onHandleNext: _ctx.handlleNext,
              onHandlePrev: _ctx.handlePrev
            }, {
              section: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, [
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
                      _createElementVNode("img", {
                        src: _imports_1,
                        alt: "",
                        class: "w-[0.8rem] h-[0.8rem]"
                      })
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchQuery) = $event)),
                      class: "font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                      placeholder: "Search media",
                      type: "text",
                      name: "search"
                    }, null, 512), [
                      [_vModelText, _ctx.searchQuery]
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
          : _createCommentVNode("", true),
        _createVNode(_component_SmeTable, {
          tableName: _ctx.tableName,
          tableHeaderData: _ctx.routerTableHeaders
        }, {
          tableBodyData: _withCtx(() => [
            (_ctx.paginatedTableData.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paginatedTableData, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                  }, [
                    _createElementVNode("td", {
                      title: item.file_type
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          (item.file_type)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: 
                        item.file_type === 'Image'
                          ? require('../../assets/image.png')
                          : item.file_type === 'Video'
                          ? require('../../assets/video.png')
                          : require('../../assets/document.png')
                      ,
                                alt: item.realm_name,
                                class: "w-[1.1rem] h-[1.100rem]"
                              }, null, 8, _hoisted_8))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(item.realm_name), 1),
                          _createElementVNode("span", null, _toDisplayString(item.realm), 1)
                        ])
                      ])
                    ], 8, _hoisted_5),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(item.file_type), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(item.status), 1)
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      (item.file)
                        ? (_openBlock(), _createElementBlock("button", _hoisted_14, [
                            _createElementVNode("span", {
                              class: "font-poppins font-semibold text-[10.28px] text-[#fff] flex",
                              onClick: ($event: any) => (_ctx.downloadFile(item.file, item.file_type))
                            }, "Download", 8, _hoisted_15)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_16, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerTableIcons, (icon) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: icon.content,
                            class: "bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2"
                          }, [
                            _createElementVNode("button", {
                              onClick: ($event: any) => (_ctx.disableMedia(item.id)),
                              title: "Delete"
                            }, [
                              _createElementVNode("img", {
                                src: icon.content,
                                alt: "africa",
                                class: "w-[1.125rem] h-[1.067rem]"
                              }, null, 8, _hoisted_18)
                            ], 8, _hoisted_17)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[6] || (_cache[6] = [
                  _createElementVNode("b", null, "No Record found", -1)
                ])))
          ]),
          _: 1
        }, 8, ["tableName", "tableHeaderData"]),
        _createElementVNode("div", _hoisted_20, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}