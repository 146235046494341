import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex my-6 justify-between" }
const _hoisted_2 = { class: "md:flex flex-row items-start gap-y-8 gap-x-8 mt-8 sm:gap-y-16" }
const _hoisted_3 = { class: "lg:basis-2/6 relative flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible shadow-lg px-5 pt-5 pb-6 shadow-gray-900/5 bg-white rounded-2xl h-100 min-h-[132px] justify-center items-center" }
const _hoisted_4 = { class: "font-bold font-lg text-center" }
const _hoisted_5 = { class: "user-profile-block lg:col-span-3 asis-1/2 shadow-lg px-6 pt-6 pb-8 shadow-gray-900/5 bg-white rounded-2xl full-width" }
const _hoisted_6 = { class: "w-full table-fixed table-border" }
const _hoisted_7 = { class: "bg-light" }
const _hoisted_8 = { class: "font-medium text-[1rem]" }
const _hoisted_9 = { class: "bg-light" }
const _hoisted_10 = { class: "font-poppin text-[1rem]" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "font-medium text-[1rem]" }
const _hoisted_13 = {
  key: 0,
  class: "font-poppins font-medium text-[0.8rem] text-[#4e8d6e] bg-[#DCE9E3] px-3 py-1 rounded-[0.327rem]"
}
const _hoisted_14 = {
  key: 1,
  class: "font-poppins font-medium text-[0.8rem] text-[#4e8d6e] bg-[#DCE9E3] px-3 py-1 rounded-[0.327rem]"
}
const _hoisted_15 = { class: "bg-light" }
const _hoisted_16 = { class: "font-medium text-[1rem]" }
const _hoisted_17 = {
  key: 0,
  class: "font-medium text-[1rem]"
}
const _hoisted_18 = {
  key: 1,
  class: "font-medium text-[1rem]"
}
const _hoisted_19 = { class: "bg-light" }
const _hoisted_20 = {
  key: 0,
  class: "font-medium text-[1rem]"
}
const _hoisted_21 = {
  key: 1,
  class: "font-medium text-[1rem]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex items-center" }, [
        _createElementVNode("i", { class: "fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2" }),
        _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, "ADVERT DETAILS")
      ], -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editDetails && _ctx.editDetails(...args))),
        type: "button",
        class: "bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold text-[0.8rem] text-[#fff]"
      }, " Edit Advert ")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.advert.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor" }, " Advert details ", -1)),
        _createElementVNode("table", _hoisted_6, [
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", _hoisted_7, [
              _cache[3] || (_cache[3] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Title", -1)),
              _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.advert?.title), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[4] || (_cache[4] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Realm", -1)),
              _createElementVNode("td", {
                class: "font-medium text-[1rem] cursor-pointer text-[#5e72e4]",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.realmDetails(_ctx.advert.realm.id)))
              }, _toDisplayString(_ctx.advert.realm.name), 1)
            ]),
            _createElementVNode("tr", _hoisted_9, [
              _cache[5] || (_cache[5] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Media", -1)),
              _createElementVNode("td", _hoisted_10, [
                _createElementVNode("a", {
                  class: "text-[#5e72e4]",
                  href: _ctx.advert.file,
                  target: "_blank"
                }, "View Media", 8, _hoisted_11)
              ])
            ]),
            _createElementVNode("tr", null, [
              _cache[6] || (_cache[6] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Status", -1)),
              _createElementVNode("td", _hoisted_12, [
                (_ctx.advert.is_active)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, "ENABLED"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_14, "DISABLED"))
              ])
            ]),
            _createElementVNode("tr", _hoisted_15, [
              _cache[7] || (_cache[7] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Date Created", -1)),
              _createElementVNode("td", _hoisted_16, _toDisplayString(new Date(_ctx.advert.created).toLocaleString().split(',')[0]), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[8] || (_cache[8] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Start Date", -1)),
              (_ctx.advert.start_date)
                ? (_openBlock(), _createElementBlock("td", _hoisted_17, _toDisplayString(new Date(_ctx.advert.start_date).toLocaleString().split(',')[0]), 1))
                : (_openBlock(), _createElementBlock("td", _hoisted_18, "n/a"))
            ]),
            _createElementVNode("tr", _hoisted_19, [
              _cache[9] || (_cache[9] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "End Date", -1)),
              (_ctx.advert.end_date)
                ? (_openBlock(), _createElementBlock("td", _hoisted_20, _toDisplayString(new Date(_ctx.advert.end_date).toLocaleString().split(',')[0]), 1))
                : (_openBlock(), _createElementBlock("td", _hoisted_21, "n/a"))
            ])
          ])
        ])
      ])
    ])
  ], 64))
}