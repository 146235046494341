import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/close-icon.svg'


const _hoisted_1 = { class: "popup-modal" }
const _hoisted_2 = { class: "popup-modal-content portal-model rounded-xl" }
const _hoisted_3 = { class: "bg-white" }
const _hoisted_4 = { class: "mx-auto mt-3 grid max-w-2xl grid-cols-1 items-top gap-x-8 px-4 sm:px-6 lg:max-w-7xl lg:grid-cols-2 lg:p-5" }
const _hoisted_5 = { class: "grid grid-cols-1" }
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = { class: "mb-2 relative" }
const _hoisted_10 = { class: "flex items-center space-x-4 mb-4" }
const _hoisted_11 = { class: "inline-flex items-center" }
const _hoisted_12 = { class: "inline-flex items-center" }
const _hoisted_13 = { class: "w-full mt-8" }
const _hoisted_14 = {
  key: 1,
  type: "button",
  class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] px-3 py-2 font-medium"
}
const _hoisted_15 = { class: "hidden lg:block" }
const _hoisted_16 = { class: "flex items-center w-full justify-center mb-2" }
const _hoisted_17 = { class: "flex justify-center items-center my-3" }
const _hoisted_18 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_19 = {
  class: "p-1",
  style: {"width":"228px","height":"468px","background":"url('phone.png') no-repeat"}
}
const _hoisted_20 = {
  class: "overflow-y-hidden",
  style: {"max-height":"450px"}
}
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "px-1 mt-1 text-lg text-dark text-center" }
const _hoisted_23 = {
  key: 1,
  class: "px-2 text-sm text-dark text-center"
}
const _hoisted_24 = {
  key: 1,
  class: "flex justify-center items-center"
}
const _hoisted_25 = {
  class: "p-1",
  style: {"width":"300px","height":"460px","background":"url('tab.png') no-repeat"}
}
const _hoisted_26 = {
  class: "overflow-y-hidden",
  style: {"max-height":"450px"}
}
const _hoisted_27 = ["src"]
const _hoisted_28 = { class: "px-1 mt-1 text-lg text-dark text-center" }
const _hoisted_29 = {
  key: 1,
  class: "px-2 text-sm text-dark text-center"
}
const _hoisted_30 = {
  key: 2,
  class: "flex justify-center items-center"
}
const _hoisted_31 = {
  class: "p-1",
  style: {"width":"350px","height":"460px","background":"url('monitor.png') no-repeat"}
}
const _hoisted_32 = {
  class: "overflow-y-hidden",
  style: {"max-height":"326px"}
}
const _hoisted_33 = {
  class: "mx-auto",
  style: {
                    width: '324px',
                    marginTop: '17px',
                    backgroundColor: '#ffffff',
                  }
}
const _hoisted_34 = { class: "flex" }
const _hoisted_35 = { class: "w-1/5" }
const _hoisted_36 = { class: "text-white text-xs text-center" }
const _hoisted_37 = {
  class: "nav-menu text-white mt-4 p-1",
  style: {"font-size":"5px"}
}
const _hoisted_38 = {
  "data-toggle": "collapse",
  role: "button",
  "aria-expanded": "false",
  "aria-controls": "collapseExample"
}
const _hoisted_39 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "10",
  viewBox: "0 0 24 24",
  style: {"fill":"rgba(255, 255, 255, 1)","display":"inline-block"}
}
const _hoisted_40 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "10",
  viewBox: "0 0 24 24",
  style: {"fill":"rgba(255, 255, 255, 1)","display":"inline-block"}
}
const _hoisted_41 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "10",
  viewBox: "0 0 24 24",
  style: {"fill":"rgba(255, 255, 255, 1)","display":"inline-block"}
}
const _hoisted_42 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "8",
  height: "8",
  viewBox: "0 0 512 512",
  style: {"fill":"rgba(255, 255, 255, 1)","display":"inline-block"}
}
const _hoisted_43 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "10",
  viewBox: "0 0 24 24",
  style: {"fill":"rgba(255, 255, 255, 1)","display":"inline-block"}
}
const _hoisted_44 = { class: "w-4/5" }
const _hoisted_45 = { class: "flex items-center justify-end p-2" }
const _hoisted_46 = ["src"]
const _hoisted_47 = { class: "px-1 mt-1 text-lg text-dark text-center" }
const _hoisted_48 = {
  key: 1,
  class: "px-2 text-sm text-dark text-center"
}
const _hoisted_49 = ["src"]
const _hoisted_50 = { class: "px-1 mt-1 text-lg text-dark text-center" }
const _hoisted_51 = {
  key: 1,
  class: "px-2 text-sm text-dark text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_clip_loader = _resolveComponent("clip-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        class: "float-right"
      }, _cache[14] || (_cache[14] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "w-[0.8rem] mt-1",
          alt: ""
        }, null, -1)
      ])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _cache[22] || (_cache[22] = _createElementVNode("h3", { class: "text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, " Customize Captive Portal ", -1)),
            _createElementVNode("dl", _hoisted_5, [
              _cache[17] || (_cache[17] = _createElementVNode("label", {
                for: "name",
                class: "mb-2"
              }, "Name", -1)),
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.captivePortalData.name) = $event)),
                  type: "text",
                  min: "5",
                  maxlength: "15",
                  id: "small-input",
                  class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                }, null, 512), [
                  [_vModelText, _ctx.captivePortalData.name]
                ])
              ]),
              _cache[18] || (_cache[18] = _createElementVNode("label", {
                for: "primary-message",
                class: "mb-2"
              }, "Primary Message", -1)),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.captivePortalData.primary_message) = $event)),
                  type: "text",
                  min: "5",
                  maxlength: "30",
                  id: "small-input",
                  class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.625rem] px-3 py-2 border-textFieldBorder"
                }, null, 512), [
                  [_vModelText, _ctx.captivePortalData.primary_message]
                ])
              ]),
              _cache[19] || (_cache[19] = _createElementVNode("label", {
                for: "background-color",
                class: "mb-2"
              }, "Background Color", -1)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_color_picker, {
                  pureColor: _ctx.pureColor,
                  "onUpdate:pureColor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pureColor) = $event)),
                  gradientColor: _ctx.gradientColor,
                  "onUpdate:gradientColor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.gradientColor) = $event)),
                  format: "hex"
                }, null, 8, ["pureColor", "gradientColor"])
              ]),
              _cache[20] || (_cache[20] = _createElementVNode("label", {
                for: "primary-message",
                class: "mb-2"
              }, "Logo", -1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("input", {
                  id: "file",
                  ref: "fileInput",
                  type: "file",
                  placeholder: "Choose a file or drop it here...",
                  class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder",
                  onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.previewLogo && _ctx.previewLogo(...args)))
                }, null, 544)
              ]),
              _cache[21] || (_cache[21] = _createElementVNode("label", { class: "mb-2" }, [
                _createTextVNode("Display "),
                _createElementVNode("strong", null, "You Need To Connect To"),
                _createTextVNode(" message")
              ], -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    class: "form-radio",
                    name: "display-connect-message",
                    value: true,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.captivePortalData.show_connect_message) = $event))
                  }, null, 512), [
                    [_vModelRadio, _ctx.captivePortalData.show_connect_message]
                  ]),
                  _cache[15] || (_cache[15] = _createElementVNode("span", { class: "ml-2" }, "Yes", -1))
                ]),
                _createElementVNode("label", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    class: "form-radio",
                    name: "display-connect-message",
                    value: false,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.captivePortalData.show_connect_message) = $event))
                  }, null, 512), [
                    [_vModelRadio, _ctx.captivePortalData.show_connect_message]
                  ]),
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "ml-2" }, "No", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                (!_ctx.loadingSave && !_ctx.loadingReset)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] px-3 py-2 font-medium",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateCaptivePortal()))
                    }, " Save "))
                  : _createCommentVNode("", true),
                (_ctx.loadingSave)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_14, [
                      _createVNode(_component_clip_loader, { color: "#fff" })
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.loadingReset && !_ctx.loadingSave)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 2,
                      type: "button",
                      class: "mt-4 w-full font-['poppins'] text-[15px] bg-[#f24236] text-white rounded-[0.432rem] px-3 py-2 font-medium",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.resetCaptivePortal()))
                    }, " Reset the Changes "))
                  : _createCommentVNode("", true),
                (_ctx.loadingReset)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 3,
                      type: "button",
                      class: "mt-4 w-full font-['poppins'] text-[15px] bg-[#f24236] text-white rounded-[0.432rem] px-3 py-2 font-medium",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.resetCaptivePortal()))
                    }, [
                      _createVNode(_component_clip_loader, { color: "#fff" })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", null, [
                  _cache[23] || (_cache[23] = _createElementVNode("h3", { class: "text-left mb-0 font-poppins font-semibold md:text-[15px] text-[0.90rem] text-routersTextColor" }, " Preview Portal ", -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("img", {
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.changePreview($event, 'phone'))),
                      src: "phone-icon.png",
                      class: "inline-block mr-2 p-1",
                      style: _normalizeStyle({ background: _ctx.preview.phone ? '#ccc' : '' }),
                      alt: ""
                    }, null, 4),
                    _createElementVNode("img", {
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changePreview($event, 'tab'))),
                      src: "tab-icon.png",
                      class: "inline-block mr-2 p-1",
                      style: _normalizeStyle({ background: _ctx.preview.tab ? '#ccc' : '' }),
                      alt: ""
                    }, null, 4),
                    _createElementVNode("img", {
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.changePreview($event, 'monitor'))),
                      src: "monitor-icon.png",
                      class: "inline-block p-1",
                      style: _normalizeStyle({ background: _ctx.preview.desktop ? '#ccc' : '' }),
                      alt: ""
                    }, null, 4)
                  ])
                ])
              ])
            ]),
            (_ctx.preview.phone)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", {
                        class: "mt-2 mx-auto",
                        style: _normalizeStyle({
                    width: '212px',
                    height: '35px',
                    backgroundColor: _ctx.pureColor,
                  })
                      }, _cache[24] || (_cache[24] = [
                        _createStaticVNode("<div class=\"flex\" data-v-0eac6462><div class=\"w-1/5\" data-v-0eac6462><img src=\"menu-icon.png\" class=\"p-2\" alt=\"\" data-v-0eac6462></div><div class=\"w-3/5\" data-v-0eac6462><p class=\"text-center text-white pt-1\" data-v-0eac6462>Home</p></div><div class=\"w-1/5\" data-v-0eac6462><img src=\"globe-icon.png\" class=\"p-2 mx-auto block\" alt=\"\" data-v-0eac6462></div></div>", 1)
                      ]), 4),
                      _createElementVNode("img", {
                        src: "wifi-transparent.png",
                        class: "mx-auto mt-3",
                        style: _normalizeStyle({ backgroundColor: _ctx.pureColor }),
                        alt: ""
                      }, null, 4),
                      (_ctx.previewLogoURL)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: _ctx.previewLogoURL,
                            alt: "Logo",
                            class: "mt-3 mx-auto h-auto w-auto",
                            style: {"max-height":"140px"}
                          }, null, 8, _hoisted_21))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.captivePortalData.primary_message), 1),
                      (_ctx.captivePortalData.show_connect_message)
                        ? (_openBlock(), _createElementBlock("h3", _hoisted_23, " You need to connect to " + _toDisplayString(_ctx.captivePortalData.name ? _ctx.captivePortalData.name : 'ARED') + " to get internet. ", 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        type: "button",
                        class: "w-40 block mt-3 mx-auto text-[12px] text-white rounded-[0.432rem] px-3 py-2",
                        style: _normalizeStyle({ backgroundColor: _ctx.pureColor })
                      }, " CONNECT TO " + _toDisplayString(_ctx.captivePortalData.name ? _ctx.captivePortalData.name : 'ARED'), 5)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.preview.tab)
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", {
                        class: "mx-auto",
                        style: _normalizeStyle({
                    width: '278px',
                    height: '35px',
                    marginTop: '7px',
                    backgroundColor: _ctx.pureColor,
                  })
                      }, _cache[25] || (_cache[25] = [
                        _createStaticVNode("<div class=\"flex\" data-v-0eac6462><div class=\"w-1/5\" data-v-0eac6462><img src=\"menu-icon.png\" class=\"p-2\" alt=\"\" data-v-0eac6462></div><div class=\"w-3/5\" data-v-0eac6462><p class=\"text-center text-white pt-1\" data-v-0eac6462>Home</p></div><div class=\"w-1/5\" data-v-0eac6462><img src=\"globe-icon.png\" class=\"p-2 mx-auto block\" alt=\"\" data-v-0eac6462></div></div>", 1)
                      ]), 4),
                      _createElementVNode("img", {
                        src: "wifi-transparent.png",
                        class: "mx-auto mt-3",
                        style: _normalizeStyle({ backgroundColor: _ctx.pureColor }),
                        alt: ""
                      }, null, 4),
                      (_ctx.previewLogoURL)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: _ctx.previewLogoURL,
                            alt: "Logo",
                            class: "mt-3 mx-auto h-auto w-auto",
                            style: {"max-height":"140px"}
                          }, null, 8, _hoisted_27))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.captivePortalData.primary_message), 1),
                      (_ctx.captivePortalData.show_connect_message)
                        ? (_openBlock(), _createElementBlock("h3", _hoisted_29, " You need to connect to " + _toDisplayString(_ctx.captivePortalData.name ? _ctx.captivePortalData.name : 'ARED') + " to get internet. ", 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        type: "button",
                        class: "w-40 block mt-3 mx-auto text-[12px] text-white rounded-[0.432rem] px-3 py-2",
                        style: _normalizeStyle({ backgroundColor: _ctx.pureColor })
                      }, " CONNECT TO " + _toDisplayString(_ctx.captivePortalData.name ? _ctx.captivePortalData.name : 'ARED'), 5)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.preview.desktop)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("div", {
                              style: _normalizeStyle({
                          height: '100vh',
                          paddingTop: '14vh',
                          backgroundColor: _ctx.pureColor,
                        })
                            }, [
                              _createElementVNode("h1", _hoisted_36, _toDisplayString(_ctx.captivePortalData.name
                                ? _ctx.captivePortalData.name
                                : 'ARED'), 1),
                              _createElementVNode("ul", _hoisted_37, [
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", _hoisted_38, [
                                    (_openBlock(), _createElementBlock("svg", _hoisted_39, _cache[26] || (_cache[26] = [
                                      _createElementVNode("path", { d: "M11 18.791V20H9v2h6v-2h-2v-1.845a9.934 9.934 0 0 0 3.071-2.084c3.898-3.898 3.898-10.243 0-14.143l-1.414 1.414c3.119 3.12 3.119 8.195 0 11.314-3.119 3.118-8.195 3.12-11.314 0L1.929 16.07A9.971 9.971 0 0 0 9 18.994a9.98 9.98 0 0 0 2-.203z" }, null, -1),
                                      _createElementVNode("path", { d: "M3 9c0 3.309 2.691 6 6 6s6-2.691 6-6-2.691-6-6-6-6 2.691-6 6zm10 0c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4z" }, null, -1)
                                    ]))),
                                    _cache[27] || (_cache[27] = _createTextVNode(" Offline Hub "))
                                  ])
                                ]),
                                _cache[36] || (_cache[36] = _createElementVNode("li", null, [
                                  _createElementVNode("ul", { class: "pl-3 m-0" }, [
                                    _createElementVNode("li", null, [
                                      _createElementVNode("a", null, "Audio")
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("a", null, "Video")
                                    ]),
                                    _createElementVNode("li", null, [
                                      _createElementVNode("a", null, "Nature")
                                    ])
                                  ])
                                ], -1)),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", null, [
                                    (_openBlock(), _createElementBlock("svg", _hoisted_40, _cache[28] || (_cache[28] = [
                                      _createElementVNode("path", { d: "M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm7.931 9h-2.764a14.67 14.67 0 0 0-1.792-6.243A8.013 8.013 0 0 1 19.931 11zM12.53 4.027c1.035 1.364 2.427 3.78 2.627 6.973H9.03c.139-2.596.994-5.028 2.451-6.974.172-.01.344-.026.519-.026.179 0 .354.016.53.027zm-3.842.7C7.704 6.618 7.136 8.762 7.03 11H4.069a8.013 8.013 0 0 1 4.619-6.273zM4.069 13h2.974c.136 2.379.665 4.478 1.556 6.23A8.01 8.01 0 0 1 4.069 13zm7.381 6.973C10.049 18.275 9.222 15.896 9.041 13h6.113c-.208 2.773-1.117 5.196-2.603 6.972-.182.012-.364.028-.551.028-.186 0-.367-.016-.55-.027zm4.011-.772c.955-1.794 1.538-3.901 1.691-6.201h2.778a8.005 8.005 0 0 1-4.469 6.201z" }, null, -1)
                                    ]))),
                                    _cache[29] || (_cache[29] = _createTextVNode(" Get Shiriki WiFi App "))
                                  ])
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", null, [
                                    (_openBlock(), _createElementBlock("svg", _hoisted_41, _cache[30] || (_cache[30] = [
                                      _createElementVNode("path", { d: "M7 11h7v2H7zm0-4h10.97v2H7zm0 8h13v2H7zM4 4h2v16H4z" }, null, -1)
                                    ]))),
                                    _cache[31] || (_cache[31] = _createTextVNode(" Surveys "))
                                  ])
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", null, [
                                    (_openBlock(), _createElementBlock("svg", _hoisted_42, _cache[32] || (_cache[32] = [
                                      _createElementVNode("path", { d: "M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" }, null, -1)
                                    ]))),
                                    _cache[33] || (_cache[33] = _createTextVNode("   Feedback "))
                                  ])
                                ]),
                                _cache[37] || (_cache[37] = _createElementVNode("li", null, [
                                  _createElementVNode("hr", { class: "my-2" })
                                ], -1)),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", null, [
                                    (_openBlock(), _createElementBlock("svg", _hoisted_43, _cache[34] || (_cache[34] = [
                                      _createElementVNode("path", { d: "M12 6c3.537 0 6.837 1.353 9.293 3.809l1.414-1.414C19.874 5.561 16.071 4 12 4c-4.071.001-7.874 1.561-10.707 4.395l1.414 1.414C5.163 7.353 8.463 6 12 6zm5.671 8.307c-3.074-3.074-8.268-3.074-11.342 0l1.414 1.414c2.307-2.307 6.207-2.307 8.514 0l1.414-1.414z" }, null, -1),
                                      _createElementVNode("path", { d: "M20.437 11.293c-4.572-4.574-12.301-4.574-16.873 0l1.414 1.414c3.807-3.807 10.238-3.807 14.045 0l1.414-1.414z" }, null, -1),
                                      _createElementVNode("circle", {
                                        cx: "12",
                                        cy: "18",
                                        r: "2"
                                      }, null, -1)
                                    ]))),
                                    _cache[35] || (_cache[35] = _createTextVNode(" Internet Access "))
                                  ])
                                ])
                              ])
                            ], 4)
                          ]),
                          _createElementVNode("div", _hoisted_44, [
                            _createElementVNode("div", _hoisted_45, [
                              (_openBlock(), _createElementBlock("svg", {
                                xmlns: "http://www.w3.org/2000/svg",
                                style: _normalizeStyle({ fill: _ctx.pureColor }),
                                height: "1em",
                                viewBox: "0 0 512 512"
                              }, _cache[38] || (_cache[38] = [
                                _createElementVNode("path", { d: "M177.8 63.2l10 17.4c2.8 4.8 4.2 10.3 4.2 15.9v41.4c0 3.9 1.6 7.7 4.3 10.4c6.2 6.2 16.5 5.7 22-1.2l13.6-17c4.7-5.9 12.9-7.7 19.6-4.3l15.2 7.6c3.4 1.7 7.2 2.6 11 2.6c6.5 0 12.8-2.6 17.4-7.2l3.9-3.9c2.9-2.9 7.3-3.6 11-1.8l29.2 14.6c7.8 3.9 12.6 11.8 12.6 20.5c0 10.5-7.1 19.6-17.3 22.2l-35.4 8.8c-7.4 1.8-15.1 1.5-22.4-.9l-32-10.7c-3.3-1.1-6.7-1.7-10.2-1.7c-7 0-13.8 2.3-19.4 6.5L176 212c-10.1 7.6-16 19.4-16 32v28c0 26.5 21.5 48 48 48h32c8.8 0 16 7.2 16 16v48c0 17.7 14.3 32 32 32c10.1 0 19.6-4.7 25.6-12.8l25.6-34.1c8.3-11.1 12.8-24.6 12.8-38.4V318.6c0-3.9 2.6-7.3 6.4-8.2l5.3-1.3c11.9-3 20.3-13.7 20.3-26c0-7.1-2.8-13.9-7.8-18.9l-33.5-33.5c-3.7-3.7-3.7-9.7 0-13.4c5.7-5.7 14.1-7.7 21.8-5.1l14.1 4.7c12.3 4.1 25.7-1.5 31.5-13c3.5-7 11.2-10.8 18.9-9.2l27.4 5.5C432 112.4 351.5 48 256 48c-27.7 0-54 5.4-78.2 15.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" }, null, -1)
                              ]), 4))
                            ]),
                            _createElementVNode("img", {
                              src: "wifi-transparent.png",
                              class: "mx-auto mt-3",
                              style: _normalizeStyle({
                          width: 'auto',
                          height: '30px',
                          backgroundColor: _ctx.pureColor,
                        }),
                              alt: ""
                            }, null, 4),
                            (_ctx.previewLogoURL)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: _ctx.previewLogoURL,
                                  alt: "Logo",
                                  class: "mt-3 mx-auto h-auto w-auto",
                                  style: {"max-height":"60px"}
                                }, null, 8, _hoisted_46))
                              : _createCommentVNode("", true),
                            _createElementVNode("p", _hoisted_47, _toDisplayString(_ctx.captivePortalData.primary_message), 1),
                            (_ctx.captivePortalData.show_connect_message)
                              ? (_openBlock(), _createElementBlock("h3", _hoisted_48, " You need to connect to " + _toDisplayString(_ctx.captivePortalData.name
                              ? _ctx.captivePortalData.name
                              : 'ARED') + " to get internet. ", 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("button", {
                              type: "button",
                              class: "w-40 block mt-3 mx-auto text-[12px] text-white rounded-[0.432rem] px-3 py-2",
                              style: _normalizeStyle({ backgroundColor: _ctx.pureColor })
                            }, " CONNECT TO " + _toDisplayString(_ctx.captivePortalData.name
                              ? _ctx.captivePortalData.name
                              : 'ARED'), 5)
                          ])
                        ])
                      ]),
                      _createElementVNode("img", {
                        src: "wifi-transparent.png",
                        class: "mx-auto mt-3",
                        style: _normalizeStyle({ backgroundColor: _ctx.pureColor }),
                        alt: ""
                      }, null, 4),
                      (_ctx.previewLogoURL)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: _ctx.previewLogoURL,
                            alt: "Logo",
                            class: "mt-3 mx-auto h-auto w-40",
                            style: {"max-height":"140px"}
                          }, null, 8, _hoisted_49))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.captivePortalData.primary_message), 1),
                      (_ctx.captivePortalData.show_connect_message)
                        ? (_openBlock(), _createElementBlock("h3", _hoisted_51, " You need to connect to " + _toDisplayString(_ctx.captivePortalData.name ? _ctx.captivePortalData.name : 'ARED') + " to get internet. ", 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("button", {
                        type: "button",
                        class: "w-40 block mt-3 mx-auto text-[12px] text-white rounded-[0.432rem] px-3 py-2",
                        style: _normalizeStyle({ backgroundColor: _ctx.pureColor })
                      }, " CONNECT TO " + _toDisplayString(_ctx.captivePortalData.name ? _ctx.captivePortalData.name : 'ARED'), 5)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}