<template>
  <div
    class="bg-light w-full rounded-[0.938rem] py-4 md:px-6 px-3 shadow overflow-auto relative"
  >
    <h4 class="flex justify-center font-bold mb-3">REALM - SALE</h4>

    <div class="flex items-center justify-between">
      <div
        class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
      >
        REALMS MEDIA
      </div>
    </div>
    <div class="md:w-[100%] w-[50%] mb-2 mt-4">
      <label class="relative block">
        <span class="sr-only">Search</span>
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <img
            src="../../assets/search-icon.png"
            alt=""
            class="w-[0.8rem] h-[0.8rem]"
          />
        </span>
        <input
          v-model="searchQuery"
          class="bg-white font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
          placeholder="Search"
          type="text"
          name="search"
        />
      </label>
    </div>
    <div
      class="p-3 flex items-center justify-between md:w-[100%] w-[50%]"
      v-if="showButtons()"
    >
      <div>
        <span class="me-2 text-[#d3aa3b] font-bold text-sm"
          >{{ rowsSelected }} Rows selected</span
        >
        <span
          class="cursor-pointer text-[#5e72e4] font-bold text-sm"
          @click="clearRows"
          >clear</span
        >
      </div>
      <div>
        <button
          type="button"
          class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
          @click="unallocateRoutersFromSme"
        >
          unassign SME router
        </button>
        <button
          type="button"
          class="capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
          @click="unallocateRoutersFromPartner"
        >
          unassign Partner router
        </button>
      </div>
    </div>
    <SmeTable
      :sorting="true"
      @sort="sortBy"
      :inputShow="true"
      :selectAllValues="selectAll"
      @selectAllRows="selectAllRows"
      :tableName="'Realm Routers'"
      :tableHeaderData="accountsTableHeader"
    >
      <template v-slot:tableBodyData>
        <span
          v-if="!paginatedTableData.length"
          class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
        >
          No records
        </span>

        <div
          v-else
          v-for="item in paginatedTableData"
          :key="item.id"
          class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
        >
          <td>
            <div class="flex items-center">
              <div class="flex flex-col ml-3">
                <input
                  v-model="item.selected"
                  @change="updateSelectedRows(item)"
                  type="checkbox"
                  class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center">
              <div class="flex flex-col ml-3">
                <span class="b-avatar-img capitalize text-[#464E5F] mb-0.5">{{
                  item.title
                }}</span>
              </div>
            </div>
          </td>

          <td>
            <div class="flex items-center">
              <div class="flex flex-col ml-3">
                <span class="b-avatar-img capitalize text-[#464E5F] mb-0.5">{{
                  item.is_active
                }}</span>
              </div>
            </div>
          </td>

          <td>
            <div class="flex flex-col">
              <span class="capitalize text-[#464E5F] mb-0.5">{{
                item.created
              }}</span>
            </div>
          </td>

          <td>
            <div>
              <span
                @click="downloadFile(item.file, item.file_type)"
                class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center"
                >Download<i class="ml-1.5 fa-solid fa-download"></i
              ></span>
            </div>
          </td>
          <td>
            <div class="flex flex-col">
              <i
                @click="disableMedia(item.id)"
                class="fa-solid fa-trash text-lg text-[#d7191c]"
              ></i>
            </div>
          </td>
        </div>
      </template>
    </SmeTable>
    <div class="spinner-container">
      <div v-if="isLoading" class="spinner"></div>
    </div>
    <DashboardTableTitle
      v-if="propLoaded"
      :perPageItems="perSize"
      :pageNumber="page"
      :ApiData="apiData"
      @handleNext="handlleNext"
      @handlePrev="handlePrev"
    >
      <template v-slot:section>
        <div>
          <div class="flex items-center">
            <span
              class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]"
              >Rows per page</span
            ><span
              class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]"
            >
              <select v-model="selectedOption" @change="handleChange">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select></span
            >
          </div>
        </div>
      </template>
    </DashboardTableTitle>
  </div>
</template>
  
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import { mapGetters } from 'vuex';
import instance from '../../axios-interceptor';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import moment from 'moment';
import { useRoute } from '@/router';

export default defineComponent({
  name: 'realmOfflineFiles',
  components: {
    DashboardTableTitle,
    SmeTable,
  },
  props: {
    modal: {
      type: Boolean,
    },
    voucherId: {
      type: [String, Array],
    },
  },
  setup() {
    // const mode = ref(4);
    const pageNumber = ref(1);
    const perPageItems = ref(5);
    const startIndex = ref(0);

    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Ticket ID');

    const center = ref({ lat: -1.8899577, lng: 30.0634073 });

    const accountsTableHeader = ref([
      'Category',
      'Status',
      'Added',
      'Download',
      'Delete',
    ]);

    const selectedSubscription = ref(false);

    return {
      center,

      tableName,
      icon,

      selectedSubscription,

      pageNumber,
      perPageItems,
      startIndex,

      accountsTableHeader,
    };
  },
  data() {
    return {
      showModal: false,
      realm: {
        id: '',
        created: '',
        no_of_routers: '',
        sme: {
          name: '',
          id: '',
        },
        name: '',
      },
      filteredRouters: [
        {
          id: '',
          created: '',
          selected: false,
          status: '',
          category: '',
        },
      ],
      changeName: false,
      rowsSelected: 0,
      selectAll: false,
      selectedRows: [] as string[],
      page: 1,
      perSize: 10,
      sortKey: '', // Sorting key
      sortDirection: 'asc',
      searchQuery: '',
      propLoaded: false,
      selectedOption: 10,
      apiData: [],
      isLoading: false,
      activeTab: 'partner',
      filteredMicroFranchisees: [],
      adminRole: 'partner_admin',
      totalMicroFranchisees: null,
      baseUserUrl: '',
    };
  },

  methods: {
    downloadFile(file: string, fileType: string) {
      window.open(file, '_blank', 'noreferrer');
    },
    async disableMedia(id: any) {
      try {
        await instance
          .patch(`files/${id}/`, { status: 'Disabled' })
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success('Media has been successfully deleted', {
                position: 'top-right',
              });
            }
          });
      } catch (e: any) {
        if (e.response.data.detail) {
          this.$toast.error(e.response.data.detail, {
            position: 'top-right',
          });
        } else {
          this.$toast.error('Could not delete media at this time', {
            position: 'top-right',
          });
        }
      }
    },
    selectAllRows(checked: any) {
      this.selectAll = checked;
      this.filteredRouters.forEach((row) => {
        row.selected = this.selectAll;
      });
      this.rowsSelected = this.paginatedTableData.length;
    },
    sortBy(key: string) {
      let currentKey;
      if (key === 'Category') {
        currentKey = 'category';
      } else if (key === 'Status') {
        currentKey = 'status';
      } else {
        currentKey = 'created';
      }
      if (this.sortKey === currentKey) {
        // Toggle the sort direction if the same key is clicked again
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = currentKey;
        this.sortDirection = 'asc';
      }
    },
    updateSelectedRows(row: any) {
      if (!row.selected) {
        this.selectAll = false;
      } else {
        const selectedRows = this.filteredRouters.filter((row) => row.selected);
        this.rowsSelected = selectedRows.length;
        this.selectAll = selectedRows.length === this.filteredRouters.length;
      }
    },
    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('M/D/YYYY, h:mm:ss a');
      }
    },
    handlePrev() {
      this.page--;
      this.getAdvert(this.voucherId);
    },
    handlleNext() {
      this.page++;
      this.getAdvert(this.voucherId);
    },
    handleChange() {
      this.perSize = this.selectedOption;
      this.getAdvert(this.voucherId);
    },

    unallocateRoutersFromSme() {
      const route = useRoute();
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const assignSmeRoutersUrl = 'routers/assign-to-sme/';
      const data = {
        routers: selectedRouters,
        sme: route.params.id,
      };
      instance
        .patch(assignSmeRoutersUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.getAdvert(this.voucherId);
            this.$toast.success(response.data.detail, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unallocateRoutersFromPartner() {
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const unassignRoutersFromPartnerUrl = 'routers/assign-to-partner/';
      const data = {
        routers: selectedRouters,
      };
      instance
        .patch(unassignRoutersFromPartnerUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.getAdvert(this.voucherId);
            this.$toast.success(`Router removed`, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearRows() {
      this.paginatedTableData.filter((row) => (row.selected = false));
      this.selectAll = false;
    },
    showButtons() {
      return this.paginatedTableData.some((row) => row.selected === true);
    },
    async getAdvert(id: any) {
      this.isLoading = true;
      let voucherUrl = `files/?realm=${id}&status=Enabled`;

      await instance.get(voucherUrl).then((response) => {
        if (response.status === 200) {
          this.isLoading = false;
          this.propLoaded = true;
          this.filteredRouters = response.data.map((row: any) => ({
            ...row,
            selected: false,
          }));
        }
      });
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),

    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.filteredRouters;
      }

      // Filter table data based on search query
      const filteredData = this.filteredRouters.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const category = item.category.toString().toLowerCase();
        const status = item.status.toString().toLowerCase();

        const created = this.createdAt(item.created.toString().toLowerCase());
        const query = this.searchQuery.toLowerCase();

        //Check if name or age contain the search query
        return (
          category?.includes(query) ||
          status?.includes(query) ||
          created?.includes(query)
        );
      });

      return filteredData;
    },

    sortedTableData(sortKey: any): any[] {
      if (!this.sortKey) {
        // If sort key is empty, return the filtered data as-is
        return this.filteredTableData;
      }

      // Sort the filtered table data based on the sort key and direction
      const sortedData = this.filteredTableData.slice().sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];

        // Perform the actual comparison based on the data type
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return (
            aValue.localeCompare(bValue) *
            (this.sortDirection === 'asc' ? 1 : -1)
          );
        } else {
          return (aValue - bValue) * (this.sortDirection === 'asc' ? 1 : -1);
        }
      });

      return sortedData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.sortedTableData.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.getAdvert(this.voucherId);
  },
});
</script>
  