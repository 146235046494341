import { createApp } from 'vue';
import Toast from 'vue-toast-notification';
import VueSweetalert2 from 'vue-sweetalert2';
import Vue3ColorPicker from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import './styles/app.css';
import App from './App.vue';
import router from './router';
// import './registerServiceWorker';
import { store, key } from './store';

const swalOptions = {
  confirmButtonColor: '#348f6a',
  cancelButtonColor: '#ff7674',
  timer: 2000,
};

createApp(App)
  .use(Toast)
  .use(store, key)
  .use(router)
  .use(VueSweetalert2, swalOptions)
  .use(Vue3ColorPicker)
  .mount('#app');
