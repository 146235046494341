<template>
  <div>
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="flex justify-between mb-3 items-center">
          <h3
            class="text-left font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
          >
            Internet Settings
          </h3>
          <button
            v-if="!show"
            type="button"
            @click="showForm"
            class="bg-[#2f6887] font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-4 font-medium border"
          >
            Add Setting
          </button>
        </div>

        <div
          class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative"
        >
          <div class="main-block" v-if="show">
            <div
              class="flex justify-between p-3 border-[#e5e5e5] border rounded mt-3 mb-4"
            >
              <h3>Internet Setting History</h3>
              <button
                type="button"
                @click="hideForm"
                class="bg-[#2f6887] font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-4 font-medium border"
              >
                Cancel
              </button>
            </div>
            <div class="grid grid-cols-4 gap-4">
              <div class="mb-2">
                <label
                  for="small-input"
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >Pack Name:</label
                >
                <input
                  required=""
                  type="text"
                  id="amount"
                  v-model="pack_name"
                  class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                />
              </div>

              <div class="mb-2">
                <label
                  for="small-input"
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >Price Per GB:</label
                >
                <input
                  required=""
                  type="number"
                  id="amount"
                  v-model="profitCalculator.price_per_gb"
                  class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                />
              </div>

              <div class="mb-2">
                <label
                  for="small-input"
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >Minimum Profit (%):</label
                >
                <input
                  required=""
                  type="number"
                  id="amount"
                  v-model="profitCalculator.minimum_profit_percentage"
                  class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                />
              </div>

              <div class="mb-2">
                <label
                  for="small-input"
                  class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                  >Bandwidth (Mbps):</label
                >
                <input
                  required=""
                  type="number"
                  id="amount"
                  v-model="profitCalculator.bandwidth"
                  class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                />
              </div>
            </div>
            <div class="setting-btns mb-4">
              <button
                type="button"
                @click="saveSetting"
                class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
              >
                Submit
              </button>
              <button
                type="button"
                @click="hideForm"
                class="bg-[#d7191c] font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
              >
                Cancel
              </button>
            </div>

            <div
              class="amount-list bg-light p-5 rounded-[12px] mb-4 m-auto w-[260px]"
            >
              <ul class="list-none">
                <li>
                  Price Per GB:
                  <strong>CAF {{ profitCalculator.price_per_gb }}</strong>
                </li>
                <li>
                  Percentage Profit:
                  <strong>{{
                    profitCalculator.minimum_profit_percentage
                  }}</strong>
                </li>
                <li>
                  Total Amount:
                  <strong>CAF {{ totalPrice ? totalPrice : 0 }}</strong>
                </li>
                <li>
                  Price Per Minute:
                  <strong
                    >CAF
                    {{ pricePerGbPerMinute ? pricePerGbPerMinute : 0 }}</strong
                  >
                </li>
              </ul>
            </div>

            <div
              class="mb-4 shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6"
            >
              <table class="w-full table-fixed table-border mb-4">
                <thead>
                  <tr>
                    <th class="text-[13px] text-left p-2 text-[#434349]">
                      TIME (MINUTES)
                    </th>
                    <th class="text-[13px] text-left p-2 text-[#434349]">
                      BUYING PRICE PER MINUTE
                    </th>
                    <th class="text-[13px] text-left p-2 text-[#434349]">
                      SELLING PRICE PER MINUTE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-light">
                    <td class="font-poppin text-[1rem] text-[#888889]">15</td>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      {{
                        retailPricePerGbPerMinute
                          ? retailPricePerGbPerMinute * 15
                          : 0
                      }}
                    </td>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      {{ pricePerGbPerMinute ? pricePerGbPerMinute * 15 : 0 }}
                    </td>
                  </tr>

                  <tr>
                    <td class="font-poppin text-[1rem] text-[#888889]">30</td>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      {{
                        retailPricePerGbPerMinute
                          ? retailPricePerGbPerMinute * 30
                          : 0
                      }}
                    </td>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      {{ pricePerGbPerMinute ? pricePerGbPerMinute * 30 : 0 }}
                    </td>
                  </tr>

                  <tr class="bg-light">
                    <td class="font-poppin text-[1rem] text-[#888889]">45</td>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      {{
                        retailPricePerGbPerMinute
                          ? retailPricePerGbPerMinute * 45
                          : 0
                      }}
                    </td>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      {{ pricePerGbPerMinute ? pricePerGbPerMinute * 45 : 0 }}
                    </td>
                  </tr>

                  <tr>
                    <td class="font-poppin text-[1rem] text-[#888889]">60</td>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      {{
                        retailPricePerGbPerMinute
                          ? retailPricePerGbPerMinute * 60
                          : 0
                      }}
                    </td>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      {{ pricePerGbPerMinute ? pricePerGbPerMinute * 60 : 0 }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <SmeTable
            :tableName="'Aggregators'"
            :tableHeaderData="accountsTableHeader"
          >
            <template v-slot:tableBodyData>
              <span
                v-if="!settings.length"
                class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
              >
                No records
              </span>
              <div
                v-else
                v-for="item in settings"
                :key="item.id"
                class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
              >
                <td>
                  <div class="flex items-center">
                    <div class="flex flex-col ml-3">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.id
                      }}</span>
                    </div>
                  </div>
                </td>

                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.name
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.price_per_gb
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.minimum_profit_percentage
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.total_internet_price
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.max_speed_in_mbps
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.price_per_minute
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="capitalize text-[#464E5F] mb-0.5">
                      {{ new Date(item.created).toLocaleString() }}</span
                    >
                  </div>
                </td>
              </div>
            </template>
          </SmeTable>

          <div v-if="isLoading" class="spinner-container">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import instance from '@/axios-interceptor';
import { mapGetters } from 'vuex';
export default {
  components: { SmeTable },
  setup() {
    // const mode = ref(4);

    const tableName = ref('Ticket ID');

    const accountsTableHeader = ref([
      'Id',
      'Pack Name',
      'Price Per GB',
      'Minimum Profit %',
      'Total Internet Price (after profit)',
      'Bandwidth (Mbps)',
      'Price Per Minute',
      'Date',
    ]);

    return {
      tableName,

      accountsTableHeader,
    };
  },
  data() {
    return {
      settings: [
        {
          price_per_minute: '',
          max_speed_in_mbps: '',
          minimum_profit_percentage: '',
          total_internet_price: '',
          price_per_gb: '',
          name: '',
          id: '',
          created: '',
        },
      ],
      page: 1,
      perSize: 10,
      propLoaded: true,
      loading: false,
      selectedOption: 10,
      apiData: [],

      pack_name: '',
      show: false,
      profitCalculator: {
        price_per_gb: '',
        minimum_profit_percentage: '',
        bandwidth: '',
      },
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    pricePerGbPerMinute() {
      if (this.totalPrice && this.profitCalculator.bandwidth) {
        if (this.profitCalculator.bandwidth == 4) {
          const gbPrice = this.totalPrice / 30;
          return gbPrice.toFixed(2);
        } else if (this.profitCalculator.bandwidth > 4) {
          const correctionFactor = (this.profitCalculator.bandwidth - 4) * 5;
          const gbPrice = this.totalPrice / (30 - correctionFactor);
          return gbPrice.toFixed(2);
        } else if (
          this.profitCalculator.bandwidth > 0 &&
          this.profitCalculator.bandwidth < 4
        ) {
          const correctionFactor = (4 - this.profitCalculator.bandwidth) * 5;
          const gbPrice = this.totalPrice / (30 + correctionFactor);
          return gbPrice.toFixed(2);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    retailPricePerGbPerMinute() {
      if (
        this.profitCalculator.price_per_gb &&
        this.profitCalculator.bandwidth
      ) {
        if (this.profitCalculator.bandwidth == 4) {
          const gbPrice = this.profitCalculator.price_per_gb / 30;
          return gbPrice.toFixed(2);
        } else if (this.profitCalculator.bandwidth > 4) {
          const correctionFactor = (this.profitCalculator.bandwidth - 4) * 5;
          const gbPrice =
            this.profitCalculator.price_per_gb / (30 - correctionFactor);
          return gbPrice.toFixed(2);
        } else if (
          this.profitCalculator.bandwidth > 0 &&
          this.profitCalculator.bandwidth < 4
        ) {
          const correctionFactor = (4 - this.profitCalculator.bandwidth) * 5;
          const gbPrice =
            this.profitCalculator.price_per_gb / (30 + correctionFactor);
          return gbPrice.toFixed(2);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    totalPrice() {
      if (this.profitCalculator.minimum_profit_percentage) {
        const totalPrice =
          ((100 + parseInt(this.profitCalculator.minimum_profit_percentage)) /
            100) *
          parseInt(this.profitCalculator.price_per_gb);
        return totalPrice.toFixed(2);
      } else {
        return null;
      }
    },
  },
  methods: {
    showForm() {
      this.show = true;
    },
    hideForm() {
      this.show = false;
    },
    handlePrev() {
      this.page--;
    },
    handlleNext() {
      this.page++;
    },

    handleChange() {
      this.perSize = this.selectedOption;
      this.getRouterData(this.activeTab);
    },
    reloadIt() {
      if (window.location.href.substr(-2) !== '?r') {
        window.location = window.location.href + '/#';
      }
    },
    async getInternetSettings() {
      await instance
        .get(`internet-settings/?partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.settings = response.data;
            console.log(this.settings);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(error.response[0], {
              position: 'top-right',
            });
          }
        });
    },
    saveSetting() {
      console.log(this.totalPrice, this.totalPrice);
      const data = {
        name: this.pack_name,
        partner: this.loggedInUser.partner.id,
        price_per_gb: this.profitCalculator.price_per_gb,
        minimum_profit_percentage:
          this.profitCalculator.minimum_profit_percentage,
        max_speed_in_mbps: this.profitCalculator.bandwidth,
        total_internet_price: this.totalPrice,
      };
      instance
        .post('internet-settings/', data)
        .then((response) => {
          this.loading = false;
          this.show = false;
          this.getInternetSettings();
          if (response.status === 201) {
            this.$toast.success(' Setting added Successfully', {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);

          this.responseErrors = error.response.data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getInternetSettings();
    //this.reloadIt();
  },
};
</script>
