export const dashboard_routes = [
  {
    id: 1,
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'fa-solid fa-desktop',
  },
  {
    id: 2,
    title: 'Analytics',
    path: '/analytics',
    icon: 'fa-solid fa-chart-column',
  },
  {
    id: 7,
    title: 'Content Management',
    path: '/adverts',
    icon: 'fa-solid fa-book',
    subMenus: [
      {
        id: 71,
        title: 'Adverts',
        path: '/adverts',
        icon: 'fa-solid fa-folder',
      },
      {
        id: 72,
        title: 'Surveys',
        path: '/surveys',
        icon: 'fa-solid fa-list',
      },
      {
        id: 73,
        title: 'Offline Files',
        path: '/offline-files',
        icon: 'fa-solid fa-folder-open',
      },
    ],
  },
  {
    id: 3,
    title: 'Micro Franchisee Management',
    path: '/micro-franchisees',
    icon: 'fa-solid fa-users',
    subMenus: [
      {
        id: 31,
        title: 'MF Profiles',
        path: '/micro-franchisees',
        icon: 'fa-solid fa-users',
      },
      {
        id: 32,
        title: 'Float Transfer',
        path: '/float-transfer',
        icon: 'fa-solid fa-right-left',
      },
      {
        id: 33,
        title: 'Services',
        path: '/services',
        icon: 'fa-solid fa-right-left',
      },
    ],
  },
  {
    id: 4,
    title: 'Kiosk Management',
    path: '/kiosks',
    icon: 'fa-solid fa-building',
  },
  {
    id: 5,
    title: 'Officers',
    path: '/field-officers',
    icon: 'fa-solid fa-user',
    subMenus: [
      {
        id: 51,
        title: 'Field Officers',
        path: '/field-officers',
        icon: 'fa-solid fa-users',
      },
      {
        id: 52,
        title: 'Maintenance Officers',
        path: '/maintenance-officers',
        icon: 'fa-solid fa-gear',
      },
    ],
  },
  {
    id: 6,
    title: 'Router Management',
    path: '/routers',
    icon: 'fa-solid fa-globe',
    subMenus: [
      {
        id: 61,
        title: 'Routers',
        path: '/routers',
        icon: 'fa-solid fa-wifi',
      },
      {
        id: 62,
        title: 'Realms',
        path: '/realms',
        icon: 'fa-solid fa-layer-group',
      },
      {
        id: 63,
        title: 'Vouchers',
        path: '/vouchers',
        icon: 'fa-solid fa-ticket',
      },
      {
        id: 64,
        title: 'Subscription',
        path: '/subscription',
        icon: 'fa-solid fa-dollar-sign',
      },
      {
        id: 65,
        title: 'Subscriber',
        path: '/subscriber',
        icon: 'fa-solid fa-dollar-sign',
      },
    ],
  },
  {
    id: 8,
    title: 'Shiriki Add-On',
    path: '/shiriki-add-on',
    icon: 'fa-solid fa-puzzle-piece',
  },
  {
    id: 9,
    title: 'Landing Page Builder',
    path: '/captive-portal',
    icon: require('../../assets/captive-portal-icon.svg'),
  },
  {
    id: 10,
    title: 'Billing',
    path: '/billings',
    icon: 'fa-solid fa-dollar-sign',
  },
  {
    id: 11,
    title: 'SMEs',
    path: '/smes',
    icon: 'fa-solid fa-briefcase',
  },
  {
    id: 12,
    title: 'Location Cluster',
    path: '/locations-cluster',
    icon: 'fa-solid fa-map',
  },
  {
    id: 13,
    title: 'App store',
    path: '/apps',
    icon: 'fa-solid fa-mobile',
  },
  {
    id: 14,
    title: 'User Management',
    path: '/system-admin',
    icon: 'fa-solid fa-users',
    subMenus: [
      {
        id: 141,
        title: 'User Signup',
        path: '/user-signup',
        icon: 'fa-solid fa-user-plus',
      },
      {
        id: 142,
        title: 'System Admins',
        path: '/system-admin',
        icon: 'fa-solid fa-users',
      },
      {
        id: 143,
        title: 'My Profile',
        path: '/account-info',
        icon: 'fa-solid fa-user',
      },
    ],
  },
];
