<template>
    <div style="display: flex; justify-content: space-between;">
        <div>
            <DashboardHeader title="Dashboard" :icon="icon.dashboard"></DashboardHeader>

        </div>

        <div style="display: flex;">
            <div style="margin-right: 5px;">
                <select v-model="selectedMonth">
                    <option v-for="(monthName, monthNumber) in months" :key="monthNumber" :value="monthNumber">
                        {{ monthName }}
                    </option>
                </select>
            </div>

            <div>
                <select v-model="selectedYear" v-on:change="fetchSentSMS(selectedYear)">
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
            </div>
        </div>

    </div>

    <div class="w-full my-3">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 pt-2">
            <div v-if="SMSData">
                <CardDesign :data="{
                    topic: 'SMS Sent',
                    figures: SMSData[months[selectedMonth]],
                    iconcolor: '#8280FF',
                    icon: 'fa-solid fa-message',
                    color: '#E5E4FF'
                }" />
            </div>

            <div>
                <CardDesign :data="{
                    topic: 'SMS Failed',
                    figures: 0,
                    iconcolor: '#FF9066',
                    icon: 'fa-solid fa-comment-slash',
                    color: '#FFDED1'
                }" />
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Router } from '@/interfaces/router';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import CardDesign from "../../components/Common/CardDesign.vue";
import {
    routerTableHeaders as _routerTableHeaders,
    routerTableIcons as _routerTableIcons,
    dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';
import instance from '@/axios-interceptor';
import { mapGetters } from "vuex";


export default defineComponent({
    name: 'HomePage',
    components: {
        DashboardHeader,
        CardDesign,
    },
    data() {
        return {
            selectedMonth: (new Date().getMonth() + 1),
            months: {
                1: 'Jan',
                2: 'Feb',
                3: 'Mar',
                4: 'Apr',
                5: 'May',
                6: 'Jun',
                7: 'Jul',
                8: 'Aug',
                9: 'Sep',
                10: 'Oct',
                11: 'Nov',
                12: 'Dec'
            },
            selectedYear: new Date().getFullYear(),
        }
    },
    computed: {
        ...mapGetters(["loggedInUser"]),
        years() {
            const currentYear = new Date().getFullYear();
            return Array.from({ length: 7 }, (_, index) => currentYear - 6 + index);
        }
    },
    setup() {
        const icon = ref({ ...dashboardHeadericon });
        const tableName = ref('Serial Number');
        const routers = ref<Router[]>([]);
        const routerTableHeaders = ref([..._routerTableHeaders]);
        const routerTableIcons = ref([..._routerTableIcons]);
        const fetchingRouters = ref(false);

        const sampleSMSData = ref([
            {
                name: "Jane Doe",
                message: "Hi there! Just checking in.",
                time: "10:35 AM",
                success: true,
            },
            {
                name: "David Smith",
                message: "Can we meet later today?",
                time: "11:12 AM",
                success: true,
            },
            {
                name: "Emily Jones",
                message: "The report is ready.",
                time: "2:05 PM",
                success: false
            },
        ]);

        const SMSData = ref({});

        return {
            fetchingRouters,
            routers,
            routerTableHeaders,
            routerTableIcons,
            tableName,
            icon,
            sampleSMSData,
            SMSData
        };
    },
    methods: {
        fetchSentSMS(year:number) {
            instance
                .get(`analytics/sent-token-sms/?year=${year}&partner=${this.loggedInUser.partner.id}`)
                .then((response: { data: any }) => {
                    this.SMSData = response.data.sent_sms;
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    },
    mounted() {
        this.fetchSentSMS(this.selectedYear);
    }
});
</script>


<style scoped>
.card {
    display: flex;
    justify-content: space-between;
}

.card .card-holder {
    width: 70%;

}

.check {
    color: #006665;
    margin-left: 50px
}

.info {
    color: red;
    margin-left: 50px
}

.name {
    font-weight: bold;
}

.sms-cont {
    box-shadow: 1px 1px 10px 1px rgb(219, 219, 219);
    margin-top: 3%;
    padding: 20px
}

select {
    width: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}
</style>
