import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { auth, billings } from './modules';
import { RootState } from './types/billing';

export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
  modules: {
    auth,
    billings,
  },
  plugins: [createPersistedState()],
});
