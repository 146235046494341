<template>
  <div class="w-full lg:flex justify-between">
    <div class="lg:w-[50%]">
      <div class="flex flex-col justify-center items-center h-full relative">
        <div class="max-form w-full lg:py-0 py-10">
          <img
            src="../../assets/ared-logo.svg"
            class="w-[8rem] lg:hidden block m-auto mb-5"
            alt="right view"
          />
          <div class="bg-white rounded-[0.432rem] lg:p-0 p-4">
            <h1 class="font-['poppins'] font-bold text-[26px] text-center">
              Reset Password
            </h1>

            <form class="mt-10">
              <TextField
                name="password"
                class="mb-10 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Password"
                type="password"
                v-model="v$.password.$model"
                :showErrors="v$.password.$errors.length ? true : false"
                :errors="v$.password.$errors"
              />
              <TextField
                name="password"
                class="font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Confirm Password"
                type="password"
                v-model="v$.confirmPassword.$model"
                :showErrors="v$.confirmPassword.$errors.length ? true : false"
                :errors="v$.confirmPassword.$errors"
              />
              <div v-if="passwordsNotMatch">
                <span class="text-red text-[12px] mt-2"
                  >Passwords must match</span
                >
              </div>
              <div v-if="passwordErrors.length">
                <p
                  v-for="error in passwordErrors"
                  :key="error"
                  class="text-red text-[12px] mt-2"
                >
                  {{ error }}
                </p>
              </div>
              <div class="flex">
                <button
                  :disabled="v$.$invalid"
                  type="button"
                  class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mr-2 mt-10"
                  @click="resetPassword"
                >
                  Reset
                  <span
                    v-if="loading"
                    :class="{ 'spinner btn-spinner ml-2': loading }"
                  >
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>

        <!-- form footer -->
        <div
          class="max-form w-full absolute bottom-5 justify-between items-center lg:flex hidden"
        >
          <!-- <div class="flex">
            <img
              src="../../assets/usa-flag.png"
              class="w-[20px] h-[20px] rounded-full mr-1"
              alt=""
            />
            <select class="border-none text-[#464E5F] text-[12px] font-medium">
              <option value="">English</option>
            </select>
          </div> -->

          <ul class="flex">
            <li>
              <span
                class="text-success font-poppins font-medium text-[0.778rem] mr-4"
                ><a href="javascript:void(0)">Terms</a></span
              >
            </li>
            <li>
              <span
                class="text-success font-poppins font-medium text-[0.778rem] mr-4"
                ><a href="javascript:void(0)">Plans</a></span
              >
            </li>
            <li>
              <span
                class="text-success font-poppins font-medium text-[0.778rem]"
                ><a href="javascript:void(0)">Contact Us</a></span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="lg:w-[50%]">
      <div class="flex justify-center items-center">
        <auth-right-image />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import AuthRightImage from "../../components/markup/AuthRightImage.vue";
import TextField from "../../components/reusable/TextField.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import router, { useRoute } from "@/router";
import instance from "@/axios-interceptor";

export default defineComponent({
  name: "PasswordResetPage",
  components: {
    AuthRightImage,
    TextField,
  },
  setup() {
    const formData = ref({
      password: "",
      confirmPassword: "",
    });

    const loading = ref(false);
    const passwordsNotMatch = ref(false);
    const passwordErrors = ref([]);

    const rules = {
      password: { required, minLength: minLength(6), maxLength: maxLength(50) },
      confirmPassword: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formData);

    return {
      formData,
      v$,
      loading,
      passwordsNotMatch,
      passwordErrors,
    };
  },
  methods: {
    async resetPassword() {
      const route = useRoute();

      if (this.formData.confirmPassword !== this.formData.password) {
        this.passwordErrors = [];
        this.passwordsNotMatch = true;
        return;
      }

      this.passwordsNotMatch = false;

      try {
        this.loading = true;

        const data = {
          uid: route.query.uid,
          token: route.query.token,
          new_password: this.formData.password,
        };
        await instance
          .post(`/auth/users/reset_password_confirm/`, data)
          .then((response) => {
            if (response.status === 204) {
              this.loading = false;
              router.push({ name: "login" });
              this.$toast.success("Password Reset successfully");
            }
          });
      } catch (e: any) {
        this.loading = false;

        if (e.response.data.new_password.length) {
          this.passwordErrors = e.response.data.new_password;
        } else {
          this.$toast.info("Could not reset password");
        }
      }
    },
    handleCancel() {
      router.push({ path: "/" });
    },
  },
});
</script>


<style scoped>
@media screen and (max-width: 1023px) {
  .max-form {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 100vh;
    background: linear-gradient(180deg, #4c8a6c 0%, #0b1c14 100%);
  }
}
</style>