<template>
  <div class="bg-light sm:p-5 p-4 rounded">
    <div class="row mb-4">
      <div class="col-12">
        <h3 class="text-left mb-3 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">Search
          Micro Franchisee Penalty</h3>
        <form>
          <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-1 mb-5">
            <div class="form-group col-md-8">
              <label for="phone" class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Micro Franchisee Phone Number:</label>
              <input id="phone" type="text" class="w-full text-[14px] w-6/12 border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder">
            </div>
            <div class="md:pt-[25px] pt-2">
              <button class="font-['poppins'] text-[14px] bg-primary text-white rounded-[0.4rem] px-3 py-2 font-medium">Search</button>
            </div>
          </div>
        </form>
      </div>

      <div class="col-12 pt-2">
        <h3 class="text-left mb-3 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">Micro
          Franchisee Details</h3>

        <div class="">
          <h3>Full Name</h3>
          <p>0712 345 678</p>
        </div>
        <form>
          <ul class="bg-[#4e8d6e] text-white p-3 px-4 rounded mt-3 md:w-2/4 w-full">
            <li class="flex justify-between mb-2">
              <label class="leading-5">Current account balance:</label>
              <P class="font-semibold pl-2 leading-5">CAF 71.27</P>
            </li>
            <li class="flex justify-between mb-2">
              <label class="leading-5">Current balance:</label>
              <P class="font-semibold pl-2 leading-5">CAF 0.00</P>
            </li>
            <li class="flex justify-between mb-2">
              <label class="leading-5">Penalty Charges:</label>
              <P class="font-semibold pl-2 leading-5">CAF 0.00</P>
            </li>
          </ul>

          <div class="mt-4 md:w-2/4 w-full">
            <div class="mb-2">
            <label for="paymentMode" class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Payment Type:</label>
            <div class="">
              <select class="form-select focus:outline-none w-full text-[14px] w-6/12 border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" id="paymentMode">
                    <option value="">Select penalty type:</option>
                  </select>
            </div>
            </div>

            <div class="mb-4">
            <label for="paymentMode" class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Reference Number:</label>
            <div class="">
              <input type="number" id="referenceNumber"
                    class="w-full text-[14px] w-6/12 border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder">
            </div>
            </div>

            <button class="font-['poppins'] text-[14px] bg-primary text-white rounded-[0.4rem] py-2 px-4 font-medium">
              Submit
            </button>
          </div>

        </form>






      </div>
    </div>
  </div>
</template>