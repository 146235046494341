<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3
            class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
          >
            Add Subscription
          </h3>
          <form>
            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              >
                Subscription Plan:
              </label>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="small-input"
                v-model="subscriptionPlan"
                required
              >
                <option value="">Select Subscription Plan</option>
                <option
                  v-for="plan in eligibleSubscriptionPlans"
                  :key="plan.name"
                  :value="plan.name"
                >
                  {{ plan.name }}
                </option>
              </select>
              <p class="text-primary my-3 text-sm" v-if="subscriptionPlans">
                {{ subscriptionPlanFeatures }}
              </p>
            </div>

            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              >
                Price In USD:</label
              >
              <input
                placeholder="Subscription plan price"
                v-model="price"
                type="number"
                id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
              />
            </div>

            <p
              class="text-red mt-3 text-sm mb-6"
              v-if="subscriptionPlans.length === 4"
            >
              Warning: You have already created the 1 eligible subscription plan.
            </p>

            <div
              class="w-full flex lg:justify-end justify-start items-center mt-3"
            >
              <button
                @click="addSubscriptionPlan"
                type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center"
                :class="{ 'cursor-not-allowed': !subscriptionPlan || !price }"
              >
                Add
                <span
                  v-if="loading"
                  :class="{ 'spinner btn-spinner ml-2': loading }"
                >
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent, PropType } from 'vue';

import axios from '@/axios-interceptor';
import { SubscriptionPlan, SubscriptionPlanPackage } from '@/interfaces/subscription';

export default defineComponent({
  name: 'subscriptionForm',
  props: {
    modal: {
      type: Boolean,
    },
    subscriptionPlans: {
      type: Array as PropType<SubscriptionPlan[]>,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      name: '',
      price: null,
      subscriptionPlan: '',
      subscriptionPlanPackages: [
        {
          name: 'Starter',
          features: 'Features: Basic Token QR Code system',
        },
        {
          name: 'Plus',
          features:
            'Features: Start option + Token receive via SMS, strong analytics, customer feedback, custom captive portal, & loyalty program',
        },
        {
          name: 'Pro',
          features: 'Features: Plus option + 15 GB of storage',
        },
        {
          name: 'Pro+',
          features:
            'Features: Security camera system (coming soon). Note: camera system is a separate package',
        },
      ] as SubscriptionPlanPackage[],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    subscriptionPlanFeatures(): string {
      const subscriptionPlan = this.subscriptionPlanPackages.find(
        (plan) => plan.name === this.subscriptionPlan
      );
      return subscriptionPlan ? subscriptionPlan.features : '';
    },
    eligibleSubscriptionPlans(): SubscriptionPlanPackage[] {
      return this.subscriptionPlanPackages.filter((planPackage) => {
        const found = this.subscriptionPlans.find((plan) => plan.name === planPackage.name);
        return !found;
      });
    },
  },
  methods: {
    async addSubscriptionPlan() {
      if (!this.subscriptionPlan || !this.price) return;

      let data = {
        price: this.price,
        name: this.subscriptionPlan,
        partner: this.loggedInUser.partner.id,
      };

      this.loading = true;

      await axios
        .post(`partner/subscription/`, data)
        .then(() => {
          this.loading = false;
          this.$toast.success('Subscription plan added successfully', {
            position: 'top-right',
          });
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error('Could not create the subscription plan', {
            position: 'top-right',
          });
        });
    },
  },
});
</script>
