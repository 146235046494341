import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col mt-6 md:mb-6 mb-3" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }
const _hoisted_4 = { class: "w-full shadow-lg px-6 pt-6 pb-8 shadow-gray-900/5 bg-white rounded-2xl full-width" }
const _hoisted_5 = { class: "grid lg:grid-cols-2 grid-cols-1 items-start gap-y-8 gap-x-8 lg:gap-y-16" }
const _hoisted_6 = { class: "user-profile-block" }
const _hoisted_7 = { class: "user-profile-innr w-full" }
const _hoisted_8 = { class: "w-full table-fixed table-border" }
const _hoisted_9 = { class: "bg-light" }
const _hoisted_10 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_11 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_12 = { class: "bg-light" }
const _hoisted_13 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_16 = { class: "bg-light" }
const _hoisted_17 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_20 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_21 = {
  key: 1,
  class: "bg-light"
}
const _hoisted_22 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_23 = { key: 2 }
const _hoisted_24 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_25 = {
  key: 3,
  class: "bg-light"
}
const _hoisted_26 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_27 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_28 = { class: "font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal" }
const _hoisted_29 = { class: "location-block" }
const _hoisted_30 = {
  key: 0,
  class: "full-width"
}
const _hoisted_31 = { class: "router-btns pt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrForm = _resolveComponent("qrForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, "Router - " + _toDisplayString(_ctx.router.serial_number), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[13] || (_cache[13] = _createElementVNode("h3", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor lg:mb-4 mb-2" }, " Router Details ", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("table", _hoisted_8, [
              _createElementVNode("tbody", null, [
                _createElementVNode("tr", _hoisted_9, [
                  _cache[2] || (_cache[2] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " Serial Number ", -1)),
                  _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.router.serial_number), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[3] || (_cache[3] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " IMEI ", -1)),
                  _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.router.imei), 1)
                ]),
                _createElementVNode("tr", _hoisted_12, [
                  _cache[4] || (_cache[4] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " Status ", -1)),
                  _createElementVNode("td", _hoisted_13, [
                    _createElementVNode("span", {
                      class: _normalizeClass([[
                      _ctx.router.active
                        ? 'text-[#4e8d6e] bg-[#DCE9E3]'
                        : 'text-white bg-[#d7191c]',
                    ], "font-poppins font-medium text-[0.8rem] px-3 py-1 rounded-[0.327rem]"])
                    }, _toDisplayString(_ctx.router.active ? 'Functional' : 'Damaged'), 3)
                  ])
                ]),
                (_ctx.router.location_name)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
                      _cache[5] || (_cache[5] = _createElementVNode("td", { lass: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " Location ", -1)),
                      _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.router.location_name), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", _hoisted_16, [
                  _cache[6] || (_cache[6] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " On Field ", -1)),
                  _createElementVNode("td", _hoisted_17, [
                    (_ctx.router.sme)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Yes"))
                      : (_openBlock(), _createElementBlock("span", _hoisted_19, "No"))
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _cache[7] || (_cache[7] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " Kiosk ", -1)),
                  _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.router?.router_kiosk?.serial_number), 1)
                ]),
                (_ctx.router.realm)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
                      _cache[8] || (_cache[8] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " Realm ", -1)),
                      _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.router.realm.name), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.router.super_realm)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_23, [
                      _cache[9] || (_cache[9] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " Super Realm ", -1)),
                      _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.router.super_realm_name), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.router.last_contact)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_25, [
                      _cache[10] || (_cache[10] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " IMEI ", -1)),
                      _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.router.last_contact), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", null, [
                  _cache[11] || (_cache[11] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " Assigned To ", -1)),
                  _createElementVNode("td", _hoisted_27, _toDisplayString(_ctx.router.sme?.name), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[12] || (_cache[12] = _createElementVNode("td", { class: "font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal" }, " Created ", -1)),
                  _createElementVNode("td", _hoisted_28, _toDisplayString(new Date(_ctx.router.created).toLocaleString()), 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor lg:mb-4 mb-2" }, " Router Location ", -1)),
          (_ctx.router?.lat === null && _ctx.router?.lon === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, _cache[14] || (_cache[14] = [
                _createElementVNode("h4", { class: "text-[#d7191c] md:text-[1rem] text-[0.9rem]" }, " Device Location Not Yet Set ", -1)
              ])))
            : _createCommentVNode("", true),
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "card-body" }, [
            _createElementVNode("div", {
              class: "w-full h-[500px] mt-6",
              id: "map"
            })
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showQR && _ctx.showQR(...args))),
          type: "submit",
          class: "bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
        }, " Get QR CCode "),
        (_ctx.showQRModal)
          ? (_openBlock(), _createBlock(_component_qrForm, {
              key: 0,
              QRnumber: _ctx.router.serial_number,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showQRModal = false))
            }, null, 8, ["QRnumber"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}