<template>
  <DashboardHeader title="Billing" description="" :icon="icon.Billing">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex justify-between">
        <span class="font-poppins font-medium text-[0.8rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[0.8rem] h-[0.8rem] rounded ml-1" />
      </div>
      <div v-if="activeTab === 'discount-coupons'">
        <button @click="showModal = true" type="button"
          class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]">
          <img src="../../assets/plus-icon.png" class="w-[0.6rem] h-[0.6rem] mr-1" alt="" />
          Create Coupon
        </button>
        <CreateCouponForm v-if="showModal" @close="closeModal"></CreateCouponForm>
      </div>
    </template>
  </DashboardHeader>

  <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative">
    <ul class="flex text-sm font-medium text-center mb-2">
      <li class="mr-2" v-for="tab in tabs" :key="tab.id">
        <button class="inline-block p-4 py-2 rounded-md w-full" :class="tab.isActive && tab.value == activeTab
          ? 'bg-light text-[#4e8d6e]'
          : 'bg-[transparent]'
          " @click="handleSetActiveTab(tab)">
          <!-- <img
            :src="item.image"
            class="mr-1"
          /> -->
          <span>{{ tab.label }}</span>
        </button>
      </li>
    </ul>


    <div class="flex justify-between">
      <p class="text-sm text-[#f24236]"
        v-if="selectedRows.length > 1 || selectedRows.length && selectedRows[0].is_paid">
        Only 1 unpaid billing can be selected</p>
      <button v-if="selectedRows.length === 1 && !selectedRows[0].is_paid
      " type="button"
        class="me-2 mb-2 cursor-pointer capitalize p-2 rounded bg-primary text-white inline-block text-center"
        @click="showMarkBillingAsPaidModal = true">
        Mark As Paid
      </button>
    </div>

    <!-- MARK BILLING AS PAID -->
    <confirmation-dialog :show="showMarkBillingAsPaidModal" title="Are you sure you want to mark the billing as paid"
      message="Please ensure that you only execute this action when the client has made a manual payment due to any issues with payment processing. Upon receiving the manual payment, kindly mark their billing as paid."
      actionLabel="Confirm" :loading="loading" @cancel="showMarkBillingAsPaidModal = false"
      @confirm="markBillinAsPaid()" />

    <!-- BILLING HISTORY -->
    <div v-if="activeTab === 'subscription'">
      <div class="w-full max-h-[25rem] overflow-auto relative">
        <SmeTable :tableName="'Billings'" :tableHeaderData="billingsTableHeader" :selectAllValues="selectAll"
          @selectAllRows="selectAllRows" :inputShow="true">
          <template v-slot:tableBodyData>
            <span v-if="!billings.length" class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap">
              No billing history found
            </span>
            <div v-else v-for="billing in billings" :key="billing.id"
              class="table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <input v-model="billing.selected" @change="updateSelectedRows(billing)" type="checkbox"
                      class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight" />
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    billing.sme.name
                  }}</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    moment(billing.due_date).format('MMMM Do, YYYY')
                  }}</span>
                </div>
              </td>
              <td v-if="!billing.is_paid" class="capitalize text-[#464E5F] mb-0.5">
                {{ billingStartDate }} to {{
                  billingEndDate }}
              </td>
              <td v-if="billing.is_paid" class="capitalize text-[#464E5F] mb-0.5">
                {{ moment(billing.start_date).format('D-MMM-YYYY') }} to {{
                  moment(billing.end_date).format('D-MMM-YYYY') }}
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    billing.is_paid ? 'Yes' : 'No'
                  }}</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{ billing.amount }} RWF</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    billing.subscription_plan.name
                  }}</span>
                </div>
              </td>
            </div>
          </template>
        </SmeTable>
      </div>
    </div>

    <!-- SMS BILLINGS -->
    <div v-if="activeTab === 'sms'">
      <div class="w-full max-h-[25rem] overflow-auto relative">
        <SmeTable :tableName="'SMS Billings'" :tableHeaderData="smsBillingTableHeadings">
          <template v-slot:tableBodyData>
            <span v-if="!smsBillings.length" class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap">
              No sms billing history found
            </span>
            <div v-else v-for="billing in smsBillings" :key="billing.id"
              class="table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    billing.sme.name
                  }}</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    moment(billing.due_date).format('MMMM Do, YYYY')
                  }}</span>
                </div>
              </td>
              <td v-if="!billing.is_paid" class="capitalize text-[#464E5F] mb-0.5">
                {{ billingStartDate }} to {{
                  billingEndDate }}
              </td>
              <td v-if="billing.is_paid" class="capitalize text-[#464E5F] mb-0.5">
                {{ moment(billing.start_date).format('D-MMM-YYYY') }} to {{
                  moment(billing.end_date).format('D-MMM-YYYY') }}
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    billing.is_paid ? 'Yes' : 'No'
                  }}</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{ billing.amount }} RWF</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    billing.sms_package.name
                  }}</span>
                </div>
              </td>
            </div>
          </template>
        </SmeTable>
      </div>
    </div>

    <!-- DISCOUNT COUPONS -->
    <div v-if="activeTab === 'discount-coupons'">
      <div class="w-full max-h-[25rem] overflow-auto relative">
        <SmeTable :tableName="'Discount Coupons'" :tableHeaderData="discounCouponsTableHeader">
          <template v-slot:tableBodyData>
            <span v-if="!discountCoupons.length" class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap">
              No discount coupons found
            </span>
            <div v-else v-for="discountCoupon in discountCoupons" :key="discountCoupon.id"
              class="table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    discountCoupon.sme.name
                  }}</span>
                </div>
              </td>
              <td>
                <span class="text-[#464E5F]">
                  {{ discountCoupon.code }}
                </span>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    discountCoupon.percentage_discount
                    }}%</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">

                  <span class="capitalize text-[#464E5F] mb-0.5" v-if="discountCoupon.addons.length">{{
                    discountCoupon.addons.map(addon => addon.name).join(', ')
                    }}</span>
                  <span v-else>
                    None
                  </span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5" v-if="discountCoupon.used">Yes</span>
                  <span class="capitalize text-[#464E5F] mb-0.5" v-else>No</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{ discountCoupon.created.split('T')[0] }}</span>
                </div>
              </td>
            </div>
          </template>
        </SmeTable>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import router from '@/router';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import {
  support as _support,
  supportTableHeaders as _supportTableHeaders,
  supportTableIcons as _supportTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';
import instance from '@/axios-interceptor';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import CreateCouponForm from '@/components/Billing/CreateCouponForm.vue';
import ConfirmationDialog from '@/components/Common/ConfirmationDialog.vue';

export default defineComponent({
  name: 'BillingPage',
  components: {
    DashboardHeader,
    SmeTable,
    CreateCouponForm,
    ConfirmationDialog,
  },
  setup() {
    const mode = ref(4);
    const activeTab = ref('subscription');
    const showPayBillModal = ref(false);
    const pageNumber = ref(1);
    const perPageItems = ref(5);
    const startIndex = ref(0);
    const isLoadingBillings = ref(false);
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Ticket ID');
    const routers = ref([..._support]);
    const routerTableHeaders = ref([..._supportTableHeaders]);
    const routerTableIcons = ref([..._supportTableIcons]);
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const showModal = ref(false);
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const activeSubscriptionPlans = ref([]);
    const cancelledSubscriptions = ref([]);
    const discountCoupons = ref([]);
    const selectAll = ref(false);
    const rowsSelected = ref(0);
    const selectedRows = ref([]);
    const loading = ref(false);
    const showMarkBillingAsPaidModal = ref(false);


    const billingsTableHeader = ref([
      'SME',
      'Billing Due Date',
      'Billing Cycle Month',
      'Paid',
      'Amount',
      'Subscription',
    ]);

    const smsBillingTableHeadings = [
      'SME',
      'Billing Due Date',
      'Billing Cycle Month',
      'Paid',
      'Amount',
      'SMS Package',
    ];

    const accountsTableHeader = ref([
      'Cancellation Date',
      'Reason',
      'Feedback',
      'Status',
    ]);

    const activeSubscriptionPlansTableHeader = ref([
      'SME',
      'Admin',
      'Email',
      'Subscription',
    ]);

    const cancelledSubscriptionsTableHeader = ref([
      'SME',
      'Admin',
      'Email',
      'Subscription',
      'Cancelled Date',
    ]);

    const discounCouponsTableHeader = ref([
      'SME',
      'Code',
      'Percentage',
      'Add-Ons',
      'Used',
      'Created',
    ]);

    const billingButtons = ref([
      { id: 1, label: 'Generate Monthly Revenues', isActive: false },
    ]);
    const selectedSubscription = ref(false);
    const tabs = ref([
      {
        id: 1,
        isActive: true,
        label: 'Subscription Plan + Add-Ons',
        value: 'subscription',
        image: require('../../assets/sync.png'),
      },
      {
        id: 2,
        isActive: false,
        label: 'SMS Package',
        value: "sms",
        image: require('../../assets/sync.png'),
      },
      {
        id: 3,
        isActive: false,
        label: 'Discount Coupons',
        value: 'discount-coupons',
        image: require('../../assets/sync.png'),
      },
    ]);

    return {
      routers,
      routerTableHeaders,
      routerTableIcons,
      center,
      markers,
      tableName,
      icon,
      activeSubscriptionPlansTableHeader,
      cancelledSubscriptionsTableHeader,
      billingButtons,
      selectedSubscription,
      isLoadingBillings,
      pageNumber,
      perPageItems,
      startIndex,
      showPayBillModal,
      mode,
      tabs,
      activeTab,
      billingsTableHeader,
      accountsTableHeader,
      activeSubscriptionPlans,
      cancelledSubscriptions,
      discountCoupons,
      discounCouponsTableHeader,
      showModal,
      selectAll,
      rowsSelected,
      selectedRows,
      loading,
      showMarkBillingAsPaidModal,
      smsBillingTableHeadings,
    };
  },
  computed: {
    ...mapGetters([
      'billings',
      'smsBillings',
      'payments',
      'customers',
      'chartData',
      'totalAmount',
      'revenues',
      'loggedInUser',
    ]),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    billingStartDate() {
      const startDate = new Date();

      return moment(startDate).format('D-MMM-YYYY');
    },
    billingEndDate() {
      // Set the start date as the current date
      const startDate = new Date();

      // Calculate the end date by adding 29 days to the start date
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 29);

      return moment(endDate).format('D-MMM-YYYY');
    },
  },
  methods: {
    moment: moment,
    handleSetActiveTab(tab) {
      this.activeTab = tab.value;
      tab.isActive = true;
      router.push({ query: { tab: tab.value } });
    },
    handleShowModal(id) {
      this.setMode(id);
      this.showPayBillModal = !this.showPayBillModal;
    },
    async getMonthlyRevenues() {
      this.isLoadingBillings = true;
      try {
        await this.$store.dispatch('getMonthlyRevenues');
        this.isLoadingBillings = false;
      } catch (error) {
        this.isLoadingBillings = false;
        console.log(error);
      }
    },
    async fetchSubscriptionBillings() {
      this.isLoadingBillings = true;
      const partnerId = localStorage.getItem('partnerId');

      try {
        await this.$store.dispatch('fetchBillings', partnerId);
        this.isLoadingBillings = false;
      } catch (error) {
        this.isLoadingBillings = false;
        console.log(error);
      }
    },
    async fetchSMSPackageBillings() {
      this.isLoadingBillings = true;
      const partnerId = localStorage.getItem('partnerId');

      try {
        await this.$store.dispatch('fetchSMSBillings', partnerId);
        this.isLoadingBillings = false;
      } catch (error) {
        this.isLoadingBillings = false;
        console.log(error);
      }
    },
    async fetchActiveCancelledSubscriptions() {
      const partnerId = localStorage.getItem('partnerId');

      instance
        .get(`partner/subscription/active-cancelled?partner=${partnerId}`)
        .then((response) => {
          this.activeSubscriptionPlans = response.data.active_subscriptions;
          this.cancelledSubscriptions = response.data.cancelled_subscriptions;
        })
        .catch(() => {
          this.$toast.error(
            'Could not fetch active and cancelled subscriptions',
            {
              position: 'top-right',
            }
          );
        });
    },
    async fetchCustomers() {
      const partnerId = localStorage.getItem('partnerId');

      try {
        await this.$store.dispatch('fetchCustomers', partnerId);
      } catch (error) {
        console.log(error);
      }
    },
    fetchDiscountCoupons() {
      const partnerId = localStorage.getItem('partnerId');

      instance
        .get(`discount-coupon/?partner=${partnerId}`)
        .then((response) => {
          this.discountCoupons = response.data;
        })
        .catch(() => {
          this.$toast.error(
            'Could not fetch discount coupons',
            {
              position: 'top-right',
            }
          );
        });
    },
    getDate(date) {
      return moment(date).calendar();
    },
    formatDate(date, format = 'MM-DD-YYYY') {
      return moment(date).format(format);
    },
    formatRenewalDate(date, format = 'MM-DD-YYYY') {
      return moment(date).add(1, 'M').format(format);
    },
    handlePrev() {
      this.pageNumber--;
      this.startIndex -= this.perPageItems;
      this.fetchSubscriptionBillings();
    },
    handleNext() {
      this.pageNumber++;
      this.startIndex += this.perPageItems;
      this.fetchSubscriptionBillings();
    },
    handleCloseModal() {
      this.fetchSubscriptionBillings();
      this.showPayBillModal = false;
    },
    setMode(id) {
      this.mode = id;
    },
    closeModal() {
      this.showModal = false;
      this.fetchDiscountCoupons();
    },
    closeDialogModal() {
      this.$emit('close');
    },
    updateSelectedRows(row) {
      if (!row.selected) {
        this.selectAll = false;
        this.selectedRows = this.selectedRows.filter(
          (selectedRow) => selectedRow.id !== row.id
        );
      } else {
        this.selectedRows = this.billings.filter((billing) => billing.selected);
        this.rowsSelected = this.selectedRows.length;
        this.selectAll = this.selectedRows.length === this.billings.length;
      }
    },
    selectAllRows(checked) {
      this.selectAll = checked;
      this.billings.forEach((row) => {
        row.selected = this.selectAll;
      });

      if (this.selectAll) {
        this.selectedRows = [...this.billings];
        this.rowsSelected = this.selectedRows.length;
      } else {
        this.selectedRows = [];
        this.rowsSelected = 0;
      }
    },
    markBillinAsPaid() {
      this.error = '';
      this.loading = true;
      let payload = {
        billing_id: this.selectedRows[0].id,
        amount: this.selectedRows[0].final_amount,
      };

      instance
        .post('partner/billing/payment/', payload)
        .then(() => {
          this.$toast.success('The billing marked as paid successfully', {
            position: 'top-right',
          });
          this.loading = false;
          this.showBillingPaymentModal = false;
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {
          this.loading = false;

          if (error.response.status === 404) {
            this.$toast.error(error.response.data.message, {
              position: 'top-right',
            });
          } else {
            this.$toast.error('Could not mark the billing as paid', {
              position: 'top-right',
            });
          }

        });
    },
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    if (tab) {
      this.activeTab = tab;
      const targetedTab = this.tabs.find(t => t.value === tab);

      if (targetedTab) {
        targetedTab.isActive = true;
      }
    }

    this.fetchSubscriptionBillings();
    this.fetchSMSPackageBillings();
    this.fetchDiscountCoupons();
  },
});
</script>
