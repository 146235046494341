<template>
 
      <div class="bg-light sm:p-5 p-4 rounded">
        <h3 class="text-left mb-3 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">
          Aggregator Deposit
          </h3>
          <form class="">
            <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-1">
            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Aggregator:</label
              >
              <select
                class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="aggregator"
                required
              >
                <option value="">Select aggregator</option>
                
              </select>
            </div>
            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Amount:</label
              >
              <input
                required
                type="number"
                id="amount"
                class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
              />
            </div>
            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Payment Mode:</label
              >
              <select
                class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="paymentMode"
                required
              >
                <option value="">Select Payment Mode</option>
                
              </select>
            </div>
            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Reference Number:</label
              >
              <input
                required
                type="number"
                id="referenceNumber"
                class="w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
              />
            </div>
          </div>
            <div class="w-full flex lg:justify-end justify-end items-center mt-3">
              <button type="submit"
                class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border">
                Submit
              </button>
            </div>
          </form>
      </div>
 
</template>