import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect } from "vue"
import _imports_0 from '../../assets/plus-icon.png'
import _imports_1 from '../../assets/search-icon.png'


const _hoisted_1 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:py-6 md:px-6 px-3 shadow overflow-auto relative" }
const _hoisted_2 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  class: "flex flex-wrap -mb-px text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = { class: "mr-2" }
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = { id: "myTabContent" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "bg-light w-full rounded-[0.938rem] py-4 md:px-6 px-3 shadow overflow-auto relative my-6" }
const _hoisted_12 = { class: "flex justify-center font-bold uppercase mb-4" }
const _hoisted_13 = { class: "table-responsive w-full" }
const _hoisted_14 = { class: "min-w-full divide-y divide-gray-200" }
const _hoisted_15 = { class: "bg-gray-50" }
const _hoisted_16 = {
  key: 0,
  class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
}
const _hoisted_17 = {
  key: 1,
  class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
}
const _hoisted_18 = { class: "divide-y divide-gray-200 border-[#eaeaee]" }
const _hoisted_19 = { class: "px-3 py-1 whitespace-nowrap font-poppins text-xs" }
const _hoisted_20 = { class: "px-3 py-1 whitespace-nowrap font-poppins text-xs" }
const _hoisted_21 = { class: "px-3 py-1 whitespace-nowrap font-poppins text-xs" }
const _hoisted_22 = { class: "px-3 py-1 whitespace-nowrap font-poppins text-xs" }
const _hoisted_23 = {
  key: 1,
  class: "px-3 py-1 whitespace-nowrap font-poppins text-xs"
}
const _hoisted_24 = { class: "bg-light w-full rounded-[0.938rem] py-4 md:px-6 px-3 shadow overflow-auto relative" }
const _hoisted_25 = { class: "sm:flex items-center justify-between" }
const _hoisted_26 = { class: "sm:mb-0 mb-2" }
const _hoisted_27 = { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }
const _hoisted_28 = { class: "" }
const _hoisted_29 = { class: "md:w-[100%] w-[50%] mb-2 mt-4" }
const _hoisted_30 = { class: "relative block" }
const _hoisted_31 = {
  key: 0,
  class: "p-3 flex items-center justify-between md:w-[100%] w-[50%]"
}
const _hoisted_32 = { class: "me-2 text-[#d3aa3b] font-bold text-sm" }
const _hoisted_33 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
}
const _hoisted_34 = { class: "flex items-center" }
const _hoisted_35 = { class: "flex flex-col ml-3" }
const _hoisted_36 = ["onUpdate:modelValue", "onChange"]
const _hoisted_37 = { class: "flex items-center" }
const _hoisted_38 = { class: "flex flex-col ml-3" }
const _hoisted_39 = { class: "b-avatar-img capitalize text-[#464E5F] mb-0.5" }
const _hoisted_40 = { class: "flex flex-col" }
const _hoisted_41 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_42 = { class: "flex flex-col" }
const _hoisted_43 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_44 = { class: "flex flex-col" }
const _hoisted_45 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_46 = { class: "flex flex-col" }
const _hoisted_47 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_48 = ["onClick"]
const _hoisted_49 = { class: "spinner-container" }
const _hoisted_50 = {
  key: 0,
  class: "spinner"
}
const _hoisted_51 = { class: "flex items-center" }
const _hoisted_52 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }
const _hoisted_53 = { key: 1 }
const _hoisted_54 = { key: 2 }
const _hoisted_55 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editrealmForm = _resolveComponent("editrealmForm")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_realmVouchers = _resolveComponent("realmVouchers")!
  const _component_realmAdverts = _resolveComponent("realmAdverts")!
  const _component_realmOfflineFiles = _resolveComponent("realmOfflineFiles")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[22] || (_cache[22] = _createElementVNode("div", { class: "flex flex-col md:block hidden my-6" }, [
      _createElementVNode("div", { class: "flex items-center" }, [
        _createElementVNode("i", { class: "fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2" }),
        _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, "Realms Details")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'realm'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSetActiveTab('realm')))
              }, " Realms Details ", 2)
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'vouchers'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "dashboard-tab",
                "data-tabs-target": "#dashboard",
                type: "button",
                role: "tab",
                "aria-controls": "dashboard",
                "aria-selected": "false",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSetActiveTab('vouchers')))
              }, " Vouchers ", 2)
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'adverts'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSetActiveTab('adverts')))
              }, " Adverts ", 2)
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'files'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSetActiveTab('files')))
              }, " Offline Files ", 2)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.activeTab === 'realm')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.realm.name), 1),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("table", _hoisted_14, [
                      _createElementVNode("thead", _hoisted_15, [
                        _createElementVNode("tr", null, [
                          _cache[13] || (_cache[13] = _createElementVNode("th", { class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500" }, " Name ", -1)),
                          _cache[14] || (_cache[14] = _createElementVNode("th", { class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500" }, " Type ", -1)),
                          (_ctx.realm.sme)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_16, " SME "))
                            : _createCommentVNode("", true),
                          _cache[15] || (_cache[15] = _createElementVNode("th", { class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500" }, " No. of Routers ", -1)),
                          _cache[16] || (_cache[16] = _createElementVNode("th", { class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500" }, " Created ", -1)),
                          (_ctx.loggedInUser.role === _ctx.adminRole)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_17, " Action "))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("tbody", _hoisted_18, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.realm.name), 1),
                          _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.realm.type), 1),
                          (_ctx.realm.sme)
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 0,
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.smeDetails(_ctx.realm.sme.id))),
                                class: "px-3 py-1 text-[#4e8d6e] cursor-pointer whitespace-nowrap font-poppins text-xs"
                              }, _toDisplayString(_ctx.realm.sme.name), 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.realm.no_of_routers), 1),
                          _createElementVNode("td", _hoisted_22, _toDisplayString(new Date(_ctx.realm.created).toLocaleString()), 1),
                          (_ctx.loggedInUser.role === _ctx.adminRole)
                            ? (_openBlock(), _createElementBlock("td", _hoisted_23, [
                                _createElementVNode("button", {
                                  type: "button",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showModal = true)),
                                  class: "font-poppins bg-primary px-4 py-2 text-xs block font-medium rounded-[0.4rem] focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
                                }, " Edit "),
                                (_ctx.showModal)
                                  ? (_openBlock(), _createBlock(_component_editrealmForm, {
                                      key: 0,
                                      id: _ctx.realm.id,
                                      onClose: _ctx.closeModal
                                    }, null, 8, ["id", "onClose"]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.changeName ? 'Assign Realm Routers' : 'Realm Routers'), 1)
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "font-poppins font-semibold text-[0.8rem] text-[#fff] flex items-center bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem]",
                        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.changeBtn && _ctx.changeBtn(...args)))
                      }, [
                        _cache[17] || (_cache[17] = _createElementVNode("img", {
                          src: _imports_0,
                          class: "w-[0.6rem] h-[0.6rem] mr-1",
                          alt: ""
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.changeName ? 'Add Realm Routers' : 'Add Routers'), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("label", _hoisted_30, [
                      _cache[18] || (_cache[18] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
                      _cache[19] || (_cache[19] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
                        _createElementVNode("img", {
                          src: _imports_1,
                          alt: "",
                          class: "w-[0.8rem] h-[0.8rem]"
                        })
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.searchQuery) = $event)),
                        class: "bg-white font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                        placeholder: "Search",
                        type: "text",
                        name: "search"
                      }, null, 512), [
                        [_vModelText, _ctx.searchQuery]
                      ])
                    ])
                  ]),
                  (_ctx.showButtons())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.rowsSelected) + " Rows selected", 1),
                          _createElementVNode("span", {
                            class: "cursor-pointer text-[#5e72e4] font-bold text-sm",
                            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.clearRows && _ctx.clearRows(...args)))
                          }, "clear")
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromSme && _ctx.unallocateRoutersFromSme(...args)))
                          }, " unassign SME router "),
                          _createElementVNode("button", {
                            type: "button",
                            class: "capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromPartner && _ctx.unallocateRoutersFromPartner(...args)))
                          }, " unassign Partner router ")
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_SmeTable, {
                    sorting: true,
                    onSort: _ctx.sortBy,
                    inputShow: true,
                    selectAllValues: _ctx.selectAll,
                    onSelectAllRows: _ctx.selectAllRows,
                    tableName: 'Realm Routers',
                    tableHeaderData: _ctx.accountsTableHeader
                  }, {
                    tableBodyData: _withCtx(() => [
                      (!_ctx.paginatedTableData.length)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_33, " No records "))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.paginatedTableData, (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: item.id,
                              class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                            }, [
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("div", _hoisted_35, [
                                    _withDirectives(_createElementVNode("input", {
                                      "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                                      onChange: ($event: any) => (_ctx.updateSelectedRows(item)),
                                      type: "checkbox",
                                      class: "shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                                    }, null, 40, _hoisted_36), [
                                      [_vModelCheckbox, item.selected]
                                    ])
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_37, [
                                  _createElementVNode("div", _hoisted_38, [
                                    _createElementVNode("span", _hoisted_39, _toDisplayString(item.serial_number), 1)
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_40, [
                                  _createElementVNode("span", _hoisted_41, _toDisplayString(item.active ? 'online' : 'offline'), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_42, [
                                  _createElementVNode("span", _hoisted_43, _toDisplayString(item.imei), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_44, [
                                  _createElementVNode("span", _hoisted_45, _toDisplayString(item.deployed_to), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_46, [
                                  _createElementVNode("span", _hoisted_47, _toDisplayString(item.created), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", {
                                    class: "capitalize px-2 py-1 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center cursor-pointer",
                                    onClick: ($event: any) => (_ctx.viewDetails(item.id))
                                  }, "view", 8, _hoisted_48)
                                ])
                              ])
                            ]))
                          }), 128))
                    ]),
                    _: 1
                  }, 8, ["onSort", "selectAllValues", "onSelectAllRows", "tableHeaderData"]),
                  _createElementVNode("div", _hoisted_49, [
                    (_ctx.isLoading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_50))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.propLoaded)
                    ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                        key: 1,
                        perPageItems: _ctx.perSize,
                        pageNumber: _ctx.page,
                        ApiData: _ctx.apiData,
                        onHandleNext: _ctx.handlleNext,
                        onHandlePrev: _ctx.handlePrev
                      }, {
                        section: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_51, [
                              _cache[21] || (_cache[21] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                              _createElementVNode("span", _hoisted_52, [
                                _withDirectives(_createElementVNode("select", {
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedOption) = $event)),
                                  onChange: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                                }, _cache[20] || (_cache[20] = [
                                  _createElementVNode("option", { value: "10" }, "10", -1),
                                  _createElementVNode("option", { value: "20" }, "20", -1),
                                  _createElementVNode("option", { value: "30" }, "30", -1),
                                  _createElementVNode("option", { value: "40" }, "40", -1),
                                  _createElementVNode("option", { value: "50" }, "50", -1)
                                ]), 544), [
                                  [_vModelSelect, _ctx.selectedOption]
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'vouchers')
            ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                _createVNode(_component_realmVouchers, {
                  voucherId: _ctx.$route.params.id
                }, null, 8, ["voucherId"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'adverts')
            ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                _createVNode(_component_realmAdverts, {
                  voucherId: _ctx.$route.params.id
                }, null, 8, ["voucherId"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'files')
            ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                _createVNode(_component_realmOfflineFiles, {
                  voucherId: _ctx.$route.params.id
                }, null, 8, ["voucherId"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}