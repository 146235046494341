<template>
  <DashboardHeader title="Allocated Routers" :icon="icon.router">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium text-[0.8rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[0.8rem] h-[0.8rem] rounded ml-1" />
      </div>
    </template>
  </DashboardHeader>
  <div class="w-full my-3">
    <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative">
      <div class="tabs-block" id="tabs-block">
        <div class="mb-3">
          <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab" data-tabs-toggle="#myTabContent"
            role="tablist">
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
                type="button" role="tab" aria-controls="profile" aria-selected="false"
                v-on:click="handleSetActiveTab('all')" v-bind:class="[
                  activeTab === 'all'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                All
              </button>
            </li>
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="dashboard-tab" data-tabs-target="#dashboard"
                type="button" role="tab" aria-controls="dashboard" aria-selected="false"
                v-on:click="handleSetActiveTab('suspended')" v-bind:class="[
                  activeTab === 'suspended'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Unassigned
              </button>
            </li>
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="settings-tab" data-tabs-target="#settings"
                type="button" role="tab" aria-controls="settings" aria-selected="false"
                v-on:click="handleSetActiveTab('active')" v-bind:class="[
                  activeTab === 'active'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Assigned
              </button>
            </li>
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="hashhub-tab" data-tabs-target="#settings"
                type="button" role="tab" aria-controls="hashhub" aria-selected="false"
                v-on:click="handleSetActiveTab('hashhub')" v-bind:class="[
                  activeTab === 'hashhub'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Hash Hub
              </button>
            </li>
          </ul>
        </div>
        <div id="myTabContent">
          <!-- <div class="md:w-[100%] w-[50%] mb-2 mt-4">
            <label class="relative block">
              <span class="sr-only">Search</span>
              <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                <img
                  src="../../assets/search-icon.png"
                  alt=""
                  class="w-[0.8rem] h-[0.8rem]"
                />
              </span>
              <input
                v-model="searchQuery"
                class="font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                placeholder="Search"
                type="text"
                name="search"
              />
            </label>
          </div> -->
          <div class="p-3 flex items-center justify-between md:w-[100%] w-[50%]" v-if="showButtons()">
            <div>
              <span class="me-2 text-[#d3aa3b] font-bold text-sm">{{ rowsSelected }} Rows selected</span>
              <span class="cursor-pointer text-[#5e72e4] font-bold text-sm" @click="clearRows">clear</span>
            </div>
            <div>
              <button v-if="selectedRows.length === 1 &&
                !selectedRows[0].has_been_assigned_xmrig
              " type="button"
                class="me-2 cursor-pointer capitalize p-2 rounded bg-primary text-white mb-0.5 inline-block text-center"
                @click="onAssignXMRIG">
                Assign Hash Hub
              </button>
              <button v-if="selectedRows.length === 1 &&
                selectedRows[0].has_been_assigned_xmrig
              " type="button"
                class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                @click="onUnassignXMRIG(selectedRows[0].ssid)">
                Unssign Hash Hub
              </button>
              <button v-if="selectedRows.length === 1 &&
                !selectedRows[0].realm?.id
              " type="button"
                class="me-2 cursor-pointer capitalize p-2 rounded bg-primary text-white mb-0.5 inline-block text-center"
                @click="onAssignRouterToRealm">
                Assign Realm
              </button>
              <button v-if="selectedRows.length === 1 &&
                selectedRows[0].realm?.id" type="button"
                class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                @click="onUnassignRouterToRealm">
                Unassign Realm
              </button>
              <button v-if="selectedRows.length" type="button"
                class="me-2 cursor-pointer capitalize p-2 rounded bg-primary text-white mb-0.5 inline-block text-center"
                @click="onAssignRouterToSME">
                Assign SME
              </button>
              <button v-if="selectedRows.length" type="button"
                class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                @click="showUnassignSmeModal = true">
                Unassign SME
              </button>
              <button v-if="selectedRows.length === 1 &&
                selectedRows[0].partner?.id" type="button"
                class="capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                @click="showUnassignPartnerModal = true">
                Unassign Partner
              </button>
            </div>
          </div>
          <div v-if="activeTab !== 'hashhub'">
            <SmeTable :sorting="true" @sort="sortBy" :inputShow="true" :selectAllValues="selectAll"
              :tableHeaderData="routerTableHeaders" :tableName="'Routers'" @selectAllRows="selectAllRows">
              <template v-slot:tableBodyData>
                <template v-if="paginatedTableData.length">
                  <div v-for=" router in paginatedTableData " :key="router.id"
                    class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
                    <td>
                      <div class="flex items-center">
                        <div class="flex flex-col ml-3">
                          <input v-model="router.selected" @change="updateSelectedRows(router)" type="checkbox"
                            class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="flex items-center">
                        <div
                          class="bg-[#F3F6F9] w-[2.806rem] h-[2.806rem] rounded-[0.337rem] flex items-center justify-center">
                          <img :src="router.location === 'In Transit'
                            ? require('../../assets/router.png')
                            : router.location === 'Manufacturer'
                              ? require('../../assets/transit_router.png')
                              : require('../../assets/active_router.png')
                            " :alt="router.nasidentifier" class="w-[1.89rem] h-[1.779rem]" />
                        </div>

                        <div class="flex flex-col ml-3">
                          <span class="capitalize text-[#464E5F] mb-0.5">{{
                            router.serial_number
                          }}</span>
                          <span class="dotonline ml-2" v-if="router.is_online"></span>
                          <span class="dotoffline ml-2" v-else></span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="flex flex-col">
                        <span>{{ router.ssid }}</span>
                      </div>
                    </td>
                    <td class="whitespace-nowrap">
                      <span class="text-[#464E5F]">{{ router.sme?.name || router.partner?.name + 'PARTNER' }}</span>
                    </td>
                    <td>
                      <div class="flex flex-col">
                        <span class="capitalize text-[#464E5F] mb-0.5">{{
                          router.os_version_name
                        }}</span>
                        <span>version {{ router.os_version_id }}</span>
                      </div>
                    </td>
                    <td>
                      <span class="text-[#464E5F]">{{
                        router.realm ? router.realm.name : 'No Realm'
                      }}</span>
                    </td>
                    <td>
                      <span class="text-[#464E5F]">{{
                        router.location_name
                      }}</span>
                    </td>
                    <td>
                      <div class="flex items-center justify-end">
                        <div class="text-right">
                          <span @click="viewDetails(router.id)"
                            class="capitalize px-2 py-1 rounded bg-[#342e37] text-white mb-0.5 inline-block text-center cursor-pointer">view</span>
                        </div>
                        <div v-if="!router.sme"
                          class="bg-[#F3F6F9] w-[4rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2 p-2">
                          <button @click="editRouter(router)" class="flex">
                            <img :src="routerTableIcons[1]" alt="africa" class="w-[1.125rem] h-[1.067rem]" />
                            <span class="ml-2">Edit</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </div>
                </template>
                <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm">
                  <b>No Data Found</b>
                </div>
              </template>
            </SmeTable>
            <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="apiData"
              @handleNext="handlleNext" @handlePrev="handlePrev">
              <template v-slot:section>
                <div>
                  <div class="flex items-center">
                    <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                      class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                      <select v-model="selectedOption" @change="handleChange">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select></span>
                  </div>
                </div>
              </template>
            </DashboardTableTitle>
            <div v-if="isLoading" class="spinner-container">
              <div class="spinner"></div>
            </div>
          </div>
          <div v-if="activeTab === 'hashhub'">
            <div class="flex my-4">
              <div class="mr-8">
                <div class="flex mb-2">
                  <i class="fa fa-bank"></i>
                  <p class="text-sm ml-2">
                    Pending Balance:
                    <strong>{{ miningWalletStats['Pending Balance'] }}</strong>
                  </p>
                </div>
                <div class="flex mb-2">
                  <i class="fa fa-credit-card"></i>
                  <p class="text-sm ml-2">
                    Last Block Reward:
                    <strong>{{
                      miningWalletStats['Last Block Reward']
                    }}</strong>
                  </p>
                </div>
                <div class="flex mb-2">
                  <i class="fa-solid fa-money-bill"></i>
                  <p class="text-sm ml-2">
                    Total Paid:
                    <strong>{{ miningWalletStats['Total Paid'] }}</strong>
                  </p>
                </div>
              </div>
              <div>
                <div class="flex mb-2">
                  <i class="fa-solid fa-clock"></i>
                  <p class="text-sm ml-2">
                    Last Share Submitted:
                    <strong>{{
                      miningWalletStats['Last Share Submitted']
                    }}</strong>
                  </p>
                </div>
                <div class="flex mb-2">
                  <i class="fa fa-cloud-upload"></i>
                  <p class="text-sm ml-2">
                    Total Hashes Submitted:
                    <strong>{{
                      miningWalletStats['Total Hashes Submitted']
                    }}</strong>
                  </p>
                </div>
                <div class="flex mb-2">
                  <i class="fa fa-tachometer"></i>
                  <p class="text-sm ml-2">
                    Hash Rate:
                    <strong>{{ miningWalletStats['Hash Rate'] }}</strong>
                  </p>
                </div>
              </div>
            </div>

            <SmeTable @sort="sortBy" :tableHeaderData="minerTableHeaders" :tableName="'Routers'">
              <template v-slot:tableBodyData>
                <template v-if="miners.length">
                  <div v-for=" miner in miners " :key="miner.id"
                    class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
                    <td>
                      <div class="flex items-center">
                        <div
                          class="bg-[#F3F6F9] w-[2.806rem] h-[2.806rem] rounded-[0.337rem] flex items-center justify-center">
                          <img :src="require('../../assets/active_router.png')" :alt="miner.router_ssid"
                            class="w-[1.89rem] h-[1.779rem]" />
                        </div>
                        <div class="flex flex-col ml-3">
                          <span class="capitalize text-[#464E5F] mb-0.5">{{
                            miner.router_ssid
                          }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="whitespace-nowrap">
                      <span class="text-[#464E5F]">{{
                        moment(miner.mining_start_time, 'HH:mm:ss').format(
                          'h:mm A'
                        )
                      }}</span>
                    </td>
                    <td class="whitespace-nowrap">
                      <span class="text-[#464E5F]">{{
                        moment(miner.mining_end_time, 'HH:mm:ss').format(
                          'h:mm A'
                        )
                      }}</span>
                    </td>
                    <td class="whitespace-nowrap">
                      <span class="text-[#464E5F]">{{ miner.status }}</span>
                    </td>
                    <td>
                      <div class="text-right">
                        <span @click="updateMinerStatus(miner, 'running')" v-if="miner.status === 'inactive'"
                          class="capitalize px-2 py-1 rounded bg-[#342e37] text-white mb-0.5 inline-block text-center cursor-pointer">Start
                          Mining</span>
                        <span @click="updateMinerStatus(miner, 'paused')" v-if="miner.status === 'running'"
                          class="capitalize px-2 py-1 rounded bg-[#342e37] text-white mb-0.5 inline-block text-center cursor-pointer">Pause
                          Mining</span>
                        <span @click="updateMinerStatus(miner, 'running')" v-if="miner.status === 'paused'"
                          class="capitalize px-2 py-1 rounded bg-[#342e37] text-white mb-0.5 inline-block text-center cursor-pointer">Resume
                          Mining</span>
                      </div>
                    </td>
                  </div>
                </template>
                <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm">
                  <b>No Data Found</b>
                </div>
              </template>
            </SmeTable>
            <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="apiData"
              @handleNext="handlleNext" @handlePrev="handlePrev">
              <template v-slot:section>
                <div>
                  <div class="flex items-center">
                    <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                      class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                      <select v-model="selectedOption" @change="handleChange">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select></span>
                  </div>
                </div>
              </template>
            </DashboardTableTitle>
            <div v-if="isLoading" class="spinner-container">
              <div class="spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODALS -->
    <!-- ASSIGN/UNASSIGN TO/FROM REALM MODAL -->
    <assign-router-to-realm v-if="showAssignRouterToRealmModal" :action="routerToRealmAction" :routers="selectedRows"
      @close="closeAssignRouterToRealmModal()"></assign-router-to-realm>
    <!-- ASSIGN/UNASSIGN TO/FROM SME MODAL -->
    <assign-router-to-SME v-if="showAssignRouterToSmeModal" :routers="selectedRows"
      @close="closeAssignRouterToSmeModal()"></assign-router-to-SME>

    <!-- CONFIMATION DIALOGS -->
    <!-- UNASSIGN ROUTER FROM THE PARTNER -->
    <confirmation-dialog :show="showUnassignPartnerModal"
      title="Are you sure you want to unassign yourself from the selected router(s)?"
      message=" You will immediately lose access and you would need support from the provider to get it back"
      actionLabel="Confirm" :loading="loadingPartnerUnassigning" @cancel="showUnassignPartnerModal = false"
      @confirm="unassignRoutersFromPartner" />
    <!-- UNASSIGN ROUTER FROM THE SME -->
    <confirmation-dialog :show="showUnassignSmeModal"
      title="Are you sure you want to unassign SME from the selected router(s)?"
      message="They will immediately lose access" actionLabel="Confirm" :loading="loadingSmeUnassigning"
      @cancel="showUnassignSmeModal = false" @confirm="unassignRoutersFromSME" />
  </div>
  <edit-form v-if="showModal" @close="showModal = false" @routerUpdated="handleSetActiveTab('all')"
    :router="currentRouter"></edit-form>
</template>

<script lang="ts">
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import router, { useRoute } from '@/router';

import instance from '../../axios-interceptor';
import EditForm from '../../components/Router/editForm.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import { Router, Miner, MoneroWalletStat } from '@/interfaces/router';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import ConfirmationDialog from '@/components/Common/ConfirmationDialog.vue';
import AssignRouterToRealm from '@/components/Dashboard/AssignRouterToRealm.vue';
import AssignRouterToSME from '@/components/Dashboard/AssignRouterToSME.vue';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import {
  routers as _routers,
  routerTableHeaders as _routerTableHeaders,
  routerTableIcons as _routerTableIcons,
  minerTableHeaders as _minerTableHeaders,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';

export default defineComponent({
  name: 'DashboardPage',
  components: {
    SmeTable,
    DashboardHeader,
    AssignRouterToSME,
    ConfirmationDialog,
    AssignRouterToRealm,
    DashboardTableTitle,
    EditForm,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Serial Number');
    const routers = ref([..._routers]);
    const routerTableHeaders = ref([..._routerTableHeaders]);
    const minerTableHeaders = ref([..._minerTableHeaders]);
    const routerTableIcons = ref([..._routerTableIcons]);
    const route = useRoute();

    return {
      routers,
      routerTableHeaders,
      routerTableIcons,
      tableName,
      icon,
      minerTableHeaders,
      route,
    };
  },

  data() {
    return {
      showUnassignPartnerModal: false,
      loadingPartnerUnassigning: false,
      showUnassignSmeModal: false,
      loadingSmeUnassigning: false,
      selectAll: false,
      selectedRows: [] as Router[],
      activetab: '1',
      isLoading: false,
      selectedOption: 10,
      apiData: [],
      propLoaded: false,
      routerToRealmAction: '',
      routerToSmeAction: '',
      showAssignRouterToRealmModal: false,
      showAssignRouterToSmeModal: false,
      page: 1,
      perSize: 10,
      sortKey: '', // Sorting key
      sortDirection: 'asc',
      searchQuery: '',
      activeTab: 'all',
      rowsSelected: 0,
      routerList: [] as Router[],
      zabbixUserName: 'Admin',
      zabbixPassword: 'wizzdev2021',
      zabbixAuthenticationToken: '',
      zabbixBaseUri: 'https://zabbix.shirikihub.com/zabbix/',
      zabbixApiEndpoint: 'api_jsonrpc.php',
      filteredRouters: [
        {
          host: '',
          id: '',
          hostid: '',
        },
      ],
      onlineRouters: [] as any,
      miners: [] as Miner[],
      miningWalletStats: {} as MoneroWalletStat,
      showModal: false,
      showUploadMenuModal: false,
      currentRouter: {} as Router,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),

    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.routerList;
      }

      // Filter table data based on search query
      const filteredData = this.routerList.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const serial_number = item.serial_number.toString().toLowerCase();
        const imei = item.imei.toString().toLowerCase();
        const smeName = item?.sme?.name.toString().toLowerCase();
        const deployed_to = item.deployed_to.toString().toLowerCase();
        const ip_address = item.ip_address.toString().toLowerCase();
        const created = this.createdAt(item.created.toString().toLowerCase());

        const os_version_id = item.os_version_id.toString().toLowerCase();
        const model_number = item.model_number.toString().toLowerCase();
        const realmName = item?.realm?.name.toString().toLowerCase();
        const query = this.searchQuery.toLowerCase();
        // Check if name or age contain the search query
        return (
          serial_number?.includes(query) ||
          imei?.includes(query) ||
          smeName?.includes(query) ||
          deployed_to?.includes(query) ||
          ip_address?.includes(query) ||
          created?.includes(query) ||
          os_version_id?.includes(query) ||
          model_number?.includes(query) ||
          realmName?.includes(query)
        );
      });

      return filteredData;
    },

    sortedTableData(sortKey: any): any[] {
      if (!this.sortKey) {
        // If sort key is empty, return the filtered data as-is
        return this.filteredTableData;
      }

      // Sort the filtered table data based on the sort key and direction
      const sortedData = this.filteredTableData.slice().sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];

        // Perform the actual comparison based on the data type
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return (
            aValue.localeCompare(bValue) *
            (this.sortDirection === 'asc' ? 1 : -1)
          );
        } else {
          return (aValue - bValue) * (this.sortDirection === 'asc' ? 1 : -1);
        }
      });

      return sortedData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.sortedTableData.slice(startIndex, endIndex);
    },
  },
  methods: {
    moment,
    viewDetails(id: string) {
      router.push({ name: 'router-details', params: { id: id } });
    },
    selectAllRows(checked: boolean) {
      this.selectAll = checked;
      this.routerList.forEach((row) => {
        row.selected = this.selectAll;
      });

      if (this.selectAll) {
        this.selectedRows = [...this.routerList];
        this.rowsSelected = this.selectedRows.length;
      } else {
        this.selectedRows = [];
        this.rowsSelected = 0;
      }
    },

    updateSelectedRows(row: Router) {
      if (!row.selected) {
        this.selectAll = false;
        this.selectedRows = this.selectedRows.filter(
          (selectedRow) => selectedRow.id !== row.id
        );
      } else {
        this.selectedRows = this.routerList.filter((row) => row.selected);
        this.rowsSelected = this.selectedRows.length;
        this.selectAll = this.selectedRows.length === this.routerList.length;
      }
    },

    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('M/D/YYYY, h:mm:ss a');
      }
    },
    handlePrev(data: any) {
      this.page--;
      this.fetchRouters(this.activeTab);
    },
    handlleNext(data: any) {
      this.page++;
      this.fetchRouters(this.activeTab);
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    fetchRouters(baseUrl: string) {
      this.isLoading = true;
      instance
        .get(baseUrl)
        .then((response: { data: any }) => {
          this.routerList = response.data.results.map((row: any) => ({
            ...row,
            selected: false,
            smeName: row?.sme?.name,
            realmName: row?.realm?.name,
          }));
          this.apiData = response.data.results;
          this.propLoaded = true;
          this.isLoading = false;
          this.loginZabbix();
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    fetchMiningWalletStats(baseUrl: string) {
      this.isLoading = true;
      instance
        .get(baseUrl)
        .then((response: { data: any }) => {
          this.miningWalletStats = response.data;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    fetchMiners(baseUrl: string) {
      this.isLoading = true;
      instance
        .get(baseUrl)
        .then((response: { data: any }) => {
          this.miners = response.data;
          this.apiData = response.data;
          this.propLoaded = true;
          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    handleSetActiveTab(tab: string) {
      this.activeTab = tab;
      if (tab === 'all') {
        const baseUrl = `routers/?partner=${this.loggedInUser.partner.id}`;
        this.fetchRouters(baseUrl);
      } else if (tab === 'active') {
        const baseUrl = `routers/?partner=${this.loggedInUser.partner.id}&has_been_assigned_sme=true`;
        this.fetchRouters(baseUrl);
      } else if (tab === 'suspended') {
        const baseUrl = `routers/?partner=${this.loggedInUser.partner.id}&has_been_assigned_sme=false`;
        this.fetchRouters(baseUrl);
      } else if (tab === 'hashhub') {
        const baseUrl = `yocto/partner/${this.loggedInUser.partner.id}/miner/`;
        this.fetchMiners(baseUrl);
      }
    },
    handleChange() {
      this.perSize = this.selectedOption;
      this.fetchRouters(this.activeTab);
    },
    sortBy(key: string) {
      let currentKey;
      if (key === 'SERIAL NUMBER') {
        currentKey = 'serial_number';
      } else if (key === 'ASSIGNED TO') {
        currentKey = 'smeName';
      } else if (key === 'ASSIGNMENT TYPE') {
        currentKey = 'deployed_to';
      } else if (key === 'IMEI NUMBER') {
        currentKey = 'imei';
      } else if (key === 'LAST CONTACT IP') {
        currentKey = 'ip_address';
      } else if (key === 'OS VERSION ID') {
        currentKey = 'os_version_id';
      } else if (key === 'REALM') {
        currentKey = 'realmName';
      } else if (key === 'MODEL NUMBER') {
        currentKey = 'model_number';
      } else {
        currentKey = 'created';
      }

      if (this.sortKey === currentKey) {
        // Toggle the sort direction if the same key is clicked again
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = currentKey;
        this.sortDirection = 'asc';
      }
    },
    unassignRoutersFromSME() {
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const assignSmeRoutersUrl = 'routers/assign-to-sme/';
      const data = {
        routers: selectedRouters,
        sme: this.route.params.id,
      };

      instance
        .patch(assignSmeRoutersUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.handleSetActiveTab(this.activeTab);
            this.$toast.success(response.data.detail, {
              position: 'top-right',
            });
            this.showUnassignSmeModal = false;
            this.loadingSmeUnassigning = false;
            this.selectAll = false;
            this.selectedRows = [];
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
            this.loadingSmeUnassigning = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unassignRoutersFromPartner() {
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const unassignRoutersFromPartnerUrl = 'routers/assign-to-partner/';
      const data = {
        routers: selectedRouters,
      };

      this.loadingPartnerUnassigning = true;

      instance
        .patch(unassignRoutersFromPartnerUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.handleSetActiveTab(this.activeTab);
            this.$toast.success(response.data.detail, {
              position: 'top-right',
            });
            this.showUnassignPartnerModal = false;
            this.loadingPartnerUnassigning = false;
            this.selectAll = false;
            this.selectedRows = [];
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });

            this.loadingPartnerUnassigning = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearRows() {
      this.paginatedTableData.filter((row) => (row.selected = false));
      this.selectAll = false;
    },
    showButtons() {
      return this.paginatedTableData.some((row) => row.selected === true);
    },
    async loginZabbix() {
      delete axios.defaults.headers.common['Authorization'];

      const loginData = {
        jsonrpc: '2.0',
        method: 'user.login',
        params: {
          user: this.zabbixUserName,
          password: this.zabbixPassword,
        },
        id: 1,
      };

      const endpoint = `${this.zabbixBaseUri}${this.zabbixApiEndpoint}`;

      try {
        // Step 1: Authenticate with Zabbix
        const loginResponse = await axios.post(endpoint, loginData);

        if (loginResponse.status === 200) {
          this.zabbixAuthenticationToken = loginResponse.data.result;
        } else {
          this.$toast.error('Could not authenticate w/Zabbix.', {
            position: 'top-right',
          });
          return;
        }

        // Step 2: Get host information
        const getHostData = {
          jsonrpc: '2.0',
          method: 'host.get',
          params: {
            output: ['hostid', 'host'],
            selectInterfaces: ['interfaceid', 'ip'],
          },
          auth: this.zabbixAuthenticationToken,
          id: 1,
        };

        const hostResponse = await axios.post(endpoint, getHostData);

        if (hostResponse.status === 200) {
          const zabbixHosts = hostResponse.data.result;
          const routerSerialNumbers = Object.keys(this.routerList).map(
            (router: any) => this.routerList[router].serial_number
          );

          const filteredRouters = [];

          for (let i = 0; i < routerSerialNumbers.length; i++) {
            const hostName = 'mini-server_' + routerSerialNumbers[i];
            const foundHost = zabbixHosts.find(
              (host: any) => host.host === hostName
            );

            if (foundHost) {
              filteredRouters.push(foundHost);
            }
          }

          this.filteredRouters = filteredRouters;
        } else {
          this.$toast.error('Could not get the hosts.', {
            position: 'top-right',
          });
          return;
        }

        // Step 3: Retrieve online status for each filtered router
        for (const router of this.filteredRouters) {
          const itemData = {
            jsonrpc: '2.0',
            method: 'item.get',
            params: {
              output: ['lastvalue', 'status'],
              hostids: router.hostid,
              search: {
                key_: 'zabbix[host,agent,available]',
                name: 'Linux: Zabbix agent availability',
              },
            },
            auth: this.zabbixAuthenticationToken,
            id: 1,
          };

          const itemResponse = await axios.post(endpoint, itemData);

          if (itemResponse.status === 200 && itemResponse.data.result.length) {
            const onlineStatus = itemResponse.data.result[0].lastvalue;

            const onlineRouter = {
              router: router.host.split('_')[1],
              status: onlineStatus,
            };

            this.onlineRouters.push(onlineRouter);
          }
        }

        // Step 4: Update the 'is_online' property for each router
        this.routerList.forEach((router) => {
          const onlineRouter = this.onlineRouters.find(
            (online: any) => online.router === router.serial_number
          );

          if (onlineRouter && onlineRouter.status === '1') {
            router.is_online = true;
          } else {
            router.is_online = false;
          }
        });
      } catch (error) {
        console.log('ZABBIX ERROR: ', error);

        // this.$toast.error('An error occurred during Zabbix login.', {
        //   position: 'top-right',
        // });
      }
    },
    closeAssignRouterToRealmModal() {
      this.selectAll = false;
      this.selectedRows = [];
      this.showAssignRouterToRealmModal = false;
      const baseUrl = `routers/?partner=${this.loggedInUser.partner.id}`;
      this.fetchRouters(baseUrl);
    },
    closeAssignRouterToSmeModal() {
      this.selectAll = false;
      this.selectedRows = [];
      this.showAssignRouterToSmeModal = false;
      const baseUrl = `routers/?partner=${this.loggedInUser.partner.id}`;
      this.fetchRouters(baseUrl);
    },
    onAssignRouterToRealm() {
      this.routerToRealmAction = 'assign';
      this.showAssignRouterToRealmModal = true;
    },
    onAssignRouterToSME() {
      this.routerToSmeAction = 'assign';
      this.showAssignRouterToSmeModal = true;
    },
    onUnassignRouterToRealm() {
      this.routerToRealmAction = 'unassign';
      this.showAssignRouterToRealmModal = true;
    },
    onAssignXMRIG() {
      const routersWithoutXMRIG = this.selectedRows.filter((router) => {
        return !this.miners.some((miner: any) => miner.router === router.id);
      });

      if (!routersWithoutXMRIG.length) {
        return this.$toast.info(
          'The selected router is already assigned to the mining Hash Hub',
          {
            position: 'top-right',
            duration: 6000,
          }
        );
      }

      const routerIDs = routersWithoutXMRIG.map((router) => router.id);

      this.isLoading = true;
      instance
        .post(`yocto/partner/${this.loggedInUser.partner.id}/miner/`, routerIDs)
        .then(() => {
          this.isLoading = false;
          this.$toast.success(
            'The selected router assigned to the mining Hash Hub',
            {
              position: 'top-right',
            }
          );
          this.clearRows();
          this.handleSetActiveTab('hashhub');
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error.response.data);
          this.$toast.error(
            'Could not assign the router to the mining Hash Hub',
            {
              position: 'top-right',
            }
          );
        });
    },
    onUnassignXMRIG(routerSSID: string) {
      const minerID = this.miners.filter(
        (miner: any) => miner.router_ssid === routerSSID
      )[0].id;
      this.isLoading = true;
      instance
        .delete(
          `yocto/partner/${this.loggedInUser.partner.id}/miner/${minerID}`
        )
        .then(() => {
          this.isLoading = false;
          this.$toast.success('Router unassigned from the mining Hash Hub', {
            position: 'top-right',
          });
          this.clearRows();
          this.handleSetActiveTab('hashhub');
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error.response.data);
          this.$toast.error(
            'Could not unassign the router from the mining Hash Hub',
            {
              position: 'top-right',
            }
          );
        });
    },
    updateMinerStatus(miner: Miner, status: string) {
      const payload = {
        status,
        partner: miner.partner,
        router: miner.router,
      };
      this.isLoading = true;
      instance
        .patch(
          `yocto/partner/${this.loggedInUser.partner.id}/miner/${miner.id}`,
          payload
        )
        .then(() => {
          this.isLoading = false;
          const targetMiner = this.miners.find(
            (item: Miner) => item.id === miner.id
          );
          if (targetMiner) {
            targetMiner.status = status;
            this.$toast.success(`Miner status updated to ${status}`, {
              position: 'top-right',
            });
          }
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error.response.data);
          this.$toast.error('Could not update the miner status', {
            position: 'top-right',
          });
        });
    },
    editRouter(router: Router) {
      this.currentRouter = router;
      this.showModal = true;
    },
  },
  mounted() {
    this.handleSetActiveTab('all');
    this.fetchMiningWalletStats('yocto/partner/mining-wallet-stats');
    this.fetchMiners(`yocto/partner/${this.loggedInUser.partner.id}/miner/`);
  },
});
</script>


<style scoped>
.dotonline {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.dotoffline {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}
</style>