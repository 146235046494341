import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/user-img.svg'


const _hoisted_1 = { class: "lg:flex flex-row items-start gap-y-8 xl:gap-x-8 gap-x-6 mt-8 sm:gap-y-16" }
const _hoisted_2 = { class: "xl:w-1/4 lg:w-1/3 md:w-full bg-white pb-4 sm:mx-0 shadow-lg px-5 pt-5 pb-6 rounded-2xl lg:mb-0 mb-4" }
const _hoisted_3 = { class: "text-center pt-2" }
const _hoisted_4 = { class: "font-bold font-lg" }
const _hoisted_5 = { class: "text-sm" }
const _hoisted_6 = { class: "edit-profile flex justify-center pt-3" }
const _hoisted_7 = {
  key: 2,
  class: "popup-modal"
}
const _hoisted_8 = { class: "popup-modal-content rounded-xl" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-md-12 mx-auto py-4" }
const _hoisted_11 = { class: "text-center mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor" }
const _hoisted_12 = { class: "w-full flex justify-center pt-2" }
const _hoisted_13 = { class: "user-profile-block xl:w-3/4 md:w-full lg:w-2/3 full-width bg-white p-5 rounded-[0.938rem]" }
const _hoisted_14 = {
  class: "tabs-block mb-2",
  id: "tabs-block"
}
const _hoisted_15 = { class: "mb-3" }
const _hoisted_16 = {
  class: "flex flex-wrap -mb-px text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_17 = { class: "mr-2" }
const _hoisted_18 = { class: "mr-2" }
const _hoisted_19 = { class: "mr-2" }
const _hoisted_20 = {
  id: "myTabContent",
  class: "full-width pt-4"
}
const _hoisted_21 = {
  key: 0,
  class: "",
  id: "profile",
  role: "tabpanel",
  "aria-labelledby": "profile"
}
const _hoisted_22 = { class: "user-profile-innr w-full" }
const _hoisted_23 = { class: "w-full table-fixed table-border" }
const _hoisted_24 = { class: "bg-light" }
const _hoisted_25 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_26 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_27 = { class: "bg-light" }
const _hoisted_28 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_29 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_30 = { class: "bg-light" }
const _hoisted_31 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_32 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_33 = { class: "bg-light" }
const _hoisted_34 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_35 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { class: "font-medium text-[1rem] whitespace-normal break-words" }
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { class: "text-center pt-2" }
const _hoisted_40 = {
  key: 0,
  class: "popup-modal"
}
const _hoisted_41 = { class: "popup-modal-content rounded-xl" }
const _hoisted_42 = { class: "row" }
const _hoisted_43 = { class: "col-md-12 mx-auto py-4" }
const _hoisted_44 = { class: "table table-striped table-hover w-full" }
const _hoisted_45 = { class: "flex justify-between mb-2" }
const _hoisted_46 = { class: "float-right" }
const _hoisted_47 = ["disabled"]
const _hoisted_48 = { key: 0 }
const _hoisted_49 = { class: "flex justify-center items-center bg-light p-3" }
const _hoisted_50 = ["value"]
const _hoisted_51 = { class: "inline-block ml-2" }
const _hoisted_52 = { key: 1 }
const _hoisted_53 = { class: "text-center pt-4" }
const _hoisted_54 = ["disabled"]
const _hoisted_55 = {
  key: 1,
  class: "",
  id: "assessments",
  role: "tabpanel",
  "aria-labelledby": "assessments"
}
const _hoisted_56 = {
  key: 2,
  class: "",
  id: "analytics",
  role: "tabpanel",
  "aria-labelledby": "analytics"
}
const _hoisted_57 = { class: "user-profile-innr w-full" }
const _hoisted_58 = { class: "grid xl:grid-cols-2 grid-cols-1 gap-4" }
const _hoisted_59 = { class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-4 bg-gray-100 bg-white" }
const _hoisted_60 = { class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-4 bg-gray-100 bg-white" }
const _hoisted_61 = { class: "shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-4 bg-gray-100 bg-white" }
const _hoisted_62 = { class: "spinner-container" }
const _hoisted_63 = {
  key: 0,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[36] || (_cache[36] = _createElementVNode("div", { class: "flex flex-col md:block hidden my-6" }, [
      _createElementVNode("div", { class: "flex items-center" }, [
        _createElementVNode("i", { class: "fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2" }),
        _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, "User Profile")
      ])
    ], -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "user-img rounded-full w-[100px] h-[100px] overflow-hidden mb-2 m-auto" }, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "w-full h-full",
              alt: "user"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.first_name) + " " + _toDisplayString(_ctx.last_name), 1),
            _createElementVNode("span", _hoisted_5, "Role: " + _toDisplayString(_ctx.role), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('edit'))),
              class: "bg-primary font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
            }, " Edit Profile "),
            (_ctx.status === 'active')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal('suspend'))),
                  type: "button",
                  class: "bg-[#d7191c] font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
                }, " Suspend "))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openModal('activate'))),
                  type: "button",
                  class: "bg-primary font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
                }, " Activate ")),
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openModal('updatePassword'))),
              type: "button",
              class: "bg-[#2f6887] font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium border"
            }, " Reset Password "),
            (_ctx.showModal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("h3", _hoisted_11, " Are You sure you want to " + _toDisplayString(_ctx.btnName), 1),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("button", {
                            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
                            type: "button",
                            class: "px-3 py-2 font-['poppins'] text-[13px] bg-primary text-white rounded-[0.432rem] mr-2 font-medium"
                          }, " Cancel "),
                          _createElementVNode("button", {
                            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
                            type: "button",
                            class: "px-3 py-2 font-['poppins'] text-[13px] bg-primary text-white rounded-[0.432rem] font-medium flex items-center"
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.btnName) + " ", 1),
                            (_ctx.isLoader)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  class: _normalizeClass({ 'spinner btn-spinner ml-2': _ctx.isLoader })
                                }, null, 2))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("ul", _hoisted_16, [
                _createElementVNode("li", _hoisted_17, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                    _ctx.activeTab === 'profile'
                      ? 'bg-light text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]]),
                    id: "profile",
                    "data-tabs-target": "#profile",
                    type: "button",
                    role: "tab",
                    "aria-controls": "profile",
                    "aria-selected": "false",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.activeTab = 'profile'))
                  }, " Profile ", 2)
                ]),
                _createElementVNode("li", _hoisted_18, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                    _ctx.activeTab === 'assessments'
                      ? 'bg-light text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]]),
                    id: "assessments",
                    "data-tabs-target": "#assessments",
                    type: "button",
                    role: "tab",
                    "aria-controls": "assessments",
                    "aria-selected": "false",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.activeTab = 'assessments'))
                  }, " Assessments ", 2)
                ]),
                _createElementVNode("li", _hoisted_19, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                    _ctx.activeTab === 'analytics'
                      ? 'bg-light text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]]),
                    id: "analytics",
                    "data-tabs-target": "#analytics",
                    type: "button",
                    role: "tab",
                    "aria-controls": "analytics",
                    "aria-selected": "false",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.activeTab = 'analytics'))
                  }, " Analytics ", 2)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            (_ctx.activeTab === 'profile')
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _cache[30] || (_cache[30] = _createElementVNode("h3", { class: "text-left mb-3 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, " User profile ", -1)),
                    _createElementVNode("table", _hoisted_23, [
                      _createElementVNode("tbody", null, [
                        _createElementVNode("tr", _hoisted_24, [
                          _cache[17] || (_cache[17] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Name", -1)),
                          _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.first_name) + " " + _toDisplayString(_ctx.last_name), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _cache[18] || (_cache[18] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, " Email ", -1)),
                          _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.email), 1)
                        ]),
                        _createElementVNode("tr", _hoisted_27, [
                          _cache[19] || (_cache[19] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, " Phone ", -1)),
                          _createElementVNode("td", _hoisted_28, _toDisplayString(_ctx.phone), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _cache[20] || (_cache[20] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, " Country ", -1)),
                          _createElementVNode("td", _hoisted_29, _toDisplayString(_ctx.country), 1)
                        ]),
                        _createElementVNode("tr", _hoisted_30, [
                          _cache[21] || (_cache[21] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Role", -1)),
                          _createElementVNode("td", _hoisted_31, _toDisplayString(_ctx.role), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _cache[22] || (_cache[22] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, "Type", -1)),
                          _createElementVNode("td", _hoisted_32, _toDisplayString(_ctx.userType), 1)
                        ]),
                        _createElementVNode("tr", _hoisted_33, [
                          _cache[23] || (_cache[23] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, " Status ", -1)),
                          _createElementVNode("td", _hoisted_34, [
                            _createElementVNode("span", {
                              class: _normalizeClass([[
                          _ctx.status === 'suspended'
                            ? 'text-white bg-[#d7191c]'
                            : 'text-[#4e8d6e] bg-[#DCE9E3]',
                        ], "font-poppins font-medium text-[0.8rem] px-3 py-1 rounded-[0.327rem]"])
                            }, _toDisplayString(_ctx.status), 3)
                          ])
                        ]),
                        _createElementVNode("tr", null, [
                          _cache[24] || (_cache[24] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, " Date Joined ", -1)),
                          _createElementVNode("td", _hoisted_35, _toDisplayString(_ctx.date_joined), 1)
                        ]),
                        (_ctx.user_kiosks.length > 0)
                          ? (_openBlock(), _createElementBlock("tr", _hoisted_36, [
                              _cache[25] || (_cache[25] = _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, " Kiosk ", -1)),
                              _createElementVNode("td", _hoisted_37, _toDisplayString(_ctx.user_kiosks[0].serial_number), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("tr", _hoisted_38, _cache[26] || (_cache[26] = [
                              _createElementVNode("td", { class: "font-poppin text-[1rem] text-[#888889]" }, " Kiosk ", -1),
                              _createElementVNode("td", { class: "font-medium text-[1rem] whitespace-normal break-words" }, " - ", -1)
                            ])))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      (_ctx.user.has_kiosk)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.Modal = true)),
                            type: "button",
                            class: "bg-[#d7191c] font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-1 border"
                          }, " Unassign Kiosk "))
                        : (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            type: "button",
                            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.Modal = true)),
                            class: "bg-primary font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-1 border"
                          }, " Assign Kiosk "))
                    ]),
                    (_ctx.Modal)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                          _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("div", _hoisted_42, [
                              _createElementVNode("div", _hoisted_43, [
                                _cache[29] || (_cache[29] = _createElementVNode("h3", { class: "text-center mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor" }, " Select Kiosk to assign ", -1)),
                                _createElementVNode("table", _hoisted_44, [
                                  _createElementVNode("thead", null, [
                                    _createElementVNode("tr", null, [
                                      _createElementVNode("th", _hoisted_45, [
                                        _cache[27] || (_cache[27] = _createTextVNode(" Kiosk Serial Number ")),
                                        _createElementVNode("div", _hoisted_46, [
                                          _createElementVNode("button", {
                                            type: "button",
                                            onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.assignKiosk && _ctx.assignKiosk(...args))),
                                            class: _normalizeClass([{
                                    disabled: _ctx.selectedKiosk.length === 0,
                                  }, "bg-primary font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"]),
                                            disabled: _ctx.selectedKiosk.length === 0
                                          }, " Assign Kiosk ", 10, _hoisted_47),
                                          _createElementVNode("button", {
                                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.Modal = false)),
                                            type: "button",
                                            class: "bg-[#d7191c] font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
                                          }, " cancel ")
                                        ])
                                      ])
                                    ])
                                  ]),
                                  (_ctx.filteredKiosks && _ctx.filteredKiosks.length > 0)
                                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_48, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredKiosks, (kiosk) => {
                                          return (_openBlock(), _createElementBlock("tr", {
                                            key: kiosk.id,
                                            class: "bg-light p-3"
                                          }, [
                                            _createElementVNode("td", _hoisted_49, [
                                              _withDirectives(_createElementVNode("input", {
                                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.selectedKiosk) = $event)),
                                                value: kiosk.id,
                                                type: "radio",
                                                class: "form-check-input"
                                              }, null, 8, _hoisted_50), [
                                                [_vModelRadio, _ctx.selectedKiosk]
                                              ]),
                                              _createElementVNode("span", _hoisted_51, _toDisplayString(kiosk.serial_number), 1)
                                            ])
                                          ]))
                                        }), 128))
                                      ]))
                                    : (_openBlock(), _createElementBlock("tbody", _hoisted_52, _cache[28] || (_cache[28] = [
                                        _createElementVNode("tr", null, [
                                          _createElementVNode("td", { class: "p-3 bg-light" }, "No available kiosk.")
                                        ], -1)
                                      ])))
                                ]),
                                _createElementVNode("div", _hoisted_53, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.assignKiosk && _ctx.assignKiosk(...args))),
                                    class: _normalizeClass([{ disabled: _ctx.selectedKiosk.length === 0 }, "bg-primary font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-1 border"]),
                                    disabled: _ctx.selectedKiosk.length === 0
                                  }, " Assign Kiosk ", 10, _hoisted_54),
                                  _createElementVNode("button", {
                                    onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.Modal = false)),
                                    type: "button",
                                    class: "bg-[#d7191c] font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-1 border"
                                  }, " cancel ")
                                ])
                              ])
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'assessments')
              ? (_openBlock(), _createElementBlock("div", _hoisted_55, _cache[31] || (_cache[31] = [
                  _createStaticVNode("<div class=\"user-profile-innr w-full\"><h3 class=\"text-left mb-3 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor\"> MF Assessments </h3><div class=\"overflow-x-auto\"><table class=\"min-w-full divide-y divide-gray-200 table-border\"><thead class=\"bg-gray-50\"><tr><th class=\"px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500\"> OFFICER </th><th class=\"px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500\"> Date </th><th class=\"px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500\"> Action </th></tr></thead><tbody class=\"divide-y divide-gray-200 border-[#eaeaee]\"><tr class=\"bg-light\"><td colspan=\"3\" class=\"font-poppin text-[14px] text-[#888889] text-center\"> Officer has not received any kiosk yet. </td></tr></tbody></table></div></div>", 1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'analytics')
              ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                  _createElementVNode("div", _hoisted_57, [
                    _cache[35] || (_cache[35] = _createElementVNode("h3", { class: "text-left mb-3 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, " Micro Franchisee Analytics ", -1)),
                    _createElementVNode("div", _hoisted_58, [
                      _createElementVNode("div", _hoisted_59, [
                        _cache[32] || (_cache[32] = _createElementVNode("h5", { class: "mb-4 text-base font-bold" }, "Commissions Report", -1)),
                        _createVNode(_component_apexchart, {
                          width: "300px",
                          height: "500px",
                          type: "donut",
                          options: _ctx.serviceoptions,
                          series: _ctx.serviceseries,
                          labels: _ctx.servicelabels
                        }, null, 8, ["options", "series", "labels"])
                      ]),
                      _createElementVNode("div", _hoisted_60, [
                        _cache[33] || (_cache[33] = _createElementVNode("h5", { class: "mb-4 text-base font-bold" }, "Summary Report", -1)),
                        _createVNode(_component_apexchart, {
                          width: "300px",
                          height: "500px",
                          type: "donut",
                          options: _ctx.summaryoptions,
                          series: _ctx.summaryseries,
                          labels: _ctx.summarylabels
                        }, null, 8, ["options", "series", "labels"])
                      ]),
                      _createElementVNode("div", _hoisted_61, [
                        _cache[34] || (_cache[34] = _createElementVNode("h5", { class: "mb-4 text-base font-bold" }, "Transaction Report", -1)),
                        _createVNode(_component_apexchart, {
                          width: "300px",
                          height: "500px",
                          type: "donut",
                          options: _ctx.transoptions,
                          series: _ctx.transseries,
                          labels: _ctx.translabels
                        }, null, 8, ["options", "series", "labels"])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_62, [
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_63))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 64))
}