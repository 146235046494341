import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/today.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium text-[0.8rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative" }
const _hoisted_5 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = {
  class: "flex flex-wrap text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Dashboard",
      icon: _ctx.icon.dashboard
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[3] || (_cache[3] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[0.8rem] h-[0.8rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["icon"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("ul", _hoisted_7, [
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'all'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "tab3",
                "data-tabs-target": "#all",
                type: "button",
                role: "tab",
                "aria-controls": "all",
                "aria-selected": "false",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSetactiveTab('all')))
              }, " All ", 2)
            ]),
            _createElementVNode("li", _hoisted_9, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'router locations'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "tab2",
                "data-tabs-target": "#routerlocations",
                type: "button",
                role: "tab",
                "aria-controls": "routerlocations",
                "aria-selected": "false",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSetactiveTab('router locations')))
              }, " Router Locations ", 2)
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'kiosk locations'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "tab1",
                "data-tabs-target": "#kiosklocations",
                type: "button",
                role: "tab",
                "aria-controls": "kiosklocations",
                "aria-selected": "false",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSetactiveTab('kiosk locations')))
              }, " Kiosk Locations ", 2)
            ])
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { id: "myTabContent" }, [
          _createElementVNode("div", {
            id: "tab1",
            role: "tabpanel",
            "aria-labelledby": "profile-tab"
          }, [
            _createElementVNode("div", { class: "row" }, [
              _createElementVNode("div", { class: "col-md-12 animated slideInUp" }, [
                _createElementVNode("div", { class: "card-body" }, [
                  _createElementVNode("div", {
                    class: "w-full h-[500px] mt-6",
                    id: "map"
                  })
                ])
              ])
            ])
          ])
        ], -1))
      ])
    ])
  ], 64))
}