<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3
            class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
          >
            {{ submit }}
          </h3>
          <form>
            <div class="mb-2">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              >
                Realm Name:</label
              >
              <input
                placeholder=" Name"
                :value="name"
                type="text"
                id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                readonly
              />
            </div>

            <div class="mb-2" v-if="!isPartnerRealm">
              <label
                for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
                >Realm Type:</label
              >
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="realm_type"
                v-model="selectedType"
                required
              >
                <option value="">Select Realm Type</option>
                <option value="Sub Realm">Sub Realm</option>
                <option value="Independent Realm">Independent Realm</option>
              </select>
            </div>

            <div
              class="w-full flex lg:justify-end justify-start items-center mt-3"
            >
              <button
                @click="updateRealm"
                type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium"
              >
                Update Realm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import instance from '@/axios-interceptor';

export default {
  name: 'addrealmForm',
  props: {
    modal: {
      type: Boolean,
    },
    id: {
      type: Number, // Adjust the data type according to your needs
      required: true,
    },
  },
  data() {
    return {
      name: '',
      isPartnerRealm: '',
      selectedType: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  mounted() {
    this.getRealm(this.id);
  },
  methods: {
    async getRealm(id) {
      await instance({
        url: `realms/${id}/`,
      })
        .then((response) => {
          if (response.status === 200) {
            this.realm = response.data;
            this.name = response.data.name;
            this.isPartnerRealm = response.data.partner;
            this.selectedType = response.data.type;
          }
        })
        .catch((error) => {
          if (error.response) {
            // miniToastr.error("realm not found");
            console.log('realm not found');
          }
        });
    },

    async updateRealm() {
      let data = {
        name: this.name,
        type: this.selectedType,
      };
      await instance
        .patch(`realms/${this.id}/`, data)
        .then((response) => {
          this.$emit('close');
          if (response.status === 201) {
            if (response.data.detail) {
              this.$toast.success(response.data.detail, {
                position: 'top-right',
              });
            } else {
              this.$toast.success('Realm Added Successfully', {
                position: 'top-right',
              });
            }
          }
        })
        .catch((error) => {
          this.$emit('close');
          console.log(error);
        });
    },
  },
};
</script>
