import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect } from "vue"
import _imports_0 from '../../assets/user-img.svg'
import _imports_1 from '../../assets/plus-icon.png'
import _imports_2 from '../../assets/search-icon.png'


const _hoisted_1 = {
  key: 0,
  class: "lg:flex flex-row items-start gap-y-8 xl:gap-x-8 gap-x-6 mt-8 sm:gap-y-16"
}
const _hoisted_2 = { class: "xl:w-1/4 lg:w-1/3 md:w-full bg-white pb-4 sm:mx-0 shadow-lg px-5 pt-5 rounded-2xl lg:mb-0 mb-6" }
const _hoisted_3 = { class: "text-center pt-2" }
const _hoisted_4 = { class: "font-bold font-lg" }
const _hoisted_5 = { class: "edit-profile flex justify-center pt-3" }
const _hoisted_6 = { class: "user-profile-block xl:w-3/4 md:w-full lg:w-2/3 full-width bg-white p-5 rounded-[0.938rem]" }
const _hoisted_7 = { class: "user-profile-innr w-full" }
const _hoisted_8 = { class: "overflow-x-auto" }
const _hoisted_9 = { class: "min-w-full divide-y divide-gray-200 table-border" }
const _hoisted_10 = { class: "divide-y divide-gray-200 border-[#eaeaee]" }
const _hoisted_11 = { class: "bg-light" }
const _hoisted_12 = { class: "font-poppin text-[14px] text-[#888889]" }
const _hoisted_13 = { class: "font-poppin text-[14px] text-[#888889]" }
const _hoisted_14 = { class: "font-poppin text-[14px] text-[#888889]" }
const _hoisted_15 = { class: "bg-white w-full rounded-[0.938rem] md:p-6 p-4 shadow overflow-auto relative my-6" }
const _hoisted_16 = {
  class: "tabs-block my-4",
  id: "tabs-block"
}
const _hoisted_17 = { class: "mb-3" }
const _hoisted_18 = {
  class: "flex flex-wrap -mb-px text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_19 = { class: "mr-2" }
const _hoisted_20 = { class: "mr-2" }
const _hoisted_21 = { id: "myTabContent" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "bg-light w-full rounded-[0.938rem] md:p-6 p-4 shadow overflow-auto relative mt-6" }
const _hoisted_24 = { class: "sm:flex items-center justify-between" }
const _hoisted_25 = { class: "sm:mb-0 mb-2font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }
const _hoisted_26 = { class: "md:w-[100%] w-[50%] mb-2 mt-4" }
const _hoisted_27 = { class: "relative block" }
const _hoisted_28 = {
  key: 0,
  class: "p-3 flex items-center justify-between md:w-[100%] w-[50%]"
}
const _hoisted_29 = { class: "me-2 text-[#d3aa3b] font-bold text-sm" }
const _hoisted_30 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5 text-sm"
}
const _hoisted_31 = { class: "flex items-center" }
const _hoisted_32 = { class: "flex flex-col ml-3" }
const _hoisted_33 = ["onUpdate:modelValue", "onChange"]
const _hoisted_34 = { class: "flex items-center" }
const _hoisted_35 = { class: "flex flex-col ml-3" }
const _hoisted_36 = { class: "b-avatar-img capitalize text-[#464E5F] mb-0.5" }
const _hoisted_37 = { class: "flex flex-col" }
const _hoisted_38 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_39 = { class: "flex flex-col" }
const _hoisted_40 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_41 = { class: "flex flex-col" }
const _hoisted_42 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_43 = { class: "flex flex-col" }
const _hoisted_44 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_45 = ["onClick"]
const _hoisted_46 = { class: "spinner-container" }
const _hoisted_47 = {
  key: 0,
  class: "spinner"
}
const _hoisted_48 = { class: "flex items-center" }
const _hoisted_49 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }
const _hoisted_50 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_adminSmes = _resolveComponent("adminSmes")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "user-img rounded-full w-[100px] h-[100px] overflow-hidden mb-2 m-auto" }, [
              _createElementVNode("img", {
                src: _imports_0,
                class: "w-full h-full",
                alt: "user"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.sme.name), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.sme.is_active)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSuspendActivateAccountModal('deactivate-account'))),
                    class: "bg-[#d7191c] font-['poppins'] text-[11px] text-white rounded-[0.327rem] py-2 px-3 font-medium mr-1 border"
                  }, " Suspend Account "))
                : _createCommentVNode("", true),
              (!_ctx.sme.is_active)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openSuspendActivateAccountModal('activate-account'))),
                    class: "bg-[#4e8d6e] font-['poppins'] text-[11px] text-white rounded-[0.327rem] py-2 px-3 font-medium mr-1 border"
                  }, " Activate Account "))
                : _createCommentVNode("", true),
              (!_ctx.sme.is_terminated)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    type: "button",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openTerminateRestoreAccountModal('terminate-account'))),
                    class: "bg-[#171614] font-['poppins'] text-[11px] text-white rounded-[0.327rem] py-2 px-3 font-medium mr-1 border"
                  }, " Terminate Account "))
                : _createCommentVNode("", true),
              (_ctx.sme.is_terminated)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 3,
                    type: "button",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openTerminateRestoreAccountModal('restore-account'))),
                    class: "bg-[#4e8d6e] font-['poppins'] text-[11px] text-white rounded-[0.327rem] py-2 px-3 font-medium mr-1 border"
                  }, " Restore Account "))
                : _createCommentVNode("", true),
              (_ctx.showAccountSuspensionActivationModal)
                ? (_openBlock(), _createBlock(_component_confirmation_dialog, {
                    key: 4,
                    show: _ctx.showAccountSuspensionActivationModal,
                    title: _ctx.getAccountSuspensionActivationTitle(),
                    message: _ctx.getAccountSuspensionActivationMessage(),
                    actionLabel: _ctx.getAccountSuspensionActivationLabel(),
                    loading: _ctx.processing,
                    onCancel: _ctx.cancel,
                    onConfirm: _ctx.saveAccount
                  }, null, 8, ["show", "title", "message", "actionLabel", "loading", "onCancel", "onConfirm"]))
                : _createCommentVNode("", true),
              (_ctx.showAccountTerminationRestoreModal)
                ? (_openBlock(), _createBlock(_component_confirmation_dialog, {
                    key: 5,
                    show: _ctx.showAccountTerminationRestoreModal,
                    title: _ctx.getAccountTerminationRestoreTitle(),
                    message: _ctx.getAccountTerminationRestoreMessage(),
                    actionLabel: _ctx.getAccountTerminationRestoreLabel(),
                    loading: _ctx.processing,
                    onCancel: _ctx.cancel,
                    onConfirm: _ctx.saveAccount
                  }, null, 8, ["show", "title", "message", "actionLabel", "loading", "onCancel", "onConfirm"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "text-left mb-3 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, " FULL PROFILE ", -1)),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("table", _hoisted_9, [
                  _cache[14] || (_cache[14] = _createElementVNode("thead", { class: "bg-gray-50" }, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", { class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500" }, " Name "),
                      _createElementVNode("th", { class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500" }, " Country "),
                      _createElementVNode("th", { class: "px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500" }, " REGISTERED ")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", _hoisted_10, [
                    _createElementVNode("tr", _hoisted_11, [
                      _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.sme.name), 1),
                      _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.sme.country), 1),
                      _createElementVNode("td", _hoisted_14, _toDisplayString(new Date(_ctx.sme.created).toLocaleDateString()), 1)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("ul", _hoisted_18, [
            _createElementVNode("li", _hoisted_19, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'routers'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.activeTab = 'routers'))
              }, " Routers ", 2)
            ]),
            _createElementVNode("li", _hoisted_20, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'admin'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.activeTab = 'admin'))
              }, " Admin ", 2)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          (_ctx.activeTab === 'routers')
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("h3", _hoisted_25, _toDisplayString(_ctx.changeName ? 'ASSIGN SME ROUTERS' : 'ALL SME ROUTERS'), 1),
                    _createElementVNode("button", {
                      type: "button",
                      class: "font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff] flex items-center bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem]",
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.changeBtn && _ctx.changeBtn(...args)))
                    }, [
                      _cache[16] || (_cache[16] = _createElementVNode("img", {
                        src: _imports_1,
                        class: "w-[0.6rem] h-[0.6rem] mr-1",
                        alt: ""
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.changeName ? 'All Sme Routers' : 'Assign Routers'), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("label", _hoisted_27, [
                      _cache[17] || (_cache[17] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
                      _cache[18] || (_cache[18] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
                        _createElementVNode("img", {
                          src: _imports_2,
                          alt: "",
                          class: "w-[0.8rem] h-[0.8rem]"
                        })
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.searchQuery) = $event)),
                        class: "bg-white font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1",
                        placeholder: "Search",
                        type: "text",
                        name: "search"
                      }, null, 512), [
                        [_vModelText, _ctx.searchQuery]
                      ])
                    ])
                  ]),
                  (_ctx.showButtons())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.rowsSelected) + " Rows selected", 1),
                          _createElementVNode("span", {
                            class: "cursor-pointer text-[#5e72e4] font-bold text-sm",
                            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.clearRows && _ctx.clearRows(...args)))
                          }, "clear")
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromSme && _ctx.unallocateRoutersFromSme(...args)))
                          }, " unassign SME router "),
                          _createElementVNode("button", {
                            type: "button",
                            class: "capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromPartner && _ctx.unallocateRoutersFromPartner(...args)))
                          }, " unassign Partner router ")
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_SmeTable, {
                    sorting: true,
                    onSort: _ctx.sortBy,
                    inputShow: true,
                    selectAllValues: _ctx.selectAll,
                    onSelectAllRows: _ctx.selectAllRows,
                    tableName: 'Realm Routers',
                    tableHeaderData: _ctx.accountsTableHeader
                  }, {
                    tableBodyData: _withCtx(() => [
                      (!_ctx.paginatedTableData.length)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_30, " No records "))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredRouters, (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: item.id,
                              class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                            }, [
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_31, [
                                  _createElementVNode("div", _hoisted_32, [
                                    _withDirectives(_createElementVNode("input", {
                                      "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                                      onChange: ($event: any) => (_ctx.updateSelectedRows(item)),
                                      type: "checkbox",
                                      class: "shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                                    }, null, 40, _hoisted_33), [
                                      [_vModelCheckbox, item.selected]
                                    ])
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("div", _hoisted_35, [
                                    _createElementVNode("span", _hoisted_36, _toDisplayString(item.serial_number), 1)
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_37, [
                                  _createElementVNode("span", _hoisted_38, _toDisplayString(item.active ? 'online' : 'offline'), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_39, [
                                  _createElementVNode("span", _hoisted_40, _toDisplayString(item.realm.name.split('Realm')[0] + item.realm.type), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_41, [
                                  _createElementVNode("span", _hoisted_42, _toDisplayString(item.imei), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_43, [
                                  _createElementVNode("span", _hoisted_44, _toDisplayString(item.created), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", {
                                    onClick: ($event: any) => (_ctx.viewrouterDetails(item.id)),
                                    class: "capitalize px-2 py-1 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center cursor-pointer"
                                  }, "view", 8, _hoisted_45)
                                ])
                              ])
                            ]))
                          }), 128))
                    ]),
                    _: 1
                  }, 8, ["onSort", "selectAllValues", "onSelectAllRows", "tableHeaderData"]),
                  _createElementVNode("div", _hoisted_46, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_47))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.propLoaded)
                    ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                        key: 1,
                        perPageItems: _ctx.perSize,
                        pageNumber: _ctx.page,
                        ApiData: _ctx.apiData,
                        onHandleNext: _ctx.handlleNext,
                        onHandlePrev: _ctx.handlePrev
                      }, {
                        section: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_48, [
                              _cache[20] || (_cache[20] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                              _createElementVNode("span", _hoisted_49, [
                                _withDirectives(_createElementVNode("select", {
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedOption) = $event)),
                                  onChange: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                                }, _cache[19] || (_cache[19] = [
                                  _createElementVNode("option", { value: "10" }, "10", -1),
                                  _createElementVNode("option", { value: "20" }, "20", -1),
                                  _createElementVNode("option", { value: "30" }, "30", -1),
                                  _createElementVNode("option", { value: "40" }, "40", -1),
                                  _createElementVNode("option", { value: "50" }, "50", -1)
                                ]), 544), [
                                  [_vModelSelect, _ctx.selectedOption]
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'admin')
            ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                _createVNode(_component_adminSmes, {
                  voucherId: _ctx.$route.params.id
                }, null, 8, ["voucherId"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}