<template>
    <div class="flex flex-col md:block hidden my-6">
        <div class="flex items-center">
            <i class="fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2"></i>
            <span class="font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">Services</span>
        </div>

    </div>

    <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative">

        <!-- tabs -->
        <div class="tabs-block" id="tabs-block">
            <div class="mb-3">
                <ul class="flex flex-wrap -mb-px text-sm font-medium text-center mb-2" id="myTab"
                    data-tabs-toggle="#myTabContent" role="tablist">
                    <li class="mr-2" v-for="tab in tabs" :key="tab">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab1" data-tabs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="false"
                            v-on:click="setActiveTab(tab)"
                            v-bind:class="[activeTab === tab ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">
                            {{ tab.replaceAll('_', ' ') }}</button>
                    </li>

                </ul>
            </div>
            <div id="myTabContent">
                <div class="bg-light sm:p-5 p-4 rounded">
                    <internetSettings v-if="activeTab === 'Internet Setting'"></internetSettings>
                    <AggregatorsList v-if="activeTab === 'Aggregators'"></AggregatorsList>
                    <Announcements v-if="activeTab === 'Announcements'"></Announcements>
                    <APIKeys v-if="activeTab === 'API_Keys'"></APIKeys>
                    <LeasingFees v-if="activeTab === 'Leasing_Fees'"></LeasingFees>
                    <ServiceSettings v-if="activeTab === 'Service_Settings'"></ServiceSettings>
                    <CreditFees v-if="activeTab === 'Credit_Fees'"></CreditFees>
                    <PaymentModes v-if="activeTab === 'Payment_Mode'"></PaymentModes>
                    <MFPenalties v-if="activeTab === 'MF_Penalty'"></MFPenalties>
                    <ServiceFees v-if="activeTab === 'Service_Fees'"></ServiceFees>
                    <TaxConfiguration v-if="activeTab === 'Tax_Configuration'"></TaxConfiguration>
                    <InternetProfitCalculatorForm v-if="activeTab == 'Internet Setting' && plan === 'Premium'">
                    </InternetProfitCalculatorForm>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import AggregatorsList from './AggregatorsList.vue';
import Announcements from './AnnouncementsList.vue';
import APIKeys from './APIKeys.vue';
import LeasingFees from './LeasingFees.vue';
import ServiceSettings from './ServiceSettings.vue';
import CreditFees from './CreditFees.vue';
import PaymentModes from './PaymentModes.vue';
import MFPenalties from './MFPenalties.vue';
import ServiceFees from './ServiceFees.vue';
import TaxConfiguration from './TaxConfiguration.vue';
import internetSettings from './internetSettings.vue'
export default defineComponent({
    name: "ServicePage",
    components: {
        AggregatorsList,
        Announcements,
        APIKeys,
        LeasingFees,
        ServiceSettings,
        CreditFees,
        PaymentModes,
        MFPenalties,
        ServiceFees,
        TaxConfiguration,
        internetSettings
    },
    setup() {

        const accountsTableHeader = ref([
            "Name",
            "Email",
            "Phone",
            "Status",
            "Action",
        ]);

        const selectedSubscription = ref(false);

        return {
            accountsTableHeader,
        };
    },
    data() {
        return {
            activeTab: 'Internet Setting',
            tabs: [
                'Internet Setting',
                'Aggregators',
                'Announcements',
                'API_Keys',
                'Leasing_Fees',
                'Service_Settings',
                'Credit_Fees',
                'Payment_Mode',
                'MF_Penalty',
                'Service_Fees',
                'Tax_Configuration',
            ],
            plan: localStorage.getItem('plan'),

        };
    },
    mounted() {
        // if (this.plan !== 'Premium') {
        //     this.tabs = this.tabs.filter((tab) => tab !== 'Internet Setting');
        //     this.activeTab = 'Aggregators';
        // }
    },
    methods: {
        setActiveTab(tab: string) {
            this.activeTab = tab;
        },
    },
    computed: {

    },

});
</script>