import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelSelect as _vModelSelect, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/today.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium text-[0.8rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = { class: "w-full my-3" }
const _hoisted_5 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative" }
const _hoisted_6 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = {
  class: "flex flex-wrap text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { class: "mr-2" }
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "mr-2" }
const _hoisted_13 = { id: "myTabContent" }
const _hoisted_14 = {
  key: 0,
  class: "p-3 flex items-center justify-between md:w-[100%] w-[50%]"
}
const _hoisted_15 = { class: "me-2 text-[#d3aa3b] font-bold text-sm" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "flex items-center" }
const _hoisted_18 = { class: "flex flex-col ml-3" }
const _hoisted_19 = ["onUpdate:modelValue", "onChange"]
const _hoisted_20 = { class: "flex items-center" }
const _hoisted_21 = { class: "bg-[#F3F6F9] w-[2.806rem] h-[2.806rem] rounded-[0.337rem] flex items-center justify-center" }
const _hoisted_22 = ["src", "alt"]
const _hoisted_23 = { class: "flex flex-col ml-3" }
const _hoisted_24 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_25 = {
  key: 0,
  class: "dotonline ml-2"
}
const _hoisted_26 = {
  key: 1,
  class: "dotoffline ml-2"
}
const _hoisted_27 = { class: "flex flex-col" }
const _hoisted_28 = { class: "whitespace-nowrap" }
const _hoisted_29 = { class: "text-[#464E5F]" }
const _hoisted_30 = { class: "flex flex-col" }
const _hoisted_31 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_32 = { class: "text-[#464E5F]" }
const _hoisted_33 = { class: "text-[#464E5F]" }
const _hoisted_34 = { class: "flex items-center justify-end" }
const _hoisted_35 = { class: "text-right" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = {
  key: 0,
  class: "bg-[#F3F6F9] w-[4rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2 p-2"
}
const _hoisted_38 = ["onClick"]
const _hoisted_39 = ["src"]
const _hoisted_40 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm"
}
const _hoisted_41 = { class: "flex items-center" }
const _hoisted_42 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }
const _hoisted_43 = {
  key: 1,
  class: "spinner-container"
}
const _hoisted_44 = { key: 2 }
const _hoisted_45 = { class: "flex my-4" }
const _hoisted_46 = { class: "mr-8" }
const _hoisted_47 = { class: "flex mb-2" }
const _hoisted_48 = { class: "text-sm ml-2" }
const _hoisted_49 = { class: "flex mb-2" }
const _hoisted_50 = { class: "text-sm ml-2" }
const _hoisted_51 = { class: "flex mb-2" }
const _hoisted_52 = { class: "text-sm ml-2" }
const _hoisted_53 = { class: "flex mb-2" }
const _hoisted_54 = { class: "text-sm ml-2" }
const _hoisted_55 = { class: "flex mb-2" }
const _hoisted_56 = { class: "text-sm ml-2" }
const _hoisted_57 = { class: "flex mb-2" }
const _hoisted_58 = { class: "text-sm ml-2" }
const _hoisted_59 = { class: "flex items-center" }
const _hoisted_60 = { class: "bg-[#F3F6F9] w-[2.806rem] h-[2.806rem] rounded-[0.337rem] flex items-center justify-center" }
const _hoisted_61 = ["src", "alt"]
const _hoisted_62 = { class: "flex flex-col ml-3" }
const _hoisted_63 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_64 = { class: "whitespace-nowrap" }
const _hoisted_65 = { class: "text-[#464E5F]" }
const _hoisted_66 = { class: "whitespace-nowrap" }
const _hoisted_67 = { class: "text-[#464E5F]" }
const _hoisted_68 = { class: "whitespace-nowrap" }
const _hoisted_69 = { class: "text-[#464E5F]" }
const _hoisted_70 = { class: "text-right" }
const _hoisted_71 = ["onClick"]
const _hoisted_72 = ["onClick"]
const _hoisted_73 = ["onClick"]
const _hoisted_74 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm"
}
const _hoisted_75 = { class: "flex items-center" }
const _hoisted_76 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }
const _hoisted_77 = {
  key: 1,
  class: "spinner-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_assign_router_to_realm = _resolveComponent("assign-router-to-realm")!
  const _component_assign_router_to_SME = _resolveComponent("assign-router-to-SME")!
  const _component_confirmation_dialog = _resolveComponent("confirmation-dialog")!
  const _component_edit_form = _resolveComponent("edit-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Allocated Routers",
      icon: _ctx.icon.router
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[22] || (_cache[22] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[23] || (_cache[23] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[0.8rem] h-[0.8rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["icon"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("ul", _hoisted_8, [
              _createElementVNode("li", _hoisted_9, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'all'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                  id: "profile-tab",
                  "data-tabs-target": "#profile",
                  type: "button",
                  role: "tab",
                  "aria-controls": "profile",
                  "aria-selected": "false",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSetActiveTab('all')))
                }, " All ", 2)
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'suspended'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                  id: "dashboard-tab",
                  "data-tabs-target": "#dashboard",
                  type: "button",
                  role: "tab",
                  "aria-controls": "dashboard",
                  "aria-selected": "false",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSetActiveTab('suspended')))
                }, " Unassigned ", 2)
              ]),
              _createElementVNode("li", _hoisted_11, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'active'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                  id: "settings-tab",
                  "data-tabs-target": "#settings",
                  type: "button",
                  role: "tab",
                  "aria-controls": "settings",
                  "aria-selected": "false",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSetActiveTab('active')))
                }, " Assigned ", 2)
              ]),
              _createElementVNode("li", _hoisted_12, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activeTab === 'hashhub'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                  id: "hashhub-tab",
                  "data-tabs-target": "#settings",
                  type: "button",
                  role: "tab",
                  "aria-controls": "hashhub",
                  "aria-selected": "false",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSetActiveTab('hashhub')))
                }, " Hash Hub ", 2)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            (_ctx.showButtons())
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.rowsSelected) + " Rows selected", 1),
                    _createElementVNode("span", {
                      class: "cursor-pointer text-[#5e72e4] font-bold text-sm",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clearRows && _ctx.clearRows(...args)))
                    }, "clear")
                  ]),
                  _createElementVNode("div", null, [
                    (_ctx.selectedRows.length === 1 &&
                !_ctx.selectedRows[0].has_been_assigned_xmrig
              )
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: "me-2 cursor-pointer capitalize p-2 rounded bg-primary text-white mb-0.5 inline-block text-center",
                          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onAssignXMRIG && _ctx.onAssignXMRIG(...args)))
                        }, " Assign Hash Hub "))
                      : _createCommentVNode("", true),
                    (_ctx.selectedRows.length === 1 &&
                _ctx.selectedRows[0].has_been_assigned_xmrig
              )
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          type: "button",
                          class: "me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onUnassignXMRIG(_ctx.selectedRows[0].ssid)))
                        }, " Unssign Hash Hub "))
                      : _createCommentVNode("", true),
                    (_ctx.selectedRows.length === 1 &&
                !_ctx.selectedRows[0].realm?.id
              )
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 2,
                          type: "button",
                          class: "me-2 cursor-pointer capitalize p-2 rounded bg-primary text-white mb-0.5 inline-block text-center",
                          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onAssignRouterToRealm && _ctx.onAssignRouterToRealm(...args)))
                        }, " Assign Realm "))
                      : _createCommentVNode("", true),
                    (_ctx.selectedRows.length === 1 &&
                _ctx.selectedRows[0].realm?.id)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 3,
                          type: "button",
                          class: "me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onUnassignRouterToRealm && _ctx.onUnassignRouterToRealm(...args)))
                        }, " Unassign Realm "))
                      : _createCommentVNode("", true),
                    (_ctx.selectedRows.length)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 4,
                          type: "button",
                          class: "me-2 cursor-pointer capitalize p-2 rounded bg-primary text-white mb-0.5 inline-block text-center",
                          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onAssignRouterToSME && _ctx.onAssignRouterToSME(...args)))
                        }, " Assign SME "))
                      : _createCommentVNode("", true),
                    (_ctx.selectedRows.length)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 5,
                          type: "button",
                          class: "me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showUnassignSmeModal = true))
                        }, " Unassign SME "))
                      : _createCommentVNode("", true),
                    (_ctx.selectedRows.length === 1 &&
                _ctx.selectedRows[0].partner?.id)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 6,
                          type: "button",
                          class: "capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showUnassignPartnerModal = true))
                        }, " Unassign Partner "))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.activeTab !== 'hashhub')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_component_SmeTable, {
                    sorting: true,
                    onSort: _ctx.sortBy,
                    inputShow: true,
                    selectAllValues: _ctx.selectAll,
                    tableHeaderData: _ctx.routerTableHeaders,
                    tableName: 'Routers',
                    onSelectAllRows: _ctx.selectAllRows
                  }, {
                    tableBodyData: _withCtx(() => [
                      (_ctx.paginatedTableData.length)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paginatedTableData, (router) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: router.id,
                              class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                            }, [
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createElementVNode("div", _hoisted_18, [
                                    _withDirectives(_createElementVNode("input", {
                                      "onUpdate:modelValue": ($event: any) => ((router.selected) = $event),
                                      onChange: ($event: any) => (_ctx.updateSelectedRows(router)),
                                      type: "checkbox",
                                      class: "shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                                    }, null, 40, _hoisted_19), [
                                      [_vModelCheckbox, router.selected]
                                    ])
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("div", _hoisted_21, [
                                    _createElementVNode("img", {
                                      src: router.location === 'In Transit'
                            ? require('../../assets/router.png')
                            : router.location === 'Manufacturer'
                              ? require('../../assets/transit_router.png')
                              : require('../../assets/active_router.png')
                            ,
                                      alt: router.nasidentifier,
                                      class: "w-[1.89rem] h-[1.779rem]"
                                    }, null, 8, _hoisted_22)
                                  ]),
                                  _createElementVNode("div", _hoisted_23, [
                                    _createElementVNode("span", _hoisted_24, _toDisplayString(router.serial_number), 1),
                                    (router.is_online)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_25))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_26))
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_27, [
                                  _createElementVNode("span", null, _toDisplayString(router.ssid), 1)
                                ])
                              ]),
                              _createElementVNode("td", _hoisted_28, [
                                _createElementVNode("span", _hoisted_29, _toDisplayString(router.sme?.name || router.partner?.name + 'PARTNER'), 1)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_30, [
                                  _createElementVNode("span", _hoisted_31, _toDisplayString(router.os_version_name), 1),
                                  _createElementVNode("span", null, "version " + _toDisplayString(router.os_version_id), 1)
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", _hoisted_32, _toDisplayString(router.realm ? router.realm.name : 'No Realm'), 1)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("span", _hoisted_33, _toDisplayString(router.location_name), 1)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("div", _hoisted_35, [
                                    _createElementVNode("span", {
                                      onClick: ($event: any) => (_ctx.viewDetails(router.id)),
                                      class: "capitalize px-2 py-1 rounded bg-[#342e37] text-white mb-0.5 inline-block text-center cursor-pointer"
                                    }, "view", 8, _hoisted_36)
                                  ]),
                                  (!router.sme)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                        _createElementVNode("button", {
                                          onClick: ($event: any) => (_ctx.editRouter(router)),
                                          class: "flex"
                                        }, [
                                          _createElementVNode("img", {
                                            src: _ctx.routerTableIcons[1],
                                            alt: "africa",
                                            class: "w-[1.125rem] h-[1.067rem]"
                                          }, null, 8, _hoisted_39),
                                          _cache[24] || (_cache[24] = _createElementVNode("span", { class: "ml-2" }, "Edit", -1))
                                        ], 8, _hoisted_38)
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ]))
                          }), 128))
                        : (_openBlock(), _createElementBlock("div", _hoisted_40, _cache[25] || (_cache[25] = [
                            _createElementVNode("b", null, "No Data Found", -1)
                          ])))
                    ]),
                    _: 1
                  }, 8, ["onSort", "selectAllValues", "tableHeaderData", "onSelectAllRows"]),
                  (_ctx.propLoaded)
                    ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                        key: 0,
                        perPageItems: _ctx.perSize,
                        pageNumber: _ctx.page,
                        ApiData: _ctx.apiData,
                        onHandleNext: _ctx.handlleNext,
                        onHandlePrev: _ctx.handlePrev
                      }, {
                        section: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_41, [
                              _cache[27] || (_cache[27] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                              _createElementVNode("span", _hoisted_42, [
                                _withDirectives(_createElementVNode("select", {
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedOption) = $event)),
                                  onChange: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                                }, _cache[26] || (_cache[26] = [
                                  _createElementVNode("option", { value: "10" }, "10", -1),
                                  _createElementVNode("option", { value: "20" }, "20", -1),
                                  _createElementVNode("option", { value: "30" }, "30", -1),
                                  _createElementVNode("option", { value: "40" }, "40", -1),
                                  _createElementVNode("option", { value: "50" }, "50", -1)
                                ]), 544), [
                                  [_vModelSelect, _ctx.selectedOption]
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                    : _createCommentVNode("", true),
                  (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_43, _cache[28] || (_cache[28] = [
                        _createElementVNode("div", { class: "spinner" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'hashhub')
              ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", _hoisted_47, [
                        _cache[30] || (_cache[30] = _createElementVNode("i", { class: "fa fa-bank" }, null, -1)),
                        _createElementVNode("p", _hoisted_48, [
                          _cache[29] || (_cache[29] = _createTextVNode(" Pending Balance: ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.miningWalletStats['Pending Balance']), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_49, [
                        _cache[32] || (_cache[32] = _createElementVNode("i", { class: "fa fa-credit-card" }, null, -1)),
                        _createElementVNode("p", _hoisted_50, [
                          _cache[31] || (_cache[31] = _createTextVNode(" Last Block Reward: ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.miningWalletStats['Last Block Reward']), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_51, [
                        _cache[34] || (_cache[34] = _createElementVNode("i", { class: "fa-solid fa-money-bill" }, null, -1)),
                        _createElementVNode("p", _hoisted_52, [
                          _cache[33] || (_cache[33] = _createTextVNode(" Total Paid: ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.miningWalletStats['Total Paid']), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_53, [
                        _cache[36] || (_cache[36] = _createElementVNode("i", { class: "fa-solid fa-clock" }, null, -1)),
                        _createElementVNode("p", _hoisted_54, [
                          _cache[35] || (_cache[35] = _createTextVNode(" Last Share Submitted: ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.miningWalletStats['Last Share Submitted']), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_55, [
                        _cache[38] || (_cache[38] = _createElementVNode("i", { class: "fa fa-cloud-upload" }, null, -1)),
                        _createElementVNode("p", _hoisted_56, [
                          _cache[37] || (_cache[37] = _createTextVNode(" Total Hashes Submitted: ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.miningWalletStats['Total Hashes Submitted']), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_57, [
                        _cache[40] || (_cache[40] = _createElementVNode("i", { class: "fa fa-tachometer" }, null, -1)),
                        _createElementVNode("p", _hoisted_58, [
                          _cache[39] || (_cache[39] = _createTextVNode(" Hash Rate: ")),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.miningWalletStats['Hash Rate']), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createVNode(_component_SmeTable, {
                    onSort: _ctx.sortBy,
                    tableHeaderData: _ctx.minerTableHeaders,
                    tableName: 'Routers'
                  }, {
                    tableBodyData: _withCtx(() => [
                      (_ctx.miners.length)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.miners, (miner) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: miner.id,
                              class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                            }, [
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_59, [
                                  _createElementVNode("div", _hoisted_60, [
                                    _createElementVNode("img", {
                                      src: require('../../assets/active_router.png'),
                                      alt: miner.router_ssid,
                                      class: "w-[1.89rem] h-[1.779rem]"
                                    }, null, 8, _hoisted_61)
                                  ]),
                                  _createElementVNode("div", _hoisted_62, [
                                    _createElementVNode("span", _hoisted_63, _toDisplayString(miner.router_ssid), 1)
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", _hoisted_64, [
                                _createElementVNode("span", _hoisted_65, _toDisplayString(_ctx.moment(miner.mining_start_time, 'HH:mm:ss').format(
                          'h:mm A'
                        )), 1)
                              ]),
                              _createElementVNode("td", _hoisted_66, [
                                _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.moment(miner.mining_end_time, 'HH:mm:ss').format(
                          'h:mm A'
                        )), 1)
                              ]),
                              _createElementVNode("td", _hoisted_68, [
                                _createElementVNode("span", _hoisted_69, _toDisplayString(miner.status), 1)
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_70, [
                                  (miner.status === 'inactive')
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        onClick: ($event: any) => (_ctx.updateMinerStatus(miner, 'running')),
                                        class: "capitalize px-2 py-1 rounded bg-[#342e37] text-white mb-0.5 inline-block text-center cursor-pointer"
                                      }, "Start Mining", 8, _hoisted_71))
                                    : _createCommentVNode("", true),
                                  (miner.status === 'running')
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 1,
                                        onClick: ($event: any) => (_ctx.updateMinerStatus(miner, 'paused')),
                                        class: "capitalize px-2 py-1 rounded bg-[#342e37] text-white mb-0.5 inline-block text-center cursor-pointer"
                                      }, "Pause Mining", 8, _hoisted_72))
                                    : _createCommentVNode("", true),
                                  (miner.status === 'paused')
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 2,
                                        onClick: ($event: any) => (_ctx.updateMinerStatus(miner, 'running')),
                                        class: "capitalize px-2 py-1 rounded bg-[#342e37] text-white mb-0.5 inline-block text-center cursor-pointer"
                                      }, "Resume Mining", 8, _hoisted_73))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ]))
                          }), 128))
                        : (_openBlock(), _createElementBlock("div", _hoisted_74, _cache[41] || (_cache[41] = [
                            _createElementVNode("b", null, "No Data Found", -1)
                          ])))
                    ]),
                    _: 1
                  }, 8, ["onSort", "tableHeaderData"]),
                  (_ctx.propLoaded)
                    ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
                        key: 0,
                        perPageItems: _ctx.perSize,
                        pageNumber: _ctx.page,
                        ApiData: _ctx.apiData,
                        onHandleNext: _ctx.handlleNext,
                        onHandlePrev: _ctx.handlePrev
                      }, {
                        section: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_75, [
                              _cache[43] || (_cache[43] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                              _createElementVNode("span", _hoisted_76, [
                                _withDirectives(_createElementVNode("select", {
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.selectedOption) = $event)),
                                  onChange: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                                }, _cache[42] || (_cache[42] = [
                                  _createElementVNode("option", { value: "10" }, "10", -1),
                                  _createElementVNode("option", { value: "20" }, "20", -1),
                                  _createElementVNode("option", { value: "30" }, "30", -1),
                                  _createElementVNode("option", { value: "40" }, "40", -1),
                                  _createElementVNode("option", { value: "50" }, "50", -1)
                                ]), 544), [
                                  [_vModelSelect, _ctx.selectedOption]
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
                    : _createCommentVNode("", true),
                  (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_77, _cache[44] || (_cache[44] = [
                        _createElementVNode("div", { class: "spinner" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.showAssignRouterToRealmModal)
        ? (_openBlock(), _createBlock(_component_assign_router_to_realm, {
            key: 0,
            action: _ctx.routerToRealmAction,
            routers: _ctx.selectedRows,
            onClose: _cache[16] || (_cache[16] = ($event: any) => (_ctx.closeAssignRouterToRealmModal()))
          }, null, 8, ["action", "routers"]))
        : _createCommentVNode("", true),
      (_ctx.showAssignRouterToSmeModal)
        ? (_openBlock(), _createBlock(_component_assign_router_to_SME, {
            key: 1,
            routers: _ctx.selectedRows,
            onClose: _cache[17] || (_cache[17] = ($event: any) => (_ctx.closeAssignRouterToSmeModal()))
          }, null, 8, ["routers"]))
        : _createCommentVNode("", true),
      _createVNode(_component_confirmation_dialog, {
        show: _ctx.showUnassignPartnerModal,
        title: "Are you sure you want to unassign yourself from the selected router(s)?",
        message: " You will immediately lose access and you would need support from the provider to get it back",
        actionLabel: "Confirm",
        loading: _ctx.loadingPartnerUnassigning,
        onCancel: _cache[18] || (_cache[18] = ($event: any) => (_ctx.showUnassignPartnerModal = false)),
        onConfirm: _ctx.unassignRoutersFromPartner
      }, null, 8, ["show", "loading", "onConfirm"]),
      _createVNode(_component_confirmation_dialog, {
        show: _ctx.showUnassignSmeModal,
        title: "Are you sure you want to unassign SME from the selected router(s)?",
        message: "They will immediately lose access",
        actionLabel: "Confirm",
        loading: _ctx.loadingSmeUnassigning,
        onCancel: _cache[19] || (_cache[19] = ($event: any) => (_ctx.showUnassignSmeModal = false)),
        onConfirm: _ctx.unassignRoutersFromSME
      }, null, 8, ["show", "loading", "onConfirm"])
    ]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 0,
          onClose: _cache[20] || (_cache[20] = ($event: any) => (_ctx.showModal = false)),
          onRouterUpdated: _cache[21] || (_cache[21] = ($event: any) => (_ctx.handleSetActiveTab('all'))),
          router: _ctx.currentRouter
        }, null, 8, ["router"]))
      : _createCommentVNode("", true)
  ], 64))
}