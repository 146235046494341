import { SmsPackageBilling } from '@/store/types/billing';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { Billing, ChartData, Subscription } from '@/interfaces/billing';

export default {
  namespace: true,
  state() {
    return {
      billings: [] as Billing[],
      smsBillings: [] as SmsPackageBilling[],
      chartData: [] as ChartData[],
      packages: [] as Subscription[],
      package: { packageName: 'Starter', paymentStatus: 'Pending' },
      totalAmount: 0,
      revenues: 0,
    };
  },
  mutations,
  actions,
  getters,
};
