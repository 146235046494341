import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-start items-center bg-gray-100" }
const _hoisted_2 = { class: "w-[500px] p-6 bg-white rounded-lg shadow" }
const _hoisted_3 = {
  key: 0,
  class: "mb-4 p-4 border rounded-md border-[#4E8D6E]"
}
const _hoisted_4 = { class: "mt-3 text-sm" }
const _hoisted_5 = {
  key: 2,
  type: "button",
  class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-4 p-4 border rounded-md border-[#4E8D6E]" }, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, "SMS Add-On"),
          _createElementVNode("p", { class: "mt-3 text-sm" }, " The SMS Add-On will help your users to generate wifi access tokens via sms instead of you providing them with the tokens manually ")
        ])
      ], -1)),
      (_ctx.loggedInUser.partner.has_voucher_sms_feature_enabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "font-bold text-lg mb-2 text-primary" }, " Total Sent SMS Messages ", -1)),
              _createElementVNode("p", _hoisted_4, [
                _cache[1] || (_cache[1] = _createTextVNode(" You have sent ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.loggedInUser.partner.total_sms_sent), 1),
                _cache[2] || (_cache[2] = _createTextVNode(" sms messages "))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loggedInUser.partner.has_voucher_sms_feature_enabled && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activateAddOn && _ctx.activateAddOn(...args)))
          }, " Activate "))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("button", _hoisted_5, " Processing "))
        : _createCommentVNode("", true)
    ])
  ]))
}