<template>
  <div class="flex flex-col mt-6 md:mb-6 mb-3">
    <div class="flex items-center">
      <span
        class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
        >Router - {{ router.serial_number }}
      </span>
    </div>
  </div>
  <div
    class="w-full shadow-lg px-6 pt-6 pb-8 shadow-gray-900/5 bg-white rounded-2xl full-width"
  >
    <div
      class="grid lg:grid-cols-2 grid-cols-1 items-start gap-y-8 gap-x-8 lg:gap-y-16"
    >
      <div class="user-profile-block">
        <h3
          class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor lg:mb-4 mb-2"
        >
          Router Details
        </h3>
        <div class="user-profile-innr w-full">
          <table class="w-full table-fixed table-border">
            <tbody>
              <tr class="bg-light">
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  Serial Number
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ router.serial_number }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  IMEI
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ router.imei }}
                </td>
              </tr>
              <tr class="bg-light">
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  Status
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  <span
                    v-bind:class="[
                      router.active
                        ? 'text-[#4e8d6e] bg-[#DCE9E3]'
                        : 'text-white bg-[#d7191c]',
                    ]"
                    class="font-poppins font-medium text-[0.8rem] px-3 py-1 rounded-[0.327rem]"
                  >
                    {{ router.active ? 'Functional' : 'Damaged' }}</span
                  >
                </td>
              </tr>
              <tr v-if="router.location_name">
                <td
                  lass="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  Location
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ router.location_name }}
                </td>
              </tr>
              <tr class="bg-light">
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  On Field
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  <span v-if="router.sme">Yes</span>
                  <span v-else class="text-danger">No</span>
                </td>
              </tr>
              <tr>
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  Kiosk
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ router?.router_kiosk?.serial_number }}
                </td>
              </tr>
              <tr class="bg-light" v-if="router.realm">
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  Realm
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ router.realm.name }}
                </td>
              </tr>
              <tr v-if="router.super_realm">
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  Super Realm
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ router.super_realm_name }}
                </td>
              </tr>
              <tr class="bg-light" v-if="router.last_contact">
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  IMEI
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ router.last_contact }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  Assigned To
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ router.sme?.name }}
                </td>
              </tr>
              <tr>
                <td
                  class="font-poppin md:text-[1rem] text-[0.9rem] break-words leading-5 text-[#888889] whitespace-normal"
                >
                  Created
                </td>
                <td
                  class="font-medium md:text-[1rem] text-[0.9rem] break-words leading-5 whitespace-normal"
                >
                  {{ new Date(router.created).toLocaleString() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="location-block">
        <h3
          class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor lg:mb-4 mb-2"
        >
          Router Location
        </h3>

        <div
          class="full-width"
          v-if="router?.lat === null && router?.lon === null"
        >
          <h4 class="text-[#d7191c] md:text-[1rem] text-[0.9rem]">
            Device Location Not Yet Set
          </h4>
        </div>
        <div class="card-body">
          <div class="w-full h-[500px] mt-6" id="map"></div>
        </div>
      </div>
    </div>
    <div class="router-btns pt-7">
      <!-- <button
        @click="showModel = true"
        type="submit"
        class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
      >
        Edit Location
      </button>
      <locationForm
        :RouterId="route.params.id"
        v-if="showModel"
        @close="closeModal"
      ></locationForm> -->

      <button
        @click="showQR"
        type="submit"
        class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
      >
        Get QR CCode
      </button>
      <qrForm
        :QRnumber="router.serial_number"
        v-if="showQRModal"
        @close="showQRModal = false"
      ></qrForm>
    </div>
  </div>
</template>

<script lang="ts">
import { useRoute } from '@/router';
import { defineComponent, ref } from 'vue';

import mapboxgl from 'mapbox-gl';
import qrForm from './qrForm.vue';
import instance from '@/axios-interceptor';
import { Router } from '@/interfaces/router';
// import locationForm from './locationform.vue';

export default defineComponent({
  name: 'RouterDetailsPage',
  components: {
    qrForm,
    // locationForm,
  },
  setup() {
    const accessToken = ref(
      'pk.eyJ1IjoiZ3MwMWhhbiIsImEiOiJjbGtjamU3dmEwN2pwM2pxam9taGZ0cTJ6In0.8_zydJrYKh6734GskrBRqA'
    );
    const mapStyle = ref({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [12.550343, 55.665957],
      zoom: 12,
    });

    return {
      accessToken,
      mapStyle,
    };
  },
  data() {
    return {
      showModel: false,
      isLoading: false,
      activeTab: 'all',
      router: {} as Router,
      userId: '',
      user: {},
      showQRModal: false,
    };
  },

  methods: {
    closeModal() {
      this.showModel = false;
      this.fetchRouter(this.userId);
    },
    showQR() {
      this.showQRModal = true;
    },
    async fetchRouter(id: string) {
      await instance({
        url: `routers/${id}/`,
      })
        .then((response) => {
          this.router = response.data;

          if (this.router.lat && this.router.lon) {
            this.initializeMap(this.router.lat, this.router.lon);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('Router could not be found');
          }
        });
    },
    initializeMap(lat: number, lon: number) {
      mapboxgl.accessToken = this.accessToken;

      const map = new mapboxgl.Map(this.mapStyle);

      new mapboxgl.Marker().setLngLat([lon, lat]).addTo(map);

      map.setCenter([lon, lat]);

      map.setZoom(8);
    },
  },
  mounted() {
    const route = useRoute();
    
    if (route.params.id) {
      this.userId = route.params.id.toString();
      this.fetchRouter(this.userId);
    }
  },
});
</script>

<style scoped>
.map {
  width: 100%;
  height: 30rem;
}
</style>