<template>
  <form>

    <div class="bg-light sm:p-5 p-4 rounded">
      <h3 class="text-left mb-3 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">
        Search Micro Franchisee Deposit
      </h3>
      <div class="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-1">
        <div class="md:mb-4 mb-2">
          <label for="depositOption"
            class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Deposit
            Option:</label>
          <select
            class="w-full text-[14px] w-6/12 border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
            id="depositOption" required>
            <option value="">M-Shiriki Account</option>
          </select>
        </div>
        <div class="md:mb-6 mb-2">
          <label for="phone" class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">MF
            Phone Number:</label>
          <input required type="text" id="phone"
            class="w-full text-[14px] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
        </div>
        <div class="w-full mb-2 md:pt-[25px]">
          <button type="submit"
            class="font-['poppins'] text-[14px] bg-primary text-white rounded-[0.4rem] px-3 py-2 font-medium border">
            Search
          </button>
        </div>
      </div>

      <div class="col-12 md:pt-2 pt-4">
        <h3 class="text-left mb-3 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">Micro
          Franchisee Details</h3>

        <div class="">
          <h3>Full Name</h3>
          <p>0712 345 678</p>
        </div>
        <form>
          <ul class="bg-[#4e8d6e] text-white p-3 px-4 rounded mt-3 md:w-2/4 w-full">
            <li class="flex justify-between mb-2">
              <label class="leading-5">Current account balance:</label>
              <P class="font-semibold pl-2 leading-5">CAF 71.27</P>
            </li>
            <li class="flex justify-between mb-2">
              <label class="leading-5">Current balance:</label>
              <P class="font-semibold pl-2 leading-5">CAF 0.00</P>
            </li>
            <li class="flex justify-between mb-2">
              <label class="leading-5">Penalty Charges:</label>
              <P class="font-semibold pl-2 leading-5">CAF 0.00</P>
            </li>
          </ul>

          <div class="mt-4 md:w-2/4 w-full">
            <div class="mb-4">
              <label for="amount" class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                Amount:</label>
              <div class="">
                <input type="number" id="amount"
                  class="w-full text-[14px] w-6/12 border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder">
              </div>
            </div>
            <div class="mb-2">
              <label for="paymentMode"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Payment
                Type:</label>
              <div class="">
                <select
                  class="form-select focus:outline-none w-full text-[14px] w-6/12 border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                  id="paymentMode">
                  <option value="">Select Payment Mode:</option>
                </select>
              </div>
            </div>

            <div class="mb-4">
              <label for="paymentMode"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Reference
                Number:</label>
              <div class="">
                <input type="number" id="referenceNumber"
                  class="w-full text-[14px] w-6/12 border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder">
              </div>
            </div>

            <button class="font-['poppins'] text-[14px] bg-primary text-white rounded-[0.4rem] py-2 px-4 font-medium">
              Submit
            </button>
          </div>

        </form>






      </div>
    </div>
  </form>
</template>