<template>
  <confirmation-dialog v-if="action === 'unassign'" :show="action === 'unassign'"
    title="Are you sure you want to unassign the selected router(s) from the realm?"
    message="Do this If you want to assign a different realm to the routers or you want to reset the router realm"
    actionLabel="Confirm" :loading="loading" @cancel="closeModal()" @confirm="unassignFromRealm()" />
  <div class="popup-modal" v-else>
    <div class="popup-modal-content rounded-xl">
      <button @click="closeModal()" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3 class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
            Assign Router(s) to Realm
          </h3>
          <p class="text-sm mb-4">
            You are about to assign the selected router(s) to a realm
          </p>
          <form>
            <div class="mb-1">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Realm:</label>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="realm" v-model="realm" required>
                <option value="">Select Realm</option>
                <option v-for="realm in realms" :key="realm.id" :value="realm.id">
                  {{ realm.name }}
                </option>
              </select>
            </div>

            <div class="w-full flex lg:justify-end justify-start items-center mt-3">
              <button @click="assignToRealm" type="button"
                class="w-full mt-2 font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-mediumflex items-center justify-center">
                Assign
                <span v-if="loading" :class="{ 'spinner btn-spinner ml-2': loading }">
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent, PropType } from 'vue';

import { Router } from '@/interfaces/router';
import instance from '../../axios-interceptor';
import ConfirmationDialog from '../Common/ConfirmationDialog.vue';

export default defineComponent({
  name: 'AssignRouterToRealm',
  components: {
    ConfirmationDialog,
  },
  props: {
    action: {
      type: String,
    },
    routers: {
      type: Array as PropType<Router[]>,
    },
  },
  data() {
    return {
      loading: false,
      realms: [
        {
          id: '',
          name: '',
        },
      ],
      realm: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    fetchRealms() {
      instance
        .get(`realms/?partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          this.realms = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    assignToRealm() {
      const payload = {
        realm: this.realm,
        routers: this.routers,
      };

      instance
        .post('routers/add-to-realm/', payload)
        .then((response) => {
          this.$toast.success(response.data.detail, {
            position: 'top-right',
          });
          this.closeModal();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Could not assign the router(s) to the realm', {
            position: 'top-right',
          });
        });
    },
    unassignFromRealm() {
      const payload = {
        realm: this.realm,
        routers: this.routers,
      };

      instance
        .patch('routers/add-to-realm/', payload)
        .then((response) => {
          this.$toast.success(response.data.detail, {
            position: 'top-right',
          });
          this.closeModal();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Could not uassign the router(s) to the realm', {
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.fetchRealms();
  },
});
</script>
