import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-poppins font-normal text-sm bg-dark w-[26.063rem] min-h-[2.688rem] z-50 fixed left-5 bottom-5 border-2 border-primary flex justify-between items-center px-6 py-3 rounded-md drop-shadow-2xl" }
const _hoisted_2 = { class: "text-primary text-center max-w-[80%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.message), 1),
    _createElementVNode("button", {
      type: "button",
      class: "text-red",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCloseSnackbar && _ctx.handleCloseSnackbar(...args)))
    }, " close ")
  ]))
}