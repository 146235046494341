<template>
  <div class="flex justify-start items-center bg-gray-100">
    <div class="w-[500px] p-6 bg-white rounded-lg shadow">
      <div class="mb-4 p-4 border rounded-md border-[#4E8D6E]">
        <div>
          <h3 class="font-bold text-lg mb-2 text-primary">SMS Add-On</h3>
          <p class="mt-3 text-sm">
            The SMS Add-On will help your users to generate wifi access tokens
            via sms instead of you providing them with the tokens manually
          </p>
        </div>
      </div>

      <!-- Display total sent sms messages -->
      <div class="mb-4 p-4 border rounded-md border-[#4E8D6E]"
        v-if="loggedInUser.partner.has_voucher_sms_feature_enabled">
        <div>
          <h3 class="font-bold text-lg mb-2 text-primary">
            Total Sent SMS Messages
          </h3>
          <p class="mt-3 text-sm">
            You have sent
            <strong>{{ loggedInUser.partner.total_sms_sent }}</strong>
            sms messages
          </p>
        </div>
      </div>

      <!-- Activate sms package button -->
      <button type="button" v-if="!loggedInUser.partner.has_voucher_sms_feature_enabled && !loading"
        class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2"
        @click="activateAddOn">
        Activate
      </button>

      <!-- Loading button -->
      <button type="button" v-if="loading"
        class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mt-2">
        Processing
      </button>
    </div>
  </div>
</template>


<script lang="ts">
import { ref, defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import instance from "@/axios-interceptor";


export default defineComponent({
  name: 'SMSAddOn',

  setup() {
    const loading = ref(false);
    const selectedSMSPackage = ref('');

    return {
      loading,
      selectedSMSPackage,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    toggleCancellationDialog(value: boolean) {
      this.$emit('toggle-cancellation-dialog', value);
    },
    activateAddOn() {
      const payload = { has_voucher_sms_feature_enabled: true }

      instance
        .patch(`/partners/${this.loggedInUser.partner.id}/`, payload)
        .then(() => {
          this.loading = false;
          this.$toast.success('Add-On activated successfully', {
            position: 'top-right',
          });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error('Could not activate the Add-On', {
            position: 'top-right',
          });
        });
    },
  },
});
</script>

<style></style>
