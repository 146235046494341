import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "col-md-12 animated slideInUp" }, [
      _createElementVNode("div", { class: "card-body" }, [
        _createElementVNode("div", {
          class: "w-full h-[500px] mt-6",
          id: "viewmap"
        })
      ])
    ], -1)
  ])))
}