import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, vModelSelect as _vModelSelect } from "vue"
import _imports_0 from '../../assets/plus-icon.png'
import _imports_1 from '../../assets/search-icon.png'


const _hoisted_1 = { class: "sm:flex items-center justify-between my-6" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "bg-white w-full rounded-[0.938rem] md:p-6 p-4 shadow overflow-auto relative" }
const _hoisted_4 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = {
  class: "flex flex-wrap -mb-px text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { id: "myTabContent" }
const _hoisted_11 = { class: "md:w-[100%] w-[50%] mt-4 mb-2" }
const _hoisted_12 = { class: "relative block" }
const _hoisted_13 = {
  key: 0,
  class: "p-3 flex items-center justify-between md:w-[100%] w-[50%]"
}
const _hoisted_14 = { class: "me-2 text-[#d3aa3b] font-bold text-sm" }
const _hoisted_15 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
}
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "flex flex-col ml-3" }
const _hoisted_18 = ["onUpdate:modelValue", "onChange"]
const _hoisted_19 = { class: "flex items-center" }
const _hoisted_20 = { class: "flex flex-col ml-3" }
const _hoisted_21 = { class: "b-avatar-img capitalize text-[#464E5F] mb-0.5" }
const _hoisted_22 = ["src"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "flex flex-col" }
const _hoisted_25 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_26 = { class: "flex flex-col" }
const _hoisted_27 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_28 = { class: "flex flex-col" }
const _hoisted_29 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_30 = { class: "flex flex-col" }
const _hoisted_31 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_32 = { class: "flex flex-col" }
const _hoisted_33 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "flex flex-col" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
}
const _hoisted_38 = { class: "flex flex-col" }
const _hoisted_39 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_40 = { class: "flex flex-col" }
const _hoisted_41 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_42 = { class: "flex flex-col" }
const _hoisted_43 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_44 = { class: "flex flex-col" }
const _hoisted_45 = ["onClick"]
const _hoisted_46 = { class: "spinner-container" }
const _hoisted_47 = {
  key: 0,
  class: "spinner"
}
const _hoisted_48 = { class: "flex items-center" }
const _hoisted_49 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upload_media = _resolveComponent("upload-media")!
  const _component_ScaleLoader = _resolveComponent("ScaleLoader")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "sm:mb-0 mb-2" }, [
        _createElementVNode("i", { class: "fa-regular fa-calendar w-[1rem] h-[1rem] rounded mr-2" }),
        _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, "Offline Files")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
          type: "button",
          class: "bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]"
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("img", {
            src: _imports_0,
            class: "w-[0.6rem] h-[0.6rem] mr-1",
            alt: ""
          }, null, -1),
          _createTextVNode(" Add Media ")
        ])),
        (_ctx.showModal)
          ? (_openBlock(), _createBlock(_component_upload_media, {
              key: 0,
              files: _ctx.files,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
            }, null, 8, ["files"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("ul", _hoisted_6, [
            _createElementVNode("li", _hoisted_7, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'partner'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSetActiveTab('partner')))
              }, " Partner Realms Media ", 2)
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'sme'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "dashboard-tab",
                "data-tabs-target": "#dashboard",
                type: "button",
                role: "tab",
                "aria-controls": "dashboard",
                "aria-selected": "false",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSetActiveTab('sme')))
              }, " Sme Realms Media ", 2)
            ]),
            _createElementVNode("li", _hoisted_9, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'realm-categories'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "dashboard-tab",
                "data-tabs-target": "#dashboard",
                type: "button",
                role: "tab",
                "aria-controls": "dashboard",
                "aria-selected": "false",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleSetActiveTab('realm-categories')))
              }, " Realm Categories ", 2)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("label", _hoisted_12, [
            _cache[13] || (_cache[13] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
            _cache[14] || (_cache[14] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
              _createElementVNode("img", {
                src: _imports_1,
                alt: "",
                class: "w-[0.8rem] h-[0.8rem]"
              })
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.searchQuery) = $event)),
              class: "font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1",
              placeholder: "Search",
              type: "text",
              name: "search"
            }, null, 512), [
              [_vModelText, _ctx.searchQuery]
            ])
          ])
        ]),
        (_ctx.showButtons())
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.rowsSelected) + " Rows selected", 1),
                _createElementVNode("span", {
                  class: "cursor-pointer text-[#5e72e4] font-bold text-sm",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.clearRows && _ctx.clearRows(...args)))
                }, "clear")
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  type: "button",
                  class: "me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromSme && _ctx.unallocateRoutersFromSme(...args)))
                }, " unassign SME router "),
                _createElementVNode("button", {
                  type: "button",
                  class: "capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromPartner && _ctx.unallocateRoutersFromPartner(...args)))
                }, " unassign Partner router ")
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeTab !== 'realm-categories')
          ? (_openBlock(), _createBlock(_component_SmeTable, {
              key: 1,
              sorting: true,
              onSort: _ctx.sortBy,
              inputShow: true,
              selectAllValues: _ctx.selectAll,
              onSelectAllRows: _ctx.selectAllRows,
              tableName: 'Files',
              tableHeaderData: 
          _ctx.activeTab === 'partner' ? _ctx.accountsTableHeader : _ctx.smetableHeader
        
            }, {
              tableBodyData: _withCtx(() => [
                (!_ctx.paginatedTableData.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, " No records "))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.paginatedTableData, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("div", _hoisted_17, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                                onChange: ($event: any) => (_ctx.updateSelectedRows(item)),
                                type: "checkbox",
                                class: "shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                              }, null, 40, _hoisted_18), [
                                [_vModelCheckbox, item.selected]
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("span", _hoisted_21, [
                                _createElementVNode("img", {
                                  src: item?.thumbnail,
                                  class: "rounded-full"
                                }, null, 8, _hoisted_22)
                              ])
                            ])
                          ])
                        ]),
                        (_ctx.activeTab !== 'partner')
                          ? (_openBlock(), _createElementBlock("td", _hoisted_23, [
                              _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("span", _hoisted_25, _toDisplayString(item?.sme?.name), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("span", _hoisted_27, _toDisplayString(item.realm_name), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("span", _hoisted_29, _toDisplayString(item.category), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_30, [
                            _createElementVNode("span", _hoisted_31, _toDisplayString(item.file_type), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_32, [
                            _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.createdAt(item.created)), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.downloadFile(item.file)),
                              class: "capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer"
                            }, _cache[15] || (_cache[15] = [
                              _createTextVNode("Download"),
                              _createElementVNode("i", { class: "ml-1.5 fa-solid fa-download" }, null, -1)
                            ]), 8, _hoisted_34)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_35, [
                            (!_ctx.deletingFile || _ctx.currentDeletionFileID !== item.id)
                              ? (_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.deleteMediaFile(item.id)),
                                  class: "fa-solid fa-trash text-lg text-[#d7191c] cursor-pointer"
                                }, null, 8, _hoisted_36))
                              : _createCommentVNode("", true),
                            (_ctx.deletingFile && _ctx.currentDeletionFileID === item.id)
                              ? (_openBlock(), _createBlock(_component_ScaleLoader, {
                                  key: 1,
                                  color: "#d7191c"
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    }), 128))
              ]),
              _: 1
            }, 8, ["onSort", "selectAllValues", "onSelectAllRows", "tableHeaderData"]))
          : _createCommentVNode("", true),
        (_ctx.activeTab === 'realm-categories')
          ? (_openBlock(), _createBlock(_component_SmeTable, {
              key: 2,
              sorting: true,
              onSort: _ctx.sortBy,
              tableName: 'Realm Categories',
              tableHeaderData: _ctx.realmCategoriesTableHeader
            }, {
              tableBodyData: _withCtx(() => [
                (!_ctx.paginatedTableData.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_37, " No records "))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.paginatedTableData, (category) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: category.id,
                        class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("span", _hoisted_39, _toDisplayString(category.linked_realm.name), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_40, [
                            _createElementVNode("span", _hoisted_41, _toDisplayString(category.name), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_42, [
                            _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.createdAt(category.created)), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_44, [
                            (
                    !_ctx.deletingCategory ||
                    _ctx.currentDeletionCategoryID !== category.id
                  )
                              ? (_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.deleteCategory(category.id)),
                                  class: "fa-solid fa-trash text-lg text-[#d7191c] cursor-pointer"
                                }, null, 8, _hoisted_45))
                              : _createCommentVNode("", true),
                            (
                    _ctx.deletingCategory &&
                    _ctx.currentDeletionCategoryID === category.id
                  )
                              ? (_openBlock(), _createBlock(_component_ScaleLoader, {
                                  key: 1,
                                  color: "#d7191c"
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    }), 128))
              ]),
              _: 1
            }, 8, ["onSort", "tableHeaderData"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_46, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_47))
            : _createCommentVNode("", true)
        ]),
        (_ctx.propLoaded)
          ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
              key: 3,
              perPageItems: _ctx.perSize,
              pageNumber: _ctx.page,
              ApiData: _ctx.apiData,
              onHandleNext: _ctx.handlleNext,
              onHandlePrev: _ctx.handlePrev
            }, {
              section: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_48, [
                    _cache[17] || (_cache[17] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                    _createElementVNode("span", _hoisted_49, [
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedOption) = $event)),
                        onChange: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                      }, _cache[16] || (_cache[16] = [
                        _createElementVNode("option", { value: "10" }, "10", -1),
                        _createElementVNode("option", { value: "20" }, "20", -1),
                        _createElementVNode("option", { value: "30" }, "30", -1),
                        _createElementVNode("option", { value: "40" }, "40", -1),
                        _createElementVNode("option", { value: "50" }, "50", -1)
                      ]), 544), [
                        [_vModelSelect, _ctx.selectedOption]
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}