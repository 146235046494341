import { BillingState } from '@/store/types/billing';

export default {
  billings(state: BillingState) {
    return state.billings;
  },
  smsBillings(state: BillingState) {
    return state.smsBillings;
  },
  payments(state: BillingState) {
    return state.payments;
  },
  customers(state: BillingState) {
    return state.customers;
  },
  chartData(state: BillingState) {
    return state.chartData;
  },
  totalAmount(state: BillingState) {
    return state.totalAmount;
  },
  revenues(state: BillingState) {
    return state.revenues;
  },
  packages(state: BillingState) {
    return state.packages;
  },
  package(state: BillingState) {
    return state.package;
  },
};
