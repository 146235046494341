<template>
  <div v-if="!loading" class="lg:flex flex-row items-start gap-y-8 xl:gap-x-8 gap-x-6 mt-8 sm:gap-y-16">
    <div class="xl:w-1/4 lg:w-1/3 md:w-full bg-white pb-4 sm:mx-0 shadow-lg px-5 pt-5 rounded-2xl lg:mb-0 mb-6">
      <div class="user-img rounded-full w-[100px] h-[100px] overflow-hidden mb-2 m-auto">
        <img src="../../assets/user-img.svg" class="w-full h-full" alt="user" />
      </div>

      <div class="text-center pt-2">
        <h3 class="font-bold font-lg">{{ sme.name }}</h3>
      </div>
      <div class="edit-profile flex justify-center pt-3">
        <button v-if="sme.is_active" type="button" @click="openSuspendActivateAccountModal('deactivate-account')"
          class="bg-[#d7191c] font-['poppins'] text-[11px] text-white rounded-[0.327rem] py-2 px-3 font-medium mr-1 border">
          Suspend Account
        </button>
        <button v-if="!sme.is_active" type="button" @click="openSuspendActivateAccountModal('activate-account')"
          class="bg-[#4e8d6e] font-['poppins'] text-[11px] text-white rounded-[0.327rem] py-2 px-3 font-medium mr-1 border">
          Activate Account
        </button>

        <button v-if="!sme.is_terminated" type="button" @click="openTerminateRestoreAccountModal('terminate-account')"
          class="bg-[#171614] font-['poppins'] text-[11px] text-white rounded-[0.327rem] py-2 px-3 font-medium mr-1 border">
          Terminate Account
        </button>
        <button v-if="sme.is_terminated" type="button" @click="openTerminateRestoreAccountModal('restore-account')"
          class="bg-[#4e8d6e] font-['poppins'] text-[11px] text-white rounded-[0.327rem] py-2 px-3 font-medium mr-1 border">
          Restore Account
        </button>

        <!-- MODAL FOR ACCOUNT SUSPENSION AND ACTIVATION -->
        <confirmation-dialog v-if="showAccountSuspensionActivationModal" :show="showAccountSuspensionActivationModal"
          :title="getAccountSuspensionActivationTitle()" :message="getAccountSuspensionActivationMessage()"
          :actionLabel="getAccountSuspensionActivationLabel()" :loading="processing" @cancel="cancel"
          @confirm="saveAccount" />


        <!-- MODAL FOR ACCOUNT TERMINATION AND RESTORE -->
        <confirmation-dialog v-if="showAccountTerminationRestoreModal" :show="showAccountTerminationRestoreModal"
          :title="getAccountTerminationRestoreTitle()" :message="getAccountTerminationRestoreMessage()"
          :actionLabel="getAccountTerminationRestoreLabel()" :loading="processing" @cancel="cancel"
          @confirm="saveAccount" />
      </div>
    </div>

    <div class="user-profile-block xl:w-3/4 md:w-full lg:w-2/3 full-width bg-white p-5 rounded-[0.938rem]">
      <div class="user-profile-innr w-full">
        <h3 class="text-left mb-3 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
          FULL PROFILE
        </h3>

        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray-200 table-border">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500">
                  Name
                </th>
                <th class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500">
                  Country
                </th>
                <th class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500">
                  REGISTERED
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 border-[#eaeaee]">
              <tr class="bg-light">
                <td class="font-poppin text-[14px] text-[#888889]">
                  {{ sme.name }}
                </td>
                <td class="font-poppin text-[14px] text-[#888889]">
                  {{ sme.country }}
                </td>
                <td class="font-poppin text-[14px] text-[#888889]">
                  {{ new Date(sme.created).toLocaleDateString() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white w-full rounded-[0.938rem] md:p-6 p-4 shadow overflow-auto relative my-6">
    <div class="tabs-block my-4" id="tabs-block">
      <div class="mb-3">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center mb-2" id="myTab"
          data-tabs-toggle="#myTabContent" role="tablist">
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false" v-on:click="activeTab = 'routers'"
              v-bind:class="[
                activeTab === 'routers'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]">
              Routers
            </button>
          </li>
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false" v-on:click="activeTab = 'admin'"
              v-bind:class="[
                activeTab === 'admin'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]">
              Admin
            </button>
          </li>
        </ul>
      </div>
      <div id="myTabContent">
        <div v-if="activeTab === 'routers'">
          <div class="bg-light w-full rounded-[0.938rem] md:p-6 p-4 shadow overflow-auto relative mt-6">
            <div class="sm:flex items-center justify-between">
              <h3
                class="sm:mb-0 mb-2font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
                {{ changeName ? 'ASSIGN SME ROUTERS' : 'ALL SME ROUTERS' }}
              </h3>
              <button type="button"
                class="font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff] flex items-center bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem]"
                @click="changeBtn">
                <img src="../../assets/plus-icon.png" class="w-[0.6rem] h-[0.6rem] mr-1" alt="" />
                {{ changeName ? 'All Sme Routers' : 'Assign Routers' }}
              </button>
            </div>
            <div class="md:w-[100%] w-[50%] mb-2 mt-4">
              <label class="relative block">
                <span class="sr-only">Search</span>
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <img src="../../assets/search-icon.png" alt="" class="w-[0.8rem] h-[0.8rem]" />
                </span>
                <input v-model="searchQuery"
                  class="bg-white font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                  placeholder="Search" type="text" name="search" />
              </label>
            </div>
            <div class="p-3 flex items-center justify-between md:w-[100%] w-[50%]" v-if="showButtons()">
              <div>
                <span class="me-2 text-[#d3aa3b] font-bold text-sm">{{ rowsSelected }} Rows selected</span>
                <span class="cursor-pointer text-[#5e72e4] font-bold text-sm" @click="clearRows">clear</span>
              </div>
              <div>
                <button type="button"
                  class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                  @click="unallocateRoutersFromSme">
                  unassign SME router
                </button>
                <button type="button"
                  class="capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                  @click="unallocateRoutersFromPartner">
                  unassign Partner router
                </button>
              </div>
            </div>
            <SmeTable :sorting="true" @sort="sortBy" :inputShow="true" :selectAllValues="selectAll"
              @selectAllRows="selectAllRows" :tableName="'Realm Routers'" :tableHeaderData="accountsTableHeader">
              <template v-slot:tableBodyData>
                <span v-if="!paginatedTableData.length" class="capitalize text-[#464E5F] mb-0.5 text-sm">
                  No records
                </span>

                <div v-else v-for="item in filteredRouters" :key="item.id"
                  class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
                  <td>
                    <div class="flex items-center">
                      <div class="flex flex-col ml-3">
                        <input v-model="item.selected" @change="updateSelectedRows(item)" type="checkbox"
                          class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center">
                      <div class="flex flex-col ml-3">
                        <span class="b-avatar-img capitalize text-[#464E5F] mb-0.5">{{ item.serial_number }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.active ? 'online' : 'offline'
                        }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.realm.name.split('Realm')[0] + item.realm.type
                        }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.imei
                        }}</span>
                    </div>
                  </td>

                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.created
                        }}</span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <span @click="viewrouterDetails(item.id)"
                        class="capitalize px-2 py-1 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center cursor-pointer">view</span>
                    </div>
                  </td>
                </div>
              </template>
            </SmeTable>
            <div class="spinner-container">
              <div v-if="loading" class="spinner"></div>
            </div>
            <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="apiData"
              @handleNext="handlleNext" @handlePrev="handlePrev">
              <template v-slot:section>
                <div>
                  <div class="flex items-center">
                    <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                      class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                      <select v-model="selectedOption" @change="handleChange">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select></span>
                  </div>
                </div>
              </template>
            </DashboardTableTitle>
          </div>
        </div>
        <div v-if="activeTab === 'admin'">
          <adminSmes :voucherId="$route.params.id"></adminSmes>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import adminSmes from './adminSmes.vue';
import { SME } from '@/interfaces/billing';
import instance from '../../axios-interceptor';
import SmeTable from '../../components/reusable/SmeTable.vue';
import ConfirmationDialog from '@/components/Common/ConfirmationDialog.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import router, { useRoute } from '@/router';

export default defineComponent({
  name: 'smeDetailsPage',
  components: {
    SmeTable,
    adminSmes,
    ConfirmationDialog,
    DashboardTableTitle,
  },
  setup() {
    const pageNumber = ref(1);
    const perPageItems = ref(5);
    const startIndex = ref(0);
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Ticket ID');
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const accountsTableHeader = ref([
      'Serial Number',
      'Status',
      'Realm',
      'IMEI',
      'Added',
      'Action',
    ]);

    const selectedSubscription = ref(false);

    return {
      center,
      tableName,
      icon,
      selectedSubscription,
      pageNumber,
      perPageItems,
      startIndex,
      accountsTableHeader,
    };
  },
  data() {
    return {
      loadingModalUpdate: false,
      showAccountModal: false,
      sme: {} as SME,
      processing: false,
      showAccountSuspensionActivationModal: false,
      showAccountTerminationRestoreModal: false,
      filteredRouters: [] as any[],
      changeName: false,
      rowsSelected: 0,
      selectAll: false,
      selectedRows: [] as string[],
      page: 1,
      perSize: 10,
      sortKey: '', // Sorting key
      sortDirection: 'asc',
      searchQuery: '',
      propLoaded: false,
      selectedOption: 10,
      apiData: [] as any,
      loading: false,
      activeTab: 'routers',
      filteredMicroFranchisees: [],
      adminRole: 'partner_admin',
      totalMicroFranchisees: null,
      baseUserUrl: '',
      modalType: '',
      operation: ''

    };
  },
  methods: {
    getAccountSuspensionActivationTitle() {
      const action = this.sme.is_active ? 'suspend' : 'activate';
      const title = `Are you sure you want to ${action} this SME account?`;
      return title;
    },
    getAccountSuspensionActivationLabel() {
      const actionLabel = this.sme.is_active ? 'Suspend' : 'Activate';
      return actionLabel;
    },
    getAccountSuspensionActivationMessage() {
      const suspensionMessage = "This action will revert account activation and the SME will be able to login but have no access to the main feature tabs such as addons, content management, routers, analytics, etc. They will only have access the dashboard, billing, and account tabs"
      const activationMessage = "This action will revert account suspension and the SME will be able to login and have access to all the tabs"
      const message = this.sme.is_active ? suspensionMessage : activationMessage;

      return message;
    },
    getAccountTerminationRestoreMessage() {
      const terminationMessage = "This action will revert account restoration and the SME won't be able to login after this operation"
      const restoreMessage = "This action will revert account termination and the SME will be able to login after this operation"
      const message = this.sme.is_terminated ? restoreMessage : terminationMessage;

      return message;
    },
    getAccountTerminationRestoreTitle() {
      const action = this.sme.is_terminated ? 'restore' : 'terminate';
      const title = `Are you sure you want to ${action} this SME account?`;
      return title;
    },
    getAccountTerminationRestoreLabel() {
      const actionLabel = this.sme.is_terminated ? 'Restore' : 'Terminate';
      return actionLabel;
    },
    openSuspendActivateAccountModal(type: string) {
      this.showAccountSuspensionActivationModal = true;
      this.modalType = type;
    },
    openTerminateRestoreAccountModal(type: string) {
      this.showAccountTerminationRestoreModal = true;
      this.modalType = type;
    },
    saveAccount() {
      if (this.modalType === 'activate-account') {
        this.activateAccount();
      }

      if (this.modalType === 'deactivate-account') {
        this.suspendAccount();
      }

      if (this.modalType === 'terminate-account') {
        this.terminateAccount();
      }

      if (this.modalType === 'restore-account') {
        this.restoreAccount();
      }

      this.showAccountTerminationRestoreModal = false;
      this.showAccountSuspensionActivationModal = false;

    },
    cancel() {
      this.showAccountTerminationRestoreModal = false;
      this.showAccountSuspensionActivationModal = false;
    },
    viewrouterDetails(id: any) {
      router.push({ name: 'router-details', params: { id: id } });
    },
    changeBtn() {
      const route = useRoute();
      this.changeName = !this.changeName;
      if (this.changeName) {
        this.getUnassignedRouters();
      } else {
        this.getRouters(route.params.id);
      }
    },
    selectAllRows(checked: any) {
      this.selectAll = checked;
      this.filteredRouters.forEach((row) => {
        row.selected = this.selectAll;
      });
      this.rowsSelected = this.paginatedTableData.length;
    },
    sortBy(key: string) {
      let currentKey;
      if (key === 'Serial number') {
        currentKey = 'serial_number';
      } else if (key === 'Assignment Type') {
        currentKey = 'deployed_to';
      } else if (key === 'Status') {
        currentKey = 'active';
      } else if (key === 'IMEI') {
        currentKey = 'imei';
      } else {
        currentKey = 'created';
      }
      if (this.sortKey === currentKey) {
        // Toggle the sort direction if the same key is clicked again
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = currentKey;
        this.sortDirection = 'asc';
      }
    },
    updateSelectedRows(row: any) {
      if (!row.selected) {
        this.selectAll = false;
      } else {
        const selectedRows = this.filteredRouters.filter((row) => row.selected);
        this.rowsSelected = selectedRows.length;
        this.selectAll = selectedRows.length === this.filteredRouters.length;
      }
    },
    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('M/D/YYYY, h:mm:ss a');
      }
    },
    handlePrev() {
      this.page--;
      this.getRouters(this.activeTab);
    },
    handlleNext() {
      this.page++;
      this.getRouters(this.activeTab);
    },
    handleChange() {
      this.perSize = this.selectedOption;
      this.getRouters(this.activeTab);
    },
    unallocateRoutersFromSme() {
      const route = useRoute();
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const assignSmeRoutersUrl = 'routers/assign-to-sme/';
      const data = {
        routers: selectedRouters,
        sme: route.params.id,
      };
      instance
        .patch(assignSmeRoutersUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.getRouters(this.activeTab);
            this.$toast.success(response.data.detail, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unallocateRoutersFromPartner() {
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const unassignRoutersFromPartnerUrl = 'routers/assign-to-partner/';
      const data = {
        routers: selectedRouters,
      };
      instance
        .patch(unassignRoutersFromPartnerUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.getRouters(this.activeTab);
            this.$toast.success(`Router removed`, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearRows() {
      this.paginatedTableData.filter((row) => (row.selected = false));
      this.selectAll = false;
    },
    showButtons() {
      return this.paginatedTableData.some((row) => row.selected === true);
    },
    async getRouters(id: string | string[]) {
      this.loading = true;
      await instance.get(`routers/?sme=${id}`).then((response) => {
        if (response.status === 200) {
          this.loading = false;
          this.propLoaded = true;
          this.filteredRouters = response.data.results.map((row: any) => ({
            ...row,
            selected: false,
            status: row?.active ? 'online' : 'offline',
          }));
        }
      });
    },
    async getUnassignedRouters() {
      this.loading = true;
      await instance
        .get(`routers/?sme=&partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.propLoaded = true;
            this.filteredRouters = response.data.results;
          }
        });
    },
    getSme(id: any) {
      instance({
        url: `smes/${id}/`,
      }).then((response) => {
        if (response.status === 200) {
          this.sme = response.data;
        }
      });
    },
    suspendAccount() {
      const route = useRoute();
      const data = {
        is_active: false,
      };
      this.processing = true;
      instance
        .patch(`smes/${route.params.id}/`, data)
        .then((response) => {
          if (response.status === 200) {
            this.processing = false;
            this.showAccountSuspensionActivationModal = false;
            this.getSme(route.params.id);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.processing = false;
            console.log(error);
          }
        });
    },
    activateAccount() {
      const route = useRoute();
      const data = {
        is_active: true,
      };
      instance
        .patch(`smes/${route.params.id}/`, data)
        .then((response) => {
          if (response.status === 200) {
            this.getSme(route.params.id);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
          }
        });
    },
    terminateAccount() {
      const route = useRoute();
      const data = {
        is_terminated: true,
      };
      this.processing = true;

      instance
        .patch(`smes/${route.params.id}/`, data)
        .then((response) => {
          if (response.status === 200) {
            this.processing = false;
            this.showAccountTerminationRestoreModal = false;
            this.getSme(route.params.id);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.processing = false;
            console.log(error);
          }
        });
    },
    restoreAccount() {
      const route = useRoute();
      const data = {
        is_terminated: false,
      };

      instance
        .patch(`smes/${route.params.id}/`, data)
        .then((response) => {
          if (response.status === 200) {
            this.getSme(route.params.id);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
          }
        });
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    filteredTableData(): any[] {
      if (!this.searchQuery) {
        console.log('RETURNED UNSEARCH QUERY DATA: ', this.filteredRouters);

        // If search query is empty, return all data
        return this.filteredRouters;
      }

      // Filter table data based on search query
      const filteredData = this.filteredRouters.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const serial_number = item.serial_number.toString().toLowerCase();
        const imei = item.imei.toString().toLowerCase();
        const active = item?.status.toString().toLowerCase();
        const deployed_to = item?.deployed_to.toString().toLowerCase();
        const created = this.createdAt(item.created.toString().toLowerCase());
        const query = this.searchQuery.toLowerCase();

        //Check if name or age contain the search query
        return (
          serial_number?.includes(query) ||
          imei?.includes(query) ||
          active?.includes(query) ||
          deployed_to?.includes(query) ||
          created?.includes(query)
        );
      });

      console.log('FILTERED TABLE DATA: ', filteredData);

      return filteredData;
    },

    sortedTableData(sortKey: any): any[] {
      if (!this.sortKey) {
        // If sort key is empty, return the filtered data as-is
        console.log('RETURNED UNSORTED DATA');

        return this.filteredTableData;
      }

      // Sort the filtered table data based on the sort key and direction
      const sortedData = this.filteredTableData.slice().sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];

        // Perform the actual comparison based on the data type
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return (
            aValue.localeCompare(bValue) *
            (this.sortDirection === 'asc' ? 1 : -1)
          );
        } else {
          return (aValue - bValue) * (this.sortDirection === 'asc' ? 1 : -1);
        }
      });

      return sortedData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.sortedTableData.slice(startIndex, endIndex);
    },
  },
  created() {
    const route = useRoute();
    this.getRouters(route.params.id);
    this.getSme(route.params.id);
  },
});
</script>
