import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/today.png'
import _imports_1 from '../../assets/interaction-browser.svg'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = { class: "w-full my-3" }
const _hoisted_5 = { class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "flex flex-col ml-3" }
const _hoisted_8 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = { class: "flex flex-col ml-3" }
const _hoisted_11 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_15 = { class: "text-[#464E5F]" }
const _hoisted_16 = { class: "flex items-center justify-end" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4"
}
const _hoisted_19 = {
  key: 1,
  class: "text-center mt-9"
}
const _hoisted_20 = {
  key: 2,
  class: "spinner-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_CustomizePortal = _resolveComponent("CustomizePortal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Landing Page Builder",
      icon: _ctx.icon.captiveportal
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[1] || (_cache[1] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[1.099rem] h-[1.062rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["icon"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.propLoaded)
          ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
              key: 0,
              perPageItems: _ctx.perSize,
              pageNumber: _ctx.page,
              ApiData: _ctx.apiData
            }, {
              section: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("div", null, [
                  _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.01rem] text-[0.9rem] text-[#212121]" }, "Captive Portal templates"),
                  _createElementVNode("div", { class: "flex items-center mb-3" }, [
                    _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Management of captive portal templates.")
                  ])
                ], -1)
              ])),
              _: 1
            }, 8, ["perPageItems", "pageNumber", "ApiData"]))
          : _createCommentVNode("", true),
        _createVNode(_component_SmeTable, {
          tableName: _ctx.tableName,
          tableHeaderData: _ctx.routerTableHeaders
        }, {
          tableBodyData: _withCtx(() => [
            (_ctx.paginatedTableData.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paginatedTableData, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(item.name), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(item.router), 1)
                        ])
                      ])
                    ]),
                    (item.id)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.formatDate(item.created)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.formatDate(item.updated)), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_16, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerTableIcons, (icon) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: icon.content,
                            class: "bg-[#F3F6F9] rounded-[0.375rem] flex items-center justify-center ml-2"
                          }, [
                            (icon.type == 'text')
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  class: "bg-[#F3F6F9] text-[#4E8D6D] rounded-[0.375rem] font-semibold py-2 px-3",
                                  onClick: ($event: any) => (_ctx.selectCaptivePortal(item.id))
                                }, _toDisplayString(icon.content), 9, _hoisted_17))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[4] || (_cache[4] = [
                  _createElementVNode("b", null, "No Record found", -1)
                ])))
          ]),
          _: 1
        }, 8, ["tableName", "tableHeaderData"]),
        (_ctx.paginatedTableData.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[5] || (_cache[5] = [
              _createElementVNode("img", {
                src: _imports_1,
                class: "m-auto mb-4",
                alt: ""
              }, null, -1),
              _createElementVNode("span", { class: "text-[#000] font-poppins font-medium text-[12px]" }, "No Captive Portal found", -1)
            ])))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[6] || (_cache[6] = [
              _createElementVNode("div", { class: "spinner" }, null, -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_CustomizePortal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
          "loading-save": _ctx.loadingSave,
          "loading-reset": _ctx.loadingReset,
          captivePortal: _ctx.selectedCaptivePortal,
          onUpdateCaptivePortal: _ctx.updateCaptivePortal,
          onResetCaptivePortal: _ctx.resetCaptivePortal
        }, null, 8, ["loading-save", "loading-reset", "captivePortal", "onUpdateCaptivePortal", "onResetCaptivePortal"]))
      : _createCommentVNode("", true)
  ], 64))
}