import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_2 = { style: {"display":"flex"} }
const _hoisted_3 = { style: {"margin-right":"5px"} }
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "w-full my-3" }
const _hoisted_7 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 pt-2" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_CardDesign = _resolveComponent("CardDesign")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_DashboardHeader, {
          title: "Dashboard",
          icon: _ctx.icon.dashboard
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMonth) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.months, (monthName, monthNumber) => {
              return (_openBlock(), _createElementBlock("option", {
                key: monthNumber,
                value: monthNumber
              }, _toDisplayString(monthName), 9, _hoisted_4))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.selectedMonth]
          ])
        ]),
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedYear) = $event)),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchSentSMS(_ctx.selectedYear)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (year) => {
              return (_openBlock(), _createElementBlock("option", {
                key: year,
                value: year
              }, _toDisplayString(year), 9, _hoisted_5))
            }), 128))
          ], 544), [
            [_vModelSelect, _ctx.selectedYear]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.SMSData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_CardDesign, {
                data: {
                    topic: 'SMS Sent',
                    figures: _ctx.SMSData[_ctx.months[_ctx.selectedMonth]],
                    iconcolor: '#8280FF',
                    icon: 'fa-solid fa-message',
                    color: '#E5E4FF'
                }
              }, null, 8, ["data"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_CardDesign, { data: {
                    topic: 'SMS Failed',
                    figures: 0,
                    iconcolor: '#FF9066',
                    icon: 'fa-solid fa-comment-slash',
                    color: '#FFDED1'
                } })
        ])
      ])
    ])
  ], 64))
}