import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative" }
const _hoisted_2 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  class: "flex flex-wrap text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = { class: "mr-2" }
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = { id: "myTabContent" }
const _hoisted_10 = { class: "bg-light sm:p-5 p-4 rounded" }
const _hoisted_11 = { class: "w-full sm:flex items-center justify-between mb-3" }
const _hoisted_12 = { class: "mb-0 text-left uppercase font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  class: "text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "flex flex-col ml-3" }
const _hoisted_18 = { class: "flex items-center" }
const _hoisted_19 = { class: "flex flex-col ml-3" }
const _hoisted_20 = { class: "b-avatar-img text-[#464E5F] mb-0.5" }
const _hoisted_21 = { class: "flex flex-col" }
const _hoisted_22 = { class: "text-[#464E5F] mb-0.5" }
const _hoisted_23 = { class: "flex flex-col" }
const _hoisted_24 = { class: "text-[#464E5F] mb-0.5" }
const _hoisted_25 = { class: "flex flex-col" }
const _hoisted_26 = { class: "text-[#464E5F] mb-0.5" }
const _hoisted_27 = { class: "flex flex-col" }
const _hoisted_28 = { class: "text-[#464E5F] mb-0.5" }
const _hoisted_29 = { class: "flex justify-between" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "spinner-container" }
const _hoisted_33 = {
  key: 0,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SMEForm = _resolveComponent("SMEForm")!
  const _component_SmeTable = _resolveComponent("SmeTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-col md:block my-6" }, [
      _createElementVNode("div", { class: "flex items-center" }, [
        _createElementVNode("i", { class: "fa-solid fa-business-time w-[1rem] h-[1rem] rounded mr-2" }),
        _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, "SME's")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'all'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setActiveTab('all')))
              }, " All ", 2)
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'suspended'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActiveTab('suspended')))
              }, " Suspended ", 2)
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'terminated'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setActiveTab('terminated')))
              }, " Terminated ", 2)
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("button", {
                class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                _ctx.activeTab === 'active'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]]),
                id: "profile-tab",
                "data-tabs-target": "#profile",
                type: "button",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setActiveTab('active')))
              }, " Active ", 2)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h3", _hoisted_12, [
                _createTextVNode(_toDisplayString(_ctx.activeTab) + " SMES ", 1),
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.smes.length), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModal = true)),
                type: "button",
                class: "bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]"
              }, " Add SME "),
              (_ctx.showModal)
                ? (_openBlock(), _createBlock(_component_SMEForm, {
                    key: 0,
                    onClose: _ctx.closeModal
                  }, null, 8, ["onClose"]))
                : _createCommentVNode("", true),
              (_ctx.showEditSMEModal)
                ? (_openBlock(), _createBlock(_component_SMEForm, {
                    key: 1,
                    onClose: _ctx.closeEditSMEModal,
                    sme: _ctx.selectedSME
                  }, null, 8, ["onClose", "sme"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_SmeTable, {
              sorting: false,
              inputShow: false,
              tableName: 'SMEs',
              tableHeaderData: _ctx.accountsTableHeader
            }, {
              tableBodyData: _withCtx(() => [
                (!_ctx.smes.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, " No records "))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.smes, (sme) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: sme.id,
                        class: "table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                      }, [
                        false
                          ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  _withDirectives(_createElementVNode("input", {
                                    checked: "",
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedSubscription) = $event)),
                                    type: "checkbox",
                                    class: "shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                                  }, null, 512), [
                                    [_vModelCheckbox, _ctx.selectedSubscription]
                                  ])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("span", _hoisted_20, _toDisplayString(sme.name), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("span", _hoisted_22, _toDisplayString(sme.admin_email), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("span", _hoisted_24, _toDisplayString(sme.admin_phone), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("span", _hoisted_26, _toDisplayString(sme.tin_number), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("span", _hoisted_28, _toDisplayString(sme.type), 1)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_29, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.smeDetails(sme.id)),
                              class: "px-2 py-1 rounded bg-textDark text-white mb-0.5 inline-block text-center cursor-pointer"
                            }, "view", 8, _hoisted_30)
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.editSME(sme.id)),
                              class: "px-2 py-1 rounded bg-textDark text-white mb-0.5 inline-block text-center cursor-pointer"
                            }, "edit", 8, _hoisted_31)
                          ])
                        ])
                      ]))
                    }), 128))
              ]),
              _: 1
            }, 8, ["tableHeaderData"]),
            _createElementVNode("div", _hoisted_32, [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ], 64))
}