<template>
  <div class="flex flex-col my-6">
    <div class="sm:flex items-center justify-between">
      <div class="sm:mb-0 mb-2">
        <i class="fa-regular fa-calendar w-[1rem] h-[1rem] rounded mr-2"></i>
        <span
          class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
          >Adverts</span
        >
      </div>
      <div class="">
        <button
          @click="addAdvert"
          type="button"
          class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]"
        >
          <img
            src="../../assets/plus-icon.png"
            class="w-[0.6rem] h-[0.6rem] mr-1"
            alt=""
          />
          Add Advert
        </button>
      </div>
    </div>
  </div>
  <div
    class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative"
  >
    <div class="md:w-[100%] w-[50%] mb-2">
      <label class="relative block">
        <span class="sr-only">Search</span>
        <span class="absolute inset-y-0 left-0 flex items-center pl-2">
          <img
            src="../../assets/search-icon.png"
            alt=""
            class="w-[0.8rem] h-[0.8rem]"
          />
        </span>
        <input
          v-model="searchQuery"
          class="font-normal rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
          placeholder="Search"
          type="text"
          name="search"
        />
      </label>
    </div>
    <div
      class="p-3 flex items-center justify-between md:w-[100%] w-[50%]"
      v-if="showButtons()"
    >
      <div>
        <span class="me-2 text-[#d3aa3b] font-bold text-sm"
          >{{ rowsSelected }} Rows selected</span
        >
        <span
          class="cursor-pointer text-[#5e72e4] font-bold text-sm"
          @click="clearRows"
          >clear</span
        >
      </div>
      <div>
        <button
          type="button"
          class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
          @click="unallocateRoutersFromSme"
        >
          unassign SME router
        </button>
        <button
          type="button"
          class="capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
          @click="unallocateRoutersFromPartner"
        >
          unassign Partner router
        </button>
      </div>
    </div>
    <SmeTable :tableHeaderData="advertTableHeadings">
      <template v-slot:tableBodyData>
        <span
          v-if="!paginatedTableData.length"
          class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
        >
          No records
        </span>
        <div
          v-else
          v-for="advert in paginatedTableData"
          :key="advert.id"
          class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
        >
          <!-- <td>
            <div class="flex items-center">
              <div class="flex flex-col ml-3">
                <input
                  v-model="advert.selected"
                  @change="updateSelectedRows(advert)"
                  type="checkbox"
                  class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                />
              </div>
            </div>
          </td> -->

          <td>
            <div class="flex items-center">
              <div class="flex flex-col ml-3">
                <span class="capitalize text-[#464E5F] mb-0.5">{{
                  advert?.title
                }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="flex flex-col">
              <span
                @click="realmDetails(advert.id)"
                class="cursor-pointer capitalize text-[#4e8d6e] mb-0.5"
                >{{ advert.realm.name }}</span
              >
            </div>
          </td>
          <td>
            <div class="flex flex-col">
              <span class="capitalize text-[#464E5F] mb-0.5">{{
                advert.is_active ? 'Enabled' : 'Disabled'
              }}</span>
            </div>
          </td>
          <td>
            <div class="flex flex-col">
              <span class="capitalize text-[#464E5F] mb-0.5">{{
                createdAt(advert.created)
              }}</span>
            </div>
          </td>
          <td>
            <div>
              <span
                @click="downloadFile(advert.file)"
                class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center whitespace-nowrap cursor-pointer"
                >Download<i class="ml-1.5 fa-solid fa-download"></i
              ></span>
            </div>
          </td>
          <td class="flex justify-end">
            <div>
              <span
                @click="viewDetails(advert.id)"
                class="capitalize p-2 rounded bg-[#4e8d6e] text-white mb-0.5 mr-2 inline-block text-center cursor-pointer"
                >View Details</span
              >
            </div>
            <div>
              <span
                v-if="!deleting || currentDeletionFileID !== advert.id"
                @click="deleteAdvert(advert.id)"
                class="capitalize p-2 rounded bg-[#f24236] text-white mb-0.5 inline-block text-center cursor-pointer"
                >Delete</span
              >
              <ScaleLoader
                v-if="deleting && currentDeletionFileID === advert.id"
                color="#d7191c"
              ></ScaleLoader>
            </div>
          </td>
        </div>
      </template>
    </SmeTable>
    <div class="spinner-container">
      <div v-if="isLoading" class="spinner"></div>
    </div>
    <DashboardTableTitle
      v-if="propLoaded"
      :perPageItems="perSize"
      :pageNumber="page"
      :ApiData="apiData"
      @handleNext="handlleNext"
      @handlePrev="handlePrev"
    >
      <template v-slot:section>
        <div>
          <div class="flex items-center">
            <span
              class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]"
              >Rows per page</span
            ><span
              class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]"
            >
              <select v-model="selectedOption" @change="handleChange">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select></span
            >
          </div>
        </div>
      </template>
    </DashboardTableTitle>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import router, { useRoute } from '@/router';

import instance from '../../axios-interceptor';
import SmeTable from '../../components/reusable/SmeTable.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';

export default defineComponent({
  name: 'adsPage',
  components: {
    DashboardTableTitle,
    SmeTable,
    ScaleLoader,
  },
  setup() {
    const pageNumber = ref(1);
    const startIndex = ref(0);
    const perPageItems = ref(10);

    const tableName = ref('');
    const icon = ref({ ...dashboardHeadericon });
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const deleting = ref(false);
    const currentDeletionFileID = ref(0);

    const advertTableHeadings = ref([
      'Name',
      'Realm',
      'Status',
      'Added',
      'Media',
      'Action',
    ]);

    const selectedSubscription = ref(false);

    return {
      center,
      tableName,
      icon,
      selectedSubscription,
      pageNumber,
      perPageItems,
      startIndex,
      advertTableHeadings,
      deleting,
      currentDeletionFileID,
    };
  },
  data() {
    return {
      adsList: [
        {
          id: '',
          title: '',
          selected: false,
          status: '',
          realm: {
            id: '',
            name: '',
          },
          is_active: '',
          created: '',
        },
      ],
      rowsSelected: 0,
      selectAll: false,
      selectedRows: [] as string[],
      page: 1,
      perSize: 10,
      sortKey: '', // Sorting key
      sortDirection: 'asc',
      searchQuery: '',
      propLoaded: false,
      selectedOption: 10,
      apiData: [],
      isLoading: false,
      activeTab: 'active',
      baseUserUrl: '',
    };
  },

  methods: {
    downloadFile(file: string) {
      window.open(file, '_blank', 'noreferrer');
    },
    realmDetails(id: string) {
      router.push({ name: 'realms-details', params: { id: id } });
    },
    viewDetails(id: string) {
      router.push({ name: 'advert-details', params: { id: id } });
    },
    clearRows() {
      this.paginatedTableData.filter((row) => (row.selected = false));
      this.selectAll = false;
    },
    showButtons() {
      return this.paginatedTableData.some((row) => row.selected === true);
    },
    selectAllRows(checked: boolean) {
      this.selectAll = checked;
      this.paginatedTableData.forEach((row) => {
        row.selected = this.selectAll;
      });
      this.rowsSelected = this.paginatedTableData.length;
    },
    sortBy(key: string) {
      let currentKey;
      if (key === 'Name') {
        currentKey = 'title';
      } else if (key === 'Realm') {
        currentKey = 'realmName';
      } else if (key === 'Status') {
        currentKey = 'status';
      } else {
        currentKey = 'created';
      }
      if (this.sortKey === currentKey) {
        // Toggle the sort direction if the same key is clicked again
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = currentKey;
        this.sortDirection = 'asc';
      }
    },
    updateSelectedRows(row: any) {
      if (!row.selected) {
        this.selectAll = false;
      } else {
        const selectedRows = this.paginatedTableData.filter(
          (row) => row.selected
        );
        this.rowsSelected = selectedRows.length;
        this.selectAll = selectedRows.length === this.paginatedTableData.length;
      }
    },
    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('M/D/YYYY, h:mm:ss a');
      }
    },
    handlePrev() {
      this.page--;
      this.fetchAdverts();
    },
    handlleNext() {
      this.page++;
      this.fetchAdverts();
    },
    handleChange() {
      this.perSize = this.selectedOption;
      this.fetchAdverts();
    },
    fetchAdverts() {
      this.isLoading = true;
      const baseUrl = `verts/?partner=${this.loggedInUser.partner.id}`;

      instance
        .get(baseUrl)
        .then((response) => {
          this.isLoading = false;
          this.apiData = response.data;
          this.propLoaded = true;
          this.adsList = response.data.map((row: any) => ({
            ...row,
            selected: false,
            realmName: row.realm.name,
            status: row.is_active ? 'Enabled' : 'Disabled',
          }));
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    unallocateRoutersFromSme() {
      const route = useRoute();
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const assignSmeRoutersUrl = 'routers/assign-to-sme/';
      const data = {
        routers: selectedRouters,
        sme: route.params.id,
      };
      instance
        .patch(assignSmeRoutersUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.fetchAdverts();
            this.$toast.success(response.data.detail, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unallocateRoutersFromPartner() {
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const unassignRoutersFromPartnerUrl = 'routers/assign-to-partner/';
      const data = {
        routers: selectedRouters,
      };

      instance
        .patch(unassignRoutersFromPartnerUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.fetchAdverts();
            this.$toast.success(`Router removed`, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addAdvert() {
      router.push({ name: 'add-advert' });
    },
    async deleteAdvert(fileID: number) {
      try {
        this.currentDeletionFileID = fileID;
        this.deleting = true;
        await instance.delete(`verts/${fileID}/`).then(() => {
          this.$toast.success('Advert has been deleted successfully', {
            position: 'top-right',
          });
          this.deleting = false;
          this.currentDeletionFileID = 0;
          this.fetchAdverts();
        });
      } catch (e: any) {
        this.deleting = false;
        this.currentDeletionFileID = 0;
        if (e.response.data.detail) {
          this.$toast.error(e.response.data.detail, {
            position: 'top-right',
          });
        } else {
          this.$toast.error('Could not delete the advert', {
            position: 'top-right',
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.adsList;
      }

      // Filter table data based on search query
      const filteredData = this.adsList.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const name = item.title.toString().toLowerCase();
        const realmName = item.realm.name.toString().toLowerCase();
        const status = item?.status.toString().toLowerCase();
        const created = this.createdAt(item.created.toString().toLowerCase());
        const query = this.searchQuery.toLowerCase();

        //Check if name or age contain the search query
        return (
          name?.includes(query) ||
          realmName?.includes(query) ||
          status?.includes(query) ||
          created?.includes(query)
        );
      });

      return filteredData;
    },

    sortedTableData(sortKey: any): any[] {
      if (!this.sortKey) {
        // If sort key is empty, return the filtered data as-is
        return this.filteredTableData;
      }

      // Sort the filtered table data based on the sort key and direction
      const sortedData = this.filteredTableData.slice().sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];

        // Perform the actual comparison based on the data type
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return (
            aValue.localeCompare(bValue) *
            (this.sortDirection === 'asc' ? 1 : -1)
          );
        } else {
          return (aValue - bValue) * (this.sortDirection === 'asc' ? 1 : -1);
        }
      });

      return sortedData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.sortedTableData.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.fetchAdverts();
  },
});
</script>
