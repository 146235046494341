<template>
  <div class="table-responsive w-full ">
    <div class="overflow-auto table w-full">
      <div class="bg-[#62937a] rounded-[0.337rem] items-center text-white font-poppins font-semibold text-[0.673rem] table-layout-tr table-th">

        <td class="text-[#464E5F] capitalize" v-if="inputShow">
        <th class="whitespace-nowrap">
          <input type="checkbox" class="mr-2 shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight relative top-1" v-model="selectAllInternal" @change="selectAllRows" :disabled="deletingVouchers">
          <!-- <span class="px-1 text-white">{{ tableName }}</span> -->
        </th></td>
        <td v-for="item in tableHeaderData" :key="item"> {{ item }}
          <span v-if="shouldHandleSort(item)" @click="sortTable(item)">
            <a v-if="sorting" href="#"><svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 ml-1 inline" aria-hidden="true"
              fill="currentColor" viewBox="0 0 320 512">
              <path
                d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
            </svg></a>
          </span>
          
        </td>

      </div>
      <slot name="tableBodyData"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SmeTable',
  props: {
    tableHeaderData: {
      type: Array as PropType<string[]>,
      required: true,
    },
    tableName: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    selectAllValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    sorting: {
      type: Boolean,
      required: false,
      default: false,
    },
    deletingVouchers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectAllInternal: false,
    };
  },
  watch: {
    selectAllValues(newVal) {
      // Update the data property when the prop value changes
      this.selectAllInternal = newVal;
    },
  },
  methods: {
    selectAllRows() {
      this.$emit('selectAllRows', this.selectAllInternal);
    },
    sortTable(sortKey: string) {
      this.$emit('sort', sortKey);
    },

    shouldHandleSort(item: string) {
      return !(
        item === 'Action' ||
        item === 'QR code' ||
        item === 'Price' ||
        item === 'Thumbnail' ||
        item === 'Download' ||
        item === 'Delete' ||
        item === 'ACTION'
      );
    },
  },
});
</script>
