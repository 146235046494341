<template>
  <DashboardHeader title="Landing Page Builder" :icon="icon.captiveportal">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium md:text-[1rem] text-[0.60rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[1.099rem] h-[1.062rem] rounded ml-1" />
      </div>
    </template>
  </DashboardHeader>

  <div class="w-full my-3">
    <div class="bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto">
      <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="apiData">
        <template v-slot:section>
          <div>
            <span class="font-poppins font-semibold md:text-[1.01rem] text-[0.9rem] text-[#212121]">Captive Portal
              templates</span>
            <div class="flex items-center mb-3">
              <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Management of captive portal
                templates.</span>
            </div>
          </div>
        </template>
      </DashboardTableTitle>
      <SmeTable :tableName="tableName" :tableHeaderData="routerTableHeaders">
        <template v-slot:tableBodyData>
          <template v-if="paginatedTableData.length">
            <div v-for="item in paginatedTableData" :key="item.id"
              class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.name
                      }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      item.router
                      }}</span>
                  </div>
                </div>
              </td>
              <td v-if="item.id">
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    formatDate(item.created)
                    }}</span>
                </div>
              </td>
              <td>
                <span class="text-[#464E5F]">{{
                  formatDate(item.updated)
                  }}</span>
              </td>
              <td>
                <div class="flex items-center justify-end">
                  <div v-for="icon in routerTableIcons" :key="icon.content"
                    class="bg-[#F3F6F9] rounded-[0.375rem] flex items-center justify-center ml-2">
                    <!-- <button
                      v-if="icon.type == 'img'"
                      class="w-[2rem] h-[2rem] text-center"
                    >
                      <img
                        :src="icon.content"
                        alt="africa"
                        class="w-[1.125rem] h-[1.067rem] mx-auto"
                      />
                    </button> -->
                    <button v-if="icon.type == 'text'"
                      class="bg-[#F3F6F9] text-[#4E8D6D] rounded-[0.375rem] font-semibold py-2 px-3"
                      @click="selectCaptivePortal(item.id)">
                      {{ icon.content }}
                    </button>
                  </div>
                </div>
              </td>
            </div>
          </template>
          <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4">
            <b>No Record found</b>
          </div>
        </template>
      </SmeTable>

      <div class="text-center mt-9" v-if="paginatedTableData.length === 0">
        <img src="../../assets/interaction-browser.svg" class="m-auto mb-4" alt="" />
        <span class="text-[#000] font-poppins font-medium text-[12px]">No Captive Portal found</span>
      </div>
      <div v-if="isLoading" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
  <CustomizePortal v-if="showModal" @close="closeModal()" :loading-save="loadingSave" :loading-reset="loadingReset"
    :captivePortal="selectedCaptivePortal" @update-captive-portal="updateCaptivePortal"
    @reset-captive-portal="resetCaptivePortal"></CustomizePortal>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import axios from '@/axios-interceptor';
import CustomizePortal from './customize-portal.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import { CaptivePortal } from '@/interfaces/captive-portal';
import DashboardHeader from '@/components/markup/DashboardHeader.vue';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import {
  captive as _captive,
  captiveTableHeaders as _captiveTableHeaders,
  captiveTableIcons as _captiveTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';

export default defineComponent({
  name: 'CaptivePortalPage',
  components: {
    DashboardTableTitle,
    SmeTable,
    DashboardHeader,
    CustomizePortal,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Name');
    const routers = ref([..._captive]);
    const routerTableHeaders = ref([..._captiveTableHeaders]);
    const routerTableIcons = ref([..._captiveTableIcons]);
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);

    return {
      routers,
      routerTableHeaders,
      routerTableIcons,
      center,
      markers,
      tableName,
      icon,
    };
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      captiveList: [] as CaptivePortal[],
      searchQuery: '',
      apiData: [] as CaptivePortal[],
      propLoaded: false,
      page: 1,
      perSize: 10,
      selectedCaptivePortal: {} as CaptivePortal,
      loadingSave: false,
      loadingReset: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.captiveList.slice(startIndex, endIndex);
    },
  },
  methods: {
    formatDate(date: any) {
      if (date !== '') {
        return moment(date).format('dddd, MMMM Do YYYY, h:mm a');
      }
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    fetchRouters() {
      this.isLoading = true;
      axios
        .get(`routers/?partner=${this.loggedInUser.partner.id}`)
        .then((response: { data: any }) => {
          const routers = response.data.results.filter((router: any) => router.sme === null);
          this.captiveList = routers.map((router: any) => {
            const captivePortal = { ...router.captive_portal };
            captivePortal.router = router.serial_number;
            return captivePortal;
          });
          this.apiData = this.captiveList;
          this.propLoaded = true;
          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    closeModal() {
      this.showModal = false;
      this.fetchRouters();
    },
    selectCaptivePortal(id: number) {
      this.selectedCaptivePortal = this.captiveList.filter(
        (captivePortal) => captivePortal.id === id
      )[0];
      this.showModal = true;
    },
    updateCaptivePortal(data: CaptivePortal) {
      this.loadingSave = true;

      console.log('DATA RECEIVED: ', data);


      const payload = new FormData();

      data.name ? payload.append('name', data.name) : '';
      data.background_color
        ? payload.append('background_color', data.background_color)
        : '';
      data.primary_message
        ? payload.append('primary_message', data.primary_message)
        : '';

      payload.append('show_connect_message', data.show_connect_message);

      if (data.logo) {
        payload.append('logo', data.logo);
      }

      axios
        .patch(`captive-portal/${this.selectedCaptivePortal.id}/`, payload)
        .then(() => {
          this.loadingSave = false;
          this.showModal = false;
          this.fetchRouters();
        })
        .catch((error: any) => {
          this.loadingSave = false;
          console.log(error);
        });
    },
    resetCaptivePortal() {
      this.loadingReset = true;

      axios
        .patch(`captive-portal/reset/?id=${this.selectedCaptivePortal.id}`)
        .then(() => {
          this.loadingReset = false;
          this.showModal = false;
          this.fetchRouters();
        })
        .catch((error: any) => {
          this.loadingReset = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchRouters();
  },
});
</script>
