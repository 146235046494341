<template>
  <div>
    <div class="flex mt-3">
      <div class="w-full">
        <div class="bg-success">
          <div class="text-center">
            <p class="mb-0 bg-[#ccebdb] text-[#4e8d6e] p-1">
              Last API Key generated on <span class="font-bold">{{ lastGenerated }}</span>
            </p>
          </div>
        </div>

        <div class="text-center mt-4">
          <button class="bg-primary text-white md:py-2 md:px-4 py-1 px-3 md:text-[0.9rem] text-[0.8rem] rounded-[0.4rem]">Generate API Key</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastGenerated: new Date().toLocaleString(),
    }
  }
}
</script>
