

<template>
  <div>


    <div class="row">
      <div class="col-md-12 mx-auto">
        <h3 class="text-left mb-3 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">
          Micro Franchisee Credit Fees Setup
        </h3>
        <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative">
          <div class="md:w-[100%] w-[50%] mb-2 md:mt-4 mt-2">
            <label class="relative block">
              <span class="sr-only">Search</span>
              <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                <img src="../../assets/search-icon.png" alt="" class="w-[0.8rem] h-[0.8rem]" />
              </span>
              <input v-model="searchQuery"
                class="font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Search" type="text" name="search" />
            </label>
          </div>

          <SmeTable :tableName="'Aggregators'" :tableHeaderData="accountsTableHeader
          ">
          <template v-slot:tableBodyData>
            <span v-if="!aggregators.length" class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap">
              No records
            </span>
            <div v-else v-for="item in aggregators" :key="item.id"
              class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">

              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <span class="capitalize text-[#464E5F] mb-0.5"></span>
                  </div>
                </div>
              </td>

              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5"></span>
                </div>
              </td>


            </div>
          </template>
        </SmeTable>
          <DashboardTableTitle :perPageItems="perSize" :pageNumber="page" :ApiData="apiData" @handleNext="handlleNext"
            @handlePrev="handlePrev">
            <template v-slot:section>
              <div>
                <div class="flex items-center">
                  <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                    class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                    <select v-model="selectedOption" @change="handleChange">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select></span>
                </div>
              </div>
            </template>
          </DashboardTableTitle>
          <div v-if="isLoading" class="spinner-container">
            <div class="spinner"></div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
                
<script>
import { defineComponent, ref } from "vue";
import SmeTable from "../../components/reusable/SmeTable.vue";
import DashboardTableTitle from "../../components/markup/DashboardTableTitle.vue";
export default {

  components: { SmeTable ,DashboardTableTitle},
  setup() {
    // const mode = ref(4);


    const tableName = ref("Ticket ID");


    const accountsTableHeader = ref([
      "Credit Amount",
      "Approval Required",
      "Credit Fee",
      "Action"
    ]);

    return {

      tableName,

      accountsTableHeader,
    };
  },
  data() {
    return {
      aggregators: [],
      page: 1,
      perSize: 10,
      propLoaded: true,

      selectedOption: 10,
      apiData: [],
    }
  },
  methods: {

    handlePrev() {
      this.page--;

    },
    handlleNext() {
      this.page++;

    },


    handleChange() {
      this.perSize = this.selectedOption;

    },


  },
}
</script>