<template>
  <div class="flex flex-col md:block my-6">
    <div class="flex items-center">
      <i class="fa-solid fa-business-time w-[1rem] h-[1rem] rounded mr-2"></i>
      <span class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">SME's</span>
    </div>
  </div>
  <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative">
    <div class="tabs-block" id="tabs-block">
      <div class="mb-3">
        <ul class="flex flex-wrap text-sm font-medium text-center mb-2" id="myTab" data-tabs-toggle="#myTabContent"
          role="tablist">
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false" v-on:click="setActiveTab('all')"
              v-bind:class="[
                activeTab === 'all'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]">
              All
            </button>
          </li>
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false"
              v-on:click="setActiveTab('suspended')" v-bind:class="[
                activeTab === 'suspended'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]">
              Suspended
            </button>
          </li>
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false"
              v-on:click="setActiveTab('terminated')" v-bind:class="[
                activeTab === 'terminated'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]">
              Terminated
            </button>
          </li>
          <li class="mr-2">
            <button class="inline-block p-4 py-2 rounded-md w-full" id="profile-tab" data-tabs-target="#profile"
              type="button" role="tab" aria-controls="profile" aria-selected="false" v-on:click="setActiveTab('active')"
              v-bind:class="[
                activeTab === 'active'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]">
              Active
            </button>
          </li>
        </ul>
      </div>
      <div id="myTabContent">
        <div class="bg-light sm:p-5 p-4 rounded">
          <div class="w-full sm:flex items-center justify-between mb-3">
            <h3
              class="mb-0 text-left uppercase font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
              {{ activeTab }} SMES <span v-if="!loading">{{ smes.length }}</span>
            </h3>
            <button @click="showModal = true" type="button"
              class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]">
              Add SME
            </button>
            <SMEForm v-if="showModal" @close="closeModal"></SMEForm>
            <SMEForm v-if="showEditSMEModal" @close="closeEditSMEModal" :sme="selectedSME"></SMEForm>
          </div>
          <SmeTable :sorting="false" :inputShow="false" :tableName="'SMEs'" :tableHeaderData="accountsTableHeader">
            <template v-slot:tableBodyData>
              <span v-if="!smes.length" class="text-[#464E5F] mb-0.5 text-sm whitespace-nowrap">
                No records
              </span>
              <div v-else v-for="sme in smes" :key="sme.id"
                class="table-layout-tr py-2 my-3 rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
                <td v-if="false">
                  <div class="flex items-center">
                    <div class="flex flex-col ml-3">
                      <input checked v-model="selectedSubscription" type="checkbox"
                        class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight" />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="flex items-center">
                    <div class="flex flex-col ml-3">
                      <span class="b-avatar-img text-[#464E5F] mb-0.5">{{
                        sme.name
                      }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="text-[#464E5F] mb-0.5">{{
                      sme.admin_email
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="text-[#464E5F] mb-0.5">{{
                      sme.admin_phone
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="text-[#464E5F] mb-0.5">{{
                      sme.tin_number
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex flex-col">
                    <span class="text-[#464E5F] mb-0.5">{{ sme.type }}</span>
                  </div>
                </td>
                <td class="flex justify-between">
                  <div>
                    <span @click="smeDetails(sme.id)"
                      class="px-2 py-1 rounded bg-textDark text-white mb-0.5 inline-block text-center cursor-pointer">view</span>
                  </div>
                  <div>
                    <span @click="editSME(sme.id)"
                      class="px-2 py-1 rounded bg-textDark text-white mb-0.5 inline-block text-center cursor-pointer">edit</span>
                  </div>
                </td>
              </div>
            </template>
          </SmeTable>
          <div class="spinner-container">
            <div v-if="loading" class="spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';

import SMEForm from './addSme.vue';
import instance from '../../axios-interceptor';
import SmeTable from '../../components/reusable/SmeTable.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import { SME } from '@/interfaces/billing';
import router from '@/router';

export default defineComponent({
  name: 'smePage',
  components: {
    SMEForm,
    SmeTable,
  },
  setup() {
    const pageNumber = ref(1);
    const perPageItems = ref(5);
    const startIndex = ref(0);

    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Ticket ID');

    const center = ref({ lat: -1.8899577, lng: 30.0634073 });

    const accountsTableHeader = ref([
      'Name',
      'Email',
      'Phone',
      'TIN Number',
      'Type',
      'Action',
    ]);

    const selectedSubscription = ref(false);

    return {
      center,

      tableName,
      icon,

      selectedSubscription,

      pageNumber,
      perPageItems,
      startIndex,

      accountsTableHeader,
    };
  },
  data() {
    return {
      showModal: false,
      showEditSMEModal: false,
      smes: [] as SME[],
      selectedSME: {} as SME,
      loading: false,
      activeTab: 'all',
      filteredMicroFranchisees: [],

      totalMicroFranchisees: null,
      baseUserUrl: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    closeModal() {
      this.showModal = false;
      const baseUrl = `smes/?partner=${this.loggedInUser.partner.id}`;
      this.fetchSME(baseUrl);
    },
    closeEditSMEModal() {
      this.showEditSMEModal = false;
      const baseUrl = `smes/?partner=${this.loggedInUser.partner.id}`;
      this.fetchSME(baseUrl);
    },
    smeDetails(id: any) {
      router.push({ name: 'sme-details', params: { id: id } });
    },
    editSME(id: any) {
      const targetSME = this.smes.find((sme) => sme.id === id);

      if (targetSME) {
        this.selectedSME = targetSME;
        this.showEditSMEModal = true;
      }
    },
    handlePrev() {
      this.pageNumber--;
      this.startIndex -= this.perPageItems;
    },
    handleNext() {
      this.pageNumber++;
      this.startIndex += this.perPageItems;
    },
    fetchSME(baseUrl: string) {
      this.loading = true;
      instance
        .get(baseUrl)
        .then((response) => {
          this.loading = false;
          this.smes = response.data;
        })
        .catch((error: any) => {
          this.loading = false;
          console.log(error);
        });
    },
    setActiveTab(tab: string) {
      this.activeTab = tab;
      router.push({ query: { tab: tab } });


      if (tab === 'all') {
        const baseUrl = `smes/?partner=${this.loggedInUser.partner.id}`;
        this.fetchSME(baseUrl);
      }

      if (tab === 'suspended') {
        const baseUrl = `smes/?partner=${this.loggedInUser.partner.id}&is_active=false`;
        this.fetchSME(baseUrl);
      }

      if (tab === 'terminated') {
        const baseUrl = `smes/?partner=${this.loggedInUser.partner.id}&is_terminated=true`;
        this.fetchSME(baseUrl);
      }

      if (tab === 'active') {
        const baseUrl = `smes/?partner=${this.loggedInUser.partner.id}&is_active=true&is_terminated=false`;
        this.fetchSME(baseUrl);
      }

    },
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    if (tab) {
      this.setActiveTab(tab);
    } else {
      this.setActiveTab(this.activeTab);
    }

  },
});
</script>
