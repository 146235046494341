<template>
  <div class="flex flex-col md:block hidden my-6">
    <div class="flex items-center">
      <i class="fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2"></i>
      <span
        class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
        >User Profile</span
      >
    </div>
  </div>
  <div>
    <div
      class="lg:flex flex-row items-start gap-y-8 xl:gap-x-8 gap-x-6 mt-8 sm:gap-y-16"
    >
      <div
        class="xl:w-1/4 lg:w-1/3 md:w-full bg-white pb-4 sm:mx-0 shadow-lg px-5 pt-5 pb-6 rounded-2xl lg:mb-0 mb-4"
      >
        <div
          class="user-img rounded-full w-[100px] h-[100px] overflow-hidden mb-2 m-auto"
        >
          <img
            src="../../assets/user-img.svg"
            class="w-full h-full"
            alt="user"
          />
        </div>

        <div class="text-center pt-2">
          <h3 class="font-bold font-lg">{{ first_name }} {{ last_name }}</h3>
          <span class="text-sm">Role: {{ role }}</span>
        </div>
        <div class="edit-profile flex justify-center pt-3">
          <button
            type="button"
            @click="openModal('edit')"
            class="bg-primary font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
          >
            Edit Profile
          </button>
          <button
            v-if="status === 'active'"
            @click="openModal('suspend')"
            type="button"
            class="bg-[#d7191c] font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
          >
            Suspend
          </button>
          <button
            v-else
            @click="openModal('activate')"
            type="button"
            class="bg-primary font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
          >
            Activate
          </button>
          <button
            @click="openModal('updatePassword')"
            type="button"
            class="bg-[#2f6887] font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium border"
          >
            Reset Password
          </button>

          <div v-if="showModal" class="popup-modal">
            <div class="popup-modal-content rounded-xl">
              <div class="row">
                <div class="col-md-12 mx-auto py-4">
                  <h3
                    class="text-center mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
                  >
                    Are You sure you want to
                    {{ btnName }}
                  </h3>
                  <div class="w-full flex justify-center pt-2">
                    <button
                      @click="cancel"
                      type="button"
                      class="px-3 py-2 font-['poppins'] text-[13px] bg-primary text-white rounded-[0.432rem] mr-2 font-medium"
                    >
                      Cancel
                    </button>
                    <button
                      @click="save"
                      type="button"
                      class="px-3 py-2 font-['poppins'] text-[13px] bg-primary text-white rounded-[0.432rem] font-medium flex items-center"
                    >
                      {{ btnName }}
                      <span
                        v-if="isLoader"
                        :class="{ 'spinner btn-spinner ml-2': isLoader }"
                      >
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="user-profile-block xl:w-3/4 md:w-full lg:w-2/3 full-width bg-white p-5 rounded-[0.938rem]"
      >
        <div class="tabs-block mb-2" id="tabs-block">
          <div class="mb-3">
            <ul
              class="flex flex-wrap -mb-px text-sm font-medium text-center mb-2"
              id="myTab"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              <li class="mr-2">
                <button
                  class="inline-block p-4 py-2 rounded-md w-full"
                  id="profile"
                  data-tabs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  v-on:click="activeTab = 'profile'"
                  v-bind:class="[
                    activeTab === 'profile'
                      ? 'bg-light text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]"
                >
                  Profile
                </button>
              </li>
              <li class="mr-2">
                <button
                  class="inline-block p-4 py-2 rounded-md w-full"
                  id="assessments"
                  data-tabs-target="#assessments"
                  type="button"
                  role="tab"
                  aria-controls="assessments"
                  aria-selected="false"
                  v-on:click="activeTab = 'assessments'"
                  v-bind:class="[
                    activeTab === 'assessments'
                      ? 'bg-light text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]"
                >
                  Assessments
                </button>
              </li>
              <li class="mr-2">
                <button
                  class="inline-block p-4 py-2 rounded-md w-full"
                  id="analytics"
                  data-tabs-target="#analytics"
                  type="button"
                  role="tab"
                  aria-controls="analytics"
                  aria-selected="false"
                  v-on:click="activeTab = 'analytics'"
                  v-bind:class="[
                    activeTab === 'analytics'
                      ? 'bg-light text-[#4e8d6e]'
                      : 'bg-[transparent]',
                  ]"
                >
                  Analytics
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div id="myTabContent" class="full-width pt-4">
          <div
            v-if="activeTab === 'profile'"
            class=""
            id="profile"
            role="tabpanel"
            aria-labelledby="profile"
          >
            <div class="user-profile-innr w-full">
              <h3
                class="text-left mb-3 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
              >
                User profile
              </h3>

              <table class="w-full table-fixed table-border">
                <tbody>
                  <tr class="bg-light">
                    <td class="font-poppin text-[1rem] text-[#888889]">Name</td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      {{ first_name }} {{ last_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      Email
                    </td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      {{ email }}
                    </td>
                  </tr>
                  <tr class="bg-light">
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      Phone
                    </td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      {{ phone }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      Country
                    </td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      {{ country }}
                    </td>
                  </tr>
                  <tr class="bg-light">
                    <td class="font-poppin text-[1rem] text-[#888889]">Role</td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      {{ role }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-poppin text-[1rem] text-[#888889]">Type</td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      {{ userType }}
                    </td>
                  </tr>
                  <tr class="bg-light">
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      Status
                    </td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      <span
                        v-bind:class="[
                          status === 'suspended'
                            ? 'text-white bg-[#d7191c]'
                            : 'text-[#4e8d6e] bg-[#DCE9E3]',
                        ]"
                        class="font-poppins font-medium text-[0.8rem] px-3 py-1 rounded-[0.327rem]"
                        >{{ status }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      Date Joined
                    </td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      {{ date_joined }}
                    </td>
                  </tr>

                  <template v-if="user_kiosks.length > 0">
                    <tr>
                      <td class="font-poppin text-[1rem] text-[#888889]">
                        Kiosk
                      </td>
                      <td
                        class="font-medium text-[1rem] whitespace-normal break-words"
                      >
                        {{ user_kiosks[0].serial_number }}
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td class="font-poppin text-[1rem] text-[#888889]">
                      Kiosk
                    </td>
                    <td
                      class="font-medium text-[1rem] whitespace-normal break-words"
                    >
                      -
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center pt-2">
                <button
                  v-if="user.has_kiosk"
                  @click="Modal = true"
                  type="button"
                  class="bg-[#d7191c] font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-1 border"
                >
                  Unassign Kiosk
                </button>
                <button
                  v-else
                  type="button"
                  @click="Modal = true"
                  class="bg-primary font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-1 border"
                >
                  Assign Kiosk
                </button>
              </div>
              <div v-if="Modal" class="popup-modal">
                <div class="popup-modal-content rounded-xl">
                  <div class="row">
                    <div class="col-md-12 mx-auto py-4">
                      <h3
                        class="text-center mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
                      >
                        Select Kiosk to assign
                      </h3>
                      <table class="table table-striped table-hover w-full">
                        <thead>
                          <tr>
                            <th class="flex justify-between mb-2">
                              Kiosk Serial Number
                              <div class="float-right">
                                <button
                                  type="button"
                                  @click="assignKiosk"
                                  :class="{
                                    disabled: selectedKiosk.length === 0,
                                  }"
                                  :disabled="selectedKiosk.length === 0"
                                  class="bg-primary font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
                                >
                                  Assign Kiosk
                                </button>
                                <button
                                  @click="Modal = false"
                                  type="button"
                                  class="bg-[#d7191c] font-['poppins'] text-[9px] text-white rounded-[0.25rem] py-1 px-2 font-medium mr-1 border"
                                >
                                  cancel
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="filteredKiosks && filteredKiosks.length > 0"
                        >
                          <tr
                            v-for="kiosk in filteredKiosks"
                            :key="kiosk.id"
                            class="bg-light p-3"
                          >
                            <td
                              class="flex justify-center items-center bg-light p-3"
                            >
                              <input
                                v-model="selectedKiosk"
                                :value="kiosk.id"
                                type="radio"
                                class="form-check-input"
                              />
                              <span class="inline-block ml-2">{{
                                kiosk.serial_number
                              }}</span>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td class="p-3 bg-light">No available kiosk.</td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="text-center pt-4">
                        <button
                          type="button"
                          @click="assignKiosk"
                          :class="{ disabled: selectedKiosk.length === 0 }"
                          :disabled="selectedKiosk.length === 0"
                          class="bg-primary font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-1 border"
                        >
                          Assign Kiosk
                        </button>
                        <button
                          @click="Modal = false"
                          type="button"
                          class="bg-[#d7191c] font-['poppins'] text-[13px] text-white rounded-[0.4rem] py-2 px-3 font-medium mr-1 border"
                        >
                          cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="activeTab === 'assessments'"
            class=""
            id="assessments"
            role="tabpanel"
            aria-labelledby="assessments"
          >
            <div class="user-profile-innr w-full">
              <h3
                class="text-left mb-3 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
              >
                MF Assessments
              </h3>

              <div class="overflow-x-auto">
                <table class="min-w-full divide-y divide-gray-200 table-border">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                      >
                        OFFICER
                      </th>
                      <th
                        class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                      >
                        Date
                      </th>
                      <th
                        class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 border-[#eaeaee]">
                    <tr class="bg-light">
                      <td
                        colspan="3"
                        class="font-poppin text-[14px] text-[#888889] text-center"
                      >
                        Officer has not received any kiosk yet.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            v-if="activeTab === 'analytics'"
            class=""
            id="analytics"
            role="tabpanel"
            aria-labelledby="analytics"
          >
            <div class="user-profile-innr w-full">
              <h3
                class="text-left mb-3 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
              >
                Micro Franchisee Analytics
              </h3>
              <div class="grid xl:grid-cols-2 grid-cols-1 gap-4">
                <div
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-4 bg-gray-100 bg-white"
                >
                  <h5 class="mb-4 text-base font-bold">Commissions Report</h5>
                  <apexchart
                    width="300px"
                    height="500px"
                    type="donut"
                    :options="serviceoptions"
                    :series="serviceseries"
                    :labels="servicelabels"
                  >
                  </apexchart>
                </div>
                <div
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-4 bg-gray-100 bg-white"
                >
                  <h5 class="mb-4 text-base font-bold">Summary Report</h5>
                  <apexchart
                    width="300px"
                    height="500px"
                    type="donut"
                    :options="summaryoptions"
                    :series="summaryseries"
                    :labels="summarylabels"
                  >
                  </apexchart>
                </div>
                <div
                  class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-4 bg-gray-100 bg-white"
                >
                  <h5 class="mb-4 text-base font-bold">Transaction Report</h5>
                  <apexchart
                    width="300px"
                    height="500px"
                    type="donut"
                    :options="transoptions"
                    :series="transseries"
                    :labels="translabels"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="spinner-container">
          <div v-if="isLoading" class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router, { useRoute } from '@/router';

import { defineComponent, ref } from 'vue';
import {
  routers as _routers,
  routerTableHeaders as _routerTableHeaders,
  routerTableIcons as _routerTableIcons,
} from '../../helpers/stub_data/dashboard_routers';
import instance from '@/axios-interceptor';
import moment from 'moment';
import ApexCharts from 'vue3-apexcharts';
import { mapGetters } from 'vuex';
export default defineComponent({
  name: 'microProfileDetailsPage',
  components: {
    apexchart: ApexCharts,
  },

  data() {
    return {
      showModal: false,
      serviceoptions: {
        colors: ['#38ef7d', '#4286f4', '#bdc3c7', '#2f4153'],
        labels: [' Internet Tokens', 'Airtime', 'Electricity', 'DTH'],
      },
      servicelabels: [' Internet Tokens', 'Airtime', 'Electricity', 'DTH'],
      serviceseries: [100, 500, 1500, 100],
      summaryoptions: {
        colors: ['#bdc3c7', '#4286f4'],
        labels: [' Account Balance', 'Maximum Credit Capacity'],
      },
      summarylabels: [' Account Balance', 'Maximum Credit Capacity'],
      summaryseries: [1500, 400],
      transoptions: {
        colors: ['#38ef7d', '#2f4153', '#bdc3c7', '#4286f4'],
        labels: [' Internet Tokens', 'Airtime', 'Electricity', 'DTH'],
      },
      translabels: [' Internet Tokens', 'Airtime', 'Electricity', 'DTH'],
      transseries: [100, 500, 1500, 100],
      isLoading: false,
      isLoader: false,
      activeTab: 'profile',
      userId: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      gender: '',
      page: '',
      userType: '',
      date_of_birth: '',
      national_id_no: '',
      country: '',
      area_of_operation: '',
      status: '',
      role: '',
      date_joined: '',
      user: {
        id: '',
        has_kiosk: '',
      },
      btnName: '',
      modalType: '',
      selectedKiosk: '',
      user_kiosks: [
        {
          assigned_to: '',
          serial_number: '',
        },
      ],
      filteredKiosks: [
        {
          id: '',
          serial_number: '',
        },
      ],
      Modal: false,
      totalAvailableKiosks: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },

  methods: {
    cancel() {
      this.showModal = false;
    },
    openModal(type: string) {
      this.modalType = type;
      if (this.modalType === 'edit') {
        this.showModal = true;
        this.btnName = 'Edit Details';
      } else if (this.modalType === 'suspend') {
        this.showModal = true;
        this.btnName = 'suspend';
      } else if (this.modalType === 'activate') {
        this.showModal = true;
        this.btnName = 'Activate';
      } else {
        this.btnName = 'Update Password';
        this.showModal = true;
      }
    },
    save() {
      if (this.modalType === 'edit') {
        this.editDetails();
      } else if (this.modalType === 'suspend') {
        this.disableUser();
      } else if (this.modalType === 'activate') {
        this.activateUser();
      } else {
        this.updatePassword();
      }
    },
    async updatePassword() {
      this.$toast.info('This feature is comming soon', {
        position: 'top-right',
      });
      // TODO: CHECK COMMIT CHANGE TO SEE WHAT THE FUNCTION DID BEFORE.
      // IT WAS USED TO SEND A RANDOM PASSWORD TO THE USER. THE API IS
      // CHANGED AND NOW REQUIRES CURRENT AND NEW PASSWORD TO BE SUBMITTED
      // SO, ON THE API, A NEW ENDPOINT CAN BE ADDED TO FULLFIL THE FUNCTION
    },
    editDetails() {
      const route = useRoute();

      router.push({
        name: 'user-details',
        params: { id: route.params.id },
      });
    },
    async fetchUser(id: any) {
      await instance.get(`auth/users/${id}/details/`).then((response) => {
        if (response.status === 200) {
          this.user = response.data;
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          this.phone = response.data.phone;
          this.country = response.data.country;
          this.userType = response.data.user_type;
          this.role = response.data.role;
          this.email = response.data.email;
          this.status = response.data.status;

          this.date_joined = moment(response.data.date_joined).format(
            'M/D/YYYY, h:mm:ss a'
          );
        } else {
          this.$toast.error(' Could not fetch user details', {
            position: 'top-right',
          });
        }
      });
    },

    async disableUser() {
      const route = useRoute();
      const data = { is_active: false, status: 'suspended' };
      this.isLoader = true;
      try {
        await instance
          .patch(`auth/users/${route.params.id}/details/`, data)
          .then((response) => {
            if (response.status === 200) {
              this.showModal = false;
              this.isLoader = false;
              this.fetchUser(route.params.id);
              this.$toast.success(' User suspended successfully', {
                position: 'top-right',
              });
            }
          });
      } catch (e) {
        this.isLoader = false;
        console.log(e);
      }
    },
    async activateUser() {
      const route = useRoute();
      const data = { is_active: true, status: 'active' };
      this.isLoader = true;
      try {
        await instance
          .patch(`auth/users/${route.params.id}/details/`, data)
          .then((response) => {
            if (response.status === 200) {
              this.showModal = false;
              this.isLoader = false;
              this.fetchUser(route.params.id);
              this.$toast.success(' User activated successfully', {
                position: 'top-right',
              });
            }
          });
      } catch (e) {
        this.isLoader = false;
        console.log(e);
      }
    },
    async fetchKiosks() {
      await instance
        .get(
          `kiosks/?partner=${this.loggedInUser.partner.id}&has_been_assigned_mf=false`
        )
        .then((response) => {
          if (response.status === 200) {
            this.filteredKiosks = response.data.results;
            this.totalAvailableKiosks = response.data.count;
            this.user_kiosks = response.data.results.filter(
              (kiosk: any) => kiosk.assigned_to == this.user.id
            );
            console.log(this.user_kiosks, this.user_kiosks);
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.error(' Could not fetch kiosks at this time', {
              position: 'top-right',
            });
          }
        });
    },
    assignKiosk() {
      const route = useRoute();
      const assignMicroFranchiseeRoutersUrl =
        'kiosks/assign-to-micro-franchisee/';
      const data = {
        kiosks: [{ id: this.selectedKiosk }],
        micro_franchisee: route.params.id,
      };
      instance
        .post(assignMicroFranchiseeRoutersUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.fetchUser(route.params.id);

            this.getMfKiosks(route.params.id);
            this.Modal = false;
            this.$toast.success(response.data.detail, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMfKiosks(id: any) {
      await instance
        .get(`kiosks/?assigned_to=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.user_kiosks = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(error.response[0], {
              position: 'top-right',
            });
          }
        });
    },
  },
  mounted() {
    const route = useRoute();
    if (route.params.id) {
      this.userId = route.params.id.toString();
      this.fetchUser(this.userId);
      this.fetchKiosks();
    }
  },
});
</script>

<style>
button.disabled {
  cursor: default;
  opacity: 0.6;
}
</style>



