<template>
    <div class="container shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] text-6xl rounded-xl p-6 bg-white">
        <div class="flex justify-between w-full">
            <h5 class="text-sm pe-4 font-light mt-1 text-[#202224] max-w-[200px]">
                {{ data.topic }}
            </h5>
            <div class="icon-container" :style="{ backgroundColor: data.color }">
                <span class="text-xl"><i :class="data.icon" :style="{ color: data.iconcolor }"></i></span>
            </div>
        </div>
        <div v-if="isLoading" class="figures text-lg font-bold">
            <ScaleLoader color="#000"></ScaleLoader>
        </div>
        <div v-else class="figures">
            <span :class="{ 'small-font': data.small }">
                {{ formatNumbers(data.figures) }}
            </span>
        </div>
        <div v-if="data.network && !isLoading" class="count mt-2 font-bold" style="font-size: small;">
            {{ data.network_figures }}
        </div>
        <div v-if="data.network && isLoading" class="figures text-lg font-bold">
            <ScaleLoader color="#000"></ScaleLoader>
        </div>
        <small v-if="data.percentage && !isLoading" class="description">
            <span :style="{ color: data.percentage >= 0 ? '#00B69B' : 'red' }">
                <span class="text-xl">
                    <i
                        :class="data.percentage >= 0 ? 'fa-solid fa-arrow-trend-up' : 'fa-solid fa-arrow-trend-down'"></i>
                </span>
                {{ Math.abs(data.percentage) }}% <span style="color: black;">{{ data.customWord }}</span>
            </span>
        </small>
    </div>
</template>

<script lang="ts">
import { CardData } from '@/interfaces/router';
import { defineComponent, computed, PropType } from 'vue';

import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

export default defineComponent({
    name: "CardDesign",
    components: {
        ScaleLoader,
    },
    props: {
        data: {
            type: Object as PropType<CardData>,
            required: true
        },
        source: {
            type: String,
            default: 'homepage'
        },
        loadingOne: {
            type: Boolean,
            required: true
        },
        loadingTwo: {
            type: Boolean,
            required: true
        },
    },
    setup(props) {
        const isLoading = computed(() => {
            if (props.data.topic === 'TOTAL ROUTERS' || props.data.topic === 'TOTAL SMES') {
                return props.loadingOne;
            } else {
                return props.loadingTwo;
            }
        });

        return {
            isLoading,
        };
    },
    methods: {
        formatNumbers(value: number | string | null | undefined): string {
            if (value === null || value === undefined || value === '') {
                return '';
            }

            if (typeof value === 'string') {
                // Special handling for Dwell Time and Peek Time
                if (this.data.topic === 'DWELL TIME' || this.data.topic === 'PEEK TIME' || this.data.topic === 'Average Duration') {
                    return value; // Return the string as-is
                }
                // If it's a string, check if it contains "MBs" or "MBPS"
                if (value.includes('MBs') || value.includes('MBPS')) {
                    return value; // Return pre-formatted strings as-is
                }
                // Otherwise, try to parse it as a number
                const num = parseFloat(value);
                if (!isNaN(num)) {
                    value = num;
                } else {
                    return value.trim(); // Return other strings as-is
                }
            }

            if (typeof value === 'number') {
                if (Number.isInteger(value)) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else {
                    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
            }

            return String(value);
        }
    },
});
</script>


<style scoped>
.container {
    border-radius: 7px;
    box-shadow: 1px 1px 10px 1px rgb(170, 168, 168);
    padding: 10px;
    height: 130px;
}

.topic .icon {
    height: 30px;
}

.topic .topic-desc {
    font-weight: light;
    font-size: small;
    color: #202224;
}

.figures {
    font-weight: bolder;
    font-size: x-large;
    position: absolute;
}

.icon-container {
    width: 40px;
    height: 40px;
    border-radius: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container #icon {
    background-color: transparent;
    font-size: 20px;
    margin-right: 5%;
    opacity: 100%;
}

.description {
    color: #202224;
    font-size: small;
}

.small-font {
    font-size: 0.7em;
    margin-top: 40%;
}
</style>
