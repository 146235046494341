<template>
    <section>
        <div v-for="(chart, index) in charts" :key="index" class="chart-container">
            <div :id="'chart' + index">
                <apexchart ref="chart" height="350" :options="chart.options" :series="chart.series"></apexchart>
            </div>
        </div>
        <div class="controls">
            <select v-model="selectedFilter" @change="fetchData">
                <option value="today">Today</option>
                <option value="1week">1 Week</option>
                <option value="1month">1 Month</option>
                <option value="3months">3 Months</option>
                <option value="6months">6 Months</option>
                <option value="12months">12 Months</option>
                <option value="5years">5 Years</option>
            </select>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '../axios-interceptor';
import ApexCharts from 'vue3-apexcharts';

export default {
    name: "ChartPage",
    components: {
        apexchart: ApexCharts,
    },
    data() {
        return {
            selectedFilter: '3months',
            charts: [
                {
                    title: 'Tokens Used and Currently Connected',
                    options: this.getChartOptions(false),
                    series: []
                },
                {
                    title: 'Tokens Generated and Expired Tokens',
                    options: this.getChartOptions(false),
                    series: []
                },
                {
                    title: 'Failed Tokens and Average Duration',
                    options: this.getChartOptions(true),
                    series: []
                }
            ]
        }
    },
    computed: {
        ...mapGetters(['loggedInUser']),
    },
    created() {
        this.fetchData();
    },
    methods: {
        getChartOptions(includeDurationAxis) {
            let options = {
                title: {
                    text: '',
                    align: 'left'
                },
                subtitle: {
                    text: '',
                    align: 'left'
                },
                chart: {
                    type: 'area',
                    height: 350
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                },
                xaxis: {
                    categories: [],
                },
                yaxis: [
                    {
                        title: {
                            text: 'Count'
                        }
                    }
                ],
                tooltip: {
                    y: {
                        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                            if (w.config.series[seriesIndex].name === 'Average Duration (minutes)') {
                                return val.toFixed(2) + ' minutes';
                            }
                            return val.toFixed(0);
                        }
                    }
                }
            };

            if (includeDurationAxis) {
                options.yaxis.push({
                    opposite: true,
                    title: {
                        text: 'Duration (minutes)'
                    },
                    decimalsInFloat: 2
                });
            }

            return options;
        },
        formatFilterDuration(filter) {
            const durations = {
                'today': 'Today',
                '1week': '1 Week',
                '1month': '1 Month',
                '3months': '3 Months',
                '6months': '6 Months',
                '12months': '12 Months',
                '5years': '5 Years'
            };
            return durations[filter] || filter;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            if (this.selectedFilter === '5years') {
                return date.getFullYear();
            } else if (this.selectedFilter === 'today') {
                return date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true });
            } else {
                return date.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });
            }
        },
        async fetchData() {
            if (!this.loggedInUser.partner.routers.length) {
                return;
            }

            const payload = {
                partner: this.loggedInUser.partner.id,
                router_serial_numbers: this.loggedInUser.partner.routers.map((router) => router.serial_number),
            };

            try {
                const response = await axios.post('/analytics/wifi-v2/', payload);
                const data = response.data[this.selectedFilter];

                this.updateCharts(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        updateCharts(data) {
            const startDate = data.categories[0];
            const endDate = data.categories[data.categories.length - 1];

            const chartTitles = [
                'Tokens Used and Currently Connected',
                'Tokens Generated and Expired Tokens',
                'Failed Tokens and Average Duration'
            ];

            this.charts.forEach((chart, index) => {
                if (index === 2) {
                    // Chart with Failed Tokens and Average Duration
                    chart.series = [
                        { name: 'Failed Tokens', data: data.series[4].data },
                        {
                            name: 'Average Duration (minutes)',
                            data: data.series[3].data.map(duration => {
                                const [hours, minutes, seconds] = duration.split(':').map(Number);
                                return Number(((hours * 60) + minutes + (seconds / 60)).toFixed(2));
                            }),
                            yAxisIndex: 1
                        }
                    ];
                } else if (index === 1) {
                    // Chart with Tokens Generated and Expired Tokens
                    chart.series = [
                        { name: 'Tokens Generated', data: data.series[2].data },
                        { name: 'Expired Tokens', data: data.series[5].data }
                    ];
                } else {
                    // Other charts
                    chart.series = [
                        { name: data.series[index * 2].name, data: data.series[index * 2].data },
                        { name: data.series[index * 2 + 1].name, data: data.series[index * 2 + 1].data }
                    ];
                }

                chart.options.xaxis.categories = data.categories;
                chart.options.title.text = `${this.formatFilterDuration(this.selectedFilter)} ${chartTitles[index]}`;

                if (this.selectedFilter === 'today') {
                    chart.options.subtitle.text = `Data analytics from 00:00 AM to 11:00 PM`;
                } else {
                    chart.options.subtitle.text = `Data analytics from ${this.formatDate(startDate)} up to ${this.formatDate(endDate)}`;
                }

                this.$nextTick(() => {
                    if (this.$refs.chart[index] && this.$refs.chart[index].updateOptions) {
                        this.$refs.chart[index].updateOptions({
                            xaxis: {
                                categories: data.categories
                            },
                            title: {
                                text: chart.options.title.text
                            },
                            subtitle: {
                                text: chart.options.subtitle.text
                            }
                        });
                    }
                });
            });
        }
    }
}
</script>

<style scoped>
.chart-container {
    margin-bottom: 30px;
}

.controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.controls select {
    padding: 10px;
    cursor: pointer;
}
</style>
