<template>
  <div class="sm:flex my-6 justify-between">
    <div class="flex items-center sm:mb-0 mb-2">
      <i class="fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2"></i>
      <span class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">Survey
        Questions</span>
    </div>
    <!-- <button type="button"
      class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]">
      Add survey Questions
    </button> -->
  </div>
  <!-- <button type="button"
    class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold text-[0.8rem] text-[#fff]">
    Add survey Questions
  </button> -->


  <div class="quest-block">
    <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative">
      <div class="tabs-block mt-6 mb-6" id="tabs-block">
        <div class="">
          <ul class="flex flex-wrap -mb-px text-sm font-medium text-center mb-2" id="myTab"
            data-tabs-toggle="#myTabContent" role="tablist">
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="questions" data-tabs-target="#questions"
                type="button" role="tab" aria-controls="questions" aria-selected="false"
                v-on:click="activetab = 'questions'" v-bind:class="[
                  activetab === 'questions'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Questions
              </button>
            </li>
            <li class="mr-2">
              <button class="inline-block p-4 py-2 rounded-md w-full" id="response" data-tabs-target="#response"
                type="button" role="tab" aria-controls="response" aria-selected="false"
                v-on:click="activetab = 'response'" v-bind:class="[
                  activetab === 'response'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]">
                Response
              </button>
            </li>
          </ul>
        </div>

        <div id="TabContent">
          <div v-if="activetab === 'questions'" class="" id="questions" role="tabpanel" aria-labelledby="questions">
            <div v-for="question in questions" :key="question"
              class="bg-light mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1 mb-1 text-sm rounded">
              {{ question }}
            </div>
            <div class="form-group flex justify-between mt-4">
              <div v-if="selectedOption === 'Short Answer'">
                <input v-model="newQuestion"
                  class="w-2/5 mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1 mr-2"
                  placeholder="Enter Your Question" type="text" name="search" />
              </div>
              <div v-if="selectedOption === 'Paragraph'">
                <textarea v-modal="newQuestion" id="message" rows="2"
                  class="w-2/5 mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1 mr-2"
                  placeholder="Write your thoughts here..."></textarea>
              </div>
              
              <select v-model="selectedOption" id="adType" type="name"
                class="max-w-[300px] ml-auto focus:outline-none w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder">
                <option value="Short Answer" selected>Short answer</option>
                <option value="Paragraph">Paragragh</option>
                <option value="Multiple choice">Multiple choice</option>
                <option value="Check Box">Checkboxes</option>
                <option value="Dropdown">Dropdown</option>
                <option value="Multiple choice grid">Multiple choice grid

                </option>
              </select>
            </div>
            <div class="mt-5 text-end">
              <button @click="addQuestion"
                class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border">
                Add
              </button>
              <button
                class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium border">
                Save
              </button>
            </div>
          </div>
          <div v-if="activetab === 'response'" class="" id="response" role="tabpanel" aria-labelledby="response">
            <div class="bg-light p-3 rounded">
              <h3 class="text-left font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">
                Work in Progress
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import instance from "../../axios-interceptor";
import {
  routers as _routers,
  routerTableHeaders as _routerTableHeaders,
  routerTableIcons as _routerTableIcons,
} from "../../helpers/stub_data/dashboard_routers";

export default defineComponent({
  name: "surveyQuestionsPage",
  components: {},

  data() {
    return {
      newQuestion: "",
      selectedOption: "Short Answer",
      survey_questions: [
        {
          id: "",
          text: "",
        },
      ],
      questions: [] as string[],
      isLoading: false,
      activetab: "questions",
    };
  },

  methods: {
    addQuestion() {
      if (this.newQuestion !== "") {
        this.questions.push(this.newQuestion);
        this.newQuestion = ""; // Clear the input field after adding the question
      }
    },
    // uploadQuestions() {
    // this.questions.forEach((new_question, index) => {
    //   new_question.survey = route.params.id;
    //   new_question.is_active = true;
    //   new_question.question_type = new_question.type;
    //   new_question.text = new_question.question;
    //   new_question.questions_data = JSON.stringify({
    //     options: [...new_question.options],
    //     rows: [...new_question.rows],
    //     columns: [...new_question.columns],
    //   });

    //   instance({
    //     method: "POST",
    //     url: "v1/survey-questions/",
    //     data: new_question,
    //   })
    //     .then(
    //       (response) => {
    //         if (response.status === 201) {
    //           this.$toast.error(`Questions added successfully`, {
    //             position: "top-right",
    //           });

    //           this.fetchSurveyQuestions(new_question.survey);
    //           this.new_questions.splice(index, 1);
    //           this.questions = [];
    //         }
    //       },
    //       (error) => {
    //         console.log(error.response);

    //         this.$toast.error(`There was an error while adding questions`, {
    //           position: "top-right",
    //         });
    //       }
    //     )
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // });

    //},
    fetchSurveyQuestions(id: any) {
      instance
        .get(`survey-questions/?survey=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.survey_questions = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response[0]}`, {
              position: "top-right",
            });
          }
        });
    },
  },
});
</script>
