import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-3" }
const _hoisted_2 = { class: "flex w-full justify-between items-center font-semibold" }
const _hoisted_3 = { class: "md:flex block items-center justify-between min-w-[145px] text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]" }
const _hoisted_4 = { class: "md:flex hidden" }
const _hoisted_5 = { class: "flex justify-end :md:justify-start" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "section"),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.pageNumber) + " - " + _toDisplayString(_ctx.totalPages) + " of " + _toDisplayString(_ctx.totalPages), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            disabled: _ctx.pageNumber === 1,
            class: "flex items-start justify-start mr-3",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlePrev()))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("span", { class: "mr-1 mt-0.4" }, "‹", -1),
            _createTextVNode(),
            _createElementVNode("span", null, "prev", -1)
          ]), 8, _hoisted_6),
          _createElementVNode("button", {
            disabled: _ctx.totalItems,
            class: "flex items-start justify-start",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleNext()))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("span", null, "next", -1),
            _createElementVNode("span", { class: "ml-1 mt-0.4" }, "›", -1)
          ]), 8, _hoisted_7)
        ])
      ])
    ])
  ]))
}