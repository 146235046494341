import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"
import _imports_0 from '../../assets/avatar.png'


const _hoisted_1 = { class: "w-full sm:flex items-center justify-between my-6" }
const _hoisted_2 = { class: "flex flex-col block md:hidden sm:mb-0 mb-2" }
const _hoisted_3 = { class: "min-w-[10vw] flex items-center relative" }
const _hoisted_4 = { class: "flex flex-col items-start justify-start mx-2" }
const _hoisted_5 = { class: "font-semibold text-[0.60rem] font-poppins text-black" }
const _hoisted_6 = { class: "flex flex-col md:block" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }
const _hoisted_10 = {
  key: 0,
  class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          src: _imports_0,
          alt: "africa",
          class: "w-[2rem] h-[2rem] rounded-[1rem]"
        }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-textLight font-medium text-[0.60rem] font-poppins" }, "Hello", -1)),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.full_name), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.iconType === 'image')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.icon,
              alt: "africa",
              class: "w-[1.1rem] h-[1.2rem] rounded mr-1"
            }, null, 8, _hoisted_8))
          : (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: _normalizeClass([_ctx.icon, "w-[1.1rem] h-[1.2rem] rounded mr-1"])
            }, null, 2)),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.title), 1)
      ]),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "aside")
  ]))
}