import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalizedLoaded,
} from 'vue-router';

import LoginPage from '../views/auth/Login.vue';
import LoginPageOTP from '../views/auth/LoginOTP.vue';
import SignupPage from '../views/auth/Signup.vue';
import ForgotPasswordPage from '../views/auth/ForgotPassword.vue';
import ChangePasswordPage from '../views/auth/ChangePassword.vue';
import PasswordResetPage from '../views/auth/PasswordReset.vue';
import DashboardPage from '../views/dashboard/Dashboard.vue';
import AccountInfoPage from '../views/Account/account-info.vue';
import VouchersPage from '../views/Vouchers/vouchers.vue';
import CaptivePortalPage from '../views/captive/captive-portal.vue';
import FileMediaPage from '../views/File/file-media.vue';
import SupportTicketsPage from '../views/support/support-tickets.vue';
import BillingsPage from '../views/billings/billings.vue';
import HomePage from '../views/home/home.vue';
import AnalyticsPage from '../views/analytics/analytics.vue';
import microFranchiseProfilePage from '../views/micro-Franchisees/mf-profiles.vue';
import kiosksPage from '../views/Kiosks management/Kiosks.vue';
import fieldOfficerPage from '../views/officers/fieldOfficers.vue';
import maintenanceOfficerPage from '../views/officers/maintenanceOfficers.vue';
import appStoresPage from '../views/app store/appStore.vue';
import realmsPage from '../views/realms/realms.vue';
import subscriptionPage from '../views/subscription/subscription.vue';
import subscriberPage from '../views/subscriber/subscriber.vue';

import { store } from '../store/index';
import advertPage from '../views/ads/ads.vue';
import surveysPage from '../views/surveys/surveys.vue';
import offlineFilesPage from '../views/offline files/offlineFiles.vue';
import smePage from '../views/SMEs/sme.vue';
import locationsClusterPage from '../views/locations/Index.vue';
import systemAdminPage from '../views/system admins/systemAdmins.vue';
import userFormPage from '../views/user signup/userform.vue';
import floatTransferPage from '../views/FloatTransfer/float-transfer.vue';
import ServicePage from '../views/Services/services.vue';
import realmsDetailPage from '../views/realms/realmDetails.vue';
import advertAddEditPage from '../views/ads/addEditForm.vue';
import surveyAddEditPage from '../views/surveys/addSurvey.vue';
import mediaAddEditPage from '../views/offline files/uploadMedia.vue';
import viewDetailsPage from '../views/system admins/view-details.vue';
import surveyDetailsPage from '../views/surveys/survey-details.vue';
import smeDetailsPage from '../views/SMEs/smeDetails.vue';
import surveyQuestionsPage from '../views/surveys/survey-questions.vue';
import microProfileDetailsPage from '../views/micro-Franchisees/microProfileDetails.vue';
import RouterDetailsPage from '../views/dashboard/routerDetails.vue';
import AdvertDetailsPage from '../views/ads/adsDetails.vue';
import ShirikiAddOn from '@/views/shiriki/AddOn.vue';
import ChartsPage from '@/views/chart.vue';
import AdViewsPage from '@/views/analytics/AdViews.vue';
import VueCharts from '@/views/vue-charts.vue';

const title = 'Ared PARTNER';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginPage,
    meta: {
      title: `${title} - Login`,
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: LoginPageOTP,
    meta: {
      title: `${title} - OTP`,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
    meta: {
      title: `${title} - SIGNUP`,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordPage,
    meta: {
      title: `${title} - Forgot Password`,
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordPage,
    meta: {
      title: `${title} - Change Password`,
      requireAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomePage,
    meta: {
      title: `${title} - Dashboard`,
      requireAuth: true,
    },
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: PasswordResetPage,
    meta: {
      title: `${title} - Password Reset`,
    },
  },
  {
    path: '/routers',
    name: 'routers',
    component: DashboardPage,
    meta: {
      title: `${title} - Routers`,
      requireAuth: true,
    },
  },
  {
    path: '/captive-portal',
    name: 'captive-portal',
    component: CaptivePortalPage,
    meta: {
      title: `${title} - CaptivePortal`,
      requireAuth: true,
    },
  },
  {
    path: '/shiriki-add-on',
    name: 'shiriki-add-on',
    component: ShirikiAddOn,
    meta: {
      title: `${title} - Shiriki Add-On`,
      requireAuth: true,
    },
  },
  {
    path: '/account-info',
    name: 'account-info',
    component: AccountInfoPage,
    meta: {
      title: `${title} - AccountInfo`,
      requireAuth: true,
    },
  },
  {
    path: '/vouchers',
    name: 'vouchers',
    component: VouchersPage,
    meta: {
      title: `${title} - Vouchers`,
      requireAuth: true,
    },
  },
  {
    path: '/charts',
    name: 'charts',
    component: ChartsPage,
    meta: {
      title: `${title} - Charts`,
      requireAuth: true,
    },
  },
  {
    path: '/vuecharts',
    name: 'vuecharts',
    component: VueCharts,
    meta: {
      title: `${title} - VueCharts`,
      requireAuth: true,
    },
  },
  {
    path: '/captive-portal',
    name: 'captive-portal',
    component: CaptivePortalPage,
    meta: {
      title: `${title} - CaptivePortal`,
      requireAuth: true,
    },
  },
  {
    path: '/file-media',
    name: 'file-media',
    component: FileMediaPage,
    meta: {
      title: `${title} - FileMedia`,
      requireAuth: true,
    },
  },
  {
    path: '/support-tickets',
    name: 'support-tickets',
    component: SupportTicketsPage,
    meta: {
      title: `${title} - SupportTickets`,
      requireAuth: true,
    },
  },
  {
    path: '/billings',
    name: 'billings',
    component: BillingsPage,
    meta: {
      title: `${title} - Billings`,
      requireAuth: true,
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: AnalyticsPage,
    meta: {
      title: `${title} - Analytics`,
      requireAuth: true,
    },
  },
  {
    path: '/micro-franchisees',
    name: 'micro-franchisees',
    component: microFranchiseProfilePage,
    meta: {
      title: `${title} - micro-franchisees`,
      requireAuth: true,
    },
  },
  {
    path: '/kiosks',
    name: 'kiosks',
    component: kiosksPage,
    meta: {
      title: `${title} - Kiosks`,
      requireAuth: true,
    },
  },
  {
    path: '/field-officers',
    name: 'field-officers',
    component: fieldOfficerPage,
    meta: {
      title: `${title} - Field-officers`,
      requireAuth: true,
    },
  },
  {
    path: '/maintenance-officers',
    name: 'maintenance-officers',
    component: maintenanceOfficerPage,
    meta: {
      title: `${title} - Maintenance-officers`,
      requireAuth: true,
    },
  },
  {
    path: '/apps',
    name: 'apps',
    component: appStoresPage,
    meta: {
      title: `${title} - Apps`,
      requireAuth: true,
    },
  },
  {
    path: '/realms',
    name: 'realms',
    component: realmsPage,
    meta: {
      title: `${title} - Realms`,
      requireAuth: true,
    },
  },
  {
    path: '/realms/:id/details',
    name: 'realms-details',
    component: realmsDetailPage,
    meta: {
      title: `${title} - Realms-details`,
      requireAuth: true,
    },
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: subscriptionPage,
    meta: {
      title: `${title} - Subscription`,
      requireAuth: true,
    },
  },
  {
    path: '/subscriber',
    name: 'subscriber',
    component: subscriberPage,
    meta: {
      title: `${title} - Subscriber`,
      requireAuth: true,
    },
  },
  {
    path: '/adverts',
    name: 'adverts',
    component: advertPage,
    meta: {
      title: `${title} - Adverts`,
      requireAuth: true,
    },
  },
  {
    path: '/advert-details/:id',
    name: 'advert-details',
    component: AdvertDetailsPage,
    meta: {
      title: `${title} - advert-details`,
      requireAuth: true,
    },
  },

  {
    path: '/add-advert/',
    name: 'add-advert',
    component: advertAddEditPage,
    meta: {
      title: `${title} - Advert`,
      requireAuth: true,
    },
  },
  {
    path: '/edit-advert/:id',
    name: 'edit-advert',
    component: advertAddEditPage,
    meta: {
      title: `${title} - Advert`,
      requireAuth: true,
    },
  },
  {
    path: '/surveys',
    name: 'surveys',
    component: surveysPage,
    meta: {
      title: `${title} - Surveys`,
      requireAuth: true,
    },
  },
  {
    path: '/survey',
    name: 'surveyAddEditPage',
    component: surveyAddEditPage,
    meta: {
      title: `${title} - Surveys`,
      requireAuth: true,
    },
  },
  {
    path: '/survey-details/:id',
    name: 'survey-details',
    component: surveyDetailsPage,
    meta: {
      title: `${title} - survey-details`,
      requireAuth: true,
    },
  },
  {
    path: '/survey-questions/:id',
    name: 'survey-questions',
    component: surveyQuestionsPage,
    meta: {
      title: `${title} - survey-questions`,
      requireAuth: true,
    },
  },
  {
    path: '/offline-files',
    name: 'offline-files',
    component: offlineFilesPage,
    meta: {
      title: `${title} - Offline-files`,
      requireAuth: true,
    },
  },
  {
    path: '/offline-files',
    name: 'mediaAdd',
    component: mediaAddEditPage,
    meta: {
      title: `${title} - Offline-files`,
      requireAuth: true,
    },
  },

  {
    path: '/smes',
    name: 'smes',
    component: smePage,
    meta: {
      title: `${title} - smes`,
      requireAuth: true,
    },
  },
  {
    path: '/smes/:id',
    name: 'sme-details',
    component: smeDetailsPage,
    meta: {
      title: `${title} - smesDetails`,
      requireAuth: true,
    },
  },
  {
    path: '/locations-cluster',
    name: 'locations-cluster',
    component: locationsClusterPage,
    meta: {
      title: `${title} - locations-cluster`,
      requireAuth: true,
    },
  },
  {
    path: '/system-admin',
    name: 'system-admin',
    component: systemAdminPage,
    meta: {
      title: `${title} - system-admin`,
      requireAuth: true,
    },
  },
  {
    path: '/view-details/:id',
    name: 'view-details',
    component: viewDetailsPage,
    meta: {
      title: `${title} - view-details`,
      requireAuth: true,
    },
  },
  {
    path: '/micro-profile-details/:id',
    name: 'micro-profile-details',
    component: microProfileDetailsPage,
    meta: {
      title: `${title} - micro-profile-details`,
      requireAuth: true,
    },
  },
  {
    path: '/router-details/:id',
    name: 'router-details',
    component: RouterDetailsPage,
    meta: {
      title: `${title} - router-details`,
      requireAuth: true,
    },
  },

  {
    path: '/user-signup',
    name: 'user-signup',
    component: userFormPage,
    meta: {
      title: `${title} - User-signup`,
      requireAuth: true,
    },
  },
  {
    path: '/user-details/:id',
    name: 'user-details',
    component: userFormPage,
    meta: {
      title: `${title} - User-details`,
      requireAuth: true,
    },
  },
  {
    path: '/float-transfer',
    name: 'float-transfer',
    component: floatTransferPage,
    meta: {
      title: `${title} - float-transfer`,
      requireAuth: true,
    },
  },
  {
    path: '/services',
    name: 'services',
    component: ServicePage,
    meta: {
      title: `${title} - services`,
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const titleFromParams = to.params?.pageTitle;
  const isAuthenticated = store.getters.isAuthenticated;

  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  } else {
    document.title = (to.meta?.title as string) ?? title;
  }

  if (
    to.matched.some((record) => record.meta.requireAuth) &&
    !isAuthenticated
  ) {
    store.commit('setLoginBtn', { text: 'Log In' });
    return next('/');
  }

  if (
    (to.path === '/' ||
      to.path === '/signup' ||
      to.path === '/forgot-password') &&
    isAuthenticated
  ) {
    return next('/dashboard');
  }

  next();
});

export default router;

export function useRoute(): RouteLocationNormalizedLoaded {
  return router.currentRoute.value;
}
