<template>
  <div class="row">
    <div class="col-md-12 animated slideInUp">
      <div class="card-body">
        <div class="w-full h-[500px] mt-6" id="viewmap"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import mapboxgl from 'mapbox-gl';

export default defineComponent({
  name: "AnalyticsMap",
  props: ['markers'],
  setup() {
    const accessToken = ref(
      'pk.eyJ1IjoiZ3MwMWhhbiIsImEiOiJjbGtjamU3dmEwN2pwM2pxam9taGZ0cTJ6In0.8_zydJrYKh6734GskrBRqA'
    );
    const fetchingRouters = ref(false);
    const mapStyle = ref({
      container: 'viewmap',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [30.0588, -1.95],
      zoom: 8,
    });

    return {
      accessToken,
      mapStyle,
      fetchingRouters
    }
  },
  watch: {
    markers: {
      handler() {
        this.initializeMap()
      }
    }
  },
  mounted() {
    this.initializeMap()
  },

  methods: {
    initializeMap() {
      mapboxgl.accessToken = this.accessToken;
      const map = new mapboxgl.Map(this.mapStyle);
      let cordsarray = JSON.parse(JSON.stringify(this.markers))
      cordsarray.map((m: any) => {
        new mapboxgl.Marker()
          .setLngLat(m)
          .addTo(map);
      })
      map.setCenter(cordsarray[0]);
    },
  }
})
</script>