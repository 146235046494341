<template>
  <button
    type="button"
    class="w-[6.3rem] flex items-center justify-center flex-col py-3 px-2 rounded-[0.438rem] mb-2"
    :class="[classes ? classes : '']" :style="isActive ? activeStyle : {}"
    @click="subMenus?.length > 0 ? toggleSubMenu : onSetActive(routePath)"
  >
    <i :class="icon" @click="toggleSubMenu" v-if="icon.includes('fa')"></i>

    <img
          v-else
          :src="icon"
          alt="africa"
          class="w-[1.188rem] h-[1.188rem] mb-1"
        />
    <span
      @click="toggleSubMenu"
      class="text-textDark font-semibold text-[10px] font-poppins mt-1"
      :style="isActive ? activeStyle : {}"
      >{{ title }}</span
    >
    <!-- Collapse icon -->
    <div @click="toggleSubMenu" class="">
      <svg
        v-if="subMenus?.length > 0 && showSubMenu"
        xmlns="http://www.w3.org/2000/svg"
        class="h-3 w-3"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        />
      </svg>
      <svg
        v-if="subMenus?.length > 0 && !showSubMenu"
        xmlns="http://www.w3.org/2000/svg"
        class="h-3 w-3"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 15l7-7 7 7"
        />
      </svg>
    </div>

    <!-- SUBMENUS -->
    <div
      v-show="showSubMenu && subMenus?.length > 0"
      class="mt-3 bg-[#f4f4f4] text-[] rounded-[5px] px-[9px] py-[7px] submenu"
    >
      <button
        v-for="subMenu in subMenus"
        :key="subMenu.id"
        type="button"
        class="w-[4.313rem] flex items-center justify-center flex-col p-2 rounded-[0.438rem] mb-1 last:border-b-0 focus:ring-transparent"
        :class="{ subselectedTab: activeMenuPath === subMenu.path }"
        @click="
          subMenu?.childSubMenus && subMenu.childSubMenus?.length > 0
            ? toggleSubMenu
            : onSetActive(subMenu.path)
        "
      >
        <i @click="toggleChildSubMenu" :class="subMenu.icon" v-if="icon.includes('fa')"></i>

        <img
          v-else
          :src="subMenu.icon"
          alt="africa"
          class="w-[1.188rem] h-[1.188rem] mb-1"
        />

        <span
          @click="toggleChildSubMenu"
          class="text-textDark font-semibold text-[10px] font-poppins mt-1 break-all"
        >
          {{ subMenu.title }}
        </span>
        <div @click="toggleChildSubMenu" class="">
          <svg
            v-if="
              subMenu?.childSubMenus &&
              subMenu.childSubMenus?.length > 0 &&
              showChild
            "
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          <svg
            v-if="
              subMenu?.childSubMenus &&
              subMenu.childSubMenus?.length > 0 &&
              !showChild
            "
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 15l7-7 7 7"
            />
          </svg>
        </div>
        <div
          v-show="
            showChild && subMenu?.childSubMenus && subMenu.childSubMenus.length
          "
          class="mt-3 bg-[#d9dada] rounded-[5px] px-[7px] py-[7px] sun-subMenu w-[4.8rem]"
        >
          <button
            v-for="childsubMenu in subMenu.childSubMenus"
            :key="childsubMenu.id"
            type="button"
            class="w-full flex items-center justify-center flex-col px-1 py-2 rounded-[0.438rem] mb-2 last:border-b-0"
            :class="{ selectedTab: activeChildMenuPath === childsubMenu.path }"
            @click="onSetchildActive(childsubMenu.path)"
          >
            <i :class="childsubMenu.icon"></i>

            <span
              class="font-semibold text-[10px] text-[#414354] font-poppins mt-1 break-all"
            >
              {{ childsubMenu.title }}
            </span>
          </button>
        </div>
      </button>
    </div>
  </button>
</template>

<script lang="ts">
import router from '@/router';
import { defineComponent, PropType } from 'vue';

import { DashboardMenu } from '@/interfaces/routes';

export default defineComponent({
  name: 'SidebarMenu',
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    routePath: {
      type: String as PropType<string>,
      required: true,
    },
    classes: {
      type: String as PropType<string>,
      required: false,
    },
    icon: {
      type: String as PropType<string>,
      required: true,
    },
    isActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    subMenus: {
      type: Array as PropType<DashboardMenu[]>,
      default: () => [],
    },
    childSubMenus: {
      type: Array as PropType<DashboardMenu[]>,
      default: () => [],
    },
  },
  data() {
    return {
      showChild: false,
      showSubMenu: false,
      activeMenuPath: '',
      activeChildMenuPath: '',
    };
  },
  methods: {
    onSetActive(path: string) {
      this.activeMenuPath = path;
      this.$emit('click');
      router.push(path);
    },
    toggleSubMenu() {
      this.showSubMenu = !this.showSubMenu;
    },
    toggleChildSubMenu() {
      this.showChild = !this.showChild;
    },
    onSetchildActive(path: string) {
      this.activeChildMenuPath = path;
      this.$emit('click');
      router.push(path);
    },
  },
  computed: {
    activeStyle() {
      return {
        backgroundColor: '#4E8D6D',
        color: '#FFFFFF'
      };
    }
  },
});
</script>
