<template>
  <section>
    <div class="controls" v-if="source !== 'homepage'">
      <select v-model="selectedFilter" @change="handleFilterChange" class="text-sm">
        <option value="today">Today</option>
        <option value="1week">1 Week</option>
        <option value="1month">1 Month</option>
        <option value="3months">3 Months</option>
        <option value="6months">6 Months</option>
        <option value="12months">12 Months</option>
        <option value="5years">5 Years</option>
      </select>
    </div>
    <div class="shadow-[0_0px_10px_0px_rgba(0,0,0,0.13)] rounded-xl p-6 bg-gray-100 bg-white my-6">
      <h5 class="mb-4 text-base font-bold">Router Management Summary</h5>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <CardDesign :loadingOne="loadingOne" :loadingTwo="loadingTwo" :data="{
          topic: 'Tokens Generated',
          icon: 'fa-solid fa-chart-line',
          figures: generatedTokens,
          color: '#D9F7E8',
          iconcolor: '#00B69B',
        }" />
        <CardDesign :loadingOne="loadingOne" :loadingTwo="loadingTwo" :data="{
          topic: 'Tokens Used',
          icon: 'fa-solid fa-users',
          figures: usedTokens,
          color: '#E5E4FF',
          iconcolor: '#8280FF',
        }" />
        <CardDesign :loadingOne="loadingOne" :loadingTwo="loadingTwo" :data="{
          topic: 'Currently Connected',
          icon: 'fa-solid fa-gem',
          figures: connectedUsers,
          color: '#FFF3D6',
          iconcolor: '#FEC53D',
        }" />
        <CardDesign :loadingOne="loadingOne" :loadingTwo="loadingTwo" :data="{
          topic: 'Average Duration',
          icon: 'fa-solid fa-hourglass-half',
          figures: averageDuration,
          iconcolor: '#FF9066',
          color: '#FFDED1'
        }" />
        <CardDesign :loadingOne="loadingOne" :loadingTwo="loadingTwo" v-if="source === 'analytics-page'" :data="{
          topic: 'EXPIRED TOKENS',
          icon: 'fa-solid fa-clock-rotate-left',
          figures: expiredTokens,
          color: '#FEE7EC',
          iconcolor: '#F93C65',
        }" />
        <CardDesign :loadingOne="loadingOne" :loadingTwo="loadingTwo" v-if="source === 'analytics-page'" :data="{
          topic: 'TOKENS FAILED',
          icon: 'fa-solid fa-bug',
          figures: failedTokens,
          color: '#FEE7EC',
          iconcolor: '#F93C65',
        }" />
      </div>
    </div>
    <div v-if="source !== 'homepage'">
      <div v-for="(chart, index) in charts" :key="index" class="chart-container">
        <div v-if="!loadingTwo">
          <apexchart ref="chart" height="350" :options="chart.options" :series="chart.series"></apexchart>
        </div>
        <div v-else>
          <p class="text-sm">Loading charts...</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApexCharts from 'vue3-apexcharts';
import CardDesign from "@/components/Common/CardDesign.vue";

export default {
  name: "TokenAnalyticsChart",
  components: {
    apexchart: ApexCharts,
    CardDesign,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    currentTimeRange: {
      type: String,
      default: '3months'
    },
    loadingOne: {
      type: Boolean,
      default: false
    },
    loadingTwo: {
      type: Boolean,
      default: false

    },
  },
  data() {
    return {
      usedTokens: null,
      generatedTokens: null,
      connectedUsers: null,
      averageDuration: null,
      failedTokens: null,
      expiredTokens: null,
      selectedFilter: this.currentTimeRange,
      charts: [
        {
          title: 'Tokens Used and Currently Connected',
          options: this.getChartOptions(false),
          series: []
        },
        {
          title: 'Tokens Generated and Expired Tokens',
          options: this.getChartOptions(false),
          series: []
        },
        {
          title: 'Failed Tokens and Average Duration',
          options: this.getChartOptions(true),
          series: []
        }
      ]
    }
  },
  inject: ['loadingTwo'],
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newData) {
        if (newData && newData[this.selectedFilter]) {
          if (this.loadingTwo) {
            this.resetCharts();
          } else {
            this.updateCharts(newData[this.selectedFilter]);
          }
        }

        console.log("TOKEN ANALYTICS DATA CHANGED: ", newData);
        console.log("PARTNER - TOKEN ANALYTICS CHART - LOADING ONE: ", this.loadingOne);
        console.log("PARTNER - TOKEN ANALYTICS CHART - LOADING TWO: ", this.loadingTwo);
      }
    },
    loadingTwo: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.resetCharts();
        } else if (this.data && this.data[this.selectedFilter]) {
          this.updateCharts(this.data[this.selectedFilter]);
        }

        console.log("LOADING TWO CHANGED: ", newValue);
        console.log("PARTNER - TOKEN ANALYTICS CHART - LOADING ONE: ", this.loadingOne);
        console.log("PARTNER - TOKEN ANALYTICS CHART - LOADING TWO: ", this.loadingTwo);
      }
    }
  },
  methods: {
    getChartOptions(includeDurationAxis) {
      let options = {
        title: {
          text: '',
          align: 'left'
        },
        subtitle: {
          text: '',
          align: 'left'
        },
        chart: {
          type: 'area',
          height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            formatter: (value) => this.formatXAxisLabel(value),
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: true,
          }
        },
        yaxis: [
          {
            title: {
              text: 'Count'
            }
          }
        ],
        tooltip: {
          y: {
            formatter: function (val, { seriesIndex, dataPointIndex, w }) {
              if (w.config.series[seriesIndex].name === 'Average Duration (minutes)') {
                return val.toFixed(2) + ' minutes';
              }
              return val.toFixed(0);
            }
          }
        }
      };
      if (includeDurationAxis) {
        options.yaxis.push({
          opposite: true,
          title: {
            text: 'Duration (minutes)'
          },
          decimalsInFloat: 2
        });
      }
      return options;
    },
    formatXAxisLabel(value) {
      switch (this.selectedFilter) {
        case 'today':
          return value; // Assuming it's already in the correct format for 'today'
        case '1week':
          return value; // Assuming it's already in the correct format for '1week'
        case '1month':
          return value; // Assuming it's already in the correct format for '1month'
        case '3months':
        case '6months':
        case '12months':
        case '5years':
          // For these cases, the value is already in "MMM YYYY" format
          return value;
        default:
          return value;
      }
    },
    handleFilterChange() {
      this.$emit("filterChange", this.selectedFilter);
    },
    updateCharts(data) {
      if (this.loadingTwo) {
        this.resetCharts();
        return;
      }

      this.usedTokens = data.series[0].total;
      this.generatedTokens = data.series[2].total;
      this.connectedUsers = data.series[1].total;
      this.averageDuration = data.series[3].total;
      this.failedTokens = data.series[4].total;
      this.expiredTokens = data.series[5].total;

      const startDate = data.categories[0];
      const endDate = data.categories[data.categories.length - 1];

      const chartTitles = [
        'Tokens Used and Currently Connected',
        'Tokens Generated and Expired Tokens',
        'Failed Tokens and Average Duration'
      ];

      this.charts.forEach((chart, index) => {
        chart.options.xaxis.categories = data.categories;

        // Adjust x-axis label display
        chart.options.xaxis.labels.rotate = 0;
        chart.options.xaxis.labels.rotateAlways = false;

        if (index === 2) {
          chart.series = [
            { name: 'Failed Tokens', data: data.series[4].data },
            {
              name: 'Average Duration (minutes)',
              data: data.series[3].data.map(duration => {
                let totalMinutes = 0;

                if (duration.includes("day")) {
                  const [daysPart, timePart] = duration.split(", ");
                  const days = parseInt(daysPart.split(" ")[0]);
                  const [hours, minutes, seconds] = timePart.split(':').map(Number);
                  totalMinutes = (days * 24 * 60) + (hours * 60) + minutes + (seconds / 60);
                } else {
                  const [hours, minutes, seconds] = duration.split(':').map(Number);
                  totalMinutes = (hours * 60) + minutes + (seconds / 60);
                }

                return Number(totalMinutes.toFixed(2));
              })
            }
          ];
        } else {
          chart.series = [
            { name: chartTitles[index].split(' and ')[0], data: data.series[index * 2].data },
            { name: chartTitles[index].split(' and ')[1], data: data.series[index * 2 + 1].data }
          ];
        }

        chart.options.title.text = chartTitles[index];
        chart.options.subtitle.text = `From ${startDate} to ${endDate}`;
      });
    },
    resetCharts() {
      this.charts.forEach(chart => {
        chart.series = [];
        chart.options.title.text = '';
        chart.options.subtitle.text = '';
        chart.options.xaxis.categories = [];
      });
    }
  }
}
</script>

<style scoped>
.chart-container {
  margin-bottom: 30px;
}

.controls {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.controls select {
  padding: 10px;
  cursor: pointer;
}
</style>