import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, vModelSelect as _vModelSelect, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "quest-block" }
const _hoisted_2 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative" }
const _hoisted_3 = {
  class: "tabs-block mt-6 mb-6",
  id: "tabs-block"
}
const _hoisted_4 = { class: "" }
const _hoisted_5 = {
  class: "flex flex-wrap -mb-px text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_6 = { class: "mr-2" }
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { id: "TabContent" }
const _hoisted_9 = {
  key: 0,
  class: "",
  id: "questions",
  role: "tabpanel",
  "aria-labelledby": "questions"
}
const _hoisted_10 = { class: "form-group flex justify-between mt-4" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  id: "message",
  rows: "2",
  class: "w-2/5 mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1 mr-2",
  placeholder: "Write your thoughts here..."
}
const _hoisted_14 = { class: "mt-5 text-end" }
const _hoisted_15 = {
  key: 1,
  class: "",
  id: "response",
  role: "tabpanel",
  "aria-labelledby": "response"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_modal = _resolveDirective("modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "sm:flex my-6 justify-between" }, [
      _createElementVNode("div", { class: "flex items-center sm:mb-0 mb-2" }, [
        _createElementVNode("i", { class: "fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2" }),
        _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, "Survey Questions")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", _hoisted_6, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activetab === 'questions'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                  id: "questions",
                  "data-tabs-target": "#questions",
                  type: "button",
                  role: "tab",
                  "aria-controls": "questions",
                  "aria-selected": "false",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activetab = 'questions'))
                }, " Questions ", 2)
              ]),
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [
                  _ctx.activetab === 'response'
                    ? 'bg-light text-[#4e8d6e]'
                    : 'bg-[transparent]',
                ]]),
                  id: "response",
                  "data-tabs-target": "#response",
                  type: "button",
                  role: "tab",
                  "aria-controls": "response",
                  "aria-selected": "false",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activetab = 'response'))
                }, " Response ", 2)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.activetab === 'questions')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: question,
                      class: "bg-light mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1 mb-1 text-sm rounded"
                    }, _toDisplayString(question), 1))
                  }), 128)),
                  _createElementVNode("div", _hoisted_10, [
                    (_ctx.selectedOption === 'Short Answer')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newQuestion) = $event)),
                            class: "w-2/5 mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1 mr-2",
                            placeholder: "Enter Your Question",
                            type: "text",
                            name: "search"
                          }, null, 512), [
                            [_vModelText, _ctx.newQuestion]
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedOption === 'Paragraph')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _withDirectives(_createElementVNode("textarea", _hoisted_13, null, 512), [
                            [_directive_modal, _ctx.newQuestion]
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedOption) = $event)),
                      id: "adType",
                      type: "name",
                      class: "max-w-[300px] ml-auto focus:outline-none w-full text-[14px] w-6/12 h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                    }, _cache[5] || (_cache[5] = [
                      _createStaticVNode("<option value=\"Short Answer\" selected>Short answer</option><option value=\"Paragraph\">Paragragh</option><option value=\"Multiple choice\">Multiple choice</option><option value=\"Check Box\">Checkboxes</option><option value=\"Dropdown\">Dropdown</option><option value=\"Multiple choice grid\">Multiple choice grid </option>", 6)
                    ]), 512), [
                      [_vModelSelect, _ctx.selectedOption]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("button", {
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.addQuestion && _ctx.addQuestion(...args))),
                      class: "bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
                    }, " Add "),
                    _cache[6] || (_cache[6] = _createElementVNode("button", { class: "bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium border" }, " Save ", -1))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.activetab === 'response')
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[7] || (_cache[7] = [
                  _createElementVNode("div", { class: "bg-light p-3 rounded" }, [
                    _createElementVNode("h3", { class: "text-left font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor" }, " Work in Progress ")
                  ], -1)
                ])))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 64))
}