import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative" }
const _hoisted_2 = {
  class: "tabs-block",
  id: "tabs-block"
}
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  class: "flex flex-wrap -mb-px text-sm font-medium text-center mb-2",
  id: "myTab",
  "data-tabs-toggle": "#myTabContent",
  role: "tablist"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { id: "myTabContent" }
const _hoisted_7 = { class: "bg-light sm:p-5 p-4 rounded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_internetSettings = _resolveComponent("internetSettings")!
  const _component_AggregatorsList = _resolveComponent("AggregatorsList")!
  const _component_Announcements = _resolveComponent("Announcements")!
  const _component_APIKeys = _resolveComponent("APIKeys")!
  const _component_LeasingFees = _resolveComponent("LeasingFees")!
  const _component_ServiceSettings = _resolveComponent("ServiceSettings")!
  const _component_CreditFees = _resolveComponent("CreditFees")!
  const _component_PaymentModes = _resolveComponent("PaymentModes")!
  const _component_MFPenalties = _resolveComponent("MFPenalties")!
  const _component_ServiceFees = _resolveComponent("ServiceFees")!
  const _component_TaxConfiguration = _resolveComponent("TaxConfiguration")!
  const _component_InternetProfitCalculatorForm = _resolveComponent("InternetProfitCalculatorForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-col md:block hidden my-6" }, [
      _createElementVNode("div", { class: "flex items-center" }, [
        _createElementVNode("i", { class: "fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2" }),
        _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor" }, "Services")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "mr-2",
                key: tab
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass(["inline-block p-4 py-2 rounded-md w-full", [_ctx.activeTab === tab ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']]),
                  id: "tab1",
                  "data-tabs-target": "#profile",
                  type: "button",
                  role: "tab",
                  "aria-controls": "profile",
                  "aria-selected": "false",
                  onClick: ($event: any) => (_ctx.setActiveTab(tab))
                }, _toDisplayString(tab.replaceAll('_', ' ')), 11, _hoisted_5)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.activeTab === 'Internet Setting')
              ? (_openBlock(), _createBlock(_component_internetSettings, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'Aggregators')
              ? (_openBlock(), _createBlock(_component_AggregatorsList, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'Announcements')
              ? (_openBlock(), _createBlock(_component_Announcements, { key: 2 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'API_Keys')
              ? (_openBlock(), _createBlock(_component_APIKeys, { key: 3 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'Leasing_Fees')
              ? (_openBlock(), _createBlock(_component_LeasingFees, { key: 4 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'Service_Settings')
              ? (_openBlock(), _createBlock(_component_ServiceSettings, { key: 5 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'Credit_Fees')
              ? (_openBlock(), _createBlock(_component_CreditFees, { key: 6 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'Payment_Mode')
              ? (_openBlock(), _createBlock(_component_PaymentModes, { key: 7 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'MF_Penalty')
              ? (_openBlock(), _createBlock(_component_MFPenalties, { key: 8 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'Service_Fees')
              ? (_openBlock(), _createBlock(_component_ServiceFees, { key: 9 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 'Tax_Configuration')
              ? (_openBlock(), _createBlock(_component_TaxConfiguration, { key: 10 }))
              : _createCommentVNode("", true),
            (_ctx.activeTab == 'Internet Setting' && _ctx.plan === 'Premium')
              ? (_openBlock(), _createBlock(_component_InternetProfitCalculatorForm, { key: 11 }))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 64))
}