<template>
  <div class="flex flex-col md:block hidden my-6">
    <div class="flex items-center">
      <i class="fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2"></i>
      <span
        class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
        >Realms Details</span
      >
    </div>
  </div>

  <div
    class="bg-white w-full rounded-[0.938rem] py-3 md:py-6 md:px-6 px-3 shadow overflow-auto relative"
  >
    <div class="tabs-block" id="tabs-block">
      <div class="mb-3">
        <ul
          class="flex flex-wrap -mb-px text-sm font-medium text-center mb-2"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          <li class="mr-2">
            <button
              class="inline-block p-4 py-2 rounded-md w-full"
              id="profile-tab"
              data-tabs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              v-on:click="handleSetActiveTab('realm')"
              v-bind:class="[
                activeTab === 'realm'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]"
            >
              Realms Details
            </button>
          </li>
          <li class="mr-2">
            <button
              class="inline-block p-4 py-2 rounded-md w-full"
              id="dashboard-tab"
              data-tabs-target="#dashboard"
              type="button"
              role="tab"
              aria-controls="dashboard"
              aria-selected="false"
              v-on:click="handleSetActiveTab('vouchers')"
              v-bind:class="[
                activeTab === 'vouchers'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]"
            >
              Vouchers
            </button>
          </li>
          <li class="mr-2">
            <button
              class="inline-block p-4 py-2 rounded-md w-full"
              id="profile-tab"
              data-tabs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              v-on:click="handleSetActiveTab('adverts')"
              v-bind:class="[
                activeTab === 'adverts'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]"
            >
              Adverts
            </button>
          </li>
          <li class="mr-2">
            <button
              class="inline-block p-4 py-2 rounded-md w-full"
              id="profile-tab"
              data-tabs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              v-on:click="handleSetActiveTab('files')"
              v-bind:class="[
                activeTab === 'files'
                  ? 'bg-light text-[#4e8d6e]'
                  : 'bg-[transparent]',
              ]"
            >
              Offline Files
            </button>
          </li>
        </ul>
      </div>
      <div id="myTabContent">
        <div v-if="activeTab === 'realm'">
          <div
            class="bg-light w-full rounded-[0.938rem] py-4 md:px-6 px-3 shadow overflow-auto relative my-6"
          >
            <h4 class="flex justify-center font-bold uppercase mb-4">
              {{ realm.name }}
            </h4>
            <div class="table-responsive w-full">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                    >
                      Name
                    </th>
                    <th
                      class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                    >
                      Type
                    </th>
                    <th
                      v-if="realm.sme"
                      class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                    >
                      SME
                    </th>
                    <th
                      class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                    >
                      No. of Routers
                    </th>
                    <th
                      class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                    >
                      Created
                    </th>
                    <th
                      v-if="loggedInUser.role === adminRole"
                      class="px-3 py-1 text-left text-xs font-bold font-poppins text-gray-500"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 border-[#eaeaee]">
                  <tr>
                    <td
                      class="px-3 py-1 whitespace-nowrap font-poppins text-xs"
                    >
                      {{ realm.name }}
                    </td>
                     <td
                      class="px-3 py-1 whitespace-nowrap font-poppins text-xs"
                    >
                      {{ realm.type }}
                    </td>
                    <td
                      @click="smeDetails(realm.sme.id)"
                      v-if="realm.sme"
                      class="px-3 py-1 text-[#4e8d6e] cursor-pointer whitespace-nowrap font-poppins text-xs"
                    >
                      {{ realm.sme.name }}
                    </td>

                    <td
                      class="px-3 py-1 whitespace-nowrap font-poppins text-xs"
                    >
                      {{ realm.no_of_routers }}
                    </td>
                    <td
                      class="px-3 py-1 whitespace-nowrap font-poppins text-xs"
                    >
                      {{ new Date(realm.created).toLocaleString() }}
                    </td>
                    <td
                      v-if="loggedInUser.role === adminRole"
                      class="px-3 py-1 whitespace-nowrap font-poppins text-xs"
                    >
                      <button
                        type="button"
                        @click="showModal = true"
                        class="font-poppins bg-primary px-4 py-2 text-xs block font-medium rounded-[0.4rem] focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
                      >
                        Edit
                      </button>
                      <editrealmForm
                        :id="realm.id"
                        v-if="showModal"
                        @close="closeModal"
                      >
                      </editrealmForm>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="bg-light w-full rounded-[0.938rem] py-4 md:px-6 px-3 shadow overflow-auto relative"
          >
            <div class="sm:flex items-center justify-between">
              <div class="sm:mb-0 mb-2">
                <span
                  class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
                >
                  {{ changeName ? 'Assign Realm Routers' : 'Realm Routers' }}
                </span>
              </div>
              <div class="">
                <button
                  type="button"
                  class="font-poppins font-semibold text-[0.8rem] text-[#fff] flex items-center bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem]"
                  @click="changeBtn"
                >
                  <img
                    src="../../assets/plus-icon.png"
                    class="w-[0.6rem] h-[0.6rem] mr-1"
                    alt=""
                  />
                  {{ changeName ? 'Add Realm Routers' : 'Add Routers' }}
                </button>
              </div>
            </div>
            <div class="md:w-[100%] w-[50%] mb-2 mt-4">
              <label class="relative block">
                <span class="sr-only">Search</span>
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                  <img
                    src="../../assets/search-icon.png"
                    alt=""
                    class="w-[0.8rem] h-[0.8rem]"
                  />
                </span>
                <input
                  v-model="searchQuery"
                  class="bg-white font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                  placeholder="Search"
                  type="text"
                  name="search"
                />
              </label>
            </div>
            <div
              class="p-3 flex items-center justify-between md:w-[100%] w-[50%]"
              v-if="showButtons()"
            >
              <div>
                <span class="me-2 text-[#d3aa3b] font-bold text-sm"
                  >{{ rowsSelected }} Rows selected</span
                >
                <span
                  class="cursor-pointer text-[#5e72e4] font-bold text-sm"
                  @click="clearRows"
                  >clear</span
                >
              </div>
              <div>
                <button
                  type="button"
                  class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                  @click="unallocateRoutersFromSme"
                >
                  unassign SME router
                </button>
                <button
                  type="button"
                  class="capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                  @click="unallocateRoutersFromPartner"
                >
                  unassign Partner router
                </button>
              </div>
            </div>
            <SmeTable
              :sorting="true"
              @sort="sortBy"
              :inputShow="true"
              :selectAllValues="selectAll"
              @selectAllRows="selectAllRows"
              :tableName="'Realm Routers'"
              :tableHeaderData="accountsTableHeader"
            >
              <template v-slot:tableBodyData>
                <span
                  v-if="!paginatedTableData.length"
                  class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
                >
                  No records
                </span>

                <div
                  v-else
                  v-for="item in paginatedTableData"
                  :key="item.id"
                  class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                >
                  <td>
                    <div class="flex items-center">
                      <div class="flex flex-col ml-3">
                        <input
                          v-model="item.selected"
                          @change="updateSelectedRows(item)"
                          type="checkbox"
                          class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center">
                      <div class="flex flex-col ml-3">
                        <span
                          class="b-avatar-img capitalize text-[#464E5F] mb-0.5"
                          >{{ item.serial_number }}</span
                        >
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.active ? 'online' : 'offline'
                      }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.imei
                      }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.deployed_to
                      }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="flex flex-col">
                      <span class="capitalize text-[#464E5F] mb-0.5">{{
                        item.created
                      }}</span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <span
                        class="capitalize px-2 py-1 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center cursor-pointer"
                        @click="viewDetails(item.id)"
                        >view</span
                      >
                    </div>
                  </td>
                </div>
              </template>
            </SmeTable>
            <div class="spinner-container">
              <div v-if="isLoading" class="spinner"></div>
            </div>
            <DashboardTableTitle
              v-if="propLoaded"
              :perPageItems="perSize"
              :pageNumber="page"
              :ApiData="apiData"
              @handleNext="handlleNext"
              @handlePrev="handlePrev"
            >
              <template v-slot:section>
                <div>
                  <div class="flex items-center">
                    <span
                      class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]"
                      >Rows per page</span
                    ><span
                      class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]"
                    >
                      <select v-model="selectedOption" @change="handleChange">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select></span
                    >
                  </div>
                </div>
              </template>
            </DashboardTableTitle>
          </div>
        </div>
        <div v-if="activeTab === 'vouchers'">
          <realmVouchers :voucherId="$route.params.id"></realmVouchers>
        </div>
        <div v-if="activeTab === 'adverts'">
          <realmAdverts :voucherId="$route.params.id"></realmAdverts>
        </div>

        <div v-if="activeTab === 'files'">
          <realmOfflineFiles :voucherId="$route.params.id"></realmOfflineFiles>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script lang="ts">
import router, { useRoute } from '@/router';

import { defineComponent, ref } from 'vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';
import { mapGetters } from 'vuex';
import instance from '../../axios-interceptor';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import SmeTable from '../../components/reusable/SmeTable.vue';
import moment from 'moment';
import editrealmForm from './editaddRealm.vue';
import realmVouchers from './realmVouhers.vue';
import realmAdverts from './realmAdverts.vue';
import realmOfflineFiles from './realmOfflineFiles.vue';
export default defineComponent({
  name: 'realmsDetailPage',
  components: {
    DashboardTableTitle,
    SmeTable,
    editrealmForm,
    realmVouchers,
    realmAdverts,
    realmOfflineFiles,
  },
  setup() {
    // const mode = ref(4);
    const pageNumber = ref(1);
    const perPageItems = ref(5);
    const startIndex = ref(0);

    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Ticket ID');

    const center = ref({ lat: -1.8899577, lng: 30.0634073 });

    const accountsTableHeader = ref([
      'Serial Number',
      'Status',
      'IMEI',
      'Assignment Type',
      'Added',
      'Action',
    ]);

    const selectedSubscription = ref(false);

    return {
      center,

      tableName,
      icon,

      selectedSubscription,

      pageNumber,
      perPageItems,
      startIndex,

      accountsTableHeader,
    };
  },
  data() {
    return {
      showModal: false,
      realm: {
        id: '',
        created: '',
        no_of_routers: '',
        sme: {
          name: '',
          id: '',
        },
        name: '',
        type: '',
      },
      filteredRouters: [
        {
          id: '',
          created: '',
          selected: false,
          deployed_to: '',
          imei: '',
          serial_number: '',
          status: '',
          active: '',
        },
      ],
      changeName: false,
      rowsSelected: 0,
      selectAll: false,
      selectedRows: [] as string[],
      page: 1,
      perSize: 10,
      sortKey: '', // Sorting key
      sortDirection: 'asc',
      searchQuery: '',
      propLoaded: false,
      selectedOption: 10,
      apiData: [],
      isLoading: false,
      activeTab: 'partner',
      filteredMicroFranchisees: [],
      adminRole: 'partner_admin',
      totalMicroFranchisees: null,
      baseUserUrl: '',
    };
  },

  methods: {
    viewDetails(id: any) {
      router.push({ name: 'router-details', params: { id: id } });
    },
    closeModal() {
      const route = useRoute();
      this.showModal = false;
      this.getRealm(route.params.id);
      this.getRouters(route.params.id);
    },

    smeDetails(id: any) {
      router.push({ name: 'sme-details', params: { id: id } });
    },
    changeBtn() {
      const route = useRoute();
      this.changeName = !this.changeName;
      if (this.changeName) {
        this.getUnassignedRouters();
      } else {
        this.getRouters(route.params.id);
      }
    },
    selectAllRows(checked: any) {
      this.selectAll = checked;
      this.filteredRouters.forEach((row) => {
        row.selected = this.selectAll;
      });
      this.rowsSelected = this.paginatedTableData.length;
    },
    sortBy(key: string) {
      let currentKey;
      if (key === 'Serial number') {
        currentKey = 'serial_number';
      } else if (key === 'Assignment Type') {
        currentKey = 'deployed_to';
      } else if (key === 'Status') {
        currentKey = 'active';
      } else if (key === 'IMEI') {
        currentKey = 'imei';
      } else {
        currentKey = 'created';
      }
      if (this.sortKey === currentKey) {
        // Toggle the sort direction if the same key is clicked again
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = currentKey;
        this.sortDirection = 'asc';
      }
    },
    updateSelectedRows(row: any) {
      if (!row.selected) {
        this.selectAll = false;
      } else {
        const selectedRows = this.filteredRouters.filter((row) => row.selected);
        this.rowsSelected = selectedRows.length;
        this.selectAll = selectedRows.length === this.filteredRouters.length;
      }
    },
    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('M/D/YYYY, h:mm:ss a');
      }
    },
    handlePrev() {
      this.page--;
      this.getRouters(this.activeTab);
    },
    handlleNext() {
      this.page++;
      this.getRouters(this.activeTab);
    },
    handleChange() {
      this.perSize = this.selectedOption;
      this.getRouters(this.activeTab);
    },

    handleSetActiveTab(tab: string) {
      this.activeTab = tab;
      if (tab === 'realm') {
        // const baseUrl = `realms/?partner=${this.loggedInUser.partner.id}`;
        // this.getRouters(baseUrl);
      } else if (tab === 'vouchers') {
        // const baseUrl = `realms/?sme__partner=${this.loggedInUser.partner.id}`;
        // this.getRouters(baseUrl);
      } else if (tab === 'adverts') {
        // const baseUrl = `realms/?sme__partner=${this.loggedInUser.partner.id}`;
        // this.getRouters(baseUrl);
      } else {
        // const baseUrl = `realms/?sme__partner=${this.loggedInUser.partner.id}`;
        // this.getRouters(baseUrl);
      }
    },
    unallocateRoutersFromSme() {
      const route = useRoute();
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const assignSmeRoutersUrl = 'routers/assign-to-sme/';
      const data = {
        routers: selectedRouters,
        sme: route.params.id,
      };
      instance
        .patch(assignSmeRoutersUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.getRouters(this.activeTab);
            this.$toast.success(response.data.detail, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unallocateRoutersFromPartner() {
      const selectedRouters = this.paginatedTableData.filter(
        (row) => row.selected
      );
      const unassignRoutersFromPartnerUrl = 'routers/assign-to-partner/';
      const data = {
        routers: selectedRouters,
      };
      instance
        .patch(unassignRoutersFromPartnerUrl, data)
        .then((response) => {
          if (response.status === 200) {
            this.getRouters(this.activeTab);
            this.$toast.success(`Router removed`, {
              position: 'top-right',
            });
          } else if (response.status === 206) {
            this.$toast.error(response.data.detail, {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearRows() {
      this.paginatedTableData.filter((row) => (row.selected = false));
      this.selectAll = false;
    },
    showButtons() {
      return this.paginatedTableData.some((row) => row.selected === true);
    },
    async getRealm(id: any) {
      await instance({
        url: `realms/${id}/`,
      })
        .then((response) => {
          if (response.status === 200) {
            this.realm = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            // miniToastr.error("realm not found");
            console.log('realm not found');
          }
        });
    },
    async getRouters(id: string | string[]) {
      this.isLoading = true;
      await instance.get(`routers/?realm=${id}`).then((response) => {
        if (response.status === 200) {
          this.isLoading = false;
          this.propLoaded = true;
          this.filteredRouters = response.data.results.map((row: any) => ({
            ...row,
            selected: false,
            status: row?.active ? 'online' : 'offline',
          }));
        }
      });
    },
    async getUnassignedRouters() {
      this.isLoading = true;
      await instance
        .get(`routers/?realm=&&partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.propLoaded = true;
            this.filteredRouters = response.data.results;
          }
        });
    },
  },
  computed: {
    ...mapGetters(['loggedInUser']),

    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.filteredRouters;
      }

      // Filter table data based on search query
      const filteredData = this.filteredRouters.filter((item) => {
        // Convert item values to lowercase for case-insensitive search
        const serial_number = item.serial_number.toString().toLowerCase();
        const imei = item.imei.toString().toLowerCase();
        const active = item?.status.toString().toLowerCase();
        const deployed_to = item?.deployed_to.toString().toLowerCase();
        const created = this.createdAt(item.created.toString().toLowerCase());
        const query = this.searchQuery.toLowerCase();

        //Check if name or age contain the search query
        return (
          serial_number?.includes(query) ||
          imei?.includes(query) ||
          active?.includes(query) ||
          deployed_to?.includes(query) ||
          created?.includes(query)
        );
      });

      return filteredData;
    },

    sortedTableData(sortKey: any): any[] {
      if (!this.sortKey) {
        // If sort key is empty, return the filtered data as-is
        return this.filteredTableData;
      }

      // Sort the filtered table data based on the sort key and direction
      const sortedData = this.filteredTableData.slice().sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];

        // Perform the actual comparison based on the data type
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return (
            aValue.localeCompare(bValue) *
            (this.sortDirection === 'asc' ? 1 : -1)
          );
        } else {
          return (aValue - bValue) * (this.sortDirection === 'asc' ? 1 : -1);
        }
      });

      return sortedData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.sortedTableData.slice(startIndex, endIndex);
    },
  },
  mounted() {
    const route = useRoute();
    this.handleSetActiveTab('realm');
    this.getRealm(route.params.id);
    this.getRouters(route.params.id);
  },
});
</script>