<template>
    <div class="chart-container">
        <Pie :data="chartData" :options="chartOptions" />
    </div>
</template>

<script>
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default {
    name: 'PieChart',
    components: { Pie },
    data() {
        return {
            chartData: {
                labels: ['Green', 'Purple', 'Orange'],
                datasets: [
                    {
                        label: 'Pie Dataset',
                        data: [150, 200, 50],
                        backgroundColor: ['#4BC0C0', '#9966FF', '#FF9F40'],
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            },
        };
    },
};
</script>

<style scoped>
.chart-container {
    width: 100%;
    max-width: 800px;
    height: 600px;
}
</style>