
<template>
  <div class="bg-light sm:p-5 p-4 rounded">
    <h3 class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
      Micro Franchisee Credit Approval
    </h3>
    <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto relative">
      <div class="md:w-[100%] w-[50%] mb-2 md:mt-4 mt-1">
        <label class="relative block">
          <span class="sr-only">Search</span>
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <img src="../../assets/search-icon.png" alt="" class="w-[0.8rem] h-[0.8rem]" />
          </span>
          <input v-model="searchQuery"
            class="font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
            placeholder="Search" type="text" name="search" />
        </label>
      </div>
      <!-- <div class="p-3 flex items-center justify-between md:w-[100%] w-[50%]" v-if="showButtons()">
            <div>
              <span class="me-2 text-[#d3aa3b] font-bold text-sm">{{ rowsSelected }} Rows selected</span>
              <span class="cursor-pointer text-[#5e72e4] font-bold text-sm" @click="clearRows">clear</span>
            </div>
            <div>
              <button type="button"
                class="me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                @click="unallocateRoutersFromSme">
                unassign SME router
              </button>
              <button type="button"
                class="capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
                @click="unallocateRoutersFromPartner">
                unassign Partner router
              </button>
            </div>
          </div> -->
      <SmeTable :tableName="'Commission Payments'" :tableHeaderData="accountsTableHeader
        ">
        <template v-slot:tableBodyData>
          <span v-if="!commissionPayments.length" class="capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap">
            No records
          </span>
          <div v-else v-for="item in commissionPayments" :key="item.id"
            class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">

            <td>
              <div class="flex items-center">
                <div class="flex flex-col ml-3">
                  <span class="capitalize text-[#464E5F] mb-0.5"></span>
                </div>
              </div>
            </td>

            <td>
              <div class="flex flex-col">
                <span class="capitalize text-[#464E5F] mb-0.5"></span>
              </div>
            </td>
            <td>
              <div class="flex flex-col">
                <span class="capitalize text-[#464E5F] mb-0.5"></span>
              </div>
            </td>
            <td>
              <div class="flex flex-col">
                <span class="capitalize text-[#464E5F] mb-0.5"></span>
              </div>
            </td>
            <td>
              <div>
                <span
                  class="capitalize p-1 py-1 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center">view</span>
              </div>
            </td>

          </div>
        </template>
      </SmeTable>
      <DashboardTableTitle :perPageItems="perSize" :pageNumber="page" :ApiData="apiData" @handleNext="handlleNext"
        @handlePrev="handlePrev">
        <template v-slot:section>
          <div>
            <div class="flex items-center">
              <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                <select v-model="selectedOption" @change="handleChange">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select></span>
            </div>
          </div>
        </template>
      </DashboardTableTitle>
      <div v-if="isLoading" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import DashboardTableTitle from "../../components/markup/DashboardTableTitle.vue";
import SmeTable from "../../components/reusable/SmeTable.vue";

export default {

  components: { SmeTable, DashboardTableTitle },
  setup() {
    // const mode = ref(4);


    const tableName = ref("Ticket ID");


    const accountsTableHeader = ref([
      "Date",
      "Name",
      "Phone",
      "tin",
      "Credit Request",
      "Action"
    ]);



    return {

      tableName,

      accountsTableHeader,
    };
  },
  data() {
    return {
      page: 1,
      perSize: 10,
      propLoaded: true,

      selectedOption: 10,
      apiData: [],
      commissionPayments: [],
    }
  },
  methods: {

    handlePrev() {
      this.page--;

    },
    handlleNext() {
      this.page++;

    },


    handleChange() {
      this.perSize = this.selectedOption;
      this.getRouterData(this.activeTab);
    },


  },
}
</script>