<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-1" alt="" />
      </button>
      <div class="row" style="max-height: 90vh; overflow-y: scroll;">
        <div class="col-md-12 mx-auto">
          <h3 v-if="!sme"
            class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
            Register SME
          </h3>
          <h3 v-else
            class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
            Update SME
          </h3>
          <form>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">SME Name:
                *</label>
              <input placeholder="Enter  name" v-model="name" v-validate="'required'" required type="text"
                id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />

              <small v-if="responseErrors.name" class="text-[#f24236]">{{
        responseErrors.name[0]
      }}</small>
            </div>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">SME TIN Number:
                *</label>
              <input placeholder="Enter TIN number" v-model="tin_number" v-validate="'required'" required type="text"
                id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />

              <small v-if="responseErrors.tin_number" class="text-[#f24236]">{{
        responseErrors.tin_number[0]
      }}</small>
            </div>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">City: *</label>
              <input v-model="location" placeholder="Enter city" type="text" v-validate="'required'" min="5"
                id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
              <small v-if="responseErrors.location" class="text-[#f24236]">{{
        responseErrors.location[0]
      }}</small>
            </div>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Admin Name:
                *</label>
              <input v-model="admin_name" placeholder="Enter admin name" type="text" v-validate="'required'"
                id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
              <small v-if="responseErrors.admin_name" class="text-[#f24236]">{{
        responseErrors.admin_name[0]
      }}</small>
            </div>

            <div class="mb-2">
              <label for="small-input" v-validate="'required'"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Admin Email:
                *</label>
              <input v-model="admin_email" placeholder="Enter admin email" type="text" min="5" id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
              <small v-if="responseErrors.admin_email" class="text-[#f24236]">{{
        responseErrors.admin_email[0]
      }}</small>
            </div>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Admin Phone:
                *</label>
              <vue-tel-input v-model="phone"></vue-tel-input>
              <small v-if="responseErrors.admin_phone" class="text-[#f24236]">{{
        responseErrors.admin_phone[0]
      }}</small>
              <!-- <input
                v-model="phone"
                type="text"
                min="5"
                id="small-input"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
              /> -->
            </div>
            <div class="mb-2">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                Select SME Type: *
              </label>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="small-input" v-model="sme_type" required>
                <option value="" disabled selected>Select SME Type</option>
                <option v-for="type in smeTypes" :key="type" :value="type">
                  {{ type }}
                </option>
              </select>
            </div>

            <div class="mb-2" v-if="!subscriptionPlanHidden">
              <label for="small-input"
                class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">Select
                Subscription Plan: *</label>
              <select
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                id="small-input" v-model="subscriptionPlan" required>
                <option value="" disabled selected>Select Plan</option>
                <option v-for="plan in subscriptionPlans" :key="plan.id" :value="plan.id">
                  {{ plan.name }} - {{ plan.price }} RWF
                </option>
              </select>
            </div>
            <div class="w-full flex lg:justify-end justify-start items-center mt-3">
              <button v-if="!sme" @click="registerSME" type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                Register
                <span v-if="loading" :class="{ 'spinner btn-spinner ml-2': loading }">
                </span>
              </button>
              <button v-else @click="updateSME" type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                Update
                <span v-if="loading" :class="{ 'spinner btn-spinner ml-2': loading }">
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import instance from '@/axios-interceptor';
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'addSmeForm',
  components: {
    VueTelInput,
  },
  props: {
    modal: {
      type: Boolean,
    },
    sme: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      responseErrors: {},
      loading: false,
      subscriptionPlans: [
        {
          id: '',
          name: '',
          price: '',
        },
      ],
      name: '',
      tin_number: '',
      admin_name: '',
      phone: '',
      admin_email: '',
      location: '',
      subscriptionPlan: null,
      sme_type: '',
      smeTypes: [
        'Hotels',
        'Restaurant/Coffee Shop',
        'Outdoor Kiosk',
        'Coworking space',
        'Universities',
        'Retail Malls & Shopping Centers',
        'Smart Cities',
        'Salons & Spas',
        'Hospitals',
        'Public Location',
        'Others',
      ],
      subscriptionPlanHidden: false,
      buttonText: 'Register SME',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    getSubscriptionPlans() {
      instance
        .get(`partner/subscription/${this.loggedInUser.partner.id}`)
        .then((response) => {
          this.subscriptionPlans = response.data;
        });
    },
    registerSME() {
      if (
        !this.name ||
        !this.tin_number ||
        !this.admin_name ||
        !this.phone ||
        !this.admin_email ||
        !this.location ||
        !this.subscriptionPlan ||
        !this.sme_type
      ) {
        this.$toast.warning('All fields are required', {
          position: 'top-right',
        });
        return;
      }

      const data = {
        country: this.loggedInUser.country,
        partner: this.loggedInUser.partner.id,
        name: this.name,
        tin_number: this.tin_number,
        admin_name: this.admin_name,
        admin_phone: this.phone,
        admin_email: this.admin_email,
        location: this.location,
        subscription_plan: this.subscriptionPlan,
        type: this.sme_type,
      };

      this.loading = true;

      instance
        .post('smes/', data)
        .then((response) => {
          // this.$emit('close');
          if (response.status === 201) {
            this.loading = false;
            this.$toast.success('Sme registered successfully', {
              position: 'top-right',
            });
          } else {
            this.loading = false;
            this.$emit('close');
            this.$toast.error('Could not register the SME at this time', {
              position: 'top-right',
            });
          }
        })
        .catch((error) => {
          if (error) {
            this.responseErrors = error.response.data;
            this.loading = false;
            if (this.responseErrors) {
              console.log(this.responseErrors);
            } else {
              this.$emit('close');
            }

            this.loading = false;
          } else {
            this.$toast.error('Could Not add SME at this time', {
              position: 'top-right',
            });
          }
        });
    },
    updateSME() {
      if (
        !this.name ||
        !this.tin_number ||
        !this.admin_name ||
        !this.phone ||
        !this.admin_email ||
        !this.location ||
        !this.sme_type
      ) {
        this.$toast.warning('All fields are required', {
          position: 'top-right',
        });
        return;
      }

      let payload = {};

      if (this.name !== this.sme.name) {
        payload.name = this.name;
      }

      if (this.tin_number !== this.sme.tin_number) {
        payload.tin_number = this.tin_number;
      }

      if (this.admin_name !== this.sme.admin_name) {
        payload.admin_name = this.admin_name;
      }

      if (this.admin_email !== this.sme.admin_email) {
        payload.admin_email = this.admin_email;
      }

      if (this.phone !== this.sme.admin_phone) {
        payload.admin_phone = this.phone;
      }

      if (this.location !== this.sme.location) {
        payload.location = this.location;
      }

      if (this.type !== this.sme.type) {
        payload.type = this.sme_type;
      }

      this.loading = true;

      if (!Object.keys(payload).length) {
        return this.$emit('close');
      }

      instance
        .patch(`smes/${this.sme.id}/`, payload)
        .then(() => {
          this.loading = false;
          this.$toast.success('SME updated successfully', {
            position: 'top-right',
          });
          this.$emit('close');
        })
        .catch((error) => {
          if (error) {
            this.responseErrors = error.response.data;
            this.loading = false;
            if (this.responseErrors) {
              console.log(this.responseErrors);
            } else {
              this.$emit('close');
            }
          } else {
            this.$toast.error('Could not update SME at this time', {
              position: 'top-right',
            });
          }
        });
    },
  },
  created() {
    this.getSubscriptionPlans();
  },
  mounted() {
    if (this.sme) {
      this.name = this.sme.name;
      this.tin_number = this.sme.tin_number;
      this.admin_name = this.sme.admin_name;
      this.phone = this.sme.admin_phone;
      this.admin_email = this.sme.admin_email;
      this.location = this.sme.location;
      if (this.sme.type) {
        this.sme_type = this.sme.type;
      }

      this.subscriptionPlanHidden = true;
      this.buttonText = 'Update SME';
    }
  },
};
</script>
