import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/search-icon.png'


const _hoisted_1 = { class: "bg-light w-full rounded-[0.938rem] py-4 md:px-6 px-3 shadow overflow-auto relative" }
const _hoisted_2 = { class: "md:w-[100%] w-[50%] mb-2 mt-4" }
const _hoisted_3 = { class: "relative block" }
const _hoisted_4 = {
  key: 0,
  class: "p-3 flex items-center justify-between md:w-[100%] w-[50%]"
}
const _hoisted_5 = { class: "me-2 text-[#d3aa3b] font-bold text-sm" }
const _hoisted_6 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
}
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "flex flex-col ml-3" }
const _hoisted_9 = ["onUpdate:modelValue", "onChange"]
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "flex flex-col ml-3" }
const _hoisted_12 = { class: "b-avatar-img capitalize text-[#464E5F] mb-0.5" }
const _hoisted_13 = { class: "flex items-center" }
const _hoisted_14 = { class: "flex flex-col ml-3" }
const _hoisted_15 = { class: "b-avatar-img capitalize text-[#464E5F] mb-0.5" }
const _hoisted_16 = { class: "flex flex-col" }
const _hoisted_17 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_18 = { class: "spinner-container" }
const _hoisted_19 = {
  key: 0,
  class: "spinner"
}
const _hoisted_20 = { class: "flex items-center" }
const _hoisted_21 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "flex justify-center font-bold mb-3" }, "REALM - SALE", -1)),
    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex items-center justify-between" }, [
      _createElementVNode("div", { class: "font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, " Realm Advertisements ")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "w-[0.8rem] h-[0.8rem]"
          })
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
          class: "bg-white font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
          placeholder: "Search",
          type: "text",
          name: "search"
        }, null, 512), [
          [_vModelText, _ctx.searchQuery]
        ])
      ])
    ]),
    (_ctx.showButtons())
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.rowsSelected) + " Rows selected", 1),
            _createElementVNode("span", {
              class: "cursor-pointer text-[#5e72e4] font-bold text-sm",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearRows && _ctx.clearRows(...args)))
            }, "clear")
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              type: "button",
              class: "me-2 cursor-pointer capitalize p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromSme && _ctx.unallocateRoutersFromSme(...args)))
            }, " unassign SME router "),
            _createElementVNode("button", {
              type: "button",
              class: "capitalize cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.unallocateRoutersFromPartner && _ctx.unallocateRoutersFromPartner(...args)))
            }, " unassign Partner router ")
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_SmeTable, {
      sorting: true,
      onSort: _ctx.sortBy,
      inputShow: true,
      selectAllValues: _ctx.selectAll,
      onSelectAllRows: _ctx.selectAllRows,
      tableName: 'Realm Routers',
      tableHeaderData: _ctx.accountsTableHeader
    }, {
      tableBodyData: _withCtx(() => [
        (!_ctx.paginatedTableData.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, " No records "))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.paginatedTableData, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                        onChange: ($event: any) => (_ctx.updateSelectedRows(item)),
                        type: "checkbox",
                        class: "shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                      }, null, 40, _hoisted_9), [
                        [_vModelCheckbox, item.selected]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(item.title), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("span", _hoisted_15, _toDisplayString(item.is_active), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(item.created), 1)
                  ])
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("td", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", { class: "capitalize px-2 py-1 rounded bg-[#4e8d6e] text-white mb-0.5 inline-block text-center cursor-pointer" }, "view")
                  ])
                ], -1))
              ]))
            }), 128))
      ]),
      _: 1
    }, 8, ["onSort", "selectAllValues", "onSelectAllRows", "tableHeaderData"]),
    _createElementVNode("div", _hoisted_18, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19))
        : _createCommentVNode("", true)
    ]),
    (_ctx.propLoaded)
      ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
          key: 1,
          perPageItems: _ctx.perSize,
          pageNumber: _ctx.page,
          ApiData: _ctx.apiData,
          onHandleNext: _ctx.handlleNext,
          onHandlePrev: _ctx.handlePrev
        }, {
          section: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_20, [
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                _createElementVNode("span", _hoisted_21, [
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedOption) = $event)),
                    onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("option", { value: "10" }, "10", -1),
                    _createElementVNode("option", { value: "20" }, "20", -1),
                    _createElementVNode("option", { value: "30" }, "30", -1),
                    _createElementVNode("option", { value: "40" }, "40", -1),
                    _createElementVNode("option", { value: "50" }, "50", -1)
                  ]), 544), [
                    [_vModelSelect, _ctx.selectedOption]
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
      : _createCommentVNode("", true)
  ]))
}