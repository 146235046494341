import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/ared-logo.svg'


const _hoisted_1 = { class: "w-full lg:flex justify-between" }
const _hoisted_2 = { class: "lg:w-[50%]" }
const _hoisted_3 = { class: "flex flex-col justify-center items-center h-full relative" }
const _hoisted_4 = { class: "max-form w-full lg:py-0 py-10" }
const _hoisted_5 = { class: "bg-white rounded-[0.432rem] lg:p-0 p-4" }
const _hoisted_6 = { class: "mt-10" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "lg:w-[50%]" }
const _hoisted_10 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_auth_right_image = _resolveComponent("auth-right-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            src: _imports_0,
            class: "w-[8rem] lg:hidden block m-auto mb-5",
            alt: "right view"
          }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "font-['poppins'] font-bold text-[26px] text-center" }, " Forgot Password ? ", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "font-poppins font-normal text-[14px] mt-2 lg:text-[#838282] text-[#4E8D6E] text-center" }, " Enter your email to reset your password. ", -1)),
            _createElementVNode("form", _hoisted_6, [
              _createVNode(_component_TextField, {
                name: "email",
                class: "mb-10 font-normal border-none border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                placeholder: "Email",
                type: "email",
                modelValue: _ctx.v$.email.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.email.$model) = $event)),
                showErrors: _ctx.v$.email.$errors.length ? true : false,
                errors: _ctx.v$.email.$errors
              }, null, 8, ["modelValue", "showErrors", "errors"]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  disabled: _ctx.v$.$invalid,
                  type: "button",
                  class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium mr-2",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleForgotPassword && _ctx.handleForgotPassword(...args)))
                }, [
                  _cache[2] || (_cache[2] = _createTextVNode(" Submit ")),
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass({ 'spinner btn-spinner ml-2': _ctx.loading })
                      }, null, 2))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_8)
              ])
            ])
          ])
        ]),
        _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"max-form w-full absolute bottom-5 justify-between items-center lg:flex hidden\" data-v-729973aa><ul class=\"flex\" data-v-729973aa><li data-v-729973aa><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-729973aa><a href=\"javascript:void(0)\" data-v-729973aa>Terms</a></span></li><li data-v-729973aa><span class=\"text-success font-poppins font-medium text-[0.778rem] mr-4\" data-v-729973aa><a href=\"javascript:void(0)\" data-v-729973aa>Plans</a></span></li><li data-v-729973aa><span class=\"text-success font-poppins font-medium text-[0.778rem]\" data-v-729973aa><a href=\"javascript:void(0)\" data-v-729973aa>Contact Us</a></span></li></ul></div>", 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_auth_right_image)
      ])
    ])
  ]))
}