<template>
  <div class="flex my-6 justify-between">
    <div class="flex items-center">
      <i class="fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2"></i>
      <span
        class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor"
        >Survey Details</span
      >
    </div>
    <button
      @click="addSurveyQuest"
      type="button"
      class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold text-[0.8rem] text-[#fff]"
    >
      Add survey Questions
    </button>
  </div>

  <div class="md:flex flex-row items-start gap-y-8 gap-x-8 mt-8 sm:gap-y-16">
    <div
      class="lg:basis-2/6 relative flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible shadow-lg px-5 pt-5 pb-6 shadow-gray-900/5 bg-white rounded-2xl h-100 min-h-[132px] justify-center items-center"
    >
      <h3 class="font-bold font-lg text-center">{{survey.title}}</h3>
    </div>

    <div
      class="user-profile-block lg:col-span-3 asis-1/2 shadow-lg px-6 pt-6 pb-8 shadow-gray-900/5 bg-white rounded-2xl full-width"
    >
      <h3
        class="text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
      >
        Survey details
      </h3>
      <table class="w-full table-fixed table-border">
        <tbody>
          <tr class="bg-light">
            <td class="font-poppin text-[1rem] text-[#888889]">Title</td>
            <td class="font-medium text-[1rem]">{{survey.title}}</td>
          </tr>
          <tr>
            <td class="font-poppin text-[1rem] text-[#888889]">Realm</td>
            <td class="font-medium text-[1rem]  cursor-pointer text-[#5e72e4]" @click="realmDetails(survey.realm.id)">{{survey.realm.name}}</td>
          </tr>
          <tr class="bg-light">
            <td class="font-poppin text-[1rem] text-[#888889]">Questions</td>
            <td class="font-medium text-[1rem]">{{ survey.questions_count }}</td>
          </tr>
          <tr>
            <td class="font-poppin text-[1rem] text-[#888889]">Status</td>
            <td class="font-medium text-[1rem]">
              <span v-if="survey.active"
                class="font-poppins font-medium text-[0.8rem] text-[#4e8d6e] bg-[#DCE9E3] px-3 py-1 rounded-[0.327rem]"
                >ENABLED</span
              >
              <span v-else
                class="font-poppins font-medium text-[0.8rem] text-[#4e8d6e] bg-[#DCE9E3] px-3 py-1 rounded-[0.327rem]"
                >DISABLED</span
              >
            </td>
          </tr>
          <tr class="bg-light">
            <td class="font-poppin text-[1rem] text-[#888889]">Date Joined</td>
            <td class="font-medium text-[1rem]">{{new Date(survey.created).toLocaleString()}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div
    class="user-profile-block lg:col-span-3 asis-1/2 shadow-lg px-6 pt-6 pb-8 shadow-gray-900/5 bg-white rounded-2xl full-width mt-8"
  >
    <h3
      class="text-left mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor"
    >
      Survey Questions
    </h3>
    <table class="w-full table-fixed table-border">
      <tbody   v-for="(question,index) in survey_questions"
        :key="question.id">
        <tr class="bg-light">
          <td class="font-poppin text-[1rem] text-[#888889]">{{ index + 1 }}</td>
          <td class="font-medium text-[1rem]">{{ question.text.slice(0, 100) }}...</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import router, { useRoute } from '@/router';

import { defineComponent, ref } from "vue";
import {
  routers as _routers,
  routerTableHeaders as _routerTableHeaders,
  routerTableIcons as _routerTableIcons,
} from "../../helpers/stub_data/dashboard_routers";
import instance from "@/axios-interceptor";

export default defineComponent({
  name: "surveyDetailsPage",
  components: {},

  data() {
    return {
        survey_questions:[{
            id:'',
          text:''
        }
           
        ],
        survey:{
            title:'',
            realm:{
                id:'',
                name:''
            },
            questions_count:'',
            active:'',
            created:''
        },
      isLoading: false,
      activeTab: "all",
    };
  },

  methods: {
    realmDetails(realmId:any) {
            router.push({ name: "realms-details", params: { id: realmId } });
        },
    addSurveyQuest() {
      const route = useRoute();
      router.push({ name: "survey-questions",params: { id: route.params.id }  });
    },
    fetchSurveyQuestions(id: any) {
      instance
        .get(`survey-questions/?survey=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.survey_questions = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error( `${error.response[0]}`, {
                            position: "top-right",
                        });
           
          }
        });
    },
    fetchSurvey(id:any) {
      instance.get(`surveys/${id}/`).then((response) => {
        if (response.status === 200) {
          this.survey = response.data;
        } else {
            this.$toast.error( `Could not fetch survey details`, {
                            position: "top-right",
                        });
        
        }
      });
    },
  },
  mounted() {
    const route = useRoute();
    this.fetchSurveyQuestions(route.params.id);
    this.fetchSurvey(route.params.id)
  },
});
</script>
