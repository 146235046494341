import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/today.png'


const _hoisted_1 = { class: "bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center" }
const _hoisted_2 = { class: "font-poppins font-medium text-[0.8rem] text-[#959CBD]" }
const _hoisted_3 = { class: "text-[#4E8D6D] font-semibold" }
const _hoisted_4 = { class: "profile-sec full-width" }
const _hoisted_5 = { class: "shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "my-4 gap-x-4 gap-y-4 full-width grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = {
  key: 0,
  class: "text-[#f24236]"
}
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  key: 0,
  class: "text-[#f24236]"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "text-[#f24236]"
}
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  key: 0,
  class: "text-[#f24236]"
}
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = { class: "mt-5 text-sm-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "User SignUp",
      icon: _ctx.icon.accountIcon
    }, {
      aside: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _cache[11] || (_cache[11] = _createTextVNode("Today: ")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.today), 1)
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("img", {
            src: _imports_0,
            alt: "africa",
            class: "w-[0.8rem] h-[0.8rem] rounded ml-1"
          }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["icon"]),
    _createElementVNode("div", {
      class: _normalizeClass(["lg:col-span-3 asis-1/2 full-width", [_ctx.activeTab == 'profile' ? 'block ' : 'hidden']])
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[24] || (_cache[24] = _createElementVNode("h3", { class: "text-left mb-3 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor" }, " Profile ", -1)),
            _createElementVNode("div", _hoisted_6, [
              _cache[14] || (_cache[14] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " Select User Type ", -1)),
              _withDirectives(_createElementVNode("select", {
                id: "userType",
                type: "name",
                class: "text-[14px] h-[2.688rem] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userType) = $event))
              }, [
                _cache[13] || (_cache[13] = _createElementVNode("option", {
                  value: "",
                  disabled: ""
                }, "Select User Type", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userTypes, (userType) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: userType.value,
                    key: userType.value
                  }, _toDisplayString(userType.name), 9, _hoisted_7))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.userType]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[15] || (_cache[15] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " First Name ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "name",
                  name: "firstName",
                  class: "mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                  placeholder: "Enter First Name",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.first_name) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.first_name]
                ]),
                (_ctx.responseErrors.first_name)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.responseErrors.first_name[0]), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[16] || (_cache[16] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " Last Name ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "name",
                  name: "lastName",
                  class: "mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                  placeholder: "Enter Your LastName",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.last_name) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.last_name]
                ]),
                (_ctx.responseErrors.last_name)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.responseErrors.last_name[0]), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " Phone ", -1)),
                _createVNode(_component_vue_tel_input, {
                  modelValue: _ctx.phone,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event))
                }, null, 8, ["modelValue"]),
                (_ctx.responseErrors.phone)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(_ctx.responseErrors.phone[0]), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[18] || (_cache[18] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " Email ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "name",
                  name: "email",
                  class: "mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                  placeholder: "Enter Email ",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.email) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.email]
                ]),
                (_ctx.responseErrors.email)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.responseErrors.email[0]), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[20] || (_cache[20] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " Gender ", -1)),
                _withDirectives(_createElementVNode("select", {
                  id: "gender",
                  type: "name",
                  class: "text-[14px] w-full h-[2.688rem] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.gender) = $event))
                }, _cache[19] || (_cache[19] = [
                  _createElementVNode("option", {
                    value: "",
                    disabled: ""
                  }, "Select Gender", -1),
                  _createElementVNode("option", { value: "Male" }, "Male", -1),
                  _createElementVNode("option", { value: "Female" }, "Female", -1),
                  _createElementVNode("option", { value: "Other" }, "Other", -1)
                ]), 512), [
                  [_vModelSelect, _ctx.gender]
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[21] || (_cache[21] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " Date of Birth ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "date",
                  name: "dob",
                  class: "mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                  placeholder: "Enter Your Date of birth",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.date_of_birth) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.date_of_birth]
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[22] || (_cache[22] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " National ID No* ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  name: "dob",
                  class: "mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                  placeholder: "National Id ",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.national_id_no) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.national_id_no]
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[23] || (_cache[23] = _createElementVNode("label", { class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1" }, " Area of Operation* ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  name: "dob",
                  class: "mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                  placeholder: "Type Location......",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.area_of_operation) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.area_of_operation]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("button", {
                class: "bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleUser && _ctx.handleUser(...args)))
              }, [
                _createTextVNode(_toDisplayString(_ctx.userId ? 'Update User' : 'Register User') + " ", 1),
                (_ctx.isLoader)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass({ 'spinner btn-spinner ml-2': _ctx.isLoader })
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.$route.params.id)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.routeBack && _ctx.routeBack(...args))),
                    class: "bg-violet-100 font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium"
                  }, " Cancel "))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ], 2)
  ], 64))
}