<template>
  <DashboardHeader title="User SignUp" :icon="icon.accountIcon">
    <template v-slot:aside>
      <div class="bg-[#DCE9E3] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-medium text-[0.8rem] text-[#959CBD]">Today:
          <span class="text-[#4E8D6D] font-semibold">{{ today }}</span></span>
        <img src="../../assets/today.png" alt="africa" class="w-[0.8rem] h-[0.8rem] rounded ml-1" />
      </div>
    </template>
  </DashboardHeader>
  <div class="lg:col-span-3 asis-1/2 full-width" :class="[activeTab == 'profile' ? 'block ' : 'hidden']">
    <div>
      <div class="profile-sec full-width">
        <div class="shadow-lg p-6 shadow-gray-900/5 bg-white rounded-2xl">
          <h3 class="text-left mb-3 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">
            Profile
          </h3>
          <div class="form-group">
            <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
              Select User Type
            </label>
            <select id="userType" type="name"
              class="text-[14px] h-[2.688rem] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
              v-model="userType">
              <option value="" disabled>Select User Type</option>
              <option v-for="userType in userTypes" :value="userType.value" :key="userType.value">
                {{ userType.name }}
              </option>
            </select>
          </div>
          <div class="my-4 gap-x-4 gap-y-4 full-width grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
            <div class="form-group">
              <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                First Name
              </label>
              <input type="name" name="firstName"
                class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Enter First Name" v-model="first_name" />
              <small v-if="responseErrors.first_name" class="text-[#f24236]">
                {{ responseErrors.first_name[0] }}
              </small>
            </div>

            <div class="form-group">
              <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                Last Name
              </label>
              <input type="name" name="lastName"
                class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Enter Your LastName" v-model="last_name" />
              <small v-if="responseErrors.last_name" class="text-[#f24236]">
                {{ responseErrors.last_name[0] }}
              </small>
            </div>

            <div class="form-group">
              <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                Phone
              </label>
              <vue-tel-input v-model="phone"></vue-tel-input>
              <small v-if="responseErrors.phone" class="text-[#f24236]">
                {{ responseErrors.phone[0] }}
              </small>
            </div>

            <div class="form-group">
              <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                Email
              </label>
              <input type="name" name="email"
                class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Enter Email " v-model="email" />
              <small v-if="responseErrors.email" class="text-[#f24236]">
                {{ responseErrors.email[0] }}
              </small>
            </div>

            <div class="form-group">
              <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                Gender
              </label>
              <select id="gender" type="name"
                class="text-[14px] w-full h-[2.688rem] border-[0rem] bg-[#f3f6f9] rounded-[0.4rem] px-3 py-2 border-textFieldBorder border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                v-model="gender">
                <option value="" disabled>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div class="form-group">
              <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                Date of Birth
              </label>
              <input type="date" name="dob"
                class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Enter Your Date of birth" v-model="date_of_birth" />
            </div>

            <div class="form-group">
              <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                National ID No*
              </label>
              <input type="text" name="dob"
                class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="National Id " v-model="national_id_no" />
            </div>

            <div class="form-group">
              <label class="after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1">
                Area of Operation*
              </label>

              <input type="text" name="dob"
                class="mt-1 px-4 py-2 border-none shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
                placeholder="Type Location......" v-model="area_of_operation" />
            </div>
          </div>
          <div class="mt-5 text-sm-end">
            <button
              class="bg-primary font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium mr-2 border"
              @click="handleUser">
              {{ userId ? 'Update User' : 'Register User' }}
              <span v-if="isLoader" :class="{ 'spinner btn-spinner ml-2': isLoader }">
              </span>
            </button>
            <button v-if="$route.params.id" @click="routeBack"
              class="bg-violet-100 font-['poppins'] text-[14px] text-white rounded-[0.4rem] py-2 px-4 font-medium">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import { VueTelInput } from 'vue-tel-input';

import router, { useRoute } from '@/router';
import instance from '@/axios-interceptor';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import { dashboardHeadericon } from '../../helpers/stub_data/dashboard_routers';

export default defineComponent({
  name: 'userFormPage',
  components: {
    DashboardHeader,
    VueTelInput,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const activeId = ref(1);
    const activeTab = ref('profile');
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const isOpenModal = ref(false);
    return {
      activeId,
      center,
      markers,
      isOpenModal,
      activeTab,
      icon,
    };
  },

  data() {
    return {
      isLoader: false,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      gender: '',
      page: '',
      userType: '',
      date_of_birth: '',
      national_id_no: '',
      country: '',
      area_of_operation: '',
      bindProps: {
        mode: 'international',
      },
      partnerId: '',
      plan: '',
      loading: false,
      responseErrors: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
      user: {
        email: '',
      },
      UpdateData: {},
      userId: '',
      adminRole: 'partner_admin',
      userTypes: [
        {
          name: 'Admin',
          value: 'Admin',
        },
        {
          name: 'Micro Franchisee',
          value: 'Micro Franchisee',
        },
        {
          name: 'Field Officer',
          value: 'Field Officer',
        },
        {
          name: 'Maintenance Officer',
          value: 'Maintenance Officer',
        },
        {
          name: 'Sales Representative',
          value: 'Sales Representative',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
  },
  mounted() {
    const route = useRoute();
    this.plan = this.loggedInUser.partner?.plan;
    if (route.params.id) {
      this.userId = route.params.id.toString();
      this.fetchUser(this.userId);
    }
  },
  methods: {
    routeBack() {
      const route = useRoute();
  
      if (route.params.id) {
        router.push({
          name: 'view-details',
          params: { id: route.params.id },
        });
        return;
      }

      router.go(-1);
    },
    handleUser() {
      if (this.userId) {
        this.updateUser();
      } else {
        this.submitForm();
      }
    },
    getUserRole(userType: string) {
      switch (userType) {
        case 'Admin':
          return 'partner_admin';
        default:
          return 'partner_staff';
      }
    },
    getValidUserType(userType: string) {
      switch (userType) {
        case 'Admin':
          return 'Partner';
        case 'Partner':
          return 'Admin';
        default:
          return userType;
      }
    },
    async submitForm() {
      this.loading = true;
      this.isLoader = true;
      const data = new FormData();
      data.append('first_name', this.first_name);
      data.append('last_name', this.last_name);
      data.append('email', this.email);
      data.append('phone', this.phone.split(' ').join(''));
      data.append('gender', this.gender);
      data.append('date_of_birth', this.date_of_birth);
      data.append('national_id_no', this.national_id_no);
      data.append('country', this.loggedInUser.country);
      data.append('user_type', this.getValidUserType(this.userType));
      data.append('role', this.getUserRole(this.userType));
      data.append('area_of_operation', this.area_of_operation);
      data.append('partner', this.loggedInUser.partner.id);

      instance
        .post('auth/users/', data)
        .then((response) => {
          this.loading = false;
          this.isLoader = false;
          if (response.status === 201) {
            this.$toast.success(' user added Successfully', {
              position: 'top-right',
            });
            router.push({ path: '/system-admin' });
          }
        })
        .catch((error) => {
          this.isLoader = false;
          this.responseErrors = error.response.data;
          this.loading = false;
        });
    },
    onSetActiveg() {
      this.$emit('click');
    },
    async fetchUser(id: any) {
      await instance.get(`auth/users/${id}/details/`).then((response) => {
        if (response.status === 200) {
          this.user = response.data;
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          this.email = response.data.email;
          this.phone = response.data.phone;
          this.gender = response.data.gender;
          this.date_of_birth = response.data.date_of_birth;
          this.national_id_no = response.data.national_id_no;
          this.country = response.data.country;
          this.userType = this.getValidUserType(response.data.user_type);
          this.area_of_operation = response.data.area_of_operation;
          this.email = response.data.email;
        } else {
          this.$toast.error(' Could not fetch user details', {
            position: 'top-right',
          });
        }
      });
    },
    async updateUser() {
      this.loading = true;
      this.isLoader = true;

      this.UpdateData = {
        first_name: this.first_name,
        last_name: this.last_name,
        phone: this.phone,
        gender: this.gender,
        date_of_birth: this.date_of_birth,
        national_id_no: this.national_id_no,
        country: this.country,
        user_type: this.getValidUserType(this.userType),
        role: this.getUserRole(this.userType),
        area_of_operation: this.area_of_operation,
      };

      if (this.email !== this.user.email) {
        this.UpdateData = {
          email: this.email,
        };
      }

      await instance
        .patch(`auth/users/${this.userId}/details/`, this.UpdateData)
        .then((response) => {
          this.loading = false;
          this.isLoader = false;
          if (response.status === 200) {
            this.$toast.success(' User updated Successfully', {
              position: 'top-right',
            });
            router.back();
            //router.push("/system-admin");
          }
        })
        .catch((error) => {
          this.isLoader = false;
          this.responseErrors = error.response.data;
          this.loading = false;
        });
    },
  },
});
</script>
