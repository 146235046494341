import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect } from "vue"
import _imports_0 from '../../assets/plus-icon.png'
import _imports_1 from '../../assets/search-icon.png'


const _hoisted_1 = { class: "flex flex-col my-6" }
const _hoisted_2 = { class: "sm:flex items-center justify-between" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative" }
const _hoisted_5 = { class: "md:w-[100%] w-[50%] mb-2" }
const _hoisted_6 = { class: "relative block" }
const _hoisted_7 = {
  key: 0,
  class: "p-3 flex items-center justify-between md:w-[100%] w-[50%]"
}
const _hoisted_8 = { class: "me-2 text-[#d3aa3b] font-bold text-sm" }
const _hoisted_9 = {
  key: 0,
  class: "capitalize text-[#464E5F] mb-0.5 text-sm whitespace-nowrap"
}
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "flex flex-col ml-3" }
const _hoisted_12 = ["onUpdate:modelValue", "onChange"]
const _hoisted_13 = { class: "flex items-center" }
const _hoisted_14 = { class: "flex flex-col ml-3" }
const _hoisted_15 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_16 = { class: "flex" }
const _hoisted_17 = { class: "capitalize text-[#464E5F] mr-2 mb-0.5" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "flex flex-col" }
const _hoisted_20 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_21 = { class: "flex items-center" }
const _hoisted_22 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_subscription_form = _resolveComponent("subscription-form")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "sm:mb-0 mb-2" }, [
          _createElementVNode("i", { class: "fa-regular fa-calendar w-[1rem] h-[1rem] rounded mr-2" }),
          _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1.062rem] sm:text-[0.90rem] text-[0.70rem] text-routersTextColor" }, "Subscription")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
            type: "button",
            class: "bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center font-poppins font-semibold sm:text-[0.8rem] text-[0.7rem] text-[#fff]"
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: "w-[0.6rem] h-[0.6rem] mr-1",
              alt: ""
            }, null, -1),
            _createTextVNode(" Create Subscription ")
          ])),
          (_ctx.showModal)
            ? (_openBlock(), _createBlock(_component_subscription_form, {
                key: 0,
                onClose: _ctx.closeModal,
                subscriptions: _ctx.subscriptions
              }, null, 8, ["onClose", "subscriptions"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "",
              class: "w-[0.8rem] h-[0.8rem]"
            })
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
            class: "font-normal w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block focus:ring-1",
            placeholder: "Search",
            type: "text",
            name: "search"
          }, null, 512), [
            [_vModelText, _ctx.searchQuery]
          ])
        ])
      ]),
      (_ctx.showButtons())
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.rowsSelected) + " Rows selected", 1),
              _createElementVNode("span", {
                class: "cursor-pointer text-[#5e72e4] font-bold text-sm",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearRows && _ctx.clearRows(...args)))
              }, "clear")
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_SmeTable, {
        sorting: true,
        onSort: _ctx.sortBy,
        inputShow: true,
        selectAllValues: _ctx.selectAll,
        onSelectAllRows: _ctx.selectAllRows,
        tableName: 'Subscriptions',
        tableHeaderData: _ctx.accountsTableHeader
      }, {
        tableBodyData: _withCtx(() => [
          (!_ctx.paginatedTableData.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, " You have no subscription plans. Create the required 1 subscription plan by clicking on the button above. "))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.paginatedTableData, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": ($event: any) => ((item.selected) = $event),
                          onChange: ($event: any) => (_ctx.updateSelectedRows(item)),
                          type: "checkbox",
                          class: "shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                        }, null, 40, _hoisted_12), [
                          [_vModelCheckbox, item.selected]
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(item?.name), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("span", _hoisted_17, _toDisplayString(item.price), 1)
                    ])
                  ]),
                  (_ctx.activeTab !== 'partner')
                    ? (_openBlock(), _createElementBlock("td", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("span", _hoisted_20, _toDisplayString(item.currency), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
        ]),
        _: 1
      }, 8, ["onSort", "selectAllValues", "onSelectAllRows", "tableHeaderData"]),
      (_ctx.propLoaded)
        ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
            key: 1,
            perPageItems: _ctx.perSize,
            pageNumber: _ctx.page,
            ApiData: _ctx.apiData,
            onHandleNext: _ctx.handlleNext,
            onHandlePrev: _ctx.handlePrev
          }, {
            section: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_21, [
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                  _createElementVNode("span", _hoisted_22, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedOption) = $event)),
                      onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                    }, _cache[9] || (_cache[9] = [
                      _createElementVNode("option", { value: "10" }, "10", -1),
                      _createElementVNode("option", { value: "20" }, "20", -1),
                      _createElementVNode("option", { value: "30" }, "30", -1),
                      _createElementVNode("option", { value: "40" }, "40", -1),
                      _createElementVNode("option", { value: "50" }, "50", -1)
                    ]), 544), [
                      [_vModelSelect, _ctx.selectedOption]
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}