<template>
    <div>
        <!-- <h2>Doughnut Chart</h2>
        <DoughnutChart /> -->

        <h2>Pie Chart</h2>
        <PieChart />

        <h2>Line Chart</h2>
        <LineChart />
    </div>
</template>

<script>
import DoughnutChart from './DoughnutChart.vue';
import PieChart from './PieChart.vue';
import LineChart from './LineChart.vue';

export default {
    name: 'App',
    components: {
        // DoughnutChart,
        PieChart,
        LineChart,
    },
};
</script>

<style scoped>
.chart-container {
    width: 50%;
    height: 100px;
}
</style>