import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, vModelSelect as _vModelSelect } from "vue"
import _imports_0 from '../../assets/plus-icon.png'
import _imports_1 from '../../assets/search-icon.png'
import _imports_2 from '../../assets/Scan-icon.png'


const _hoisted_1 = { class: "w-full my-3" }
const _hoisted_2 = { class: "bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative" }
const _hoisted_3 = { class: "md:w-[100%] w-[50%] mb-2" }
const _hoisted_4 = { class: "relative block" }
const _hoisted_5 = {
  key: 0,
  class: "p-3 flex items-center justify-between md:w-[100%] w-[50%]"
}
const _hoisted_6 = { class: "me-2 text-[#d3aa3b] font-bold text-sm" }
const _hoisted_7 = {
  key: 1,
  type: "button",
  class: "me-2 cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 text-center flex"
}
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = { class: "flex flex-col ml-3" }
const _hoisted_10 = ["disabled", "onUpdate:modelValue", "onChange"]
const _hoisted_11 = { class: "flex items-center" }
const _hoisted_12 = { class: "flex flex-col ml-3" }
const _hoisted_13 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_14 = { class: "text-[#464E5F]" }
const _hoisted_15 = { class: "flex flex-col" }
const _hoisted_16 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_17 = { class: "flex flex-col" }
const _hoisted_18 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_19 = { class: "text-[#464E5F]" }
const _hoisted_20 = { class: "flex items-center justify-start" }
const _hoisted_21 = {
  key: 0,
  class: "bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = {
  key: 1,
  class: "bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2"
}
const _hoisted_24 = { class: "text-[#464E5F]" }
const _hoisted_25 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm"
}
const _hoisted_26 = { class: "spinner-container" }
const _hoisted_27 = {
  key: 0,
  class: "spinner"
}
const _hoisted_28 = { class: "flex items-center" }
const _hoisted_29 = { class: "bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_voucherForm = _resolveComponent("voucherForm")!
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_ScaleLoader = _resolveComponent("ScaleLoader")!
  const _component_qrForm = _resolveComponent("qrForm")!
  const _component_SmeTable = _resolveComponent("SmeTable")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "All Vouchers",
      description: "",
      icon: _ctx.icon.voucher
    }, {
      aside: _withCtx(() => [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
          type: "button",
          class: "bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center"
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("span", { class: "font-poppins font-semibold sm:text-[0.80rem] text-[0.70rem] text-[#fff] flex items-center" }, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "w-[0.6rem] h-[0.6rem] mr-1",
              alt: ""
            }),
            _createTextVNode(" Generate new voucher (s)")
          ], -1)
        ])),
        (_ctx.showModal)
          ? (_openBlock(), _createBlock(_component_voucherForm, {
              key: 0,
              onClose: _ctx.closeModal
            }, null, 8, ["onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["icon"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, [
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
              _createElementVNode("img", {
                src: _imports_1,
                alt: "",
                class: "w-[0.8rem] h-[0.8rem]"
              })
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
              class: "font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
              placeholder: "Search vouchers",
              type: "text",
              name: "search"
            }, null, 512), [
              [_vModelText, _ctx.searchQuery]
            ])
          ])
        ]),
        (_ctx.showButtons())
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.rowsSelected) + " vouchers selected", 1),
                (!_ctx.deletingVouchers)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "cursor-pointer text-[#5e72e4] font-bold text-sm",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearRows && _ctx.clearRows(...args)))
                    }, "clear"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                (!_ctx.deletingVouchers)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "me-2 cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteVouchers()))
                    }, " Delete Voucher(s) "))
                  : (_openBlock(), _createElementBlock("button", _hoisted_7, [
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "mr-2 pt-2" }, " Deleting Vouchers ", -1)),
                      _createElementVNode("span", null, [
                        _createVNode(_component_ScaleLoader, {
                          color: "#ffffff",
                          size: 5
                        })
                      ])
                    ]))
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_SmeTable, {
          sorting: true,
          onSort: _ctx.sortBy,
          inputShow: true,
          selectAllValues: _ctx.selectAll,
          onSelectAllRows: _ctx.selectAllRows,
          tableName: 'Vouchers',
          tableHeaderData: _ctx.routerTableHeaders,
          "deleting-vouchers": _ctx.deletingVouchers
        }, {
          tableBodyData: _withCtx(() => [
            (_ctx.paginatedTableData.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paginatedTableData, (voucher) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: voucher.id,
                    class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _withDirectives(_createElementVNode("input", {
                            disabled: _ctx.deletingVouchers,
                            "onUpdate:modelValue": ($event: any) => ((voucher.selected) = $event),
                            onChange: ($event: any) => (_ctx.updateSelectedRows(voucher)),
                            type: "checkbox",
                            class: "shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight"
                          }, null, 40, _hoisted_10), [
                            [_vModelCheckbox, voucher.selected]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(voucher.value), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_14, _toDisplayString(voucher.time_valid), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("span", _hoisted_16, _toDisplayString(voucher.source), 1)
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(voucher.used_by_customer ? 'Yes' : 'No'), 1)
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.createdAt(voucher.created)), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_20, [
                        (voucher.is_qrcode)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                              _createElementVNode("button", {
                                onClick: ($event: any) => (_ctx.showQR(voucher.id))
                              }, _cache[11] || (_cache[11] = [
                                _createElementVNode("img", {
                                  src: _imports_2,
                                  alt: "africa",
                                  class: "w-[1.125rem] h-[1.067rem]"
                                }, null, -1)
                              ]), 8, _hoisted_22),
                              (_ctx.showQRModal)
                                ? (_openBlock(), _createBlock(_component_qrForm, {
                                    key: 0,
                                    QRnumber: _ctx.QRValue,
                                    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showQRModal = false))
                                  }, null, 8, ["QRnumber"]))
                                : _createCommentVNode("", true)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[12] || (_cache[12] = [
                              _createElementVNode("button", { disabled: "" }, [
                                _createElementVNode("img", {
                                  src: _imports_2,
                                  alt: "africa",
                                  class: "w-[1.125rem] h-[1.067rem]"
                                })
                              ], -1)
                            ])))
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_24, _toDisplayString(voucher.re_usable), 1)
                    ])
                  ]))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[13] || (_cache[13] = [
                  _createElementVNode("b", null, "No Record found", -1)
                ])))
          ]),
          _: 1
        }, 8, ["onSort", "selectAllValues", "onSelectAllRows", "tableHeaderData", "deleting-vouchers"]),
        _createElementVNode("div", _hoisted_26, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_27))
            : _createCommentVNode("", true)
        ]),
        (_ctx.propLoaded)
          ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
              key: 1,
              perPageItems: _ctx.perSize,
              pageNumber: _ctx.page,
              ApiData: _ctx.apiData,
              onHandleNext: _ctx.handlleNext,
              onHandlePrev: _ctx.handlePrev
            }, {
              section: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_28, [
                    _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-[#B5B5C3] font-poppins font-medium text-[0.673rem]" }, "Rows per page", -1)),
                    _createElementVNode("span", _hoisted_29, [
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedOption) = $event)),
                        onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
                      }, _cache[14] || (_cache[14] = [
                        _createElementVNode("option", { value: "10" }, "10", -1),
                        _createElementVNode("option", { value: "20" }, "20", -1),
                        _createElementVNode("option", { value: "30" }, "30", -1),
                        _createElementVNode("option", { value: "40" }, "40", -1),
                        _createElementVNode("option", { value: "50" }, "50", -1)
                      ]), 544), [
                        [_vModelSelect, _ctx.selectedOption]
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}