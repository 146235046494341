<template>
  <div class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <button @click="$emit('close')" class="float-right">
        <img src="../../assets/close-icon.svg" class="w-[0.8rem] mt-2" alt="" />
      </button>
      <div class="row">
        <div class="col-md-12 mx-auto">
          <h3 class="text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">
            {{ submit }}
          </h3>
          <form>
            <div class="mb-4">
              <label for="small-input mb-2"
                class="after:ml-0.5 after:text-[#f24236] block text-sm font-medium text-slate-700 mb-1">SME</label>
              <select type="text" required name="realm-type" aria-describedby="version-label" id="sme"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
                v-model="sme">
                <option value="Select SME" selected disabled>Select SME</option>
                <option v-for="sme in smes" :value="sme.id" :key="sme.id">{{ sme.name }}</option>
              </select>
              <p v-if="errors.sme" class="text-[#f24236] text-sm mt-2">{{ errors.sme }}</p>
            </div>
            <div class="mb-2">
              <label class="block text-sm font-medium text-slate-700 mb-2">Add-Ons</label>
              <div class="space-y-2">
                <template v-for="addon in addons" :key="addon.id">
                  <div class="flex items-center">
                    <input type="checkbox" :id="'addon-' + addon.id" :value="addon.id"
                      @change="toggleSelection(addon.id)">
                    <label :for="'addon-' + addon.id" class="ml-2">{{ addon.name }}</label>
                  </div>
                </template>
              </div>
              <p v-if="errors.addons" class="text-[#f24236] text-sm mt-2">{{ errors.addons }}</p>
            </div>

            <div class="mb-4">
              <label for="small-input mb-2"
                class="after:ml-0.5 after:text-[#f24236] block text-sm font-medium text-slate-700 mb-1">
                Code:</label>
              <input placeholder="Code" v-model="code" type="text" required name="code" aria-describedby="version-label"
                id="code"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />

              <p v-if="errors.code" class="text-[#f24236] text-sm mt-2">{{ errors.code }}</p>
            </div>

            <div class="mb-4">
              <label for="small-input mb-2"
                class="after:ml-0.5 after:text-[#f24236] block text-sm font-medium text-slate-700 mb-1">
                Discount Percentage:</label>
              <input placeholder="0-100" v-model="percentage_discount" type="number" max="100" required name="discount"
                id="discount"
                class="text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder" />
              <p v-if="errors.percentage_discount" class="text-[#f24236] text-sm mt-2">{{ errors.percentage_discount }}</p>
            </div>

            <div class="w-full flex lg:justify-end justify-start items-center mt-3">
              <button @click="createCoupon" type="button"
                class="w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-medium flex items-center justify-center">
                Create Coupon<span v-if="loading" :class="{ 'spinner btn-spinner ml-2': loading }">
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import instance from '@/axios-interceptor';

export default {
  name: 'CreateCouponForm',
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      percentage_discount: 0,
      code: '',
      smes: [],
      addons: [],
      sme: '',
      selectedAddons: [],
      errors: {},
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  mounted() {
    this.fetchSMEs();
    this.fetchAddOns();
  },
  methods: {
    isChecked(id) {
      return this.selectedAddons.includes(id);
    },
    toggleSelection(id) {
      const index = this.selectedAddons.indexOf(id);
      if (index === -1) {
        this.selectedAddons.push(id);
      } else {
        this.selectedAddons.splice(index, 1);
      }
    },
    clearError(field) {
      delete this.errors[field];
    },
    fetchSMEs() {
      instance
        .get(`smes/?partner=${this.loggedInUser.partner.id}`)
        .then((response) => {
          this.smes = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchAddOns() {
      instance
        .get('addon/')
        .then((response) => {
          this.addons = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async createCoupon() {
      if (!this.sme) {
        this.errors.sme = 'Please select an SME';
      } else {
        this.clearError('sme')
      }

      if (!this.selectedAddons.length) {
        this.errors.addons = 'Please select an addon';
      } else {
        this.clearError('addons')
      }

      if (!this.code) {
        this.errors.code = 'Please enter a code';
      } else {
        this.clearError('code')
      }

      if (!this.percentage_discount) {
        this.errors.percentage_discount = 'Please enter a discount percentage';
      } else {
        this.clearError('percentage_discount')
      }

      if (Object.keys(this.errors).length) return

      const payload = {
        sme: this.sme,
        addons: this.selectedAddons,
        partner: this.loggedInUser.partner.id,
        code: this.code,
        percentage_discount: this.percentage_discount,
      };

      await instance
        .post(`discount-coupon/`, payload)
        .then(() => {
          this.loading = false;
          this.$emit('close');
          this.$toast.success('Discount coupon created', {
            position: 'top-right',
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error('Could not create discount coupon', {
            position: 'top-right',
          });

          if (error.response && error.response.data && typeof error.response.data === 'object' && Object.keys(error.response.data).length) {
            Object.keys(error.response.data).forEach(fieldName => {
              const errorMessage = error.response.data[fieldName][0];
              this.errors[fieldName.toLowerCase()] = errorMessage;
            });
          } else {
            console.log('An unexpected error occurred.');
          }
        });

    },
  },
};
</script>
