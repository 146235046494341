import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../assets/close-icon.svg'


const _hoisted_1 = { class: "popup-modal" }
const _hoisted_2 = { class: "popup-modal-content rounded-xl" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12 mx-auto" }
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = { class: "mb-1" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "w-full flex lg:justify-end justify-start items-center mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        class: "float-right"
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("img", {
          src: _imports_0,
          class: "w-[0.8rem] mt-1",
          alt: ""
        }, null, -1)
      ])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-left mb-4 font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor" }, " Add Media Category ", -1)),
          _createElementVNode("form", null, [
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("label", {
                for: "small-input",
                class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              }, "Name", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
                type: "text",
                min: "5",
                id: "small-input",
                class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder"
              }, null, 512), [
                [_vModelText, _ctx.name]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[7] || (_cache[7] = _createElementVNode("label", {
                for: "small-input",
                class: "after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 mb-1"
              }, "Realms:", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "text-[14px] w-full h-[2.688rem] border-[0.063rem] bg-white rounded-[0.4rem] px-3 py-2 border-textFieldBorder",
                id: "realm",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.realm) = $event)),
                required: ""
              }, [
                _cache[6] || (_cache[6] = _createElementVNode("option", { value: "" }, "Select Realm", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.realmslist, (realmItem) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: realmItem.id,
                    value: realmItem.id
                  }, _toDisplayString(realmItem.name), 9, _hoisted_7))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.realm]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.addCategory && _ctx.addCategory(...args))),
                type: "button",
                class: "w-full font-['poppins'] text-[15px] bg-primary text-white rounded-[0.432rem] p-3 font-mediumflex items-center justify-center"
              }, [
                _cache[8] || (_cache[8] = _createTextVNode(" Add Media Category ")),
                (_ctx.isLoader)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass({ 'spinner btn-spinner ml-2': _ctx.isLoader })
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}