<template>
  <div v-if="show" class="popup-modal">
    <div class="popup-modal-content rounded-xl">
      <div class="row">
        <div class="col-md-12 mx-auto py-4">
          <h2 class="text-center mb-4 font-poppins font-semibold md:text-[1rem] text-[0.90rem] text-routersTextColor">
            {{ title }}
          </h2>
          <p v-if="message" class="mb-3 text-sm">{{ message }}</p>
          <div class="w-full flex justify-center pt-2">
            <button @click="cancel" type="button"
              class="bg-white border-[#4e8d6e] font-['poppins'] text-[14px] text-[#4e8d6e] rounded-[0.4rem] py-2 px-3 mr-2 font-medium border"
              :disabled="loading">
              Cancel
            </button>
            <button :disabled="loading" @click="confirm" type="button"
              class="px-3 py-2 font-['poppins'] text-[13px] bg-primary text-white rounded-[0.432rem] font-medium flex items-center">
              {{ actionLabel }}
              <span v-if="loading" class="spinner btn-spinner ml-2"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    show: Boolean,
    title: String,
    message: String,
    actionLabel: String,
    loading: Boolean,
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style scoped>
/* Your component's styling here */
</style>