<template>
  <DashboardHeader title="All Vouchers" description="" :icon="icon.voucher">
    <template v-slot:aside>
      <button @click="showModal = true" type="button"
        class="bg-[#4E8D6D] px-3 py-2 rounded-[0.327rem] flex items-center">
        <span class="font-poppins font-semibold sm:text-[0.80rem] text-[0.70rem] text-[#fff] flex items-center">
          <img src="../../assets/plus-icon.png" class="w-[0.6rem] h-[0.6rem] mr-1" alt="" />
          Generate new voucher (s)</span>
      </button>

      <voucherForm v-if="showModal" @close="closeModal"></voucherForm>
    </template>
  </DashboardHeader>
  <div class="w-full my-3">
    <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative">
      <div class="md:w-[100%] w-[50%] mb-2">
        <label class="relative block">
          <span class="sr-only">Search</span>
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <img src="../../assets/search-icon.png" alt="" class="w-[0.8rem] h-[0.8rem]" />
          </span>
          <input v-model="searchQuery"
            class="font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1"
            placeholder="Search vouchers" type="text" name="search" />
        </label>
      </div>

      <div class="p-3 flex items-center justify-between md:w-[100%] w-[50%]" v-if="showButtons()">
        <div>
          <span class="me-2 text-[#d3aa3b] font-bold text-sm">{{ rowsSelected }} vouchers selected</span>
          <span class="cursor-pointer text-[#5e72e4] font-bold text-sm" @click="clearRows"
            v-if="!deletingVouchers">clear</span>
        </div>
        <div>
          <button type="button"
            class="me-2 cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 inline-block text-center"
            @click="deleteVouchers()" v-if="!deletingVouchers">
            Delete Voucher(s)
          </button>
          <button v-else type="button"
            class="me-2 cursor-pointer p-2 rounded bg-[#e74c3c] text-white mb-0.5 text-center flex">
            <span class="mr-2 pt-2"> Deleting Vouchers </span>
            <span>
              <ScaleLoader color="#ffffff" :size="5"></ScaleLoader>
            </span>
          </button>
        </div>
      </div>

      <SmeTable :sorting="true" @sort="sortBy" :inputShow="true" :selectAllValues="selectAll"
        @selectAllRows="selectAllRows" :tableName="'Vouchers'" :tableHeaderData="routerTableHeaders"
        :deleting-vouchers="deletingVouchers">
        <template v-slot:tableBodyData>
          <template v-if="paginatedTableData.length">
            <div v-for="voucher in paginatedTableData" :key="voucher.id"
              class="table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]">
              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <input :disabled="deletingVouchers" v-model="voucher.selected" @change="updateSelectedRows(voucher)"
                      type="checkbox"
                      class="shadow w-4 h-4 form-checkbox bg-gray-200 rounded p-3 text-gray-700 leading-tight" />
                  </div>
                </div>
              </td>
              <td>
                <div class="flex items-center">
                  <div class="flex flex-col ml-3">
                    <span class="capitalize text-[#464E5F] mb-0.5">{{
                      voucher.value
                      }}</span>
                  </div>
                </div>
              </td>
              <td>
                <span class="text-[#464E5F]">{{ voucher.time_valid }}</span>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    voucher.source
                    }}</span>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <span class="capitalize text-[#464E5F] mb-0.5">{{
                    voucher.used_by_customer ? 'Yes' : 'No'
                    }}</span>
                </div>
              </td>
              <td>
                <span class="text-[#464E5F]">{{
                  createdAt(voucher.created)
                  }}</span>
              </td>
              <td>
                <div class="flex items-center justify-start">
                  <div v-if="voucher.is_qrcode"
                    class="bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2">
                    <button @click="showQR(voucher.id)">
                      <img src="../../assets/Scan-icon.png" alt="africa" class="w-[1.125rem] h-[1.067rem]" />
                    </button>
                    <qrForm :QRnumber="QRValue" v-if="showQRModal" @close="showQRModal = false"></qrForm>
                  </div>
                  <div v-else
                    class="bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2">
                    <button disabled>
                      <img src="../../assets/Scan-icon.png" alt="africa" class="w-[1.125rem] h-[1.067rem]" />
                    </button>
                  </div>
                </div>
              </td>
              <td>
                <span class="text-[#464E5F]">{{ voucher.re_usable }}</span>
              </td>
            </div>
          </template>
          <div v-else class="w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm">
            <b>No Record found</b>
          </div>
        </template>
      </SmeTable>
      <div class="spinner-container">
        <div v-if="loading" class="spinner"></div>
      </div>
      <DashboardTableTitle v-if="propLoaded" :perPageItems="perSize" :pageNumber="page" :ApiData="apiData"
        @handleNext="handlleNext" @handlePrev="handlePrev">
        <template v-slot:section>
          <div>
            <div class="flex items-center">
              <span class="text-[#B5B5C3] font-poppins font-medium text-[0.673rem]">Rows per page</span><span
                class="bg-[#F3F6F9] w-[1.739rem] h-[1.178rem] flex items-center justify-center ml-1 rounded-[0.337rem] text-[#464E5F] font-poppins font-semibold text-[0.673rem]">
                <select v-model="selectedOption" @change="handleChange">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select></span>
            </div>
          </div>
        </template>
      </DashboardTableTitle>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapGetters } from 'vuex';
import { defineComponent, ref } from 'vue';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

import qrForm from './qrForm.vue';
import voucherForm from './voucherForm.vue';
import instance from '../../axios-interceptor';
import SmeTable from '../../components/reusable/SmeTable.vue';
import DashboardHeader from '../../components/markup/DashboardHeader.vue';
import { dashboard_routes } from '../../helpers/stub_data/dashboard_routes';
import DashboardTableTitle from '../../components/markup/DashboardTableTitle.vue';
import {
  vouchers as _vouchers,
  voucherTableHeaders as _voucherTableHeaders,
  voucherTableIcons as _voucherTableIcons,
  dashboardHeadericon,
} from '../../helpers/stub_data/dashboard_routers';
import { Voucher } from '@/interfaces/router';

export default defineComponent({
  name: 'DashboardPage',
  components: {
    DashboardTableTitle,
    SmeTable,
    DashboardHeader,
    voucherForm,
    qrForm,
    ScaleLoader,
  },
  setup() {
    const icon = ref({ ...dashboardHeadericon });
    const tableName = ref('Vouchers Code');
    const routes = ref([...dashboard_routes]);
    const routers = ref([..._vouchers]);
    const routerTableHeaders = ref([..._voucherTableHeaders]);
    const routerTableIcons = ref([..._voucherTableIcons]);
    const activeId = ref(1);
    const center = ref({ lat: -1.8899577, lng: 30.0634073 });
    const markers = ref([
      {
        position: { lat: -1.8899577, lng: 30.0634073 },
      },
    ]);
    const isOpenModal = ref(false);
    return {
      routes,
      activeId,
      routers,
      routerTableHeaders,
      routerTableIcons,
      center,
      markers,
      isOpenModal,
      tableName,
      icon,
    };
  },

  data() {
    return {
      selectedOption: 10,
      showQRModal: false,
      showModal: false,
      loading: false,
      searchQuery: '',
      tableColumns: ['time_valid', 'value', 'source', 'created'],
      apiData: [] as any,
      selectAll: false,
      propLoaded: false,
      page: 1,
      perSize: 10,
      sortKey: '', // Sorting key
      sortDirection: 'asc', // Sorting direction
      activetab: '1',
      voucherList: [
        {
          id: '',
          time_valid: '',
          value: '',
          is_qrcode: false,
          re_usable: false,
          source: '',
          created: '',
        },
      ] as Voucher[],
      selectedRows: [] as Voucher[],
      QRValue: '',
      rowsSelected: 0,
      deletingVouchers: false,
    };
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.getVouchersData();
    },
    selectAllRows(checked: any) {
      this.selectAll = checked;
      this.paginatedTableData.forEach((row) => {
        row.selected = this.selectAll;
      });

      if (this.selectAll) {
        this.selectedRows = [...this.voucherList];
        this.rowsSelected = this.selectedRows.length;
      } else {
        this.selectedRows = [];
        this.rowsSelected = 0;
      }
    },
    updateSelectedRows(row: any) {
      if (!row.selected) {
        this.selectAll = false;
        this.selectedRows = this.selectedRows.filter(
          (selectedRow) => selectedRow.id !== row.id
        );
      } else {
        this.selectedRows = this.voucherList.filter((row) => row.selected);
        this.rowsSelected = this.selectedRows.length;
        this.selectAll = this.selectedRows.length === this.voucherList.length;
      }
    },
    createdAt(date: any) {
      if (date !== '') {
        return moment(date).format('M/D/YYYY, h:mm:ss a');
      }
    },
    handleChange() {
      this.perSize = this.selectedOption;
      this.getVouchersData();
    },
    handleSetActive(id: number) {
      this.activeId = id;
    },
    getDate(date: Date | number) {
      return moment(date).calendar();
    },
    handleOpenOrCloseModal() {
      this.isOpenModal = !this.isOpenModal;
    },
    handleCloseModal() {
      if (this.isOpenModal) {
        this.isOpenModal = false;
      }
    },
    handlePrev(data: any) {
      this.page--;
      this.getVouchersData();
    },
    handlleNext(data: any) {
      this.page++;
      this.getVouchersData();
    },
    getVouchersData() {
      this.loading = true;
      instance
        .get(`vouchers/?router__partner=${this.loggedInUser.partner.id}&&added_by=${this.loggedInUser.id}`)
        .then((response: { data: any }) => {
          this.loading = false;
          this.voucherList = response.data.results;
          this.apiData = response.data.results;
          this.propLoaded = true;
        })
        .catch((error: any) => {
          this.loading = false;
          console.log(error);
        });
    },
    showQR(id: any) {
      const voucher = this.filteredTableData.find(
        (voucher) => voucher.id === id
      );
      this.showQRModal = true;
      this.QRValue = voucher.value;
    },

    sortBy(key: string) {
      let currentKey;
      if (key === 'Vouchers Code') {
        currentKey = 'value';
      } else if (key === 'Time Valid') {
        currentKey = 'time_valid';
      } else if (key === 'Source') {
        currentKey = 'source';
      } else {
        currentKey = 'created';
      }
      if (this.sortKey === currentKey) {
        // Toggle the sort direction if the same key is clicked again
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = currentKey;
        this.sortDirection = 'asc';
      }
    },
    clearRows() {
      this.paginatedTableData.filter((row) => (row.selected = false));
      this.selectAll = false;
    },
    showButtons() {
      return this.paginatedTableData.some((row) => row.selected === true);
    },
    async deleteVouchers() {
      try {
        this.deletingVouchers = true;
        const voucher_ids = this.selectedRows.map((voucher) => voucher.value);

        await instance
          .delete('vouchers-delete', {
            data: { voucher_ids: voucher_ids }, // Include data in the request configuration
          })
          .then(() => {
            this.$toast.success('Voucher(s) deleted successfully', {
              position: 'top',
            });
            this.voucherList = this.voucherList.filter(
              (voucher) => !voucher_ids.includes(voucher.value)
            );
            this.deletingVouchers = false;
          });
      } catch (e: any) {
        this.deletingVouchers = false;
        this.$toast.error('Could not delete the vouchers', {
          position: 'top',
        });
      }
    },
  },

  created() {
    this.getVouchersData();
  },

  computed: {
    ...mapGetters(['loggedInUser']),
    today() {
      return moment(Date.now()).format('MMMM Do');
    },
    filteredTableData(): any[] {
      if (!this.searchQuery) {
        // If search query is empty, return all data
        return this.voucherList;
      }

      // Filter table data based on search query
      const filteredData = this.voucherList.filter((voucher) => {
        // Convert item values to lowercase for case-insensitive search
        const time_valid = voucher.time_valid.toString().toLowerCase();
        const value = voucher.value.toString().toLowerCase();
        const source = voucher.source.toString().toLowerCase();
        const created = this.createdAt(
          voucher.created.toString().toLowerCase()
        );
        const query = this.searchQuery.toLowerCase();

        // Check if name or age contain the search query
        return (
          time_valid.includes(query) ||
          value.includes(query) ||
          source.includes(query) ||
          created?.includes(query)
        );
      });

      return filteredData;
    },

    sortedTableData(sortKey: any): any[] {
      if (!this.sortKey) {
        // If sort key is empty, return the filtered data as-is
        return this.filteredTableData;
      }

      // Sort the filtered table data based on the sort key and direction
      const sortedData = this.filteredTableData.slice().sort((a, b) => {
        const aValue = a[this.sortKey];
        const bValue = b[this.sortKey];

        // Perform the actual comparison based on the data type
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return (
            aValue.localeCompare(bValue) *
            (this.sortDirection === 'asc' ? 1 : -1)
          );
        } else {
          return (aValue - bValue) * (this.sortDirection === 'asc' ? 1 : -1);
        }
      });

      return sortedData;
    },
    paginatedTableData(): any[] {
      const startIndex = (this.page - 1) * this.perSize;
      const endIndex = startIndex + this.perSize;
      return this.sortedTableData.slice(startIndex, endIndex);
    },
  },
});
</script>
