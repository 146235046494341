<template>
        <div class="flex flex-col md:block hidden my-6">
            <div class="flex items-center">
                <i class="fa-regular fa-user w-[1rem] h-[1rem] rounded mr-2"></i>
                <span class="font-poppins font-semibold md:text-[1.062rem] text-[0.90rem] text-routersTextColor">Active
                    Micro Franchisees</span>
            </div>

        </div>

    <div class="bg-white w-full rounded-[0.938rem] py-3 md:px-6 md:py-6 px-3 shadow overflow-auto relative">

        <!-- tabs -->
        <div class="tabs-block" id="tabs-block">
            <div class="mb-3">
                <ul class="flex flex-wrap -mb-px text-sm font-medium text-center mb-2" id="myTab"
                    data-tabs-toggle="#myTabContent" role="tablist">
                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab1" data-tabs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="false"
                            v-on:click="activeTab = 'Aggregator_Deposit'"
                            v-bind:class="[activeTab === 'Aggregator_Deposit' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">
                            Aggregator Deposit</button>
                    </li>
                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab2" data-tabs-target="#dashboard"
                            type="button" role="tab" aria-controls="dashboard" aria-selected="false"
                            v-on:click="activeTab = 'Initiate_TXN_Rollback'"
                            v-bind:class="[activeTab === 'Initiate_TXN_Rollback' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">Initiate
                            TXN Rollback</button>
                    </li>
                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab3" data-tabs-target="#settings"
                            type="button" role="tab" aria-controls="settings" aria-selected="false"
                            v-on:click="activeTab = 'Approve_TXN_Rollback'"
                            v-bind:class="[activeTab === 'Approve_TXN_Rollback' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">Approve
                            TXN Rollback</button>
                    </li>
                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab4" data-tabs-target="#settings"
                            type="button" role="tab" aria-controls="settings" aria-selected="false"
                            v-on:click="activeTab = 'Commission_Payments'"
                            v-bind:class="[activeTab === 'Commission_Payments' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">Commission
                            Payments</button>
                    </li>
                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab5" data-tabs-target="#settings"
                            type="button" role="tab" aria-controls="settings" aria-selected="false"
                            v-on:click="activeTab = 'MF_Deposit'"
                            v-bind:class="[activeTab === 'MF_Deposit' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">MF
                            Deposit</button>
                    </li>
                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab6" data-tabs-target="#settings"
                            type="button" role="tab" aria-controls="settings" aria-selected="false"
                            v-on:click="activeTab = 'MF_Credit_Approval'"
                            v-bind:class="[activeTab === 'MF_Credit_Approval' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">MF
                            Credit Approval</button>
                    </li>
                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab7" data-tabs-target="#settings"
                            type="button" role="tab" aria-controls="settings" aria-selected="false"
                            v-on:click="activeTab = 'MF_Credit_Suspend'"
                            v-bind:class="[activeTab === 'MF_Credit_Suspend' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">MF
                            Credit Suspend</button>
                    </li>
                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab8" data-tabs-target="#settings"
                            type="button" role="tab" aria-controls="settings" aria-selected="false"
                            v-on:click="activeTab = 'MF_Deposit_SMS'"
                            v-bind:class="[activeTab === 'MF_Deposit_SMS' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">MF
                            Deposit SMS</button>
                    </li>

                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab9" data-tabs-target="#settings"
                            type="button" role="tab" aria-controls="settings" aria-selected="false"
                            v-on:click="activeTab = 'MF_Penalties'"
                            v-bind:class="[activeTab === 'MF_Penalties' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">MF
                            Penalties</button>
                    </li>

                    <li class="mr-2">
                        <button class="inline-block p-4 py-2 rounded-md w-full" id="tab10" data-tabs-target="#settings"
                            type="button" role="tab" aria-controls="settings" aria-selected="false"
                            v-on:click="activeTab = 'Reimbursement'"
                            v-bind:class="[activeTab === 'Reimbursement' ? 'bg-light text-[#4e8d6e]' : 'bg-[transparent]']">Reimbursement</button>
                    </li>
                </ul>
            </div>
            <div id="myTabContent" class="pt-2">
                <AggregatorDeposit v-if="activeTab === 'Aggregator_Deposit'"></AggregatorDeposit>
                  <InitiateTransactionRollback v-if="activeTab === 'Initiate_TXN_Rollback'"></InitiateTransactionRollback>
                  <InitiateTransactionRollback v-if="activeTab === 'Approve_TXN_Rollback'"></InitiateTransactionRollback>
                  <CommissionPayments v-if="activeTab === 'Commission_Payments'"></CommissionPayments>
                  <MicroFranchiseeDeposit v-if="activeTab === 'MF_Deposit'"></MicroFranchiseeDeposit>
                  <CreditApproval v-if="activeTab === 'MF_Credit_Approval'"></CreditApproval>
                  <CreditSuspend v-if="activeTab === 'MF_Credit_Suspend'"></CreditSuspend>
                  <SMSMessages v-if="activeTab === 'MF_Deposit_SMS'"></SMSMessages>
                  <MicroFranchiseePenalty v-if="activeTab === 'MF_Penalties'"></MicroFranchiseePenalty>
                  <MicroFranchiseeReimbursement v-if="activeTab === 'Reimbursement'"></MicroFranchiseeReimbursement>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import axios from "axios";
import { dashboardHeadericon } from "@/helpers/stub_data/dashboard_routers";
import { mapGetters } from "vuex";
import instance from "@/axios-interceptor";
import AggregatorDeposit from "./AggregatorDeposit.vue";
import InitiateTransactionRollback from "./InitiateTransactionRollback.vue";
import CommissionPayments from "./CommissionPayments.vue";
import MicroFranchiseeDeposit from "./MicroFranchiseeDeposit.vue";
import MicroFranchiseePenalty from "./MicroFranchiseePenalty.vue";
import MicroFranchiseeReimbursement from "./MicroFranchiseeReimbursment.vue";
import SMSMessages from "./SMSMessages.vue";
import CreditApproval from "./CreditApproval.vue";
import CreditSuspend from "./CreditSuspend.vue";
export default defineComponent({
    name: "floatTransferPage",
    components: {
        AggregatorDeposit,
        InitiateTransactionRollback,
        CommissionPayments,
        MicroFranchiseeDeposit,
        MicroFranchiseePenalty,
        MicroFranchiseeReimbursement,
        SMSMessages,
        CreditApproval,
        CreditSuspend
  },
    setup() {

        const accountsTableHeader = ref([
            "Name",
            "Email",
            "Phone",
            "Status",
            "Action",
        ]);

        const selectedSubscription = ref(false);

        return {
            accountsTableHeader,
        };
    },
    data() {
        return {
            activeTab: 'Aggregator_Deposit',
        };
    },

    methods: {

    },
    computed: {

    },

});
</script>