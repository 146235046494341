import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../assets/plus-icon.png'
import _imports_1 from '../../assets/search-icon.png'


const _hoisted_1 = { class: "w-full my-3" }
const _hoisted_2 = { class: "bg-white w-full min-h-[25rem] rounded-[0.938rem] py-3 md:px-6 px-3 shadow overflow-auto" }
const _hoisted_3 = { class: "md:w-[30%] w-[50%]" }
const _hoisted_4 = { class: "relative block" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  key: 0,
  class: "bg-[#F3F6F9] w-[1.8rem] h-[1.8rem] rounded-[0.337rem] flex items-center justify-center"
}
const _hoisted_7 = { class: "flex flex-col ml-3" }
const _hoisted_8 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_11 = { class: "text-[#464E5F]" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 0,
  class: "text-[#efb300] px-1 rounded-sm bg-[#faedca]"
}
const _hoisted_14 = {
  key: 1,
  class: "text-[#26a403] px-1 rounded-sm bg-[#d5ffc9]"
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "flex items-center justify-end" }
const _hoisted_17 = { title: "Delete" }
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm"
}
const _hoisted_20 = { class: "spinner-container" }
const _hoisted_21 = {
  key: 0,
  class: "spinner"
}
const _hoisted_22 = { class: "w-full md:flex my-6 justify-between" }
const _hoisted_23 = { class: "bg-white md:w-[49%] min-h-[21.432rem] rounded-[0.938rem] py-4 px-6 shadow md:mb-0 mb-4 overflow-auto" }
const _hoisted_24 = { class: "w-full mt-6" }
const _hoisted_25 = { class: "flex items-center" }
const _hoisted_26 = {
  key: 0,
  class: "bg-[#F3F6F9] w-[1.8rem] h-[1.8rem] rounded-[0.337rem] flex items-center justify-center"
}
const _hoisted_27 = { class: "flex flex-col ml-3" }
const _hoisted_28 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_29 = { class: "flex flex-col" }
const _hoisted_30 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_31 = { class: "text-[#464E5F]" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = {
  key: 0,
  class: "text-[#efb300] px-1 rounded-sm bg-[#faedca]"
}
const _hoisted_34 = {
  key: 1,
  class: "text-[#26a403] px-1 rounded-sm bg-[#d5ffc9]"
}
const _hoisted_35 = { key: 1 }
const _hoisted_36 = { class: "flex items-center justify-end" }
const _hoisted_37 = { title: "Delete" }
const _hoisted_38 = ["src"]
const _hoisted_39 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm"
}
const _hoisted_40 = { class: "spinner-container" }
const _hoisted_41 = {
  key: 0,
  class: "spinner"
}
const _hoisted_42 = { class: "bg-white md:w-[49%] min-h-[21.432rem] rounded-[0.938rem] py-4 px-6 shadow md:mb-0 mb-3 overflow-auto" }
const _hoisted_43 = { class: "w-full mt-6" }
const _hoisted_44 = { class: "flex items-center" }
const _hoisted_45 = {
  key: 0,
  class: "bg-[#F3F6F9] w-[1.8rem] h-[1.8rem] rounded-[0.337rem] flex items-center justify-center"
}
const _hoisted_46 = { class: "flex flex-col ml-3" }
const _hoisted_47 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_48 = { class: "flex flex-col" }
const _hoisted_49 = { class: "capitalize text-[#464E5F] mb-0.5" }
const _hoisted_50 = { class: "text-[#464E5F]" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = {
  key: 0,
  class: "text-[#efb300] px-1 rounded-sm bg-[#faedca]"
}
const _hoisted_53 = {
  key: 1,
  class: "text-[#26a403] px-1 rounded-sm bg-[#d5ffc9]"
}
const _hoisted_54 = { key: 1 }
const _hoisted_55 = { class: "flex items-center justify-end" }
const _hoisted_56 = { title: "Delete" }
const _hoisted_57 = ["src"]
const _hoisted_58 = {
  key: 1,
  class: "w-full text-center text-[12px] text-[#afafaf] mt-4 text-sm"
}
const _hoisted_59 = { class: "spinner-container" }
const _hoisted_60 = {
  key: 0,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_newTicketForm = _resolveComponent("newTicketForm")!
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_DashboardTableTitle = _resolveComponent("DashboardTableTitle")!
  const _component_SmeTable = _resolveComponent("SmeTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardHeader, {
      title: "Support Tickets",
      description: "",
      icon: _ctx.icon.SupportTickets
    }, {
      aside: _withCtx(() => [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTicketModal = true)),
          class: "bg-[#4E8D6D] md:px-6 px-4 py-2 rounded-[0.327rem] flex items-center"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "font-poppins font-semibold md:text-[1rem] text-[0.8rem] text-[#fff] flex" }, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "md:w-[1rem] w-[0.6rem] md:h-[1rem] h-[0.6rem] mt-1 mr-2",
              alt: ""
            }),
            _createTextVNode(" Add Ticket")
          ], -1)
        ])),
        (_ctx.showTicketModal)
          ? (_openBlock(), _createBlock(_component_newTicketForm, {
              key: 0,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["icon"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.propLoaded)
          ? (_openBlock(), _createBlock(_component_DashboardTableTitle, {
              key: 0,
              perPageItems: _ctx.perSize,
              pageNumber: _ctx.page,
              ApiData: _ctx.apiData,
              onHandleNext: _ctx.handlleNext,
              onHandlePrev: _ctx.handlePrev
            }, {
              section: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, [
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "sr-only" }, "Search", -1)),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "absolute inset-y-0 left-0 flex items-center pl-2" }, [
                      _createElementVNode("img", {
                        src: _imports_1,
                        alt: "",
                        class: "w-[0.8rem] h-[0.8rem]"
                      })
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchQuery) = $event)),
                      class: "font-normal block w-full rounded-md py-2 pl-9 pr-3 placeholder:text-[#374957] md:text-[0.9rem] text-[0.7rem] placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full focus:ring-1",
                      placeholder: "Search Tickets",
                      type: "text",
                      name: "search"
                    }, null, 512), [
                      [_vModelText, _ctx.searchQuery]
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["perPageItems", "pageNumber", "ApiData", "onHandleNext", "onHandlePrev"]))
          : _createCommentVNode("", true),
        _createVNode(_component_SmeTable, {
          tableName: _ctx.tableName,
          tableHeaderData: _ctx.routerTableHeaders
        }, {
          tableBodyData: _withCtx(() => [
            (_ctx.paginatedTableData.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.paginatedTableData, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_5, [
                        (item.id)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(item.id), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("span", _hoisted_10, _toDisplayString(item.message), 1)
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.createdAt(item.created)), 1)
                    ]),
                    (item.id)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_12, [
                          (item.is_open)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Open"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_14, "Closed"))
                        ]))
                      : _createCommentVNode("", true),
                    (item.id)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerTableIcons, (item) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: item,
                                class: "bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2"
                              }, [
                                _createElementVNode("button", _hoisted_17, [
                                  _createElementVNode("img", {
                                    src: item,
                                    alt: "africa",
                                    class: "w-[1.125rem] h-[1.067rem]"
                                  }, null, 8, _hoisted_18)
                                ])
                              ]))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              : (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[6] || (_cache[6] = [
                  _createElementVNode("b", null, "No Record found", -1)
                ])))
          ]),
          _: 1
        }, 8, ["tableName", "tableHeaderData"]),
        _createElementVNode("div", _hoisted_20, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "font-poppins font-semibold text-[0.8rem] text-[#212121]" }, "ROUTER DEPARTMENT", -1)),
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_SmeTable, {
              tableName: _ctx.tableName,
              tableHeaderData: _ctx.routerTableHeaders
            }, {
              tableBodyData: _withCtx(() => [
                (_ctx.supportRouterTicketList.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.supportRouterTicketList, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_25, [
                            (item.id)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_26))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("span", _hoisted_28, _toDisplayString(item.id), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("span", _hoisted_30, _toDisplayString(item.message), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.createdAt(item.created)), 1)
                        ]),
                        (item.id)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_32, [
                              (item.is_open)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_33, "Open"))
                                : (_openBlock(), _createElementBlock("span", _hoisted_34, "Closed"))
                            ]))
                          : _createCommentVNode("", true),
                        (item.id)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_35, [
                              _createElementVNode("div", _hoisted_36, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerTableIcons, (item) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: item,
                                    class: "bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2"
                                  }, [
                                    _createElementVNode("button", _hoisted_37, [
                                      _createElementVNode("img", {
                                        src: item,
                                        alt: "africa",
                                        class: "w-[1.125rem] h-[1.067rem]"
                                      }, null, 8, _hoisted_38)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  : (_openBlock(), _createElementBlock("div", _hoisted_39, _cache[7] || (_cache[7] = [
                      _createElementVNode("b", null, "No Record found", -1)
                    ])))
              ]),
              _: 1
            }, 8, ["tableName", "tableHeaderData"]),
            _createElementVNode("div", _hoisted_40, [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_41))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_42, [
          _cache[10] || (_cache[10] = _createElementVNode("span", { class: "font-poppins font-semibold text-[0.8rem] text-[#212121]" }, "OTHERS", -1)),
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_SmeTable, {
              tableName: _ctx.tableName,
              tableHeaderData: _ctx.routerTableHeaders
            }, {
              tableBodyData: _withCtx(() => [
                (_ctx.supportOtheTicketList.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.supportOtheTicketList, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        class: "table-layout-tr py-2 my-3 uppercase rounded-[0.337rem] flex items-center justify-between text-[#B5B5C3] font-poppins font-semibold text-[0.673rem]"
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_44, [
                            (item.id)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_45))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_46, [
                              _createElementVNode("span", _hoisted_47, _toDisplayString(item.id), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_48, [
                            _createElementVNode("span", _hoisted_49, _toDisplayString(item.message), 1)
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.createdAt(item.created)), 1)
                        ]),
                        (item.id)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_51, [
                              (item.is_open)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_52, "Open"))
                                : (_openBlock(), _createElementBlock("span", _hoisted_53, "Closed"))
                            ]))
                          : _createCommentVNode("", true),
                        (item.id)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_54, [
                              _createElementVNode("div", _hoisted_55, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerTableIcons, (item) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: item,
                                    class: "bg-[#F3F6F9] w-[2rem] h-[2rem] rounded-[0.375rem] flex items-center justify-center ml-2"
                                  }, [
                                    _createElementVNode("button", _hoisted_56, [
                                      _createElementVNode("img", {
                                        src: item,
                                        alt: "africa",
                                        class: "w-[1.125rem] h-[1.067rem]"
                                      }, null, 8, _hoisted_57)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  : (_openBlock(), _createElementBlock("div", _hoisted_58, _cache[9] || (_cache[9] = [
                      _createElementVNode("b", null, "No Record found", -1)
                    ])))
              ]),
              _: 1
            }, 8, ["tableName", "tableHeaderData"]),
            _createElementVNode("div", _hoisted_59, [
              (_ctx.isLoading )
                ? (_openBlock(), _createElementBlock("div", _hoisted_60))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ], 64))
}