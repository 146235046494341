import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/ared-africa.png'


const _hoisted_1 = { class: "top-0 z-20" }
const _hoisted_2 = { class: "bg-white max-h-[4.188rem] flex py-3 fixed w-full" }
const _hoisted_3 = { class: "flex w-full justify-between items-center lg:px-[40px] pr-[20px] pl-2" }
const _hoisted_4 = {
  type: "button",
  class: "lg:min-w-[30rem] sm:min-w-[15rem] min-w-[12rem] lg:h-[2.938rem] h-[2rem] bg-light rounded-md text-primary font-poppins font-semibold lg:text-[1.062rem] sm:text-[0.8rem] text-[0.7rem]"
}
const _hoisted_5 = { class: "w-1/3 bg-[#f24236] flex justify-center ml-6 rounded-md" }
const _hoisted_6 = {
  key: 0,
  class: "text-white text-sm py-3"
}
const _hoisted_7 = {
  key: 1,
  class: "text-white text-sm py-3"
}
const _hoisted_8 = { class: "min-w-[10vw] flex items-center justify-between relative md:flex ml-auto mr-[20px]" }
const _hoisted_9 = { class: "flex flex-col items-end justify-end mr-2" }
const _hoisted_10 = { class: "font-semibold text-[0.75rem] font-poppins text-black" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 0,
  class: "z-10 absolute w-[10vw] min-h-[5vh] bg-white shadow top-[3.188rem] right-0 items-left justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "lg:w-[8vw] w-[60px] md:flex items-center justify-center" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "africa",
          class: "w-[3.188rem]"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.loggedInUser.partner?.name) + " on " + _toDisplayString(_ctx.loggedInUser.partner?.plan?.name) + " Plan ", 1),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.offlineRouters.length === 1)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.offlineRouters.length) + " router/box is offline. ", 1),
                _createVNode(_component_router_link, {
                  to: "/routers",
                  class: "underline"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Click here to see ")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.offlineRouters.length > 1)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                _createTextVNode(_toDisplayString(_ctx.offlineRouters.length) + " routers/boxes are offline. ", 1),
                _createVNode(_component_router_link, {
                  to: "/routers",
                  class: "underline"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Click here to see ")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "flex flex-row items-center z-10",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleOpenOrCloseModal && _ctx.handleOpenOrCloseModal(...args)), ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_9, [
              _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-textLight font-medium text-[0.75rem] font-poppins" }, "Welcome", -1)),
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.loggedInUser.full_name), 1)
            ]),
            _createElementVNode("img", {
              src: _ctx.loggedInUser.profile_pic
              ? _ctx.loggedInUser.profile_pic
              : require('../../assets/user-avatar.svg')
              ,
              alt: "africa",
              class: "w-[2rem] h-[2rem] rounded-[1rem]"
            }, null, 8, _hoisted_11)
          ]),
          (_ctx.isOpenModal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.profile && _ctx.profile(...args))),
                  class: "px-4 py-2 hover:bg-light hover:text-primary border-b-1 text-left w-full font-bold text-[0.75rem] font-poppins"
                }, " My Profile "),
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)), ["stop"])),
                  class: "px-4 py-2 hover:bg-light hover:text-primary w-full text-left font-bold text-[0.75rem] font-poppins"
                }, " Sign Out ")
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          id: "close-btn",
          class: "mt-2 flex items-center justify-between relative lg:hidden toggle-btn",
          onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onToggle && _ctx.onToggle(...args)), ["stop"]))
        }, [
          _createElementVNode("span", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleActive && _ctx.toggleActive(...args))),
            class: _normalizeClass([_ctx.isToggleicon ? 'open' : 'close'])
          }, null, 2)
        ])
      ])
    ])
  ]))
}